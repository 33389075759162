import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { roomListsInProgress } from "../../../appRedux/Ward/WardAction";
import TableComponent from "../../../components/Table/TableComponent";

const RoomList = ({
  selectedRoomId,
  onRoomSelect,
  roomList,
  setAllRomDetails,
  handleRoomPage,
  roomBookedData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const roomData = useSelector((state) => state.ward.roomList);

  const handleRoomSelect = (record) => {
    console.log("record", record);
    console.log(
      "selectedRoomId === record.id",
      selectedRoomId,
      record?.id,
      selectedRoomId === record.id
    );
    if (onRoomSelect) {
      onRoomSelect(record.id);
    }
    setAllRomDetails(record);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Room Number",
      dataIndex: "room_no",
      key: "room_no",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => (
        <Row className="table-button">
          <Col className="button-edit">
            {selectedRoomId === record.id ? (
              <ButtonComponent
                className={"secondary-color"}
                label={"Selected Room"}
                onClick={() => handleRoomSelect(record)}
              />
            ) : (
              <ButtonComponent
                type="primary"
                label={"Select Room"}
                onClick={() => handleRoomSelect(record)}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (roomData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [roomData]);

  console.log("roomList", roomList);
  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = roomBookedData?.data?.totalRecords;
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(roomList);
    } else {
      const newData = roomList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  // const handlePage = (page) => {
  //   const payload = { pagination: "true", pageNo: page, pageSize: "10" };
  //   dispatch(getWardListInProgress(payload));
  // };
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.roomList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : roomList}
          rowselector={handleSelectChange}
          itemList={roomList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handleRoomPage(page),
          }}
          field={"ward_name"}
          documentTitle="Ward List"
          disableTableHeaderButtons={true}
        />
      </Spin>
    </Card>
  );
};

export default RoomList;
