import React, { useEffect, useState } from "react";
import { getAllQualificationsEffect } from "../../../appRedux/admin/Doctor/sagas/effects/doctorEffect";
import { OrganisationEffect } from "../../../appRedux/doctor/sagas/effects/OrganisationEffect";
import { getQualificationListEffect } from "../../../appRedux/doctor/sagas/effects/QualificationEffect";
import DoctorSelectBox from "./DoctorSelectBox";

const QualificationSelector = ({
  placeholder = "Select Qualification",
  label = "",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = async () => {
    try {
      const { data } = await getQualificationListEffect();
      const qualList = data?.data;
      let options = qualList.map((row) => ({
        label: row.name,
        value: row.name,
      }));
      setOptions(options);
    } catch (error) {
      setOptions([]);
    }
  };

  return (
    <DoctorSelectBox
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
    />
  );
};

export default QualificationSelector;
