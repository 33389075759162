import {
  ADD_PRESCRIPTION_IN_ERROR,
  ADD_PRESCRIPTION_IN_PROGRESS,
  ADD_PRESCRIPTION_IN_SUCCESS,
  CANCEL_PRESCRIPTION_IN_ERROR,
  CANCEL_PRESCRIPTION_IN_PROGRESS,
  CANCEL_PRESCRIPTION_IN_SUCCESS,
  PRESCRIPTION_BY_PATIENT_IN_ERROR,
  PRESCRIPTION_BY_PATIENT_IN_PROGRESS,
  PRESCRIPTION_BY_PATIENT_IN_SUCCESS,
  PRESCRIPTION_DATA_IN_ERROR,
  PRESCRIPTION_DATA_IN_PROGRESS,
  PRESCRIPTION_DATA_IN_SUCCESS,
  PRESCRIPTION_LIST_IN_ERROR,
  PRESCRIPTION_LIST_IN_PROGRESS,
  PRESCRIPTION_LIST_IN_SUCCESS,
  SAVE_PRESCRIPTION_IN_ERROR,
  SAVE_PRESCRIPTION_IN_PROGRESS,
  SAVE_PRESCRIPTION_IN_SUCCESS,
  SET_PRESCRIPTION_ADD,
  SET_PRESCRIPTION_SUBMIT,
  SUBMIT_PRESCRIPTION_IN_ERROR,
  SUBMIT_PRESCRIPTION_IN_PROGRESS,
  SUBMIT_PRESCRIPTION_IN_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  addprescription: { ...defaultStatus },
  prescriptionList: { ...defaultStatus },
  prescriptionGetByPatient: { ...defaultStatus },
  prescriptionData: { ...defaultStatus },
  savePrescription: { ...defaultStatus },
  cancelPrescription: { ...defaultStatus },
  submitPrescription: { ...defaultStatus },
  getTablets: { ...defaultStatus },
};
const prescriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_PRESCRIPTION_IN_PROGRESS:
      return {
        ...state,
        addprescription: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_PRESCRIPTION_IN_SUCCESS:
      return {
        ...state,
        addprescription: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_PRESCRIPTION_IN_ERROR:
      return {
        ...state,
        addprescription: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case PRESCRIPTION_LIST_IN_PROGRESS:
      return {
        ...state,
        prescriptionList: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case PRESCRIPTION_LIST_IN_SUCCESS:
      return {
        ...state,
        prescriptionList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case PRESCRIPTION_LIST_IN_ERROR:
      return {
        ...state,
        prescriptionList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case PRESCRIPTION_DATA_IN_PROGRESS:
      return {
        ...state,
        prescriptionData: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case PRESCRIPTION_DATA_IN_SUCCESS:
      return {
        ...state,
        prescriptionData: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case PRESCRIPTION_DATA_IN_ERROR:
      return {
        ...state,
        prescriptionData: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case PRESCRIPTION_BY_PATIENT_IN_PROGRESS:
      return {
        ...state,
        prescriptionGetByPatient: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case PRESCRIPTION_BY_PATIENT_IN_SUCCESS:
      return {
        ...state,
        prescriptionGetByPatient: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case PRESCRIPTION_BY_PATIENT_IN_ERROR:
      return {
        ...state,
        prescriptionGetByPatient: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case SAVE_PRESCRIPTION_IN_PROGRESS:
      return {
        ...state,
        savePrescription: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case SAVE_PRESCRIPTION_IN_SUCCESS:
      return {
        ...state,
        savePrescription: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case SAVE_PRESCRIPTION_IN_ERROR:
      return {
        ...state,
        savePrescription: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case CANCEL_PRESCRIPTION_IN_PROGRESS:
      return {
        ...state,
        cancelPrescription: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case CANCEL_PRESCRIPTION_IN_SUCCESS:
      return {
        ...state,
        cancelPrescription: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case CANCEL_PRESCRIPTION_IN_ERROR:
      return {
        ...state,
        cancelPrescription: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case SUBMIT_PRESCRIPTION_IN_PROGRESS:
      return {
        ...state,
        submitPrescription: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case SUBMIT_PRESCRIPTION_IN_SUCCESS:
      return {
        ...state,
        submitPrescription: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case SUBMIT_PRESCRIPTION_IN_ERROR:
      return {
        ...state,
        submitPrescription: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case SET_PRESCRIPTION_SUBMIT:
      return {
        ...state,
        submitPrescription: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case SET_PRESCRIPTION_ADD:
      return {
        ...state,
        addprescription: {
          ...defaultStatus,
          ...payload,
          success: false,
        },
      };

    default:
      return state;
  }
};

export default prescriptionReducer;
