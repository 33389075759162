import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Form, Modal, Row } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import BillingPackageForm from "./BillingPackageForm";
import Auxiliary from "../../../util/Auxiliary";
import { updatePackageInProgress } from "../../../appRedux/doctor/actions/billingPackage";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "../../../constants/Routes";
import { useHistory } from "react-router-dom";

const BillingPackageEdit = (props) => {
  const editBillingData = props?.location?.state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateEntries, setDuplicateEntries] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const [navigate, setNavigate] = useState(false);
  const history = useHistory();
  const { billingPackages } = useSelector((state) => state);
  const organization_id = localStorage.getItem("org_id");

  const onFinish = (datas) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    console.log("datassubPackages", datas?.subPackages);

    const subPackagesArray = datas?.subPackages
      .filter(subPackage => subPackage !== null && Object.keys(subPackage).length > 0)
      .map(key => ({
        ...key,
        sub_package_id: uuidRegex.test(key?.package_name) ? key.package_name : key?.sub_package_id,
        status: 1,
      }));

    // Check for duplicate sub_package_id
    const subPackageIds = subPackagesArray.map(subPackage => subPackage.sub_package_id);
    const duplicates = subPackageIds.filter((item, index) => subPackageIds.indexOf(item) !== index);
    const hasDuplicates = duplicates.length > 0;

    if (hasDuplicates) {
      const duplicateNames = subPackagesArray
        .filter(subPackage => duplicates.includes(subPackage.sub_package_id))
        .map(subPackage => subPackage.package_name);
      console.error("Duplicate sub_package_id found");
      setDuplicateEntries(duplicateNames);
      setIsModalOpen(true);
    } else {
      const updatedValues = [
        {
          ...datas,
          id: editBillingData?.id,
          organization_id: organization_id,
          subPackages: subPackagesArray,
        },
      ];

      console.log("updatedValues", updatedValues);
      dispatch(updatePackageInProgress({ package_details: updatedValues }));
      setNavigate(true);
    }
  };

  useEffect(() => {
    if (billingPackages?.updatePackages?.success && navigate) {
      history.push(APP_ROUTES.BILLINGPACKAGES);
      setNavigate(false);
    }
  }, [billingPackages?.updatePackages?.success, navigate, history]);

  return (
    <Auxiliary>
      <Card>
        <Row>
          <Col span={24}>
            <h1 className="text">
              <IntlMessages id={"Addnew.billingpackage.label.editpackage"} />
            </h1>
          </Col>
          <Divider />
        </Row>
        <BillingPackageForm
          onFinish={onFinish}
          form={form}
          editBillingData={editBillingData}
          type="editBilling"
        />
      </Card>
      <Modal
        title="Duplicate Entry"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Duplicate entries found:</p>
      </Modal>
    </Auxiliary>
  );
};

export default BillingPackageEdit;
