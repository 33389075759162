import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddPurchaseReturn from "./AddPurchaseReturn";

const ViewPurchaseReturn = () => {
  const location = useLocation();
  const purchaseData = location.state?.purchaseReturnData;
  const columns = [
    {
      title: "ProductName",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Return Quantity",
      dataIndex: "returnQuantity",
      key: "returnQuantity",
    },
    {
      title: "Packing Unit ",
      dataIndex: "packingUnit",
      key: "packingUnit",
    },

    {
      title: "Purchase Price",
      dataIndex: "purchasePrice",
      key: "purchasePrice",
    },

    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Return Amount",
      dataIndex: "returnAmount",
      key: "returnAmount",
    },
  ];
  return <AddPurchaseReturn viewPurchaseData={purchaseData} viewData="true" />;
};

export default ViewPurchaseReturn;
