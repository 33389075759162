import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addBlockInProgress,
  editBlockInProgress,
} from "../../../appRedux/Ward/WardAction";

const AddBlock = ({ editBlock, editBlockData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [blockStatusValue, setBlockStatusValue] = useState("");
  const addBlockData = useSelector((state) => state?.ward?.addBlock);
  const editBlockResponse = useSelector((state) => state?.ward?.editBlock);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    console.log("datas", datas);
    const payload = datas;

    if (editBlock) {
      dispatch(editBlockInProgress({ id: editBlockData?.id, payload }));
    } else {
      dispatch(addBlockInProgress({ payload }));
    }
  };

  const [Loading, setLoading] = useState(false);

  const changeStatus = (value) => {
    setBlockStatusValue(value);
  };

  const statusList = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "2" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (editBlock) {
      form.setFieldsValue(editBlockData);
    }
  }, [editBlock]);

  useEffect(() => {
    if (addBlockData?.success || editBlockResponse?.success) {
      history.push("/block");
    }
  }, [addBlockData, editBlockResponse]);
  useEffect(() => {
    if (addBlockData?.inProgress || editBlockResponse?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addBlockData, editBlockResponse]);

  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {!editBlock ? (
                    <IntlMessages id={"label.addBlock"} />
                  ) : (
                    <IntlMessages id={"label.editBlock"} />
                  )}
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"block_number"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.block_number"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.block_number"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"block_name"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.block_name"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.block_name"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={
                        <IntlMessages id={"Addnew.ward.label.block_status"} />
                      }
                      name={"block_status"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.block_status"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Block Status"
                        value={blockStatusValue}
                        options={statusList}
                        onChange={changeStatus}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.BLOCK_LIST}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editBlock ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddBlock;
