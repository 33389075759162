import axios from "axios";

let organisationInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});
const OrganisationEffect = (page) => {
  const token = localStorage.getItem("user_id");
  return organisationInstance.request({
    url: "/organizations/list",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {
      pageNo: page,
      pageSize: 10,
      pagination_required: true,
    },
  });
};
const getOrganisationNameEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return organisationInstance.request({
    url: `/organizations/details/${formData}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
const AddOrganisationEffect = (formData) => {
  const token = localStorage.getItem("user_id");

  return organisationInstance.request({
    url: "/admin/addOrganizations",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
const DeleteOrganisationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return organisationInstance.request({
    url: `/admin/deleteOrganizations/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
const UpdateOrganisationEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");
  return organisationInstance.request({
    url: `/admin/updateOrganizations/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export {
  OrganisationEffect,
  getOrganisationNameEffect,
  AddOrganisationEffect,
  DeleteOrganisationEffect,
  UpdateOrganisationEffect,
};
