import React from "react";
import { Card, Form, Spin } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Auxiliary from "../../../util/Auxiliary";

import {
  TabletsByOrgInProgress,
  getTabletInProgress,
  updateTabletsInProgress,
} from "../../../appRedux/doctor/actions/Tablets";

import TabletsForm from "./TabletsForm";
import moment from "moment";
import { getDoctorInProgress } from "../../../appRedux/doctor/actions/doctor";
import { useHistory } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

const EditTablet = (props) => {
  const EditData = props.location.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const TabletId = EditData?.id;
  const roleID = parseInt(localStorage.getItem("role_id"));
  const [navigate, setNavigate] = useState(false);
  const TabletData = useSelector((state) => state?.tablets);
  const getTablet = TabletData?.getTablet;
  const GetTabletList = TabletData?.getTablet?.data?.data;
  const orgId = useSelector(
    (state) => state?.doctorList?.getDoctor?.data?.data?.[0]?.organization_id
  );
  const updateData = useSelector((state) => state?.tablets?.updateTablet);
  const onFinish = (datas) => {
    var e_date = moment(datas.e_date.toString()).format("D/MM/YYYY");
    var add_date = moment(datas.add_date.toString()).format("D/MM/YYYY");
    dispatch(
      updateTabletsInProgress({
        medicine_name: datas.medicine_name,
        category: datas.category,
        price: datas.price,
        box: datas.box,
        s_price: datas.s_price,
        quantity: datas.quantity,
        generic: datas.generic,
        company: datas.company,
        effects: datas.effects,
        e_date: e_date,
        add_date: add_date,
        gst_value: datas.gst_value,
        gst_percent: datas.gst_percent,
        medicine_type: datas.medicine_type,
        s_price: datas.s_price,
        reorder_quantity: datas.reorder_quantity,
        product_code: datas.product_code,
        hsn_code: datas.hsn_code,
        product_unit: datas.product_unit,
        minimum_order_quantity: datas.minimum_order_quantity,
        product_type: datas.product_type,
        organization_id: EditData?.organization_id,
        status: 1,
        TabletId: TabletId,
      })
    );
    setNavigate(true);
  };
  useEffect(() => {
    const page = 1;
    if (roleID === 2) {
      dispatch(TabletsByOrgInProgress(page));
    } else {
      dispatch(getTabletInProgress(page));
    }
  }, []);
  // useEffect(() => {
  //   dispatch(getTabletInProgress(TabletId));
  // }, []);
  // useEffect(() => {
  //   const doctorId = localStorage.getItem("doctor_id");
  //   dispatch(getDoctorInProgress(doctorId));
  // }, []);

  useEffect(() => {
    if (EditData) {
      const getTabletDetails = EditData;
      form.setFieldsValue({ add_date: moment(getTabletDetails?.add_date) });
      form.setFieldsValue({ e_date: moment(getTabletDetails?.e_date) });
      delete getTabletDetails.add_date;
      delete getTabletDetails.e_date;
      form.setFieldsValue(getTabletDetails);
    }
  }, [form, EditData]);

  // useEffect(() => {
  //   if (getTablet?.success) {
  //     const getTabletDetails = GetTabletList?.[0];
  //     form.setFieldsValue({ add_date: moment(getTabletDetails?.add_date) });
  //     form.setFieldsValue({ e_date: moment(getTabletDetails?.e_date) });
  //     delete getTabletDetails.add_date;
  //     delete getTabletDetails.e_date;
  //     delete getTabletDetails.id;
  //     delete getTabletDetails.organization_id;
  //     delete getTabletDetails.createdAt;
  //     delete getTabletDetails.updatedAt;
  //     delete getTabletDetails.deleteAt;

  //     form.setFieldsValue(getTabletDetails);
  //   }
  // }, [form, getTablet?.success]);
  useEffect(() => {
    if (updateData?.success && navigate) {
      history.push({
        pathname: "/tabletlist",
        state: { tableUpdate: updateData?.success },
      });
      setNavigate(false);
    }
  }, [updateData?.success && navigate]);

  return (
    <Auxiliary>
      <Spin
        size="large"
        spinning={updateData?.inProgress}
        tip="Please wait a moment"
        style={{ margin: "20px" }}
      >
        <Card>
          <TabletsForm
            onFinish={onFinish}
            Button={"UPDATE MEDICINE"}
            form={form}
            icon={<ArrowRightOutlined />}
            title={"Edit Medicine"}
            HeaderButton={"BACK"}
          />
        </Card>
      </Spin>
    </Auxiliary>
  );
};

export default EditTablet;
