import { BLOOD_GROUP_IN_FAILURE, BLOOD_GROUP_IN_PROGRESS, BLOOD_GROUP_IN_SUCCESS, GET_PATIENT_BILLING_REPORT_IN_ERROR, GET_PATIENT_BILLING_REPORT_IN_PROGRESS, GET_PATIENT_BILLING_REPORT_IN_SUCCESS, GET_PATIENT_LIST_BY_ID_IN_FAILURE, GET_PATIENT_LIST_BY_ID_IN_PROGRESS, GET_PATIENT_LIST_BY_ID_IN_SUCCESS, PATIENT_ADD_LIST_IN_FAILURE, PATIENT_ADD_LIST_IN_PROGRESS, PATIENT_ADD_LIST_IN_SUCCESS, PATIENT_CSV_UPLOAD_IN_FAILURE, PATIENT_CSV_UPLOAD_IN_PROGRESS, PATIENT_CSV_UPLOAD_IN_SUCCESS, PATIENT_DELETE_LIST_IN_FAILURE, PATIENT_DELETE_LIST_IN_PROGRESS, PATIENT_DELETE_LIST_IN_SUCCESS, PATIENT_LIST_IN_FAILURE, PATIENT_LIST_IN_PROGRESS, PATIENT_LIST_IN_SUCCESS, PATIENT_UPDATE_LIST_IN_FAILURE, PATIENT_UPDATE_LIST_IN_PROGRESS, PATIENT_UPDATE_LIST_IN_SUCCESS } from "../../../../constants/ActionTypes";

const defaultStatus = {
    inProgress: false,
    success: false,
    error: false,
    data: null,
  };
const initialState = {
    getList: {...defaultStatus},
    addList: {...defaultStatus},
    deleteList: {...defaultStatus},
    updateList: {...defaultStatus},
    organisationList:{...defaultStatus},
    getListById:{...defaultStatus},
    csv:{...defaultStatus},
    bloodGrp:{...defaultStatus},
    getPatientBillingReport:{...defaultStatus}
  };
  const patientReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
      // list
      case PATIENT_LIST_IN_PROGRESS:
        console.log("patientprogressno",payload)
        return {
          ...state,
          getList: {...defaultStatus, ...payload ,inProgress: true},
        };
      case PATIENT_LIST_IN_SUCCESS:
        console.log("reducer patient",payload)
        return {
          ...state,
          getList: {...defaultStatus, ...payload, success: true},
        };
      case PATIENT_LIST_IN_FAILURE :
        return {
          ...state,
          getList: {...defaultStatus, ...payload, error: true},
        };
        // add
        case PATIENT_ADD_LIST_IN_PROGRESS:
          return {
            ...state,
            addList: {...defaultStatus, ...payload, inProgress: true},
          };
        case PATIENT_ADD_LIST_IN_SUCCESS:
          return {
            ...state,
            addList: {...defaultStatus, ...payload, success: true},
          };
        case PATIENT_ADD_LIST_IN_FAILURE :
          return {
            ...state,
            addList: {...defaultStatus, ...payload, error: true},
          };
          // delete
          case PATIENT_DELETE_LIST_IN_PROGRESS:
            return {
              ...state,
              deleteList: {...defaultStatus, ...payload, inProgress: true},
            };
          case PATIENT_DELETE_LIST_IN_SUCCESS:
            return {
              ...state,
              deleteList: {...defaultStatus, ...payload, success: true},
            };
          case PATIENT_DELETE_LIST_IN_FAILURE :
            return {
              ...state,
              deleteList: {...defaultStatus, ...payload, error: true},
            };
            // update
            case PATIENT_UPDATE_LIST_IN_PROGRESS:
              console.log("Patient updated data",payload)
              return {
                ...state,
                updateList: {...defaultStatus, ...payload, inProgress: true},
              };
            case PATIENT_UPDATE_LIST_IN_SUCCESS:
              return {
                ...state,
                updateList: {...defaultStatus, ...payload, success: true},
              };
            case PATIENT_UPDATE_LIST_IN_FAILURE :
              return {
                ...state,
                updateList: {...defaultStatus, ...payload, error: true},
                
              };
              // get patient list by id
              case GET_PATIENT_LIST_BY_ID_IN_PROGRESS:
                console.log("reducer patient list by id",payload)
                return {
                  ...state,
                  getListById: {...defaultStatus, ...payload, inProgress: true},
                };
              case GET_PATIENT_LIST_BY_ID_IN_SUCCESS:
                return {
                  ...state,
                  getListById: {...defaultStatus, ...payload, success: true},
                };
              case GET_PATIENT_LIST_BY_ID_IN_FAILURE :
                return {
                  ...state,
                  getListById: {...defaultStatus, ...payload, error: true},
                };
               
                // blood grp
              case BLOOD_GROUP_IN_PROGRESS:
                return {
                  ...state,
                  bloodGrp: {...defaultStatus, inProgress: true},
                };
              case BLOOD_GROUP_IN_SUCCESS:
                return {
                  ...state,
                  bloodGrp: {...defaultStatus, ...payload, success: true},
                };
              case BLOOD_GROUP_IN_FAILURE :
                return {
                  ...state,
                  bloodGrp: {...defaultStatus, ...payload, error: true},
                };
                 // Patient billing report
      case GET_PATIENT_BILLING_REPORT_IN_PROGRESS:
    
        return {
          ...state,
          getPatientBillingReport: {...defaultStatus, ...payload ,inProgress: true},
        };
      case GET_PATIENT_BILLING_REPORT_IN_SUCCESS:
      
        return {
          ...state,
          getPatientBillingReport: {...defaultStatus, ...payload, success: true},
        };
      case GET_PATIENT_BILLING_REPORT_IN_ERROR:
        return {
          ...state,
          getPatientBillingReport: {...defaultStatus, ...payload, error: true},
        };
  default:
        return state;
    }
  };
  export default patientReducer;