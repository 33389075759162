import {
  ADD_HOLIDAYS_LIST_ERROR,
  ADD_HOLIDAYS_LIST_PROGRESS,
  ADD_HOLIDAYS_LIST_SUCCESS,
  DEFAULT_HOLIDAY,
  DELETE_HOLIDAYS_LIST_ERROR,
  DELETE_HOLIDAYS_LIST_PROGRESS,
  DELETE_HOLIDAYS_LIST_SUCCESS,
  GET_HOLIDAYS_LIST_ERROR,
  GET_HOLIDAYS_LIST_PROGRESS,
  GET_HOLIDAYS_LIST_SUCCESS,
  GET_HOLIDAY_UPDATE_LIST_ERROR,
  GET_HOLIDAY_UPDATE_LIST_PROGRESS,
  GET_HOLIDAY_UPDATE_LIST_SUCCESS,
  SET_HOLIDAY_LIST_FAILURE,
  SET_HOLIDAY_LIST_SUCCESS,
  UPDATE_HOLIDAYS_LIST_ERROR,
  UPDATE_HOLIDAYS_LIST_PROGRESS,
  UPDATE_HOLIDAYS_LIST_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  holidaylist: { ...defaultStatus },
  deleteholidaylist: { ...defaultStatus },
  addholidaylist: { ...defaultStatus },
  updateholidaylist: { ...defaultStatus },
  getholidayupdatelist: { ...defaultStatus },
  holidaylistfailure: { success: false },
};
const holidayReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_HOLIDAYS_LIST_PROGRESS:
      return {
        ...state,
        holidaylist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
      };
    case GET_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        holidaylist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_HOLIDAYS_LIST_ERROR:
      return {
        ...state,
        holidaylist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_HOLIDAYS_LIST_PROGRESS:
      return {
        ...state,
        deleteholidaylist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
      };
    case DELETE_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        deleteholidaylist: {
          ...defaultStatus,
          success: true,
          ...payload,
        },
      };
    case DELETE_HOLIDAYS_LIST_ERROR:
      return {
        ...state,
        deleteholidaylist: {
          ...defaultStatus,
          error: true,
          ...payload,
        },
      };

    case ADD_HOLIDAYS_LIST_PROGRESS:
      return {
        ...state,
        addholidaylist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        addholidaylist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_HOLIDAYS_LIST_ERROR:
      return {
        ...state,
        addholidaylist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_HOLIDAYS_LIST_PROGRESS:
      return {
        ...state,
        updateholidaylist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        updateholidaylist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_HOLIDAYS_LIST_ERROR:
      return {
        ...state,
        updateholidaylist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_HOLIDAY_UPDATE_LIST_PROGRESS:
      return {
        ...state,
        getholidayupdatelist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_HOLIDAY_UPDATE_LIST_SUCCESS:
      console.log("payload.data", payload.data);
      return {
        ...state,
        getholidayupdatelist: {
          ...defaultStatus,
          ...payload.data,
          success: true,
        },
      };
    case GET_HOLIDAY_UPDATE_LIST_ERROR:
      return {
        ...state,
        getholidayupdatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    // SETHOLIDAYSUCCESS

    case SET_HOLIDAY_LIST_FAILURE:
      return {
        ...state,
        holidaylistfailure: {
          success: false,
        },
      };
    case DEFAULT_HOLIDAY:
      return {
        ...state,
        addholidaylist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
        updateholidaylist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    default:
      return state;
  }
};

export default holidayReducer;
