import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_PHARMACY_DASHBOARD_IN_PROGRESS,
  GET_TABLETS_BY_ORG_IN_PROGRESS,
  GET_TABLET_LIST_IN_PROGRESS,
  NEW_TABLET_IN_PROGRESS,
  UPDATE_TABLET_IN_PROGRESS,
  DELETE_TABLET_IN_PROGRESS,
  GET_TABLET_IN_PROGRESS,
  GET_TABLET_CATEGORY_LIST_IN_PROGRESS,
  ADD_TABLET_CATEGORY_IN_PROGRESS,
  EDIT_TABLET_CATEGORY_IN_PROGRESS,
  DELETE_TABLET_CATEGORY_IN_PROGRESS,
  GET_PRODUCT_TYPE_IN_PROGRESS,
  GET_PRODUCT_UNIT_IN_PROGRESS,
  GET_SUPPLIER_LIST_IN_PROGRESS,
  ADD_SUPPLIER_IN_PROGRESS,
  EDIT_SUPPLIER_IN_PROGRESS,
  DELETE_SUPPLIER_IN_PROGRESS,
  GET_PHARMACY_PROFILE_IN_PROGRESS,
  EDIT_PHARMACY_PROFILE_IN_PROGRESS,
  CHANGE_PASSWORD_IN_PROGRESS,
  EXPIRY_STOCK_IN_PROGRESS,
  STOCK_ADJUSTMENT_LIST_IN_PROGRESS,
  ADD_STOCK_ADJUSTMENT_IN_PROGRESS,
  EDIT_STOCK_ADJUSTMENT_IN_PROGRESS,
  DELETE_STOCK_ADJUSTMENT_IN_PROGRESS,
  GET_PURCHASE_LIST_IN_PROGRESS,
  ADD_PURCHASE_IN_PROGRESS,
  EDIT_PURCHASE_IN_PROGRESS,
  DELETE_PURCHASE_IN_PROGRESS,
  DELETE_PURCHASE_REQUEST_IN_PROGRESS,
  EDIT_PURCHASE_REQUEST_IN_PROGRESS,
  ADD_PURCHASE_REQUEST_IN_PROGRESS,
  GET_PURCHASE_REQUEST_LIST_IN_PROGRESS,
  GET_PURCHASE_RETURN_LIST_IN_PROGRESS,
  EDIT_PURCHASE_RETURN_IN_PROGRESS,
  DELETE_PURCHASE_RETURN_IN_PROGRESS,
  ADD_PURCHASE_RETURN_IN_PROGRESS,
  GET_TABLETS_ID_IN_PROGRESS,
  ADD_SALES_IN_PROGRESS,
  GET_LIST_SALES_IN_PROGRESS,
  GET_SALES_IN_PROGRESS,
  EDIT_SALES_IN_PROGRESS,
  GET_SALES_LIST_IN_PROGRESS,
  ADD_BULK_MEDICINE_IN_PROGRESS,
  DELETE_SALES_IN_PROGRESS,
  SALES_PAYMENT_IN_PROGRESS,
  UPDATE_SALES_PAYMENT_IN_PROGRESS,
  SALES_PAYMENT_DUE_IN_PROGRESS,
  GET_DEPARTMENT_LIST_IN_PROGRESS,
  ADD_DEPARTMENT_IN_PROGRESS,
  EDIT_DEPARTMENT_IN_PROGRESS,
  DELETE_DEPARTMENT_IN_PROGRESS,
  GET_ITEM_TRANSFER__LIST_IN_PROGRESS,
  ADD_ITEM_TRANSFER_IN_PROGRESS,
  EDIT_ITEM_TRANSFER_IN_PROGRESS,
  DELETE_ITEM_TRANSFER_IN_PROGRESS,
  GET_PHARMACY_LIST_IN_PROGRESS,
  SALES_CANCEL_UNDO_IN_PROGRESS,
  PATIENT_SALES_DUE_IN_PROGRESS,
  GET_DUE_PATIENT_IN_PROGRESS,
  ADD_SALES_RETURN_IN_PROGRESS,
  GET_SALES_RETURN_LIST_IN_PROGRESS,
  UPDATE_SALES_RETURN_IN_PROGRESS,
  SALES_LIST_BY_PATIENT_PROGRESS,
  ADD_BULK_PURCHASE_IN_PROGRESS,
  BULK_MEDICINE_CATEGORY_INPROGRESS,
  GET_GST_LIST_INPROGRESS,
  GET_PHARMACYREPORT_IN_PROGRESS,
  GET_AVABILE_STOCK_IN_PROGRESS,
} from "../../constants/ActionTypes";
import {
  AddTabletCategoryEffect,
  ChangePasswordEffect,
  DeleteTabletCategoryEffect,
  DeleteTabletEffect,
  EditPharmacyProfileEffect,
  EditTabletCategoryEffect,
  GetTabletEffect,
  NewTabletEffect,
  PharmacyProfileEffect,
  ProductTypeEffect,
  ProductUnitEffect,
  TabletByOrgEffect,
  TabletCategoryListEffect,
  TabletListEffect,
  UpdateTabletEffect,
  addBulkMedicineEffect,
  addBulkPurchaseEffect,
  addDepartmentEffect,
  addItemTransferEffect,
  addPurchaseEffect,
  addPurchaseRequestEffect,
  addPurchaseReturnEffect,
  addSalesEffect,
  addSalesPaymentEffect,
  addSalesReturnEffect,
  addStockAdjustmentEffect,
  addSupplierEffect,
  bulkMedicineCategoryEffect,
  deleteDepartmentEffect,
  deleteItemTransferEffect,
  deletePurchaseEffect,
  deletePurchaseRequestEffect,
  deletePurchaseReturnEffect,
  deleteSalesEffect,
  deleteStockAdjustmentEffect,
  deleteSupplierEffect,
  departmentListEffect,
  dueByPatientEffect,
  editDepartmentEffect,
  editItemTransferEffect,
  editPurchaseEffect,
  editPurchaseRequestEffect,
  editPurchaseReturnEffect,
  editSalesEffect,
  editStockAdjustmentEffect,
  editSupplierEffect,
  expiryStockEffect,
  getAvabileStockListEffect,
  getGstListEffect,
  getPharmacyDashboardEffect,
  getPharmacyReportListEffect,
  getSalesDetailsEffect,
  getSalesDueEffect,
  getSalesEffect,
  getSalesListByPatientEffect,
  getSalesReturnListEffect,
  getTabletIdEffect,
  itemTransferListEffect,
  patientSalesDueEffect,
  pharmacyListEffect,
  purchaseListEffect,
  purchaseRequestListEffect,
  purchaseReturnListEffect,
  salesCancelUndoEffect,
  salesListEffect,
  stockAdjustmentListEffect,
  supplierListEffect,
  updateSalesPaymentEffect,
  updateSalesReturnEffect,
} from "./PharmacyEffect";
import { getErrorMessageFromAPI } from "../../util/utilz";

import Message from "../../components/Message/Message";

import {
  addPurchaseError,
  addPurchaseRequestError,
  addPurchaseRequestSuccess,
  addPurchaseReturnError,
  addPurchaseReturnSuccess,
  addPurchaseSuccess,
  addSalesError,
  addSalesSuccess,
  addStockAdjustmentError,
  addStockAdjustmentSuccess,
  addSupplierError,
  addSupplierSuccess,
  addTabletCategoryError,
  addTabletCategorySuccess,
  changePasswordError,
  changePasswordSuccess,
  deletePurchaseError,
  deletePurchaseRequestError,
  deletePurchaseRequestSuccess,
  deletePurchaseReturnError,
  deletePurchaseReturnSuccess,
  deletePurchaseSuccess,
  deleteStockAdjustmentError,
  deleteStockAdjustmentSuccess,
  deleteSupplierError,
  deleteSupplierSuccess,
  deleteTabletCategoryError,
  deleteTabletCategorySuccess,
  deleteTabletError,
  deleteTabletSuccess,
  editPharmacyProfileError,
  editPharmacyProfileSuccess,
  editPurchaseError,
  editPurchaseRequestError,
  editPurchaseRequestSuccess,
  editPurchaseReturnError,
  editPurchaseReturnSuccess,
  editPurchaseSuccess,
  editSalesError,
  editSalesSuccess,
  editStockAdjustmentError,
  editStockAdjustmentSuccess,
  editSupplierError,
  editSupplierSuccess,
  editTabletCategoryError,
  editTabletCategorySuccess,
  expiryStockError,
  expiryStockSuccess,
  getListSalesError,
  getListSalesSuccess,
  getPharmacyDashboardError,
  getPharmacyDashboardSuccess,
  getPharmacyProfileError,
  getPharmacyProfileSuccess,
  getProductTypeError,
  getProductTypeSuccess,
  getProductUnitError,
  getProductUnitSuccess,
  getPurchaseListError,
  getPurchaseListSuccess,
  getPurchaseRequestListError,
  getPurchaseRequestListSuccess,
  getPurchaseReturnListError,
  getPurchaseReturnListSuccess,
  getSalesError,
  getSalesSuccess,
  getSalesListError,
  getSalesListSuccess,
  getSupplierListError,
  getSupplierListSuccess,
  getTabletCategoryListError,
  getTabletCategoryListSuccess,
  getTabletError,
  getTabletSuccess,
  getTabletsByOrgError,
  getTabletsByOrgSuccess,
  getTabletsIdError,
  getTabletsIdSuccess,
  getTabletsListError,
  getTabletsListSuccess,
  newTabletError,
  newTabletSuccess,
  stockAdjustmentListError,
  stockAdjustmentListSuccess,
  updateTabletsError,
  updateTabletsSuccess,
  addBulkMedicineSuccess,
  addBulkMedicineError,
  deleteSalesSuccess,
  deleteSalesError,
  slesPaymentSuccess,
  slesPaymentError,
  updateSalesPaymentSuccess,
  updateSalesPaymentError,
  salesPaymentDueSuccess,
  salesPaymentDueError,
  getDepartmentListSuccess,
  getDepartmentListError,
  addDepartmentSuccess,
  addDepartmentError,
  editDepartmentSuccess,
  editDepartmentError,
  deleteDepartmentSuccess,
  deleteDepartmentError,
  getitemTransferListSuccess,
  getitemTransferListError,
  addItemTransferSuccess,
  addItemTransferError,
  editItemTransferSuccess,
  editItemTransferError,
  deleteItemTransferSuccess,
  deleteItemTransferError,
  getPharmacyListSuccess,
  getPharmacyListError,
  salesCancelUndoDueSuccess,
  salesCancelUndoDueError,
  patientSalesDueSuccess,
  patientSalesDueError,
  dueByPatientSuccess,
  dueByPatientError,
  addSalesReturnSuccess,
  addSalesReturnError,
  getSalesReturnListSuccess,
  getSalesReturnListError,
  updateSalesReturnSuccess,
  updateSalesReturnError,
  salesListByPatientSuccess,
  salesListByPatientError,
  addBulkPurchaseSuccess,
  addBulkPurchaseError,
  getGstListSuccess,
  getGstListError,
  bulkMedicineCategorySuccess,
  bulkMedicineCategoryError,
  getPharmacryReportListSuccess,
  getPharmacryReportListError,
  getAvabileStockListSuccess,
  getAvabileStockListError,
} from "./PharmacyAction";

function* getPharmacyDashboard() {
  console.log("pharmacy-dash--sagaaaaaa");
  try {
    const token = localStorage.getItem("user_id");
    console.log("pharmacy-dash--saga");
    let { data } = yield call(getPharmacyDashboardEffect, token);
    console.log("dataaa", data);
    yield put(
      getPharmacyDashboardSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getPharmacyDashboardError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
// function* GetTabletsWatcher({ payload }) {
//   try {
//     let { data } = yield call(GetTabletsEffect, payload);
//     yield put(
//       getTabletsByOrgSuccess({
//         data: data,
//         message: data["message"],
//         success: true,
//       })
//     );
//   } catch (e) {
//     yield put(
//       getTabletsByOrgError({
//         error: true,
//         message: getErrorMessageFromAPI(e),
//       })
//     );
//   }
// }
function* TabletListWatcher({ payload }) {
  try {
    let { data } = yield call(TabletListEffect, payload);

    yield put(
      getTabletsListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getTabletsListError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* NewTabletWatcher({ payload }) {
  try {
    let { data } = yield call(NewTabletEffect, payload);
    console.log();
    yield put(
      newTabletSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      newTabletError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* UpdateTabletWatcher({ payload }) {
  try {
    let { data } = yield call(UpdateTabletEffect, payload);
    console.log("dayaaaaa", data);
    yield put(
      updateTabletsSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateTabletsError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* GetTabletWatcher({ payload }) {
  try {
    let { data } = yield call(GetTabletEffect, payload);

    yield put(
      getTabletSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getTabletError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeleteTabletWatcher({ payload }) {
  try {
    let { data } = yield call(DeleteTabletEffect, payload);

    yield put(
      deleteTabletSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteTabletError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* TabletByOrgWatcher({ payload }) {
  try {
    let { data } = yield call(TabletByOrgEffect, payload);
    console.log("datadatadatadata", data);
    yield put(
      getTabletsByOrgSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getTabletsByOrgError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* TabletCategoryListWatcher({ payload }) {
  try {
    let { data } = yield call(TabletCategoryListEffect, payload);

    yield put(
      getTabletCategoryListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getTabletCategoryListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* AddTabletCategoryWatcher({ payload }) {
  try {
    let { data } = yield call(AddTabletCategoryEffect, payload);

    yield put(
      addTabletCategorySuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addTabletCategoryError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditTabletCategoryWatcher({ payload }) {
  try {
    let { data } = yield call(EditTabletCategoryEffect, payload);

    yield put(
      editTabletCategorySuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editTabletCategoryError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeleteTabletCategoryWatcher({ payload }) {
  try {
    let { data } = yield call(DeleteTabletCategoryEffect, payload);
    console.log("daadaa", data);
    yield put(
      deleteTabletCategorySuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteTabletCategoryError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* ProductTypeWatcher({ payload }) {
  try {
    let { data } = yield call(ProductTypeEffect, payload);
    console.log("daadaa", data);
    yield put(
      getProductTypeSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getProductTypeError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* ProductUnitWatcher({ payload }) {
  try {
    let { data } = yield call(ProductUnitEffect, payload);
    console.log("daadaa", data);
    yield put(
      getProductUnitSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getProductUnitError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* SupplierListWatcher({ payload }) {
  try {
    let { data } = yield call(supplierListEffect, payload);
    console.log("daadaa", data);
    yield put(
      getSupplierListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getSupplierListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* AddSupplierWatcher({ payload }) {
  try {
    let { data } = yield call(addSupplierEffect, payload);
    console.log("daadaa", data);
    yield put(
      addSupplierSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addSupplierError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditSupplierWatcher({ payload }) {
  try {
    let { data } = yield call(editSupplierEffect, payload);
    console.log("daadaa", data);
    yield put(
      editSupplierSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editSupplierError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeleteSupplierWatcher({ payload }) {
  try {
    let { data } = yield call(deleteSupplierEffect, payload);
    console.log("daadaa", data);
    yield put(
      deleteSupplierSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteSupplierError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* PharmacyProfileWatcher({ payload }) {
  try {
    let { data } = yield call(PharmacyProfileEffect, payload);
    console.log("daadaa", data);
    yield put(
      getPharmacyProfileSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      getPharmacyProfileError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditPharmacyProfileWatcher({ payload }) {
  try {
    let { data } = yield call(EditPharmacyProfileEffect, payload);
    console.log("daadaa", data);
    yield put(
      editPharmacyProfileSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      editPharmacyProfileError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* changePasswordWatcher({ payload }) {
  try {
    let { data } = yield call(ChangePasswordEffect, payload);
    console.log("daadaa", data);
    yield put(
      changePasswordSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      changePasswordError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* ExpiryStockWatcher({ payload }) {
  try {
    const requestPayload = { current_date: payload?.formattedDate };
    let { data } = yield call(expiryStockEffect, requestPayload);

    yield put(
      expiryStockSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      expiryStockError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* StockAdjustmentListWatcher({ payload }) {
  try {
    let { data } = yield call(stockAdjustmentListEffect, payload);
    console.log("daadaa", data);
    yield put(
      stockAdjustmentListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      stockAdjustmentListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* AddStockAdjustmentWatcher({ payload }) {
  try {
    let { data } = yield call(addStockAdjustmentEffect, payload);
    console.log("daadaa", data?.message);
    yield put(
      addStockAdjustmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data?.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addStockAdjustmentError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditStockAdjustmentWatcher({ payload }) {
  try {
    let { data } = yield call(editStockAdjustmentEffect, payload);

    console.log("daadaa", data);
    yield put(
      editStockAdjustmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editStockAdjustmentError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeleteStockAdjustmentWatcher({ payload }) {
  try {
    let { data } = yield call(deleteStockAdjustmentEffect, payload);

    yield put(
      deleteStockAdjustmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteStockAdjustmentError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* PurchaseListWatcher({ payload }) {
  try {
    let { data } = yield call(purchaseListEffect, payload);
    console.log("daadaa", data);
    yield put(
      getPurchaseListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPurchaseListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* AddPurchaseWatcher({ payload }) {
  try {
    let { data } = yield call(addPurchaseEffect, payload);
    console.log("daadaa", data);
    yield put(
      addPurchaseSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addPurchaseError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditPurchaseWatcher({ payload }) {
  try {
    let { data } = yield call(editPurchaseEffect, payload);
    console.log("daadaa", data);
    yield put(
      editPurchaseSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editPurchaseError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeletePurchaseWatcher({ payload }) {
  try {
    let { data } = yield call(deletePurchaseEffect, payload);
    console.log("daadaa", data);
    yield put(
      deletePurchaseSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deletePurchaseError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* PurchaseRequestListWatcher({ payload }) {
  try {
    let { data } = yield call(purchaseRequestListEffect, payload);

    yield put(
      getPurchaseRequestListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPurchaseRequestListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* AddPurchaseRequestWatcher({ payload }) {
  try {
    let { data } = yield call(addPurchaseRequestEffect, payload);
    console.log("daadaa", data);
    yield put(
      addPurchaseRequestSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addPurchaseRequestError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditPurchaseRequestWatcher({ payload }) {
  try {
    let { data } = yield call(editPurchaseRequestEffect, payload);
    console.log("daadaa", data);
    yield put(
      editPurchaseRequestSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    console.log("ddddddddddddddd", e?.response?.data?.message?.message);
    yield call(Message, {
      type: "error",
      content: e?.response?.data?.message?.message,
    });
    yield put(
      editPurchaseRequestError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeletePurchaseRequestWatcher({ payload }) {
  try {
    let { data } = yield call(deletePurchaseRequestEffect, payload);
    console.log("daadaa", data);
    yield put(
      deletePurchaseRequestSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deletePurchaseRequestError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* PurchaseReturnListWatcher({ payload }) {
  try {
    let { data } = yield call(purchaseReturnListEffect, payload);
    console.log("daadaa", data);
    yield put(
      getPurchaseReturnListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPurchaseReturnListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* AddPurchaseReturnWatcher({ payload }) {
  try {
    let { data } = yield call(addPurchaseReturnEffect, payload);
    console.log("daadaa", data);
    yield put(
      addPurchaseReturnSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addPurchaseReturnError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* EditPurchaseReturnWatcher({ payload }) {
  try {
    let { data } = yield call(editPurchaseReturnEffect, payload);
    console.log("daadaa", data);
    yield put(
      editPurchaseReturnSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editPurchaseReturnError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* DeletePurchaseReturnWatcher({ payload }) {
  try {
    let { data } = yield call(deletePurchaseReturnEffect, payload);
    console.log("daadaa", data);
    yield put(
      deletePurchaseReturnSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deletePurchaseReturnError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* getTabletIdWatcher({ payload }) {
  try {
    console.log("daadaa-tablet-saga", payload);
    let { data } = yield call(getTabletIdEffect, payload);
    console.log("daadaa-tablet-saga", data);
    yield put(
      getTabletsIdSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getTabletsIdError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* addSalesWatcher({ payload }) {
  try {
    let { data } = yield call(addSalesEffect, payload);
    console.log("daadaa", data);
    yield put(
      addSalesSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addSalesError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* salesListWatcher({ payload }) {
  try {
    let { data } = yield call(getSalesEffect, payload);
    console.log("daadaa", data);
    yield put(
      getListSalesSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getListSalesError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* getSalesWatcher({ payload }) {
  try {
    let { data } = yield call(getSalesDetailsEffect, payload);
    console.log("daadaa", data);
    yield put(
      getSalesSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getSalesError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* editSalesWatcher({ payload }) {
  try {
    let { data } = yield call(editSalesEffect, payload);
    console.log("eidtsales", data);
    yield put(
      editSalesSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editSalesError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addBulkMedicineWatcher({ payload }) {
  console.log("payload", payload);
  try {
    let { data } = yield call(addBulkMedicineEffect, payload);
    console.log("daadaa", data);
    yield put(
      addBulkMedicineSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addBulkMedicineError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* deleteSalesWatcher({ payload }) {
  try {
    let { data } = yield call(deleteSalesEffect, payload);

    yield put(
      deleteSalesSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteSalesError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* addSalesPaymentWatcher({ payload }) {
  console.log("payload", payload);
  try {
    let { data } = yield call(addSalesPaymentEffect, payload);
    console.log("daadaa", data);
    yield put(
      slesPaymentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      slesPaymentError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* updateSalesPaymentWatcher({ payload }) {
  try {
    let { data } = yield call(updateSalesPaymentEffect, payload);

    yield put(
      updateSalesPaymentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      updateSalesPaymentError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* getSalesPaymentDueWatcher({ payload }) {
  try {
    let { data } = yield call(getSalesDueEffect, payload);

    yield put(
      salesPaymentDueSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      salesPaymentDueError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* departmentListWatcher({ payload }) {
  try {
    let { data } = yield call(departmentListEffect, payload);
    yield put(
      getDepartmentListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getDepartmentListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addDepartmentWatcher({ payload }) {
  try {
    let { data } = yield call(addDepartmentEffect, payload);
    console.log("daadaa", data);
    yield put(
      addDepartmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addDepartmentError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editDepartmentWatcher({ payload }) {
  try {
    let { data } = yield call(editDepartmentEffect, payload);
    console.log("daadaa", data);
    yield put(
      editDepartmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editDepartmentError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteDepartmentWatcher({ payload }) {
  try {
    let { data } = yield call(deleteDepartmentEffect, payload);
    console.log("daadaa", data);
    yield put(
      deleteDepartmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteDepartmentError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* salesCancelUndoWatcher({ payload }) {
  try {
    let { data } = yield call(salesCancelUndoEffect, payload);

    yield put(
      salesCancelUndoDueSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      salesCancelUndoDueError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* itemTransferListWatcher({ payload }) {
  try {
    let { data } = yield call(itemTransferListEffect, payload);
    console.log("daadaa", data);
    yield put(
      getitemTransferListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getitemTransferListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addItemTransferWatcher({ payload }) {
  try {
    let { data } = yield call(addItemTransferEffect, payload);
    console.log("daadaa", data);
    yield put(
      addItemTransferSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addItemTransferError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editItemTransferWatcher({ payload }) {
  try {
    let { data } = yield call(editItemTransferEffect, payload);
    console.log("daadaa", data);
    yield put(
      editItemTransferSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editItemTransferError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteItemTransferWatcher({ payload }) {
  try {
    let { data } = yield call(deleteItemTransferEffect, payload);
    console.log("daadaa", data);
    yield put(
      deleteItemTransferSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteItemTransferError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* pharmacyListWatcher({ payload }) {
  try {
    let { data } = yield call(pharmacyListEffect, payload);
    console.log("daadaa", data);
    yield put(
      getPharmacyListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPharmacyListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* patientSalesDueWatcher({ payload }) {
  try {
    let { data } = yield call(patientSalesDueEffect, payload);

    yield put(
      patientSalesDueSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      patientSalesDueError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* dueByPatientWatcher({ payload }) {
  try {
    let { data } = yield call(dueByPatientEffect, payload);

    yield put(
      dueByPatientSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      dueByPatientError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* addSalesReturnWatcher({ payload }) {
  try {
    let { data } = yield call(addSalesReturnEffect, payload);

    yield put(
      addSalesReturnSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addSalesReturnError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* getSalesReturnList({ payload }) {
  try {
    let { data } = yield call(getSalesReturnListEffect, payload);

    yield put(
      getSalesReturnListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getSalesReturnListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* updateSalesReturnList({ payload }) {
  try {
    let { data } = yield call(updateSalesReturnEffect, payload);

    yield put(
      updateSalesReturnSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      updateSalesReturnError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* getSalesListByPatient({ payload }) {
  try {
    let { data } = yield call(getSalesListByPatientEffect, payload);

    yield put(
      salesListByPatientSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      salesListByPatientError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addBulkPurchaseWatcher({ payload }) {
  try {
    let { data } = yield call(addBulkPurchaseEffect, payload);

    yield put(
      addBulkPurchaseSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addBulkPurchaseError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* bulkMedicineCategoryWatcher({ payload }) {
  try {
    let { data } = yield call(bulkMedicineCategoryEffect, payload);

    yield put(
      bulkMedicineCategorySuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      bulkMedicineCategoryError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* gstListWatcher({ payload }) {
  try {
    let { data } = yield call(getGstListEffect, payload);

    yield put(
      getGstListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getGstListError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* gstPharmacyReportListWatcher({ payload }) {
  try {
    let { data } = yield call(getPharmacyReportListEffect, payload);

    yield put(
      getPharmacryReportListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPharmacryReportListError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* getAvabileStockListWatcher({ payload }) {
  try {
    let { data } = yield call(getAvabileStockListEffect, payload);

    yield put(
      getAvabileStockListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getAvabileStockListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
export default function* PharmacySaga() {
  yield takeLatest(GET_PHARMACY_DASHBOARD_IN_PROGRESS, getPharmacyDashboard);
  yield takeLatest(GET_TABLET_LIST_IN_PROGRESS, TabletListWatcher);
  yield takeLatest(NEW_TABLET_IN_PROGRESS, NewTabletWatcher);
  yield takeLatest(UPDATE_TABLET_IN_PROGRESS, UpdateTabletWatcher);
  yield takeLatest(GET_TABLET_IN_PROGRESS, GetTabletWatcher);
  yield takeLatest(GET_TABLETS_BY_ORG_IN_PROGRESS, TabletByOrgWatcher);
  yield takeLatest(DELETE_TABLET_IN_PROGRESS, DeleteTabletWatcher);
  yield takeLatest(
    GET_TABLET_CATEGORY_LIST_IN_PROGRESS,
    TabletCategoryListWatcher
  );
  yield takeLatest(ADD_TABLET_CATEGORY_IN_PROGRESS, AddTabletCategoryWatcher);
  yield takeLatest(EDIT_TABLET_CATEGORY_IN_PROGRESS, EditTabletCategoryWatcher);
  yield takeLatest(
    DELETE_TABLET_CATEGORY_IN_PROGRESS,
    DeleteTabletCategoryWatcher
  );
  yield takeLatest(GET_PRODUCT_TYPE_IN_PROGRESS, ProductTypeWatcher);
  yield takeLatest(GET_PRODUCT_UNIT_IN_PROGRESS, ProductUnitWatcher);
  yield takeLatest(GET_SUPPLIER_LIST_IN_PROGRESS, SupplierListWatcher);
  yield takeLatest(ADD_SUPPLIER_IN_PROGRESS, AddSupplierWatcher);
  yield takeLatest(EDIT_SUPPLIER_IN_PROGRESS, EditSupplierWatcher);
  yield takeLatest(DELETE_SUPPLIER_IN_PROGRESS, DeleteSupplierWatcher);
  yield takeLatest(GET_PHARMACY_PROFILE_IN_PROGRESS, PharmacyProfileWatcher);
  yield takeLatest(
    EDIT_PHARMACY_PROFILE_IN_PROGRESS,
    EditPharmacyProfileWatcher
  );
  yield takeLatest(CHANGE_PASSWORD_IN_PROGRESS, changePasswordWatcher);
  yield takeLatest(EXPIRY_STOCK_IN_PROGRESS, ExpiryStockWatcher);
  yield takeLatest(
    STOCK_ADJUSTMENT_LIST_IN_PROGRESS,
    StockAdjustmentListWatcher
  );
  yield takeLatest(ADD_STOCK_ADJUSTMENT_IN_PROGRESS, AddStockAdjustmentWatcher);
  yield takeLatest(
    EDIT_STOCK_ADJUSTMENT_IN_PROGRESS,
    EditStockAdjustmentWatcher
  );
  yield takeLatest(
    DELETE_STOCK_ADJUSTMENT_IN_PROGRESS,
    DeleteStockAdjustmentWatcher
  );
  yield takeLatest(GET_PURCHASE_LIST_IN_PROGRESS, PurchaseListWatcher);
  yield takeLatest(ADD_PURCHASE_IN_PROGRESS, AddPurchaseWatcher);
  yield takeLatest(EDIT_PURCHASE_IN_PROGRESS, EditPurchaseWatcher);
  yield takeLatest(DELETE_PURCHASE_IN_PROGRESS, DeletePurchaseWatcher);
  yield takeLatest(
    GET_PURCHASE_REQUEST_LIST_IN_PROGRESS,
    PurchaseRequestListWatcher
  );
  yield takeLatest(ADD_PURCHASE_REQUEST_IN_PROGRESS, AddPurchaseRequestWatcher);
  yield takeLatest(
    EDIT_PURCHASE_REQUEST_IN_PROGRESS,
    EditPurchaseRequestWatcher
  );
  yield takeLatest(
    DELETE_PURCHASE_REQUEST_IN_PROGRESS,
    DeletePurchaseRequestWatcher
  );
  yield takeLatest(
    GET_PURCHASE_RETURN_LIST_IN_PROGRESS,
    PurchaseReturnListWatcher
  );
  yield takeLatest(ADD_PURCHASE_RETURN_IN_PROGRESS, AddPurchaseReturnWatcher);
  yield takeLatest(EDIT_PURCHASE_RETURN_IN_PROGRESS, EditPurchaseReturnWatcher);
  yield takeLatest(
    DELETE_PURCHASE_RETURN_IN_PROGRESS,
    DeletePurchaseReturnWatcher
  );
  yield takeLatest(GET_TABLETS_ID_IN_PROGRESS, getTabletIdWatcher);
  yield takeLatest(ADD_SALES_IN_PROGRESS, addSalesWatcher);
  yield takeLatest(GET_LIST_SALES_IN_PROGRESS, salesListWatcher);

  yield takeLatest(GET_SALES_IN_PROGRESS, getSalesWatcher);

  yield takeLatest(EDIT_SALES_IN_PROGRESS, editSalesWatcher);
  yield takeLatest(ADD_BULK_MEDICINE_IN_PROGRESS, addBulkMedicineWatcher);

  yield takeLatest(DELETE_SALES_IN_PROGRESS, deleteSalesWatcher);
  yield takeLatest(SALES_PAYMENT_IN_PROGRESS, addSalesPaymentWatcher);
  yield takeLatest(UPDATE_SALES_PAYMENT_IN_PROGRESS, updateSalesPaymentWatcher);

  yield takeLatest(SALES_PAYMENT_DUE_IN_PROGRESS, getSalesPaymentDueWatcher);
  yield takeLatest(GET_DEPARTMENT_LIST_IN_PROGRESS, departmentListWatcher);
  yield takeLatest(ADD_DEPARTMENT_IN_PROGRESS, addDepartmentWatcher);
  yield takeLatest(EDIT_DEPARTMENT_IN_PROGRESS, editDepartmentWatcher);
  yield takeLatest(DELETE_DEPARTMENT_IN_PROGRESS, deleteDepartmentWatcher);
  yield takeLatest(
    GET_ITEM_TRANSFER__LIST_IN_PROGRESS,
    itemTransferListWatcher
  );
  yield takeLatest(ADD_ITEM_TRANSFER_IN_PROGRESS, addItemTransferWatcher);
  yield takeLatest(EDIT_ITEM_TRANSFER_IN_PROGRESS, editItemTransferWatcher);
  yield takeLatest(DELETE_ITEM_TRANSFER_IN_PROGRESS, deleteItemTransferWatcher);
  yield takeLatest(GET_PHARMACY_LIST_IN_PROGRESS, pharmacyListWatcher);

  yield takeLatest(SALES_CANCEL_UNDO_IN_PROGRESS, salesCancelUndoWatcher);

  yield takeLatest(PATIENT_SALES_DUE_IN_PROGRESS, patientSalesDueWatcher);
  yield takeLatest(GET_DUE_PATIENT_IN_PROGRESS, dueByPatientWatcher);
  yield takeLatest(ADD_SALES_RETURN_IN_PROGRESS, addSalesReturnWatcher);
  yield takeLatest(GET_SALES_RETURN_LIST_IN_PROGRESS, getSalesReturnList);
  yield takeLatest(UPDATE_SALES_RETURN_IN_PROGRESS, updateSalesReturnList);
  yield takeLatest(SALES_LIST_BY_PATIENT_PROGRESS, getSalesListByPatient);
  yield takeLatest(ADD_BULK_PURCHASE_IN_PROGRESS, addBulkPurchaseWatcher);
  yield takeLatest(
    BULK_MEDICINE_CATEGORY_INPROGRESS,
    bulkMedicineCategoryWatcher
  );
  yield takeLatest(GET_GST_LIST_INPROGRESS, gstListWatcher);
  yield takeLatest(
    GET_PHARMACYREPORT_IN_PROGRESS,
    gstPharmacyReportListWatcher
  );
  yield takeLatest(GET_AVABILE_STOCK_IN_PROGRESS, getAvabileStockListWatcher);
}
