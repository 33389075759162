import {
  GET_PHARMACY_DASHBOARD_ERROR,
  GET_PHARMACY_DASHBOARD_IN_PROGRESS,
  GET_PHARMACY_DASHBOARD_SUCCESS,
  GET_TABLETS_BY_ORG_ERROR,
  GET_TABLETS_BY_ORG_IN_PROGRESS,
  GET_TABLETS_BY_ORG_SUCCESS,
  GET_TABLET_LIST_IN_PROGRESS,
  GET_TABLET_ERROR,
  GET_TABLET_IN_PROGRESS,
  GET_TABLET_LIST_ERROR,
  GET_TABLET_LIST_SUCCESS,
  GET_TABLET_SUCCESS,
  DELETE_TABLET_ERROR,
  DELETE_TABLET_IN_PROGRESS,
  DELETE_TABLET_SUCCESS,
  UPDATE_TABLET_ERROR,
  UPDATE_TABLET_IN_PROGRESS,
  UPDATE_TABLET_SUCCESS,
  NEW_TABLET_IN_PROGRESS,
  NEW_TABLET_SUCCESS,
  NEW_TABLET_ERROR,
  TABLET_BY_ORG_IN_PROGRESS,
  TABLET_BY_ORG_SUCCESS,
  TABLET_BY_ORG_ERROR,
  GET_TABLET_CATEGORY_LIST_IN_PROGRESS,
  GET_TABLET_CATEGORY_LIST_SUCCESS,
  GET_TABLET_CATEGORY_LIST_ERROR,
  ADD_TABLET_CATEGORY_IN_PROGRESS,
  ADD_TABLET_CATEGORY_SUCCESS,
  ADD_TABLET_CATEGORY_ERROR,
  EDIT_TABLET_CATEGORY_IN_PROGRESS,
  EDIT_TABLET_CATEGORY_SUCCESS,
  EDIT_TABLET_CATEGORY_ERROR,
  DELETE_TABLET_CATEGORY_IN_PROGRESS,
  DELETE_TABLET_CATEGORY_SUCCESS,
  DELETE_TABLET_CATEGORY_ERROR,
  DEFAULT_TABLET_CATEGORY,
  DEFAULT_TABLET_LIST,
  GET_PRODUCT_TYPE_IN_PROGRESS,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_ERROR,
  GET_PRODUCT_UNIT_IN_PROGRESS,
  GET_PRODUCT_UNIT_SUCCESS,
  GET_PRODUCT_UNIT_ERROR,
  GET_SUPPLIER_LIST_IN_PROGRESS,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_LIST_ERROR,
  ADD_SUPPLIER_IN_PROGRESS,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_ERROR,
  EDIT_SUPPLIER_IN_PROGRESS,
  EDIT_SUPPLIER_ERROR,
  EDIT_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_ERROR,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_IN_PROGRESS,
  DEFAULT_SUPPLIER,
  GET_PHARMACY_PROFILE_IN_PROGRESS,
  GET_PHARMACY_PROFILE_SUCCESS,
  GET_PHARMACY_PROFILE_ERROR,
  EDIT_PHARMACY_PROFILE_IN_PROGRESS,
  EDIT_PHARMACY_PROFILE_SUCCESS,
  EDIT_PHARMACY_PROFILE_ERROR,
  CHANGE_PASSWORD_IN_PROGRESS,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  EXPIRY_STOCK_IN_PROGRESS,
  EXPIRY_STOCK_SUCCESS,
  EXPIRY_STOCK_ERROR,
  STOCK_ADJUSTMENT_LIST_IN_PROGRESS,
  STOCK_ADJUSTMENT_LIST_SUCCESS,
  STOCK_ADJUSTMENT_LIST_ERROR,
  ADD_STOCK_ADJUSTMENT_IN_PROGRESS,
  ADD_STOCK_ADJUSTMENT_SUCCESS,
  ADD_STOCK_ADJUSTMENT_ERROR,
  EDIT_STOCK_ADJUSTMENT_IN_PROGRESS,
  EDIT_STOCK_ADJUSTMENT_SUCCESS,
  EDIT_STOCK_ADJUSTMENT_ERROR,
  DELETE_STOCK_ADJUSTMENT_IN_PROGRESS,
  DELETE_STOCK_ADJUSTMENT_SUCCESS,
  DELETE_STOCK_ADJUSTMENT_ERROR,
  GET_PURCHASE_LIST_IN_PROGRESS,
  GET_PURCHASE_LIST_SUCCESS,
  GET_PURCHASE_LIST_ERROR,
  ADD_PURCHASE_IN_PROGRESS,
  ADD_PURCHASE_ERROR,
  ADD_PURCHASE_SUCCESS,
  DELETE_PURCHASE_IN_PROGRESS,
  DELETE_PURCHASE_SUCCESS,
  DELETE_PURCHASE_ERROR,
  EDIT_PURCHASE_ERROR,
  EDIT_PURCHASE_SUCCESS,
  EDIT_PURCHASE_IN_PROGRESS,
  DELETE_PURCHASE_REQUEST_ERROR,
  DELETE_PURCHASE_REQUEST_SUCCESS,
  DELETE_PURCHASE_REQUEST_IN_PROGRESS,
  EDIT_PURCHASE_REQUEST_ERROR,
  EDIT_PURCHASE_REQUEST_SUCCESS,
  EDIT_PURCHASE_REQUEST_IN_PROGRESS,
  ADD_PURCHASE_REQUEST_ERROR,
  ADD_PURCHASE_REQUEST_SUCCESS,
  GET_PURCHASE_REQUEST_LIST_ERROR,
  GET_PURCHASE_REQUEST_LIST_SUCCESS,
  ADD_PURCHASE_REQUEST_IN_PROGRESS,
  GET_PURCHASE_RETURN_LIST_SUCCESS,
  GET_PURCHASE_RETURN_LIST_ERROR,
  ADD_PURCHASE_RETURN_IN_PROGRESS,
  ADD_PURCHASE_RETURN_SUCCESS,
  ADD_PURCHASE_RETURN_ERROR,
  EDIT_PURCHASE_RETURN_SUCCESS,
  EDIT_PURCHASE_RETURN_IN_PROGRESS,
  EDIT_PURCHASE_RETURN_ERROR,
  DELETE_PURCHASE_RETURN_IN_PROGRESS,
  DELETE_PURCHASE_RETURN_SUCCESS,
  DELETE_PURCHASE_RETURN_ERROR,
  GET_PURCHASE_REQUEST_LIST_IN_PROGRESS,
  PURCHASE_REQUEST_DEFAULT_DATA,
  GET_TABLETSBY_ID_IN_PROGRESS,
  GET_TABLETS_ID_IN_PROGRESS,
  GET_TABLETS_ID_SUCCESS,
  GET_TABLETS_ID_ERROR,
  ADD_SALES_IN_PROGRESS,
  ADD_SALES_SUCCESS,
  ADD_SALES_ERROR,
  GET_LIST_SALES_IN_PROGRESS,
  GET_LIST_SALES_SUCCESS,
  GET_LIST_SALES_ERROR,
  GET_SALES_IN_PROGRESS,
  GET_SALES_SUCCESS,
  GET_SALES_ERROR,
  EDIT_SALES_IN_PROGRESS,
  EDIT_SALES_SUCCESS,
  EDIT_SALES_ERROR,
  GET_SALES_LIST_IN_PROGRESS,
  GET_SALES_LIST_SUCCESS,
  GET_SALES_LIST_ERROR,
  STOCK_ADJUSTMENT_DEFAULT_DATA,
  GET_DEPARTMENT_LIST_IN_PROGRESS,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_DEPARTMENT_LIST_ERROR,
  ADD_DEPARTMENT_IN_PROGRESS,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  EDIT_DEPARTMENT_IN_PROGRESS,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_IN_PROGRESS,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  DEPARTMENT_DEFAULT_DATA,
  GET_PHARMACY_LIST_SUCCESS,
  GET_PHARMACY_LIST_IN_PROGRESS,
  GET_PHARMACY_LIST_ERROR,
  ITEM_TRANSFER_DEFAULT_DATA,
  DELETE_ITEM_TRANSFER_ERROR,
  DELETE_ITEM_TRANSFER_SUCCESS,
  DELETE_ITEM_TRANSFER_IN_PROGRESS,
  EDIT_ITEM_TRANSFER_ERROR,
  EDIT_ITEM_TRANSFER_SUCCESS,
  EDIT_ITEM_TRANSFER_IN_PROGRESS,
  ADD_ITEM_TRANSFER_ERROR,
  ADD_ITEM_TRANSFER_SUCCESS,
  ADD_ITEM_TRANSFER_IN_PROGRESS,
  GET_ITEM_TRANSFER_LIST_ERROR,
  GET_ITEM_TRANSFER_LIST_SUCCESS,
  GET_ITEM_TRANSFER__LIST_IN_PROGRESS,
  ADD_BULK_MEDICINE_IN_PROGRESS,
  ADD_BULK_MEDICINE_SUCCESS,
  ADD_BULK_MEDICINE_ERROR,
  GET_PURCHASE_RETURN_LIST_IN_PROGRESS,
  DEFAULT_BULK_MEDICINE,
  DEFAULT_PURCHASE_RETURN,
  DELETE_SALES_IN_PROGRESS,
  DELETE_SALES_SUCCESS,
  DELETE_SALES_ERROR,
  SALES_PAYMENT_IN_PROGRESS,
  SALES_PAYMENT_SUCCESS,
  SALES_PAYMENT_ERROR,
  UPDATE_SALES_PAYMENT_IN_PROGRESS,
  UPDATE_SALES_PAYMENT_SUCCESS,
  UPDATE_SALES_PAYMENT_ERROR,
  SALES_PAYMENT_DUE_IN_PROGRESS,
  SALES_PAYMENT_DUE_SUCCESS,
  SALES_PAYMENT_DUE_ERROR,
  DEFAULT_ITEM_TRANSFER,
  SALES_CANCEL_UNDO_IN_PROGRESS,
  SALES_CANCEL_UNDO_SUCCESS,
  SALES_CANCEL_UNDO_ERROR,
  DEFAULT_UPDATE_ADD_PAYMENT,
  PATIENT_SALES_DUE_IN_PROGRESS,
  PATIENT_SALES_DUE_SUCCESS,
  PATIENT_SALES_DUE_ERROR,
  GET_DUE_PATIENT_IN_PROGRESS,
  GET_DUE_PATIENT_SUCCESS,
  GET_DUE_PATIENT_ERROR,
  ADD_SALES_RETURN_IN_PROGRESS,
  ADD_SALES_RETURN_SUCCESS,
  ADD_SALES_RETURN_ERROR,
  GET_SALES_RETURN_LIST_ERROR,
  GET_SALES_RETURN_LIST_SUCCESS,
  GET_SALES_RETURN_LIST_IN_PROGRESS,
  UPDATE_SALES_RETURN_IN_PROGRESS,
  UPDATE_SALES_RETURN_SUCCESS,
  UPDATE_SALES_RETURN_ERROR,
  SALES_LIST_BY_PATIENT_ERROR,
  SALES_LIST_BY_PATIENT_SUCCESS,
  SALES_LIST_BY_PATIENT_PROGRESS,
  ADD_BULK_PURCHASE_IN_PROGRESS,
  ADD_BULK_PURCHASE_SUCCESS,
  ADD_BULK_PURCHASE_ERROR,
  BULK_MEDICINE_CATEGORY_INPROGRESS,
  BULK_MEDICINE_CATEGORY_SUCCESS,
  BULK_MEDICINE_CATEGORY_ERROR,
  GET_GST_LIST_INPROGRESS,
  GET_GST_LIST_SUCCESS,
  GET_GST_LIST_ERROR,
  DEFAULT_BULK_MEDICINE_CATEGORY,
  GET_PHARMACYREPORT_IN_SUCCESS,
  GET_PHARMACYREPORT_IN_PROGRESS,
  GET_PHARMACYREPORT_IN_ERROR,
  GET_AVABILE_STOCK_IN_SUCCESS,
  GET_AVABILE_STOCK_IN_ERROR,
  GET_AVABILE_STOCK_IN_PROGRESS,
} from "../../constants/ActionTypes";

// GET PATIENT APPOINMENT LIST
export const getPharmacyDashboardInProgress = () => {
  console.log("action");
  return {
    type: GET_PHARMACY_DASHBOARD_IN_PROGRESS,
  };
};
export const getPharmacyDashboardSuccess = (payload) => {
  return {
    type: GET_PHARMACY_DASHBOARD_SUCCESS,
    payload: payload,
  };
};
export const getPharmacyDashboardError = (payload) => {
  return {
    type: GET_PHARMACY_DASHBOARD_ERROR,
    payload: payload,
  };
};
export const getTabletsByOrgInProgress = (payload) => ({
  type: GET_TABLETS_BY_ORG_IN_PROGRESS,
  payload: payload,
});

export const getTabletsByOrgSuccess = (payload) => ({
  type: GET_TABLETS_BY_ORG_SUCCESS,
  payload: payload,
});

export const getTabletsByOrgError = (payload) => ({
  type: GET_TABLETS_BY_ORG_ERROR,
  payload: payload,
});
export const getTabletsListInProgress = (payload) => ({
  type: GET_TABLET_LIST_IN_PROGRESS,
  payload: payload,
});

export const getTabletsListSuccess = (payload) => ({
  type: GET_TABLET_LIST_SUCCESS,
  payload: payload,
});

export const getTabletsListError = (payload) => ({
  type: GET_TABLET_LIST_ERROR,
  payload: payload,
});

export const updateTabletsInProgress = (payload) => ({
  type: UPDATE_TABLET_IN_PROGRESS,
  payload: payload,
});

export const updateTabletsSuccess = (payload) => ({
  type: UPDATE_TABLET_SUCCESS,
  payload: payload,
});

export const updateTabletsError = (payload) => ({
  type: UPDATE_TABLET_ERROR,
  payload: payload,
});

export const getTabletInProgress = (payload) => ({
  type: GET_TABLET_IN_PROGRESS,
  payload: payload,
});

export const getTabletSuccess = (payload) => ({
  type: GET_TABLET_SUCCESS,
  payload: payload,
});

export const getTabletError = (payload) => ({
  type: GET_TABLET_ERROR,
  payload: payload,
});

export const deleteTabletInProgress = (payload) => ({
  type: DELETE_TABLET_IN_PROGRESS,
  payload: payload,
});

export const deleteTabletSuccess = (payload) => ({
  type: DELETE_TABLET_SUCCESS,
  payload: payload,
});

export const deleteTabletError = (payload) => ({
  type: DELETE_TABLET_ERROR,
  payload: payload,
});

export const newTabletInProgress = (payload) => ({
  type: NEW_TABLET_IN_PROGRESS,
  payload: payload,
});

export const newTabletSuccess = (payload) => ({
  type: NEW_TABLET_SUCCESS,
  payload: payload,
});
export const newTabletError = (payload) => ({
  type: NEW_TABLET_ERROR,
  payload: payload,
});
export const getTabletCategoryListInProgress = (payload) => ({
  type: GET_TABLET_CATEGORY_LIST_IN_PROGRESS,
  payload: payload,
});

export const getTabletCategoryListSuccess = (payload) => ({
  type: GET_TABLET_CATEGORY_LIST_SUCCESS,
  payload: payload,
});

export const getTabletCategoryListError = (payload) => ({
  type: GET_TABLET_CATEGORY_LIST_ERROR,
  payload: payload,
});
export const addTabletCategoryInProgress = (payload) => ({
  type: ADD_TABLET_CATEGORY_IN_PROGRESS,
  payload: payload,
});

export const addTabletCategorySuccess = (payload) => ({
  type: ADD_TABLET_CATEGORY_SUCCESS,
  payload: payload,
});

export const addTabletCategoryError = (payload) => ({
  type: ADD_TABLET_CATEGORY_ERROR,
  payload: payload,
});
export const editTabletCategoryInProgress = (payload) => ({
  type: EDIT_TABLET_CATEGORY_IN_PROGRESS,
  payload: payload,
});

export const editTabletCategorySuccess = (payload) => ({
  type: EDIT_TABLET_CATEGORY_SUCCESS,
  payload: payload,
});

export const editTabletCategoryError = (payload) => ({
  type: EDIT_TABLET_CATEGORY_ERROR,
  payload: payload,
});
export const deleteTabletCategoryInProgress = (payload) => ({
  type: DELETE_TABLET_CATEGORY_IN_PROGRESS,
  payload: payload,
});

export const deleteTabletCategorySuccess = (payload) => ({
  type: DELETE_TABLET_CATEGORY_SUCCESS,
  payload: payload,
});

export const deleteTabletCategoryError = (payload) => ({
  type: DELETE_TABLET_CATEGORY_ERROR,
  payload: payload,
});
export const defaultTabletCategory = () => ({
  type: DEFAULT_TABLET_CATEGORY,
});
export const defaultTablet = () => ({
  type: DEFAULT_TABLET_LIST,
});
export const getProductTypeInProgress = (payload) => ({
  type: GET_PRODUCT_TYPE_IN_PROGRESS,
  payload: payload,
});

export const getProductTypeSuccess = (payload) => ({
  type: GET_PRODUCT_TYPE_SUCCESS,
  payload: payload,
});

export const getProductTypeError = (payload) => ({
  type: GET_PRODUCT_TYPE_ERROR,
  payload: payload,
});
export const getProductUnitInProgress = (payload) => ({
  type: GET_PRODUCT_UNIT_IN_PROGRESS,
  payload: payload,
});

export const getProductUnitSuccess = (payload) => ({
  type: GET_PRODUCT_UNIT_SUCCESS,
  payload: payload,
});

export const getProductUnitError = (payload) => ({
  type: GET_PRODUCT_UNIT_ERROR,
  payload: payload,
});
export const getSupplierListInProgress = (payload) => ({
  type: GET_SUPPLIER_LIST_IN_PROGRESS,
  payload: payload,
});

export const getSupplierListSuccess = (payload) => ({
  type: GET_SUPPLIER_LIST_SUCCESS,
  payload: payload,
});

export const getSupplierListError = (payload) => ({
  type: GET_SUPPLIER_LIST_ERROR,
  payload: payload,
});
export const addSupplierInProgress = (payload) => ({
  type: ADD_SUPPLIER_IN_PROGRESS,
  payload: payload,
});

export const addSupplierSuccess = (payload) => ({
  type: ADD_SUPPLIER_SUCCESS,
  payload: payload,
});

export const addSupplierError = (payload) => ({
  type: ADD_SUPPLIER_ERROR,
  payload: payload,
});
export const editSupplierInProgress = (payload) => ({
  type: EDIT_SUPPLIER_IN_PROGRESS,
  payload: payload,
});

export const editSupplierSuccess = (payload) => ({
  type: EDIT_SUPPLIER_SUCCESS,
  payload: payload,
});

export const editSupplierError = (payload) => ({
  type: EDIT_SUPPLIER_ERROR,
  payload: payload,
});
export const deleteSupplierInProgress = (payload) => ({
  type: DELETE_SUPPLIER_IN_PROGRESS,
  payload: payload,
});

export const deleteSupplierSuccess = (payload) => ({
  type: DELETE_SUPPLIER_SUCCESS,
  payload: payload,
});

export const deleteSupplierError = (payload) => ({
  type: DELETE_SUPPLIER_ERROR,
  payload: payload,
});
export const defaultSupplier = (payload) => ({
  type: DEFAULT_SUPPLIER,
  payload: payload,
});
export const getPharmacyProfileInProgress = (payload) => ({
  type: GET_PHARMACY_PROFILE_IN_PROGRESS,
  payload: payload,
});

export const getPharmacyProfileSuccess = (payload) => ({
  type: GET_PHARMACY_PROFILE_SUCCESS,
  payload: payload,
});

export const getPharmacyProfileError = (payload) => ({
  type: GET_PHARMACY_PROFILE_ERROR,
  payload: payload,
});

export const editPharmacyProfileInProgress = (payload) => ({
  type: EDIT_PHARMACY_PROFILE_IN_PROGRESS,
  payload: payload,
});

export const editPharmacyProfileSuccess = (payload) => ({
  type: EDIT_PHARMACY_PROFILE_SUCCESS,
  payload: payload,
});

export const editPharmacyProfileError = (payload) => ({
  type: EDIT_PHARMACY_PROFILE_ERROR,
  payload: payload,
});
export const changePasswordInProgress = (payload) => ({
  type: CHANGE_PASSWORD_IN_PROGRESS,
  payload: payload,
});

export const changePasswordSuccess = (payload) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: payload,
});

export const changePasswordError = (payload) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: payload,
});
export const expiryStockInProgress = (payload) => ({
  type: EXPIRY_STOCK_IN_PROGRESS,
  payload: payload,
});

export const expiryStockSuccess = (payload) => ({
  type: EXPIRY_STOCK_SUCCESS,
  payload: payload,
});

export const expiryStockError = (payload) => ({
  type: EXPIRY_STOCK_ERROR,
  payload: payload,
});
export const stockAdjustmentListInProgress = (payload) => ({
  type: STOCK_ADJUSTMENT_LIST_IN_PROGRESS,
  payload: payload,
});

export const stockAdjustmentListSuccess = (payload) => ({
  type: STOCK_ADJUSTMENT_LIST_SUCCESS,
  payload: payload,
});

export const stockAdjustmentListError = (payload) => ({
  type: STOCK_ADJUSTMENT_LIST_ERROR,
  payload: payload,
});
export const addStockAdjustmentInProgress = (payload) => ({
  type: ADD_STOCK_ADJUSTMENT_IN_PROGRESS,
  payload: payload,
});

export const addStockAdjustmentSuccess = (payload) => ({
  type: ADD_STOCK_ADJUSTMENT_SUCCESS,
  payload: payload,
});

export const addStockAdjustmentError = (payload) => ({
  type: ADD_STOCK_ADJUSTMENT_ERROR,
  payload: payload,
});
export const editStockAdjustmentInProgress = (payload) => ({
  type: EDIT_STOCK_ADJUSTMENT_IN_PROGRESS,
  payload: payload,
});

export const editStockAdjustmentSuccess = (payload) => ({
  type: EDIT_STOCK_ADJUSTMENT_SUCCESS,
  payload: payload,
});

export const editStockAdjustmentError = (payload) => ({
  type: EDIT_STOCK_ADJUSTMENT_ERROR,
  payload: payload,
});
export const deleteStockAdjustmentInProgress = (payload) => ({
  type: DELETE_STOCK_ADJUSTMENT_IN_PROGRESS,
  payload: payload,
});

export const deleteStockAdjustmentSuccess = (payload) => ({
  type: DELETE_STOCK_ADJUSTMENT_SUCCESS,
  payload: payload,
});

export const deleteStockAdjustmentError = (payload) => ({
  type: DELETE_STOCK_ADJUSTMENT_ERROR,
  payload: payload,
});
//purchase
export const getPurchaseListInProgress = (payload) => ({
  type: GET_PURCHASE_LIST_IN_PROGRESS,
  payload: payload,
});

export const getPurchaseListSuccess = (payload) => ({
  type: GET_PURCHASE_LIST_SUCCESS,
  payload: payload,
});

export const getPurchaseListError = (payload) => ({
  type: GET_PURCHASE_LIST_ERROR,
  payload: payload,
});
export const addPurchaseInProgress = (payload) => ({
  type: ADD_PURCHASE_IN_PROGRESS,
  payload: payload,
});

export const addPurchaseSuccess = (payload) => ({
  type: ADD_PURCHASE_SUCCESS,
  payload: payload,
});

export const addPurchaseError = (payload) => ({
  type: ADD_PURCHASE_ERROR,
  payload: payload,
});
export const editPurchaseInProgress = (payload) => ({
  type: EDIT_PURCHASE_IN_PROGRESS,
  payload: payload,
});

export const editPurchaseSuccess = (payload) => ({
  type: EDIT_PURCHASE_SUCCESS,
  payload: payload,
});

export const editPurchaseError = (payload) => ({
  type: EDIT_PURCHASE_ERROR,
  payload: payload,
});
export const deletePurchaseInProgress = (payload) => ({
  type: DELETE_PURCHASE_IN_PROGRESS,
  payload: payload,
});

export const deletePurchaseSuccess = (payload) => ({
  type: DELETE_PURCHASE_SUCCESS,
  payload: payload,
});

export const deletePurchaseError = (payload) => ({
  type: DELETE_PURCHASE_ERROR,
  payload: payload,
});
//purchase request
export const getPurchaseRequestListInProgress = (payload) => ({
  type: GET_PURCHASE_REQUEST_LIST_IN_PROGRESS,
  payload: payload,
});

export const getPurchaseRequestListSuccess = (payload) => ({
  type: GET_PURCHASE_REQUEST_LIST_SUCCESS,
  payload: payload,
});

export const getPurchaseRequestListError = (payload) => ({
  type: GET_PURCHASE_REQUEST_LIST_ERROR,
  payload: payload,
});
export const addPurchaseRequestInProgress = (payload) => ({
  type: ADD_PURCHASE_REQUEST_IN_PROGRESS,
  payload: payload,
});

export const addPurchaseRequestSuccess = (payload) => ({
  type: ADD_PURCHASE_REQUEST_SUCCESS,
  payload: payload,
});

export const addPurchaseRequestError = (payload) => ({
  type: ADD_PURCHASE_REQUEST_ERROR,
  payload: payload,
});
export const editPurchaseRequestInProgress = (payload) => ({
  type: EDIT_PURCHASE_REQUEST_IN_PROGRESS,
  payload: payload,
});

export const editPurchaseRequestSuccess = (payload) => ({
  type: EDIT_PURCHASE_REQUEST_SUCCESS,
  payload: payload,
});

export const editPurchaseRequestError = (payload) => ({
  type: EDIT_PURCHASE_REQUEST_ERROR,
  payload: payload,
});
export const deletePurchaseRequestInProgress = (payload) => ({
  type: DELETE_PURCHASE_REQUEST_IN_PROGRESS,
  payload: payload,
});

export const deletePurchaseRequestSuccess = (payload) => ({
  type: DELETE_PURCHASE_REQUEST_SUCCESS,
  payload: payload,
});

export const deletePurchaseRequestError = (payload) => ({
  type: DELETE_PURCHASE_REQUEST_ERROR,
  payload: payload,
});
// purcahse return
export const getPurchaseReturnListInProgress = (payload) => ({
  type: GET_PURCHASE_RETURN_LIST_IN_PROGRESS,
  payload: payload,
});

export const getPurchaseReturnListSuccess = (payload) => ({
  type: GET_PURCHASE_RETURN_LIST_SUCCESS,
  payload: payload,
});

export const getPurchaseReturnListError = (payload) => ({
  type: GET_PURCHASE_RETURN_LIST_ERROR,
  payload: payload,
});
export const addPurchaseReturnInProgress = (payload) => ({
  type: ADD_PURCHASE_RETURN_IN_PROGRESS,
  payload: payload,
});

export const addPurchaseReturnSuccess = (payload) => ({
  type: ADD_PURCHASE_RETURN_SUCCESS,
  payload: payload,
});

export const addPurchaseReturnError = (payload) => ({
  type: ADD_PURCHASE_RETURN_ERROR,
  payload: payload,
});
export const editPurchaseReturnInProgress = (payload) => ({
  type: EDIT_PURCHASE_RETURN_IN_PROGRESS,
  payload: payload,
});

export const editPurchaseReturnSuccess = (payload) => ({
  type: EDIT_PURCHASE_RETURN_SUCCESS,
  payload: payload,
});

export const editPurchaseReturnError = (payload) => ({
  type: EDIT_PURCHASE_RETURN_ERROR,
  payload: payload,
});
export const deletePurchaseReturnInProgress = (payload) => ({
  type: DELETE_PURCHASE_RETURN_IN_PROGRESS,
  payload: payload,
});

export const deletePurchaseReturnSuccess = (payload) => ({
  type: DELETE_PURCHASE_RETURN_SUCCESS,
  payload: payload,
});

export const deletePurchaseReturnError = (payload) => ({
  type: DELETE_PURCHASE_RETURN_ERROR,
  payload: payload,
});
export const purchaseRequestDefaultData = (payload) => ({
  type: PURCHASE_REQUEST_DEFAULT_DATA,
  payload: payload,
});
export const getTabletsIdInProgress = (payload) => ({
  type: GET_TABLETS_ID_IN_PROGRESS,
  payload: payload,
});

export const getTabletsIdSuccess = (payload) => ({
  type: GET_TABLETS_ID_SUCCESS,
  payload: payload,
});

export const getTabletsIdError = (payload) => ({
  type: GET_TABLETS_ID_ERROR,
  payload: payload,
});

// sales

export const addSalesInProgress = (payload) => ({
  type: ADD_SALES_IN_PROGRESS,
  payload: payload,
});

export const addSalesSuccess = (payload) => ({
  type: ADD_SALES_SUCCESS,
  payload: payload,
});

export const addSalesError = (payload) => ({
  type: ADD_SALES_ERROR,
  payload: payload,
});

export const getListSalesInProgress = (payload) => ({
  type: GET_LIST_SALES_IN_PROGRESS,
  payload: payload,
});

export const getListSalesSuccess = (payload) => ({
  type: GET_LIST_SALES_SUCCESS,
  payload: payload,
});

export const getListSalesError = (payload) => ({
  type: GET_LIST_SALES_ERROR,
  payload: payload,
});

export const getSalesInProgress = (payload) => ({
  type: GET_SALES_IN_PROGRESS,
  payload: payload,
});

export const getSalesSuccess = (payload) => ({
  type: GET_SALES_SUCCESS,
  payload: payload,
});

export const getSalesError = (payload) => ({
  type: GET_SALES_ERROR,
  payload: payload,
});

export const editSalesInProgress = (payload) => ({
  type: EDIT_SALES_IN_PROGRESS,
  payload: payload,
});

export const editSalesSuccess = (payload) => ({
  type: EDIT_SALES_SUCCESS,
  payload: payload,
});

export const editSalesError = (payload) => ({
  type: EDIT_SALES_ERROR,
  payload: payload,
});
export const getDepartmentListInProgress = (payload) => ({
  type: GET_DEPARTMENT_LIST_IN_PROGRESS,
  payload: payload,
});

export const getDepartmentListSuccess = (payload) => ({
  type: GET_DEPARTMENT_LIST_SUCCESS,
  payload: payload,
});

export const getDepartmentListError = (payload) => ({
  type: GET_DEPARTMENT_LIST_ERROR,
  payload: payload,
});
export const addDepartmentInProgress = (payload) => ({
  type: ADD_DEPARTMENT_IN_PROGRESS,
  payload: payload,
});

export const addDepartmentSuccess = (payload) => ({
  type: ADD_DEPARTMENT_SUCCESS,
  payload: payload,
});

export const addDepartmentError = (payload) => ({
  type: ADD_DEPARTMENT_ERROR,
  payload: payload,
});
export const editDepartmentInProgress = (payload) => ({
  type: EDIT_DEPARTMENT_IN_PROGRESS,
  payload: payload,
});

export const editDepartmentSuccess = (payload) => ({
  type: EDIT_DEPARTMENT_SUCCESS,
  payload: payload,
});

export const editDepartmentError = (payload) => ({
  type: EDIT_DEPARTMENT_ERROR,
  payload: payload,
});
export const deleteDepartmentInProgress = (payload) => ({
  type: DELETE_DEPARTMENT_IN_PROGRESS,
  payload: payload,
});

export const deleteDepartmentSuccess = (payload) => ({
  type: DELETE_DEPARTMENT_SUCCESS,
  payload: payload,
});

export const deleteDepartmentError = (payload) => ({
  type: DELETE_DEPARTMENT_ERROR,
  payload: payload,
});

export const departmentDefaultData = (payload) => ({
  type: DEPARTMENT_DEFAULT_DATA,
  payload: payload,
});
export const getPharmacyListInProgress = (payload) => ({
  type: GET_PHARMACY_LIST_IN_PROGRESS,
  payload: payload,
});

export const getPharmacyListSuccess = (payload) => ({
  type: GET_PHARMACY_LIST_SUCCESS,
  payload: payload,
});

export const getPharmacyListError = (payload) => ({
  type: GET_PHARMACY_LIST_ERROR,
  payload: payload,
});
export const getitemTransferListInProgress = (payload) => ({
  type: GET_ITEM_TRANSFER__LIST_IN_PROGRESS,
  payload: payload,
});

export const getitemTransferListSuccess = (payload) => ({
  type: GET_ITEM_TRANSFER_LIST_SUCCESS,
  payload: payload,
});

export const getitemTransferListError = (payload) => ({
  type: GET_ITEM_TRANSFER_LIST_ERROR,
  payload: payload,
});
export const addItemTransferInProgress = (payload) => ({
  type: ADD_ITEM_TRANSFER_IN_PROGRESS,
  payload: payload,
});

export const addItemTransferSuccess = (payload) => ({
  type: ADD_ITEM_TRANSFER_SUCCESS,
  payload: payload,
});

export const addItemTransferError = (payload) => ({
  type: ADD_ITEM_TRANSFER_ERROR,
  payload: payload,
});
export const editItemTransferInProgress = (payload) => ({
  type: EDIT_ITEM_TRANSFER_IN_PROGRESS,
  payload: payload,
});

export const editItemTransferSuccess = (payload) => ({
  type: EDIT_ITEM_TRANSFER_SUCCESS,
  payload: payload,
});

export const editItemTransferError = (payload) => ({
  type: EDIT_ITEM_TRANSFER_ERROR,
  payload: payload,
});
export const deleteItemTransferInProgress = (payload) => ({
  type: DELETE_ITEM_TRANSFER_IN_PROGRESS,
  payload: payload,
});

export const deleteItemTransferSuccess = (payload) => ({
  type: DELETE_ITEM_TRANSFER_SUCCESS,
  payload: payload,
});

export const deleteItemTransferError = (payload) => ({
  type: DELETE_ITEM_TRANSFER_ERROR,
  payload: payload,
});

export const itemTransferDefaultData = (payload) => ({
  type: ITEM_TRANSFER_DEFAULT_DATA,
  payload: payload,
});
export const addBulkMedicineInprogress = (payload) => ({
  type: ADD_BULK_MEDICINE_IN_PROGRESS,
  payload: payload,
});

export const addBulkMedicineSuccess = (payload) => ({
  type: ADD_BULK_MEDICINE_SUCCESS,
  payload: payload,
});

export const addBulkMedicineError = (payload) => ({
  type: ADD_BULK_MEDICINE_ERROR,
  payload: payload,
});
export const defaultBulkMedicine = (payload) => ({
  type: DEFAULT_BULK_MEDICINE,
  payload: payload,
});
export const defaultPurchaseReturn = (payload) => ({
  type: DEFAULT_PURCHASE_RETURN,
  payload: payload,
});

export const deleteSalesInProgress = (payload) => ({
  type: DELETE_SALES_IN_PROGRESS,
  payload: payload,
});

export const deleteSalesSuccess = (payload) => ({
  type: DELETE_SALES_SUCCESS,
  payload: payload,
});

export const deleteSalesError = (payload) => ({
  type: DELETE_SALES_ERROR,
  payload: payload,
});

export const slesPaymentInProgress = (payload) => ({
  type: SALES_PAYMENT_IN_PROGRESS,
  payload: payload,
});

export const slesPaymentSuccess = (payload) => ({
  type: SALES_PAYMENT_SUCCESS,
  payload: payload,
});

export const slesPaymentError = (payload) => ({
  type: SALES_PAYMENT_ERROR,
  payload: payload,
});

export const updateSalesPaymentInProgress = (payload) => ({
  type: UPDATE_SALES_PAYMENT_IN_PROGRESS,
  payload: payload,
});

export const updateSalesPaymentSuccess = (payload) => ({
  type: UPDATE_SALES_PAYMENT_SUCCESS,
  payload: payload,
});

export const updateSalesPaymentError = (payload) => ({
  type: UPDATE_SALES_PAYMENT_ERROR,
  payload: payload,
});

export const salesPaymentDueInProgress = (payload) => ({
  type: SALES_PAYMENT_DUE_IN_PROGRESS,
  payload: payload,
});

export const salesPaymentDueSuccess = (payload) => ({
  type: SALES_PAYMENT_DUE_SUCCESS,
  payload: payload,
});

export const salesPaymentDueError = (payload) => ({
  type: SALES_PAYMENT_DUE_ERROR,
  payload: payload,
});
export const defaultItemTransfer = (payload) => ({
  type: DEFAULT_ITEM_TRANSFER,
  payload: payload,
});

export const salesCancelUndoInProgress = (payload) => ({
  type: SALES_CANCEL_UNDO_IN_PROGRESS,
  payload: payload,
});

export const salesCancelUndoDueSuccess = (payload) => ({
  type: SALES_CANCEL_UNDO_SUCCESS,
  payload: payload,
});

export const salesCancelUndoDueError = (payload) => ({
  type: SALES_CANCEL_UNDO_ERROR,
  payload: payload,
});

export const defaultStateAddUpdatePayment = () => ({
  type: DEFAULT_UPDATE_ADD_PAYMENT,
});

export const patientSalesDueInProgress = (payload) => ({
  type: PATIENT_SALES_DUE_IN_PROGRESS,
  payload: payload,
});

export const patientSalesDueSuccess = (payload) => ({
  type: PATIENT_SALES_DUE_SUCCESS,
  payload: payload,
});

export const patientSalesDueError = (payload) => ({
  type: PATIENT_SALES_DUE_ERROR,
  payload: payload,
});

export const dueByPatientInProgress = (payload) => ({
  type: GET_DUE_PATIENT_IN_PROGRESS,
  payload: payload,
});

export const dueByPatientSuccess = (payload) => ({
  type: GET_DUE_PATIENT_SUCCESS,
  payload: payload,
});

export const dueByPatientError = (payload) => ({
  type: GET_DUE_PATIENT_ERROR,
  payload: payload,
});

export const addSalesReturnInProgress = (payload) => ({
  type: ADD_SALES_RETURN_IN_PROGRESS,
  payload: payload,
});

export const addSalesReturnSuccess = (payload) => ({
  type: ADD_SALES_RETURN_SUCCESS,
  payload: payload,
});

export const addSalesReturnError = (payload) => ({
  type: ADD_SALES_RETURN_ERROR,
  payload: payload,
});

export const getSalesReturnListInProgress = (payload) => ({
  type: GET_SALES_RETURN_LIST_IN_PROGRESS,
  payload: payload,
});

export const getSalesReturnListSuccess = (payload) => ({
  type: GET_SALES_RETURN_LIST_SUCCESS,
  payload: payload,
});

export const getSalesReturnListError = (payload) => ({
  type: GET_SALES_RETURN_LIST_ERROR,
  payload: payload,
});

export const updateSalesReturnInProgress = (payload) => ({
  type: UPDATE_SALES_RETURN_IN_PROGRESS,
  payload: payload,
});

export const updateSalesReturnSuccess = (payload) => ({
  type: UPDATE_SALES_RETURN_SUCCESS,
  payload: payload,
});

export const updateSalesReturnError = (payload) => ({
  type: UPDATE_SALES_RETURN_ERROR,
  payload: payload,
});

export const salesListByPatientInProgress = (payload) => ({
  type: SALES_LIST_BY_PATIENT_PROGRESS,
  payload: payload,
});

export const salesListByPatientSuccess = (payload) => ({
  type: SALES_LIST_BY_PATIENT_SUCCESS,
  payload: payload,
});

export const salesListByPatientError = (payload) => ({
  type: SALES_LIST_BY_PATIENT_ERROR,
  payload: payload,
});

export const addBulkPurchaseInprogress = (payload) => ({
  type: ADD_BULK_PURCHASE_IN_PROGRESS,
  payload: payload,
});

export const addBulkPurchaseSuccess = (payload) => ({
  type: ADD_BULK_PURCHASE_SUCCESS,
  payload: payload,
});

export const addBulkPurchaseError = (payload) => ({
  type: ADD_BULK_PURCHASE_ERROR,
  payload: payload,
});
export const bulkMedicineCategoryInprogress = (payload) => ({
  type: BULK_MEDICINE_CATEGORY_INPROGRESS,
  payload: payload,
});

export const bulkMedicineCategorySuccess = (payload) => ({
  type: BULK_MEDICINE_CATEGORY_SUCCESS,
  payload: payload,
});

export const bulkMedicineCategoryError = (payload) => ({
  type: BULK_MEDICINE_CATEGORY_ERROR,
  payload: payload,
});
export const getGstListInProgress = (payload) => ({
  type: GET_GST_LIST_INPROGRESS,
  payload: payload,
});

export const getGstListSuccess = (payload) => ({
  type: GET_GST_LIST_SUCCESS,
  payload: payload,
});

export const getGstListError = (payload) => ({
  type: GET_GST_LIST_ERROR,
  payload: payload,
});
export const defaultBulkMedicineCategory = (payload) => ({
  type: DEFAULT_BULK_MEDICINE_CATEGORY,
  payload: payload,
});

export const getPharmacryReportListInProgress = (payload) => ({
  type: GET_PHARMACYREPORT_IN_PROGRESS,
  payload: payload,
});

export const getPharmacryReportListSuccess = (payload) => ({
  type: GET_PHARMACYREPORT_IN_SUCCESS,
  payload: payload,
});

export const getPharmacryReportListError = (payload) => ({
  type: GET_PHARMACYREPORT_IN_ERROR,
  payload: payload,
});

export const getAvabileStockListInProgress = (payload) => ({
  type: GET_AVABILE_STOCK_IN_PROGRESS,
  payload: payload,
});

export const getAvabileStockListSuccess = (payload) => ({
  type: GET_AVABILE_STOCK_IN_SUCCESS,
  payload: payload,
});

export const getAvabileStockListError = (payload) => ({
  type: GET_AVABILE_STOCK_IN_ERROR,
  payload: payload,
});
