import React from "react";

import AddHospital from "./AddHospital";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const EditHospital = () => {
  const location = useLocation();
  const hospitalData = location.state?.hospitalData;
  console.log("hospitalData", location.state?.hospitalData);

  return <AddHospital editHospitalData={hospitalData} editHospital={"true"} />;
};

export default EditHospital;
