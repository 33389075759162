import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateSpecializationInProgress } from "../../../appRedux/doctor/actions/Specialization";
import SpecializationForm from "./SpecializationForm";

const EditSpecialization = (props) => {
  const dispatch = useDispatch();
  const EditData = props.location.state;
  const history = useHistory();
  const id = useParams();
  const [form] = Form.useForm();
  const specializationData = useSelector((state) => state.specialization);
  const updateSpecialization = specializationData?.updateSpecialization;
  console.log("updateSpecialization", updateSpecialization);
  const onFinish = (datas) => {
    console.log("DATA", datas);
    let formData = new URLSearchParams();
    formData.append("Name", datas.Name);
    dispatch(updateSpecializationInProgress({ formData, id: EditData.id }));
  };
  useEffect(() => {
    form.setFieldsValue(EditData);
  });
  useEffect(() => {
    if (updateSpecialization?.success) {
      history.push("/specialization");
    }
  }, [updateSpecialization]);
  return (
    <SpecializationForm
      onFinish={onFinish}
      title={"specialization.label.edit"}
      buttonName={"Update"}
      form={form}
    />
  );
};

export default EditSpecialization;
