import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Typography,
  DatePicker,
  Button,
  Modal,
} from "antd";
import "../../../../styles/modules/pharmacy.less";
import { APP_ROUTES } from "../../../../constants/Routes";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  CreditCardOutlined,
  LoadingOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormInput from "../../../../components/Input/FormInput";
import IntlMessages from "../../../../util/IntlMessages";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import { data, salesIdList } from "../../../Doctor/data";
import moment from "moment";
import SalesReturnProductForm from "./SalesReturnProductForm";
import TableComponent from "../../../../components/Table/TableComponent";
import ViewSalesHistory from "./ViewSalesHistory";

import SalesFromTable from "../sales/SalesFromTable";
import { currentDate, disablePastDate } from "../../../../util/utilz";
import {
  addSalesReturnInProgress,
  getListSalesInProgress,
  updateSalesReturnInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";

const makeRow = (counter) => ({
  key: counter,
  product_name: counter,
  tablet_id: counter,
  batch_no: counter,
  quantity: counter,
  free_quantity: counter,
  purchase_price: counter,
  packing_unit: counter,
  gst_percentag: counter,
  sales_price: counter,
  discount: counter,
  total: counter,
  expiry_date: counter,
});
const AddSalesReturn = ({ salesData, editSales }) => {
  const orgId = localStorage.getItem("org_id");
  const { getSalesList, addSalesReturn, updateSalesReturn } = useSelector(
    (state) => state?.pharmacy
  );
  const medicineInformationList = getSalesList?.data?.data || [];

  const [form] = Form.useForm();
  const [counter, setCounter] = useState(1);
  const [input, setInput] = useState([makeRow(0)]);
  const [key, setKey] = useState();
  const [returnQunatity, setReturnQunatity] = useState([]);
  const [salesId, setSalesId] = useState("");
  const [salesIdData, setsalesIdData] = useState();
  const [purchaseData, setPurchaseData] = useState();

  const [salesModalOpen, setSalesModalOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const values = form.getFieldsValue();
  console.log("medicineInformationList", salesIdData?.patient_id);
  const onFinish = (datas) => {
    datas.organization_id = orgId;
    if (editSales) {
      const formData = {
        id: salesData?.id,
        data: datas,
      };

      dispatch(updateSalesReturnInProgress(formData));
    } else {
      dispatch(addSalesReturnInProgress(datas));
    }
  };
  useEffect(() => {
    if (addSalesReturn?.success || updateSalesReturn?.success) {
      history.push("/sales/return");
    }
  }, [addSalesReturn?.success, updateSalesReturn?.success]);
  const [Loading, setLoading] = useState(false);
  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };
  useEffect(() => {
    const formData = { orgId: orgId, pagination: false };
    dispatch(getListSalesInProgress(formData));
  }, []);

  const showSalesModal = () => {
    setSalesModalOpen(true);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const salesHandleOk = () => {
    setSalesModalOpen(false);
  };
  const changeSalesId = (value) => {
    setSalesId(value);
  };
  const handleCalculations = () => {
    let calculatedTotal = 0;
    let calculatedSubtotal = 0;
    let calculatedTotalDiscount = 0;
    let calculatedTotalRoundoff = 0;
    let calculatedPay = 0;

    const purchaseDetailsArray = values?.sales_return_details
      ? Object.values(values?.sales_return_details).filter(
          (value) => value != null
        )
      : [];
    purchaseDetailsArray?.forEach((product) => {
      const total = Number(product.total) ? Number(product.total) : 0;

      calculatedSubtotal += total;
    });
    calculatedTotalDiscount =
      (calculatedSubtotal * Number(values?.discount)) / 100;

    calculatedTotal =
      calculatedSubtotal -
      (calculatedTotalDiscount ? calculatedTotalDiscount : 0);

    if (calculatedTotal - Math.floor(calculatedTotal) < 0.5) {
      calculatedTotalRoundoff = Math.floor(calculatedTotal);
    } else {
      calculatedTotalRoundoff = Math.ceil(calculatedTotal);
    }
    const due = Number(values?.due) ? Number(values?.due) : 0;
    const dues = calculatedTotalRoundoff - due;
    if (dues >= 0) {
      calculatedPay = dues;
      form.setFieldValue("need_to_pay", calculatedPay);
    } else {
      calculatedPay = 0;
      form.setFieldValue("need_to_pay", calculatedPay);
    }
    form.setFieldValue("sub_total", calculatedSubtotal.toFixed(2));
    form.setFieldValue("total", calculatedTotalRoundoff.toFixed(2));
  };
  useEffect(() => {
    if (Number(key) >= 0) {
      const quantity = form.getFieldValue([
        `sales_return_details`,
        key,
        "return_quantity",
      ]);
      const purchase_price = form.getFieldValue([
        `sales_return_details`,
        key,
        "unit_price",
      ]);
      const totalAmount = Number(quantity) * Number(purchase_price);
      if (totalAmount) {
        form.setFieldValue(
          [`sales_return_details`, key, "total"],
          totalAmount.toFixed(2)
        );
      } else if (Number(totalAmount) === 0) {
        form.setFieldValue([`sales_return_details`, key, "total"], 0.0);
      }
    }
    handleCalculations();
    setKey();
  }, [key]);

  useEffect(() => {
    const salesDatas = salesData
      ? salesData
      : medicineInformationList.find((item) => item.id === values?.sales_id);
    setsalesIdData(salesDatas);
    const newInput =
      salesDatas?.sales_details?.map((value, index) => {
        onChangeTablet(value, index, true);
        return makeRow(index);
      }) ||
      salesDatas?.sales_return_details?.map((value, index) => {
        onChangeTablet(value, index, true);
        return makeRow(index);
      });

    if (newInput && newInput.length > 0) {
      setInput(newInput);
      setCounter(newInput.length);
    }

    form.setFieldValue("organization_id", salesDatas?.organization_id);
    form.setFieldValue("patient_name", salesDatas?.patient_name);
    form.setFieldValue("doctor_name", salesDatas?.doctor_name);

    form.setFieldValue("discount", salesDatas?.discount);

    form.setFieldValue(
      "deposits",
      salesDatas?.deposite || salesDatas?.deposits
    );

    if (salesData) {
      form.setFieldValue("sales_id", salesDatas.sales_id);
      form.setFieldValue("sub_total", salesDatas?.sub_total);
      form.setFieldValue("total", salesDatas?.total);
      form.setFieldValue("due", salesDatas?.due);
      form.setFieldValue("need_to_pay", salesDatas?.need_to_pay);
      form.setFieldValue("return_date", salesData?.return_date);
    } else {
      form.setFieldValue("return_date", currentDate);
      const dues = Number(salesDatas?.total) - Number(salesDatas?.deposite);
      if (dues === 0) {
        form.setFieldValue("due", 0);
        form.setFieldValue("need_to_pay", salesDatas?.deposite);
      } else if (dues > 0) {
        form.setFieldValue("due", salesDatas?.total - salesDatas?.deposite);
        form.setFieldValue("need_to_pay", 0);
      } else {
        form.setFieldValue("due", 0);
        form.setFieldValue(
          "need_to_pay",
          salesDatas?.deposite - salesDatas?.total
        );
      }
    }
  }, [values?.sales_id, salesData]);

  const onChangeTablet = (data, key, view) => {
    form.setFieldValue(
      [`sales_return_details`, key, "product_name"],
      data?.medicine_name || data.product_name
    );
    form.setFieldValue(
      [`sales_return_details`, key, "tablet_id"],
      data?.tablet_id || data?.id
    );

    form.setFieldValue(
      [`sales_return_details`, key, "purchase_code"],
      data?.purchase_code
    );
    form.setFieldValue(
      [`sales_return_details`, key, "quantity"],
      data.quantity
    );
    const index = returnQunatity.findIndex((values) => values.key === key);
    const availableStock = data.quantity;
    if (index !== -1) {
      const updatedBatchNo = [...returnQunatity];

      updatedBatchNo[index] = { availableStock, key };
      setReturnQunatity(updatedBatchNo);
    } else {
      setReturnQunatity((prevBatchNo) => [
        ...prevBatchNo,
        { availableStock, key },
      ]);
    }

    form.setFieldValue(
      [`sales_return_details`, key, "purchase_price"],
      data.purchase_price
    );

    form.setFieldValue(
      [`sales_return_details`, key, "unit_price"],
      data.unit_price
    );
    form.setFieldValue(
      [`sales_return_details`, key, "gst"],
      data.gst_percentage || data.gst
    );

    form.setFieldValue(
      [`sales_return_details`, key, "batch_no"],
      data.batch_no
    );

    form.setFieldValue(
      [`sales_return_details`, key, "return_quantity"],
      data.return_quantity
    );

    form.setFieldValue(
      [`sales_return_details`, key, "total"],
      data.return_quantity * data.unit_price
    );

    form.setFieldValue(
      [`sales_return_details`, key, "sales_price"],
      data.sale_price || data.sales_price
    );
    form.setFieldValue(
      [`sales_return_details`, key, "expiry_date"],
      data.expiry_date
    );
    form.setFieldValue([`sales_return_details`, key, "due"], "");
    form.setFieldValue(
      [`sales_return_details`, key, "remarks"],
      data.remarks || ""
    );
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      render: (_, render) => (
        <>
          {" "}
          <Form.Item shouldUpdate>
            {" "}
            <FormInput
              name={[`${render.key}`, "product_name"]}
              className="input len"
              type="text"
              readOnly={true}
            />{" "}
          </Form.Item>
        </>
      ),
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            name={[`${render.key}`, "batch_no"]}
            className="input len"
            type="text"
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Sales Price",
      dataIndex: "sales_price",
      key: "sales_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={1}
            name={[`${render.key}`, "sales_price"]}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",

      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "unit_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"Enter the unit price"} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "GST (%)",
      dataIndex: "gst",
      key: "gst",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "gst"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"Enter the gst "} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Return Quantity",
      dataIndex: "return_quantity",
      key: "Return Quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            min={1}
            name={[`${render.key}`, "return_quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    value <=
                      Number(
                        returnQunatity.find((val) => val?.key === render?.key)
                          ?.availableStock
                      )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    Number(
                      returnQunatity.find((val) => val?.key === render?.key)
                        ?.availableStock
                    )
                      ? `Available Quantity are ${Number(
                          returnQunatity.find((val) => val?.key === render?.key)
                            ?.availableStock
                        )}`
                      : ""
                  );
                },
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            name={[`${render.key}`, "total"]}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            name={[`${render.key}`, "remarks"]}
            className="input len"
            type="text"
          />{" "}
        </Form.Item>
      ),
    },
  ];
  return (
    <>
      <Spin
        indicator={antIcon}
        spinning={addSalesReturn?.inProgress || updateSalesReturn?.inProgress}
      >
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editSales ? (
                    <IntlMessages id={"label.editSalesReturn"} />
                  ) : (
                    <>
                      <PlusCircleFilled />{" "}
                      <IntlMessages id={"label.addNewSalesReturn"} />
                    </>
                  )}
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={<IntlMessages id={"Addnew.pharmacy.label.salesId"} />}
                  name="sales_id"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Medicine.validator.salesId"} />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    placeholder={"select"}
                    disabled={salesData}
                    options={
                      medicineInformationList.map((pack, i) => {
                        return {
                          label: pack.sales_code,
                          value: pack.id,
                        };
                      }) || []
                    }
                    onChange={changeSalesId}
                    className="responsive-select"
                  />
                </Form.Item>
                {/* <Typography className="purchase-label">
                  {<IntlMessages id={"Addnew.pharmacy.label.salesId"} />}
                </Typography>
                <FormSelectBox
                  value={salesId}
                  options={salesIdList}
                  onChange={changeSalesId}
                  showSearch="true"
                  className="select-pharmacy"
                  disabled={editSales}
                /> */}
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name={"return_date"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.returnDate"} />
                  }
                >
                  <FormInput
                    type="date"
                    placeholder="date"
                    name={"return_date"}
                    min={disablePastDate()}
                    labelCol={"24"}
                    wrapperCol={"24"}
                    readOnly={salesData}
                    defaultValue={currentDate}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Medicine.validator.add_date"} />
                        ),
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"patient_name"}
                  readOnly
                  label={<IntlMessages id={"Addnew.pharmacy.label.patient"} />}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  readOnly
                  name={"doctor_name"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.doctor"} />}
                />
              </Col>
              {salesId &&
                editSales != "true" &&
                salesIdData?.patient_type === 1 && (
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <ButtonComponent
                      label="view status"
                      type="primary"
                      onClick={() => showSalesModal()}
                    />
                  </Col>
                )}
            </Row>
            <Row gutter={[0, 16]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {<IntlMessages id={"label.medicines"} />}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.List name={"sales_return_details"}>
                  {(fields, { add }) => (
                    <SalesFromTable
                      addRow={addRow}
                      add={add}
                      columns={columns}
                      input={input}
                      viewData={true}
                      // disabled={error}
                    />
                  )}
                </Form.List>
                {input.length === 0 && (
                  <Typography className="red-text">
                    select the sales informations !
                  </Typography>
                )}
              </Col>

              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <SalesProductForm
                  wallet={wallet}
                  salesInformationData={salesDatas}
                  // setSaleseData={setPurchaseData}
                  editSales={editSales}
                  onFinish={onFinish}
                  paymentData={paymentData}
                  form={form}
                  setUpdatedProduct={setUpdatedProduct}
                  setDiscount={setDiscount}
                />
              </Col> */}
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <SalesReturnProductForm
                  salesInformationData={salesData}
                  setSaleseData={setPurchaseData}
                  salesData={salesIdData}
                  editSales={editSales}
                />
              </Col>
            </Row>
            <Row className="mt-10">
              <Col xl={23} align="right" className="button-alignment-pharmacy">
                <Link to={APP_ROUTES.SALESRETURN}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>
                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editSales ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
            <Modal
              title={
                <Typography className="cancel-sales">
                  Sales History-{salesIdData?.patientId}
                </Typography>
              }
              open={salesModalOpen}
              width={1000}
              // onOk={salesHandleOk}
              // onCancel={salesHandleCancel}
              closable={false}
              footer={[
                <Button
                  key="back"
                  type="danger"
                  onClick={() => salesHandleOk()}
                >
                  Close
                </Button>,
              ]}
            >
              <ViewSalesHistory patientId={salesIdData} />
            </Modal>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddSalesReturn;
