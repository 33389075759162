import { BLOOD_GROUP_IN_FAILURE, BLOOD_GROUP_IN_PROGRESS, BLOOD_GROUP_IN_SUCCESS, GET_PATIENT_BILLING_REPORT_IN_ERROR, GET_PATIENT_BILLING_REPORT_IN_PROGRESS, GET_PATIENT_BILLING_REPORT_IN_SUCCESS, GET_PATIENT_LIST_BY_ID_IN_FAILURE, GET_PATIENT_LIST_BY_ID_IN_PROGRESS, GET_PATIENT_LIST_BY_ID_IN_SUCCESS, PATIENT_ADD_LIST_IN_FAILURE, PATIENT_ADD_LIST_IN_PROGRESS, PATIENT_ADD_LIST_IN_SUCCESS, PATIENT_CSV_UPLOAD_IN_FAILURE, PATIENT_CSV_UPLOAD_IN_PROGRESS, PATIENT_CSV_UPLOAD_IN_SUCCESS, PATIENT_DELETE_LIST_IN_FAILURE, PATIENT_DELETE_LIST_IN_PROGRESS, PATIENT_DELETE_LIST_IN_SUCCESS, PATIENT_LIST_IN_FAILURE, PATIENT_LIST_IN_PROGRESS, PATIENT_LIST_IN_SUCCESS, PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE, PATIENT_UPDATE_LIST_IN_FAILURE, PATIENT_UPDATE_LIST_IN_PROGRESS, PATIENT_UPDATE_LIST_IN_SUCCESS } from "../../../../constants/ActionTypes";

// get
export const patientListInProgress = payload => {
    console.log("action")
      return {
        type: PATIENT_LIST_IN_PROGRESS,
        payload: payload,

      };
    };
    export const patientListInSuccess = payload => {
      return {
        type: PATIENT_LIST_IN_SUCCESS,
        payload: payload,
      };
    };
    export const patientListInFailure = payload => {
      return {
        type: PATIENT_LIST_IN_FAILURE,
        payload: payload,
      };
    };
  //   POST
  export const patientAddListInProgress = payload => {
      return {
        type: PATIENT_ADD_LIST_IN_PROGRESS,
        payload: payload,
      };
    };
    export const patientAddListInSuccess = payload => {
      return {
        type: PATIENT_ADD_LIST_IN_SUCCESS,
        payload: payload,
      };
    };
    export const patientAddListInFailure = payload => {
      return {
        type: PATIENT_ADD_LIST_IN_FAILURE,
        payload: payload,
      };
    };
  //   delete
  export const patientDeleteListInProgress = payload => {
      return {
        type: PATIENT_DELETE_LIST_IN_PROGRESS,
        payload: payload,
      };
    };
    export const patientDeleteListInSuccess = payload => {
      return {
        type: PATIENT_DELETE_LIST_IN_SUCCESS,
        payload: payload,
      };
    };
    export const patientDeleteListInFailure = payload => {
      return {
        type: PATIENT_DELETE_LIST_IN_FAILURE,
        payload: payload,
      };
    };
    // update
  export const patientUpdateListInProgress = payload => {
    return {
      type: PATIENT_UPDATE_LIST_IN_PROGRESS,
      payload: payload,
    };
  };
  export const patientUpdateListInSuccess = payload => {
    return {
      type: PATIENT_UPDATE_LIST_IN_SUCCESS,
      payload: payload,
    };
  };
  export const patientUpdateListInFailure = payload => {
    return {
      type: PATIENT_UPDATE_LIST_IN_FAILURE,
      payload: payload,
    };
  };
  // GET PATIENT BY ID
  export const getPatientListByIdInProgress = (payload) => {
    console.log("action")
      return {
        type: GET_PATIENT_LIST_BY_ID_IN_PROGRESS,
        payload: payload,
      };
    };
    export const getPatientListByIdInSuccess = payload => {
      return {
        type: GET_PATIENT_LIST_BY_ID_IN_SUCCESS,
        payload: payload,
      };
    };
    export const getPatientListByIdInFailure = payload => {
      return {
        type: GET_PATIENT_LIST_BY_ID_IN_FAILURE,
        payload: payload,
      };
    };
    // patient csv file upload
    export const PatientFileUploadInProgress = (payload) => {
      console.log("action")
        return {
          type: PATIENT_CSV_UPLOAD_IN_PROGRESS,
          payload: payload,
        };
      };
      export const PatientFileUploadInSuccess = payload => {
        return {
          type: PATIENT_CSV_UPLOAD_IN_SUCCESS,
          payload: payload,
        };
      };
      export const PatientFileUploadInFailure = payload => {
        return {
          type: PATIENT_CSV_UPLOAD_IN_FAILURE,
          payload: payload,
        };
      };
      // blood grp
      export const bloodGroupInProgress = (payload) => {
          return {
            type: BLOOD_GROUP_IN_PROGRESS,
            payload: payload,
          };
        };
        export const bloodGroupInSuccess = payload => {
          return {
            type: BLOOD_GROUP_IN_SUCCESS,
            payload: payload,
          };
        };
        export const bloodGroupInFailure = payload => {
          return {
            type: BLOOD_GROUP_IN_FAILURE,
            payload: payload,
          };
        };
        // get
export const patientBillingReportListInprogress = payload => {
    return {
      type: GET_PATIENT_BILLING_REPORT_IN_PROGRESS,
      payload: payload,

    };
  };
  export const patientBillingReportListInSuccess = payload => {
    return {
      type: GET_PATIENT_BILLING_REPORT_IN_SUCCESS,
      payload: payload,
    };
  };
  export const patientBillingReportListInFailure = payload => {
    return {
      type: GET_PATIENT_BILLING_REPORT_IN_ERROR,
      payload: payload,
    };
  };

