import React, { useEffect, useState } from "react";

import { Card, Modal } from "antd";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { UserLogoutInProgress } from "../../appRedux/doctor/actions/AuthAction";
import { useSelector } from "react-redux";

const LogoutScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const logoutSuccess = useSelector((state) => state.authData?.authData);
  const getToken = localStorage.getItem("user_id");
  console.log("logoutSuccess", logoutSuccess);
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(UserLogoutInProgress());
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (getToken == null) history.push("/signin");
  }, [getToken == null]);
  return (
    <Card>
      <Modal
        title="LOGOUT"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </Card>
  );
};

export default LogoutScreen;
