import axios from "axios";

const TabletsInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});
export const TabletListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  console.log("formDataformDataformData", formData);
  return TabletsInstance.request({
    url: `/tablet/organization`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      organization_id: formData?.organization_id,
      pagesize: 10,
      pageNo: formData?.page,
      pagination_required: true,
    },
  });
};

export const NewTabletEffect = (formData) => {
  console.log("formData", formData);
  const token = localStorage.getItem("user_id");
  return TabletsInstance.request({
    url: `/tablet/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const GetTabletEffect = (page) => {
  const token = localStorage.getItem("user_id");
  return TabletsInstance.request({
    url: "/tablet/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      pagesize: 10,
      pageNO: page,
      pagination_required: true,
    },
  });
};
export const UpdateTabletEffect = (formData) => {
  console.log("formData", formData);
  const token = localStorage.getItem("user_id");
  return TabletsInstance.request({
    url: `/tablet/update/${formData?.TabletId}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const DeleteTabletEffect = (formData) => {
  console.log("formData", formData);
  const token = localStorage.getItem("user_id");
  return TabletsInstance.request({
    url: `/tablet/delete/${formData}`,
    method: "DELETE",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const TabletByOrgEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return TabletsInstance.request({
    url: "/tablet/organization",
    method: "GET",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      pagesize: 10,
      pageNo: formData,
      pagination_required: true,
    },
  });
};
