import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import FormItem from "antd/es/form/FormItem";
import "../../../styles/modules/pharmacy.less";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  editPharmacyProfileInProgress,
  getPharmacyProfileInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  // if (!isJpgOrPng) {
  //   message.error("You can only upload JPG/PNG file!");
  // }
  const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error("Image must smaller than 2MB!");
  // }
  return isJpgOrPng && isLt2M;
};
const PharmacyProfileScreen = () => {
  const [LoadingPage, setLoadingPage] = useState(false);
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.pharmacy?.pharmacyProfile);
  const EditProfileData = useSelector(
    (state) => state?.pharmacy?.editPharmacyProfile
  );
  const userData = profileData?.data?.data;
  console.log("profileDataprofileDataprofileData", profileData);
  const [form] = Form.useForm();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const onFinish = (datas) => {
    console.log("purchase-Request-Data", datas);

    const base64WithoutPrefix = imageUrl.slice(22);
    console.log("base64WithoutPrefix", base64WithoutPrefix);
    const formData = {
      firstName: datas?.firstName,
      lastName: datas?.lastName,
      email: datas?.email,
      contact: datas?.contact,
      profile_image: base64WithoutPrefix,
    };
    dispatch(editPharmacyProfileInProgress({ formData, id: pharmacyId }));
  };
  const getEmail = localStorage.getItem("email");
  const getPassword = localStorage.getItem("pass");
  console.log("getEmail", getEmail);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  console.log("setImageUrl", imageUrl);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);
  useEffect(() => {
    if (profileData?.success) {
      form.setFieldsValue(userData);
      if (userData?.profile_url) {
        setImageUrl(userData.profile_image);
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (EditProfileData?.inProgress) {
      setLoadingPage(true);
    } else {
      setLoadingPage(false);
    }
  }, [EditProfileData]);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={LoadingPage}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Typography className="header-tag">
              {<IntlMessages id={"label.manageProfile"} />}
            </Typography>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              colon={"true"}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row className="register-row">
                <Col
                  xl={23}
                  lg={23}
                  md={23}
                  sm={23}
                  xs={23}
                  className="justify-center"
                >
                  <Upload
                    name="profile_url"
                    listType="picture-circle"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"firstName"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.firstName"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.firstName"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"lastName"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.lastName"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.lastName"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"email"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.email"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.email"}
                          />
                        ),
                      },
                      {
                        pattern: emailRegex,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  />
                </Col>

                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"contact"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.mobile"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.mobile"}
                          />
                        ),
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number!",
                      },
                    ]}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col
                  xl={23}
                  align="right"
                  className="button-alignment-pharmacy"
                >
                  <ButtonComponent
                    label="Update"
                    htmlType="submit"
                    type="primary"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default PharmacyProfileScreen;
