import React, { useEffect, useState } from "react";
import NewBillingPayment from "./NewBillingPayment";
import { Form } from "antd";
import Auxiliary from "../../../util/Auxiliary";
import BillingFromHeader from "./BillingFromHeader";
import { useDispatch, useSelector } from "react-redux";
import { addBillingInProgress } from "../../../appRedux/doctor/actions/addPayment";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/Routes";

function AddFrom() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { billingPaymentreducer } = useSelector((state) => state);
  const [navigate, setNavigate] = useState(false);
  const history = useHistory();
  const organization_id = localStorage.getItem("org_id");
  let DATE = moment().format("YYYY-MM-DD");
  useEffect(() => {
    form.setFieldsValue({ next_review_date: DATE });
  }, []);
  const onFinish = (data) => {
    let paid_amount = 0;
    data?.BillingPayments?.forEach((dat) => {
      if (dat.paid_amount) {
        paid_amount += Number(dat.paid_amount);
      }
    });

    const modifiedData = [
      {
        ...data,
        BillingPayments: data.BillingPayments.map((payment) => ({
          ...payment,
          Date: DATE,
        })),
        grand_total: data.sub_total - data.discount,
        organization_id: organization_id,
        DATE: DATE,
        paid_amount: paid_amount,
        remarks: data?.remarks ? data?.remarks : "",
      },
    ];

    dispatch(addBillingInProgress({ package_details: modifiedData }));
    setNavigate(true);
  };

  useEffect(() => {
    if (billingPaymentreducer?.addBilling?.success && navigate) {
      history.push(APP_ROUTES.BILLINGS);
      setNavigate(false);
    }
  }, [billingPaymentreducer?.addBilling?.success && navigate]);

  return (
    <Auxiliary>
      <BillingFromHeader />
      <NewBillingPayment form={form} onFinish={onFinish} />
    </Auxiliary>
  );
}

export default AddFrom;
