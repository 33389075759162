import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import FormInput from "../../components/Input/FormInput";
import ButtonComponent from "../../components/Button/ButtonComponent";
import {
  addWardInProgress,
  getAvailableRoomsListInProgress,
  getBlockListInProgress,
  getWardListByBlockInProgress,
  roomListsInProgress,
  roomShiftInProgress,
} from "../../appRedux/Ward/WardAction";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import { currentDate } from "../../util/utilz";

const ShiftRoom = ({ editWard }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const bookedRoomData = location.state?.roomData;
  const [blockValue, setBlockValue] = useState("");
  const [wardValue, setWardValue] = useState("");
  const [roomValue, setRoomValue] = useState("");
  const shiftRoom = useSelector((state) => state?.ward?.roomShift);

  const blockListData = useSelector((state) => state?.ward?.blockList);
  const roomListByWard = useSelector(
    (state) => state?.ward?.availableRoomsList
  );
  const wardListByBlockData = useSelector(
    (state) => state?.ward?.wardListByBlock
  );
  const blockList = blockListData?.data?.data?.map((block) => ({
    label: block.block_name,
    value: block.id,
  }));
  const wardList = wardListByBlockData?.data?.data?.map((block) => ({
    label: block.ward_name,
    value: block.id,
  }));
  const roomList = roomListByWard?.data?.data?.map((block) => ({
    label: block.room_no,
    value: block.id,
  }));
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (data) => {
    console.log("datas", data);

    const payload = {
      block_id: blockValue,
      ward_id: wardValue,
      room_id: data?.room_number,
      patient_id: bookedRoomData?.patient_id || "",
      doctor_id: bookedRoomData?.doctor_id || "",
      check_in_reason: data?.checkin_reason || "",
      follow_procedure: data?.follow_procedure || "",
      check_in_incharge: data?.checkin_incharge_name || "",
      check_in_date: data?.room_shift_date,
      checkin_by: data?.checkin_by || "",
      room_type: data?.room_type === "AC" ? "1" : "2",
      id: bookedRoomData?.id,
    };
    console.log("payload", payload);
    dispatch(roomShiftInProgress(payload));

    if (shiftRoom?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const [Loading, setLoading] = useState(false);

  const changeBlock = (value) => {
    setBlockValue(value);
    const payload = {
      pagination: "false",
      pageNo: "1",
      pageSize: "10",
      block_id: value,
    };
    console.log("payload", payload);
    dispatch(getWardListByBlockInProgress(payload));
  };
  const changeWard = (value) => {
    setWardValue(value);
  };
  const changeRoom = (value) => {
    setRoomValue(value);

    const selectedRoom = roomListByWard?.data?.data.find(
      (room) => room.id === value
    );
    console.log("selectedRoom", selectedRoom);
    form.setFieldsValue({
      room_type: selectedRoom?.room_type === "1" ? "AC" : "NON-AC" || "",
      block_name: selectedRoom?.Block?.block_name,
      ward_name: selectedRoom?.Ward?.ward_name,
    });

    setBlockValue(selectedRoom?.Block?.id);
    setWardValue(selectedRoom?.Ward?.id);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const addDefaultRow = useRef(null);
  useEffect(() => {
    if (addDefaultRow.current) {
      addDefaultRow.current();
    }
  }, []);

  useEffect(() => {
    if (shiftRoom?.success) {
      history.push("/booked-rooms");
    }
  }, [shiftRoom]);

  useEffect(() => {
    // const payload = { pagination: "false", pageNo: "1", pageSize: "10" };
    // dispatch(getBlockListInProgress(payload));
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getAvailableRoomsListInProgress(payload));
  }, []);
  console.log("bookedRoomData", bookedRoomData);
  useEffect(() => {
    form.setFieldValue(
      "checkin_incharge_name",
      bookedRoomData?.check_in_incharge
    );
    form.setFieldValue("checkin_reason", bookedRoomData?.check_in_reason);
    form.setFieldValue("checkin_by", bookedRoomData?.checkin_by);
    form.setFieldValue("follow_procedure", bookedRoomData?.follow_procedure);
    form.setFieldValue("room_shift_date", currentDate);
  }, [bookedRoomData]);

  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  <IntlMessages id={"label.shiftRoom"} />
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  {" "}
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={
                        <IntlMessages id={"Addnew.ward.label.roomNumber"} />
                      }
                      name={"room_number"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomNumber"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Room Number"
                        value={roomValue}
                        options={roomList || []}
                        onChange={changeRoom}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>{" "}
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={
                        <IntlMessages id={"Addnew.ward.label.blockName"} />
                      }
                      name={"block_name"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.blockName"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select  Block"
                        value={blockValue}
                        options={blockList || []}
                        onChange={changeBlock}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>{" "}
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.wardName"} />}
                      name={"ward_name"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.wardName"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select room Type"
                        value={wardValue}
                        options={wardList || []}
                        onChange={changeWard}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>{" "}
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      readOnly={true}
                      type="text"
                      name={"room_type"}
                      label={<IntlMessages id={"Addnew.ward.label.roomType"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomType"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="date"
                      readOnly={true}
                      name={"room_shift_date"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.roomShiftDate"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomShiftDate"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"checkin_incharge_name"}
                      readOnly={true}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.checkinInchargeName"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkinInchargeName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"checkin_reason"}
                      readOnly={true}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.checkinInReason"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkinReason"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"checkin_by"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkinInBy"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkinInBy"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"follow_procedure"}
                      readOnly={true}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.followProcedure"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.followProcedure"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link
                  to={{
                    pathname: "/booked-rooms",
                  }}
                >
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editWard ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default ShiftRoom;
