import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Typography,
  DatePicker,
  message,
} from "antd";
import "../../../../styles/modules/pharmacy.less";
import { APP_ROUTES } from "../../../../constants/Routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CreditCardOutlined,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormInput from "../../../../components/Input/FormInput";
import IntlMessages from "../../../../util/IntlMessages";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import { pharmacyList, purchaseTypeList } from "../../../Doctor/data";
import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import {
  addPurchaseInProgress,
  editPurchaseInProgress,
  getPurchaseRequestListInProgress,
  getSupplierListInProgress,
  getTabletsListInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import { disablePastDate, currentDate } from "../../../../util/utilz";
import PurchaseFromTable from "./PurchaseFromTable";
import "../purchase.less";

import PurchaseFooters from "../PurchaseFooters";

const AddPurchase = ({
  editPurchases,
  editPurchaseData,
  viewData,
  viewPurchaseData,
}) => {
  const makeRow = (counter) => ({
    key: counter,
    product_name: counter,
    tablet_id: counter,
    batch_no: counter,
    quantity: counter,
    free_quantity: counter,
    purchase_price: counter,
    packing_unit: counter,
    gst_percentag: counter,
    sale_price: counter,
    discount: counter,
    amount: counter,
    expiry_date: counter,
  });
  const orgId = localStorage.getItem("org_id");
  const [form] = Form.useForm();
  const location = useLocation();
  const [input, setInput] = useState([makeRow(0)]);
  const [key, setKey] = useState();
  const [counter, setCounter] = useState(1);
  const [purchaseType, setPurchaseType] = useState([]);
  const [purchaseRequestCode, setpurchaseRequestCode] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [purchaseData, setPurchaseData] = useState();
  const [formData, setFormData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const values = form.getFieldsValue();
  const {
    purchaseRequestList,
    tabletList,
    getSupplierList,
    addPurchase,
    editPurchase,
  } = useSelector((state) => state.pharmacy);

  const purchase_request_code =
    purchaseRequestList?.data?.data?.map((data) => {
      const obj = {};
      obj.label = data.purchase_request_code;
      obj.value = data.purchase_request_code;
      return obj;
    }) || [];
  const companyNameList =
    getSupplierList?.data?.data?.map((data) => {
      const obj = {};
      obj.label = data.company_name;
      obj.value = data.id;
      return obj;
    }) || [];
  const productType = form?.getFieldValue("purchase_type");
  const purchaseRequestCodes = form?.getFieldValue("purchase_req_code");
  const requestdetails = purchaseRequestList?.data?.data?.find((data) => {
    if (data.purchase_request_code === purchaseRequestCodes) {
      return data;
    }
  });

  useEffect(() => {
    form.setFieldValue("purchase_type", "1");
    form.setFieldValue("invoice_date", currentDate);
    const payload = {
      organization_id: orgId,
      pageNo: 1,
      pageSize: 10,
      pagination_required: false,
    };
    dispatch(getPurchaseRequestListInProgress(payload));
  }, [location.state === "Add"]);
  useEffect(() => {
    if (requestdetails) {
      form.setFieldValue(
        "company_name",
        requestdetails?.Supplier?.company_name
      );
      form.setFieldValue("address1", requestdetails?.Supplier?.address);
      form.setFieldValue("address2", requestdetails?.Supplier?.address2);
      form.setFieldValue("city", requestdetails?.Supplier?.city);
      form.setFieldValue("state", requestdetails?.Supplier?.state);
      form.setFieldValue("zipcode", requestdetails?.Supplier?.zipcode);
      form.setFieldValue("mobile", requestdetails?.Supplier?.mobile);
      form.setFieldValue("supplier_id", requestdetails?.supplier_id);
    }
  }, [requestdetails]);
  useEffect(() => {
    let calculatedTotal = 0;
    let calculatedSubtotal = 0;
    let calculatedTotalGST = 0;
    let calculatedTotalDiscount = 0;
    let calculatedTotalRoundoff = 0;

    const purchaseDetailsArray = values?.purchase_details
      ? Object.values(values?.purchase_details).filter((value) => value != null)
      : [];
    purchaseDetailsArray?.forEach((product) => {
      console.log("product", product);
      const amount =
        (Number(product.quantity) ? Number(product.quantity) : 0) *
        (Number(product.purchase_price) ? Number(product.purchase_price) : 0);

      if (Number(product.quantity) && Number(product.purchase_price)) {
        calculatedSubtotal += amount;
      }

      let gstValue =
        ((Number(product.gst_percentage) ? Number(product.gst_percentage) : 0) *
          (Number(amount) ? Number(amount) : 0)) /
        100;
      calculatedTotalGST +=
        gstValue -
        ((Number(product.gst_percentage) ? Number(product.gst_percentage) : 0) *
          (Number(product.discount) ? Number(product.discount) : 0)) /
          100;
      calculatedTotalDiscount +=
        ((Number(amount) ? Number(amount) : 0) *
          (Number(product.discount) ? Number(product.discount) : 0)) /
        100;
    });
    calculatedTotal =
      calculatedSubtotal + calculatedTotalGST - calculatedTotalDiscount;
    if (calculatedTotal - Math.floor(calculatedTotal) < 0.5) {
      calculatedTotalRoundoff = Math.floor(calculatedTotal);
    } else {
      calculatedTotalRoundoff = Math.ceil(calculatedTotal);
    }
    console.log(
      "calculatedSubtotal",
      calculatedSubtotal,
      calculatedTotal,
      calculatedTotalGST,
      calculatedTotalDiscount
    );
    form.setFieldValue(
      "gst_amount",
      calculatedTotalGST > 0 ? calculatedTotalGST.toFixed(2) : "0.0"
    );
    form.setFieldValue("sub_total", calculatedSubtotal.toFixed(2));
    form.setFieldValue(
      "total",
      calculatedTotal > 0 ? calculatedTotal.toFixed(2) : "0.0"
    );
    form.setFieldValue("discount", calculatedTotalDiscount.toFixed(2));
    form.setFieldValue(
      "round_total",
      calculatedTotalRoundoff ? calculatedTotalRoundoff : "0"
    );
    setKey();
  }, [key]);

  useEffect(() => {
    const fromData = { pagination_required: false, page: 1 };

    dispatch(getTabletsListInProgress(fromData));
    dispatch(getSupplierListInProgress(false));
  }, []);

  const changeCompanyName = (value) => {
    const selectedCompany = getSupplierList?.data?.data?.find(
      (company) => company?.id === value
    );
    form.setFieldValue("supplier_id", selectedCompany?.id);
    form.setFieldValue("company_name", selectedCompany?.company_name);
    form.setFieldValue("billNumber", selectedCompany?.billNumber);
    form.setFieldValue("address1", selectedCompany?.address);
    form.setFieldValue("address2", selectedCompany?.address2);
    form.setFieldValue("city", selectedCompany?.city);
    form.setFieldValue("state", selectedCompany?.state);
    form.setFieldValue("zipcode", selectedCompany?.zipcode);
    form.setFieldValue("mobile", selectedCompany?.mobile);
  };

  const changepurchaseType = (value) => {
    setPurchaseType(value);
  };

  const changepurchaseRequestCode = (value) => {
    setpurchaseRequestCode(value);
  };
  const onChangeTablet = (data, key, view) => {
    console.log("data", data);
    form.setFieldValue(
      [`purchase_details`, key, "product_name"],
      data?.medicine_name || data.product_name
    );
    form.setFieldValue(
      [`purchase_details`, key, "tablet_id"],
      view
        ? data?.medicine_name || data.product_name
        : data?.tablet_id || data?.id
    );
    form.setFieldValue(
      [`purchase_details`, key, "quantity"],
      data.minimum_order_quantity || data.quantity || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "purchase_price"],
      data.price || data.purchase_price || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "unit_price"],
      data.unit_price || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "gst_percentage"],
      data.gst_percentage || data.gst_percent || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "free_quantity"],
      data.free_quantity || 0
    );
    form.setFieldValue([`purchase_details`, key, "batch_no"], data.batch_no);
    form.setFieldValue([`purchase_details`, key, "amount"], data.amount || 0);
    form.setFieldValue(
      [`purchase_details`, key, "packing_unit"],
      data.packing_unit || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "sale_price"],
      data.s_price || data.sale_price || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "discount"],
      data.discount || 0
    );
    form.setFieldValue(
      [`purchase_details`, key, "expiry_date"],
      data.expiry_date
    );
    setKey(key);
  };
  useEffect(() => {
    if (editPurchases) {
      form.setFieldValue(
        "purchase_type",
        purchaseTypeList.find(
          (id) => Number(id.value) === Number(editPurchaseData?.purchase_type)
        )?.label
      );
      form.setFieldValue(
        "purchase_req_code",
        editPurchaseData?.purchase_req_code
      );
      form.setFieldValue("invoice_date", editPurchaseData?.invoice_date);
      form.setFieldValue("purchase_code", editPurchaseData?.purchase_code);
      form.setFieldValue("company_name", editPurchaseData?.company_name);
      form.setFieldValue("billNumber", editPurchaseData?.billNumber);
      form.setFieldValue("address1", editPurchaseData?.address1);
      form.setFieldValue("address2", editPurchaseData?.address2);
      form.setFieldValue("city", editPurchaseData?.city);
      form.setFieldValue("state", editPurchaseData?.state);
      form.setFieldValue("zipcode", editPurchaseData?.zipCode);
      form.setFieldValue("mobile", editPurchaseData?.mobile);
      form.setFieldValue("bill_no", editPurchaseData?.bill_no);

      form.setFieldValue("sub_total", editPurchaseData?.sub_total);
      form.setFieldValue("gst_amount", editPurchaseData?.gst_amount);
      form.setFieldValue("total", editPurchaseData?.total);
      form.setFieldValue("round_total", editPurchaseData?.round_total);
      form.setFieldValue("discount", editPurchaseData?.discount);

      const newInput = editPurchaseData?.purchase_details?.map(
        (value, index) => {
          onChangeTablet(value, index);
          return makeRow(index);
        }
      );

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    } else if (viewData) {
      console.log("viewPurchaseData", viewPurchaseData);
      form.setFieldValue(
        "invoice_date",
        moment(viewPurchaseData?.invoice_date).format("DD/MM/YYYY")
      );
      form.setFieldValue("purchase_code", viewPurchaseData?.purchase_code);
      form.setFieldValue(
        "purchase_type",
        purchaseTypeList.find(
          (id) => Number(id.value) === Number(viewPurchaseData?.purchase_type)
        )?.label
      );
      form.setFieldValue(
        "purchaseRequestCode",
        viewPurchaseData?.purchase_req_code
      );
      form.setFieldValue("company_name", viewPurchaseData?.company_name);
      form.setFieldValue("billNumber", viewPurchaseData?.billNumber);
      form.setFieldValue("address1", viewPurchaseData?.address1);
      form.setFieldValue("address2", viewPurchaseData?.address2);
      form.setFieldValue("city", viewPurchaseData?.city);
      form.setFieldValue("state", viewPurchaseData?.state);
      form.setFieldValue("zipcode", viewPurchaseData?.zipCode);
      form.setFieldValue("mobile", viewPurchaseData?.mobile);
      form.setFieldValue("bill_no", viewPurchaseData?.bill_no);
      form.setFieldValue("sub_total", viewPurchaseData?.sub_total);
      form.setFieldValue("gst_amount", viewPurchaseData?.gst_amount);
      form.setFieldValue("total", viewPurchaseData?.total);
      form.setFieldValue("round_total", viewPurchaseData?.round_total);
      form.setFieldValue("discount", viewPurchaseData?.discount);

      const newInput = viewPurchaseData?.purchase_details?.map(
        (value, index) => {
          onChangeTablet(value, index, viewPurchaseData);
          return makeRow(index);
        }
      );

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    }
  }, [purchaseData]);

  useEffect(() => {
    if (purchaseRequestCode && !viewData && !editPurchaseData) {
      setInput([makeRow(0)]);
      const newInput = requestdetails?.purchase_request_details?.map(
        (value, index) => {
          onChangeTablet(value, index);
          return makeRow(index);
        }
      );
      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    }
  }, [purchaseRequestCode]);

  useEffect(() => {
    if (Number(key) >= 0) {
      const quantity = form.getFieldValue([
        `purchase_details`,
        key,
        "quantity",
      ]);
      const purchase_price = form.getFieldValue([
        `purchase_details`,
        key,
        "purchase_price",
      ]);
      const totalAmount = Number(quantity) * Number(purchase_price);
      console.log("df", totalAmount, Number(totalAmount));
      if (Number(totalAmount)) {
        form.setFieldValue(
          [`purchase_details`, key, "amount"],
          totalAmount.toFixed(2)
        );
      } else if (Number(totalAmount) === 0) {
        form.setFieldValue([`purchase_details`, key, "amount"], 0);
      }

      setKey();
    }
  }, [key]);
  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };
  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);

    setInput(updatedInput);
    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.purchase_details[key] = null;
      setFormData(formDataCopy);
      setKey(key);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.purchase_details[key] = null;
      setFormData(formDataCopy);
      setKey(key);
    }
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "tablet_id",
      render: (_, render) => (
        <>
          {" "}
          {viewData ? (
            <Form.Item shouldUpdate>
              {" "}
              <FormInput
                name={[`${render.key}`, "tablet_id"]}
                onChange={(e) => {
                  setKey(render.key);
                }}
                className="input len"
                type="text"
                readOnly={true}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Enter the  batch no"} />,
                  },
                ]}
              />{" "}
            </Form.Item>
          ) : (
            <Form.Item
              name={[`${render.key}`, "tablet_id"]}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"select the  tablet"} />,
                },
              ]}
            >
              <FormSelectBox
                options={
                  tabletList?.data?.data?.map((pack, i) => {
                    return { label: pack.medicine_name, value: pack.id };
                  }) || []
                }
                placeholder="Select"
                className="sel select-box"
                onChange={(e) => {
                  const ids = tabletList?.data?.data?.find((id) => {
                    if (id.id === e) {
                      return id;
                    }
                  });

                  onChangeTablet(ids, render.key);
                  setKey(render.key);
                }}
                editMode="true"
                forFilter="true"
                showSearch="true"
              />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            name={[`${render.key}`, "batch_no"]}
            onChange={(e) => {
              setKey(render.key);
            }}
            className="input len"
            type="text"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the  batch no"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Free Quantity",
      dataIndex: "free_quantity",
      key: "free_quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={0}
            name={[`${render.key}`, "free_quantity"]}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Packing Unit",
      dataIndex: "packing_unit",
      key: "packing_unit",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={0}
            name={[`${render.key}`, "packing_unit"]}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the packing unit"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            name={[`${render.key}`, "expiry_date"]}
            className="input len"
            type="date"
            placeholder="date"
            min={disablePastDate()}
            // defaultValue={currentDate}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"select the expiry date"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={1}
            name={[`${render.key}`, "purchase_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the purchase price"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      key: "sale_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={1}
            name={[`${render.key}`, "sale_price"]}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the sale price"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "GST (%)",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={0}
            name={[`${render.key}`, "gst_percentage"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            step="0.01"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the gst "} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",

      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={0}
            name={[`${render.key}`, "discount"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the discount"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            readOnly
            name={[`${render.key}`, "amount"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
          />
        </Form.Item>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record) => (
        <>
          {!viewData && (
            <ButtonComponent
              icon={<DeleteFilled />}
              className="btn-edits"
              type="danger"
              onClick={() => deleteRow(record.key)}
            />
          )}
        </>
      ),
    },
  ];
  const onFinish = (datas) => {
    const data = {
      ...datas,
      organization_id: orgId,
      address2: "",
      sub_total: form.getFieldValue("sub_total"),
      gst_amount: form.getFieldValue("gst_amount"),
      total: form.getFieldValue("total"),
      round_total: form.getFieldValue("round_total"),
      discount: form.getFieldValue("discount"),
      supplier_id: form.getFieldValue("supplier_id"),
      purchase_details: Object.values(datas?.purchase_details)
        .filter((value) => value != null)
        .map((key) => ({
          ...key,
          short_quantity: 0,
          reason: "",
          new_stock: 2,
        })),
    };

    const duplicateTabletBatchCombinations = {};

    data.purchase_details.forEach((detail) => {
      const key = `${detail.product_name}-${detail.batch_no}`;
      if (duplicateTabletBatchCombinations[key]) {
        duplicateTabletBatchCombinations[key]++;
      } else {
        duplicateTabletBatchCombinations[key] = 1;
      }
    });

    const duplicates = Object.keys(duplicateTabletBatchCombinations).filter(
      (key) => duplicateTabletBatchCombinations[key] > 1
    );

    if (duplicates.length !== 0) {
      message.error(`Duplicate tablet batch_no combinations: ${duplicates}`);
    } else {
      const updateData = {
        data: data,
        id: editPurchaseData?.id || "",
      };

      if (editPurchaseData) {
        dispatch(editPurchaseInProgress(updateData));
      } else {
        dispatch(addPurchaseInProgress(data));
      }
    }
    setNavigate(true);
  };
  useEffect(() => {
    if (navigate && (addPurchase?.success || editPurchase?.success)) {
      history.push("/purchase");
    }
    setNavigate(false);
  }, [navigate && (addPurchase?.success || editPurchase?.success)]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin
      indicator={antIcon}
      spinning={addPurchase?.inProgress || editPurchase?.inProgress}
    >
      <Card>
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row className="register-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography className="header-tag">
                {editPurchases ? (
                  <IntlMessages id={"label.editPurchase"} />
                ) : viewData ? (
                  <IntlMessages id={"label.viewPurchase"} />
                ) : (
                  <IntlMessages id={"label.addPurchase"} />
                )}
              </Typography>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Typography className="subheading-upper">
                {<IntlMessages id={"Addnew.purchaseRequest.supplierSection"} />}
              </Typography>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {viewData ? (
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"purchase_type"}
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.purchaseType"}
                        />
                      }
                      readOnly={viewData ? "true" : "false"}
                    />
                  ) : (
                    <Form.Item
                      label={
                        <IntlMessages id={"Addnew.purchase.purchaseType"} />
                      }
                      name="purchase_type"
                    >
                      <FormSelectBox
                        editMode="true"
                        forFilter="true"
                        showSearch="true"
                        value={purchaseType}
                        options={purchaseTypeList}
                        onChange={changepurchaseType}
                        className="responsive-select"
                        defaultValue="1"
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {viewData ? (
                    <>
                      {viewPurchaseData?.purchase_req_code && (
                        <FormInput
                          labelCol={"24"}
                          wrapperCol={"24"}
                          type="text"
                          name={"purchaseRequestCode"}
                          label={
                            <IntlMessages
                              id={"Addnew.pharmacy.label.purchaseRequestCode"}
                            />
                          }
                          readOnly={viewData ? "true" : "false"}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {(Number(productType) ||
                        Number(editPurchaseData?.purchase_type) ||
                        Number(viewPurchaseData?.purchase_type)) !== 2 && (
                        <>
                          <Form.Item
                            label={
                              <IntlMessages
                                id={"Addnew.pharmacy.label.purchaseRequestCode"}
                              />
                            }
                            name="purchase_req_code"
                            rules={[
                              {
                                required: true,
                                message: (
                                  <IntlMessages
                                    id={
                                      "Addnew.pharmacy.validator.purchaseRequestCode"
                                    }
                                  />
                                ),
                              },
                            ]}
                          >
                            <FormSelectBox
                              placeholder="Search Purchase Request/ID"
                              editMode="true"
                              forFilter="true"
                              showSearch="true"
                              value={purchaseRequestCode}
                              options={purchase_request_code}
                              onChange={changepurchaseRequestCode}
                              className="responsive-select"
                            />
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"bill_no"}
                    placeholder={"Enter the bill no"}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"The Bill Number field is required."}
                          />
                        ),
                      },
                    ]}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.billNumber"} />
                    }
                    readOnly={location.state === "Add" ? false : true}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {Number(productType) ||
                  Number(editPurchaseData?.purchase_type) === 2 ? (
                    <Form.Item
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.companyName"}
                        />
                      }
                      name={"company_name"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.pharmacy.validator.companyName"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select the company name"
                        editMode="true"
                        forFilter="true"
                        showSearch="true"
                        value={companyName}
                        options={companyNameList}
                        onChange={changeCompanyName}
                        className="responsive-select"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.companyName"}
                        />
                      }
                      name="company_name"
                    >
                      <TextArea
                        rows={3}
                        readOnly={viewData ? "true" : "false"}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.address1"} />
                    }
                    name="address1"
                  >
                    <TextArea rows={3} readOnly={viewData ? "true" : "false"} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"city"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.city"} />}
                    readOnly={viewData ? "true" : "false"}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"state"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.state"} />}
                    readOnly={viewData ? "true" : "false"}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"zipcode"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.zipCode"} />
                    }
                    readOnly={viewData ? "true" : "false"}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Typography className="subheading-upper">
                {<IntlMessages id={"Addnew.purchaseRequest.purchaseSection"} />}
              </Typography>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"purchase_code"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.purchaseCode"} />
                    }
                    readOnly={viewData ? "true" : "false"}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {viewData ? (
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"invoice_date"}
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.invoiceDate"}
                        />
                      }
                      readOnly={viewData ? "true" : "false"}
                    />
                  ) : (
                    // <Form.Item
                    //   name={"invoice_date"}
                    //   rules={[
                    //     {
                    //       required: true,
                    //       message: (
                    //         <IntlMessages id={"Medicine.validator.add_date"} />
                    //       ),
                    //     },
                    //   ]}
                    // >
                    <FormInput
                      type="date"
                      placeholder="date"
                      name="invoice_date"
                      min={disablePastDate()}
                      defaultValue={currentDate}
                      labelCol={"24"}
                      wrapperCol={"24"}
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.invoiceDate"}
                        />
                      }
                    />
                    // </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} mt={5}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"mobile"}
                    placeholder={"Enter the mobile number"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.mobile"} />}
                    readOnly={viewData ? "true" : "false"}
                    rules={[
                      {
                        required: false,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.mobile"}
                          />
                        ),
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number!",
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography className="header-tag">
                {<IntlMessages id={"label.productInformation"} />}
              </Typography>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.List
                name={"purchase_details"}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"select the product informations"} />
                    ),
                  },
                ]}
              >
                {(fields, { add }) => (
                  <PurchaseFromTable
                    addRow={addRow}
                    add={add}
                    columns={columns}
                    input={input}
                    viewData={viewData}
                  />
                )}
              </Form.List>

              {input.length === 0 && (
                <Typography className="red-text">
                  select the product informations !
                </Typography>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="footer-total">
              <PurchaseFooters
                subtotal={form.getFieldValue("sub_total") || 0}
                totalGST={form.getFieldValue("gst_amount") || 0}
                total={form.getFieldValue("total") || 0}
                roundoff={form.getFieldValue("round_total") || 0}
                discount={form.getFieldValue("discount") || 0}
              />
            </Col>
          </Row>

          <Row>
            <Col
              xs={24}
              align="right"
              className="button-alignment-pharmacy cancel-btn"
            >
              <Link to={APP_ROUTES.PURCHASE}>
                <ButtonComponent
                  label="Cancel"
                  className="btn-cancel secondary-color"
                />
              </Link>
              {!viewData && (
                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editPurchases ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                  disabled={input.length === 0}
                />
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default AddPurchase;
