import React from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

function Exportcsv() {
  const patientData = useSelector((state) => state.patient.getList);
  const patientList = patientData.data?.data;
  const headers = [
    "FirstName",
    "LastName",
    "DOB",
    "Email",
    "Address1",
    "Address2",
    "City",
    "State",
    "role_id",
    "user_id",
    "Country_Code",
    "organization_id",
  ];
  const data = patientList.map((d) => [
    d.firstName,
    d.lastName,
    d.dob,
    d.email,
    d.address1,
    d.address2,
    d.city,
    d.state,
    d.role_id,
    d.user_id,
    d.country_code,
    d.organization_id,
  ]);
  console.log("data", data);
  return (
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "20px" }}
    >
      <CSVLink
        data={data}
        headers={headers}
        filename={"react-sample-csv"}
        target={"_blank"}
      />
    </div>
  );
}

export default Exportcsv;
