import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Card,
  Typography,
  Modal,
  Button,
  Tooltip,
  Form,
  DatePicker,
} from "antd";
import {
  LoadingOutlined,
  PrinterFilled,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  CreditCardOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import "../../../styles/modules/superAdmin.less";
import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";
import HospitalHeader from "./HospitalHeader";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { requestOrgInProgress } from "../../../appRedux/doctor/actions/organisation";
import {
  addOrgTimeInProgress,
  defaultOrganization,
  deleteOrganisationInProgress,
  getOrganisationListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import FormInput from "../../../components/Input/FormInput";
import moment from "moment";

const HospitalTable = () => {
  const dispatch = useDispatch();
  const [orgId, setId] = useState("");
  const [totalHours, setTotalHours] = useState();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const orgListData = useSelector((state) => state?.superAdmin?.orgList);
  const deleteOrgData = useSelector((state) => state?.superAdmin?.deleteOrg);
  const addOrgTime = useSelector((state) => state?.superAdmin?.addOrgTime);
  const totalRecords = orgListData?.data?.totalRecords;
  const hospitalList = orgListData?.data?.data;
  const showModal = (record) => {
    console.log("record", record);
    setIsModalOpen(true);
    setId(record?.id);

    form.setFieldsValue({ date: moment() });

    if (record?.total_hours === null) {
      setTotalHours(false);
    } else {
      setTotalHours(true);
      form.setFieldValue("time", record?.total_hours);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const [hospitalListData, setHospitalListData] = useState(hospitalList);
  const addOrgData = useSelector((state) => state?.superAdmin?.addOrg);
  const editOrgData = useSelector((state) => state?.superAdmin?.editOrg);
  const toggleStatus = (key) => {
    setHospitalListData(
      hospitalListData.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            status: item.status === "active" ? "inactive" : "active",
          };
        }
        console.log("item", item);
        return item;
      })
    );
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Organization Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Package Name",
      dataIndex: ["Subscription", "name"],
      key: "Subscription",
    },
    {
      title: "Total Hours",
      dataIndex: "total_hours",
      key: "total_hours",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) =>
    //     record.status === 1
    //       ? "Active"
    //       : record.status === 3
    //       ? "Inactive"
    //       : "Pending",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => {
    //     return (
    //       <ButtonComponent
    //         type={text === "active" ? "primary" : "danger"}
    //         label={text}
    //       />
    //     );
    //   },
    // },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        console.log("tttttttttttt", record.total_hours === null);
        return (
          <Row className="table-button">
            {/* <Col className="button-edit">
              <ButtonComponent
                onClick={() => toggleStatus(record.key)}
                label={record.status === "active" ? "Disable" : "Enable"}
                // type={record.status === "active" ? "grey-color" : "primary"}
                style={{
                  backgroundColor:
                    record.status === "active" ? "#d3d3d3" : "#2196f3",
                  borderColor: record.status === "active" ? "#d3d3d3" : "white",
                  color: "white",
                }}
              />
            </Col> */}
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Edit"}>
                {" "}
                <Link
                  to={{
                    pathname: `/organization/edit`,
                    state: { hospitalData: record },
                  }}
                >
                  <ButtonComponent
                    className="secondary-color"
                    icon={<EditFilled />}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              <Tooltip placement="top" title={"Add Time"}>
                <Typography>
                  <ButtonComponent
                    type="primary"
                    icon={<FieldTimeOutlined />}
                    // disabled={record.total_hours === null ? false : true}
                    onClick={() => showModal(record)}
                  ></ButtonComponent>
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: "10", pageNo: page };

    dispatch(getOrganisationListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(hospitalList);
    } else {
      const newData = hospitalList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const onFinish = (datas) => {
    console.log("DATA", datas);
    const payload = {
      date: moment(datas?.date).format("YYYY-MM-DD"),
      total_hours: datas?.time,
    };
    console.log("paaaaaaaaaaa", payload);
    dispatch(addOrgTimeInProgress({ payload, orgId: orgId }));
  };

  useEffect(() => {
    const payload = { pagination: true, pageSize: "10", pageNo: "1" };

    dispatch(getOrganisationListInProgress(payload));
  }, []);
  console.log("addOrgData", addOrgData, editOrgData);
  useEffect(() => {
    if (orgListData?.inProgress || deleteOrgData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [orgListData, deleteOrgData]);
  useEffect(() => {
    if (addOrgData?.success || editOrgData?.success) {
      dispatch(defaultOrganization());
    }
  }, [addOrgData, editOrgData]);
  useEffect(() => {
    form.setFieldsValue({ date: moment() });
  }, []);
  useEffect(() => {
    if (addOrgTime?.success) {
      setIsModalOpen(false);
      form.resetFields();
      const payload = { pagination: true, pageSize: "10", pageNo: "1" };

      dispatch(getOrganisationListInProgress(payload));
    }
  }, [addOrgTime]);
  return (
    <Card>
      <HospitalHeader />
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : hospitalList}
          rowselector={handleSelectChange}
          itemList={hospitalListData}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"name"}
          documentTitle={"Organization List"}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
        />
      </Spin>
      <Modal
        title={!setTotalHours ? "Add TIme" : "Update Time"}
        open={isModalOpen}
        footer={null}
        closable={false}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Spin indicator={antIcon} spinning={addOrgTime?.inProgress}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="date"
                label={<IntlMessages id={"Addnew.health.label.date"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.health.validator.date"} />
                    ),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="number"
                name={"time"}
                label={<IntlMessages id={"Addnew.health.label.totalHours"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.health.validator.totalHours"} />
                    ),
                  },
                ]}
              />
            </Col>{" "}
            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <ButtonComponent
                  type="danger"
                  label="Cancel"
                  className="btn-cancel secondary-color"
                  onClick={handleCancel}
                />

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={!setTotalHours ? "Add TIme" : "Update Time"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Spin>
        </Form>
      </Modal>
    </Card>
  );
};

export default HospitalTable;
