import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Space, Typography } from "antd";
import "../../../styles/modules/pharmacy.less";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CreditCardOutlined,
  DeleteFilled,
  PlusOutlined,
} from "@ant-design/icons";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import {
  addStockAdjustmentInProgress,
  getTabletsIdInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { currentDate, disablePastDate } from "../../../util/utilz";
import ProductTable from "./ProductTable";

const StockAdjustmentProductTable = ({
  editStockAdjustment,
  editStockAdjustmentData,
}) => {
  const makeRow = (counter) => ({
    key: counter,
    product_name: counter,
    quantity: counter,
    short_quantity: counter,
    batch_no: counter,
    purchase_price: counter,
    sale_price: counter,
    expiry_date: counter,
    reason: counter,
    batch_no_new: counter,
    free_quantity: counter,
    packing_unit: counter,
    gst_percentage: counter,
    discount: counter,
    amount: counter,
    organization_id: counter,
    purchase_code: counter,
    tablet_id: counter,
    available_stock: counter,
    new_stock: counter,
  });

  const [form] = Form.useForm();
  const org_id = localStorage.getItem("org_id");
  const [selectedRowKeyForNewBatch, setSelectedRowKeyForNewBatch] =
    useState(null);
  const [batchNoNewValue, setBatchNoNewValue] = useState("");
  const [input, setInput] = useState([makeRow(0)]);
  const [showExpandableColumn, setShowExpandableColumn] = useState(false);
  const [availableData, setAvailableData] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [key, setKey] = useState();
  const [counter, setCounter] = useState(1);
  const [batchNoList, setBatchNoList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});

  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const tabletsBatchNo = useSelector((state) => state?.pharmacy?.getTabletsId);

  const tabletListData = tabletsBatchNo?.data?.response?.data || [];
  const values = form.getFieldsValue();
  const handleBatchNoSelect = (value, renderKey) => {
    const selectedbatchNo = batchNoList.find(
      (product) => product.batch_no === value
    );
    const isBatchNumberSelected = Object.values(selectedProducts).some(
      (selectedValue, key) => selectedValue === value && key !== renderKey
    );

    if (isBatchNumberSelected) {
      form.setFieldsValue({
        [`stock_adjustment_details[${renderKey}]`]: {
          ...form.getFieldValue()[`stock_adjustment_details[${renderKey}]`],
          batch_no: {
            value,
            errors: ["Please select a different Batch No"],
          },
        },
      });
    } else {
      setKey(renderKey);

      if (value === "new") {
        const medicine = form.getFieldValue([
          `stock_adjustment_details`,
          renderKey,
          "product_name",
        ]);
        const selectedProduct = batchNoList.find(
          (product) => product.product_name === medicine
        );

        form.setFieldValue(
          [`stock_adjustment_details`, renderKey, "tablet_id"],
          selectedProduct?.tablet_id
        );
        form.setFieldValue([
          `stock_adjustment_details`,
          renderKey,
          "batch_no_new",
        ]);
        onChangeTablets(selectedbatchNo, renderKey);
        setSelectedRowKeyForNewBatch(renderKey);
        setShowExpandableColumn(true);
      } else {
        setSelectedRowKeyForNewBatch(null);
        onChangeTablet(selectedbatchNo, renderKey);
        setSelectedProducts({
          ...selectedProducts,
          [renderKey]: value,
        });
      }
    }
  };

  const validateFile = (renderKey, value) => {
    const batch_no = form.getFieldValue([
      `stock_adjustment_details`,
      key,
      "batch_no",
    ]);

    const isBatchNumberSelected = Object.values(selectedProducts).some(
      (selectedValue, key) => selectedValue === value && key !== renderKey
    );

    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [renderKey]: isBatchNumberSelected,
    }));
    if (isBatchNumberSelected) {
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  };
  const onChangeTablets = (data, key) => {
    console.log("DATA,", data, key);
    form.setFieldValue([`stock_adjustment_details`, key, "purchase_code"], "");
  };
  const onChangeTablet = (data, key) => {
    console.log("DATA,", data, key);
    form.setFieldValue(
      [`stock_adjustment_details`, key, "product_name"],
      data?.medicine_name || data.product_name
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "tablet_id"],
      data?.medicine_name
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "quantity"],
      data.quantity || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "short_quantity"],
      data.short_quantity || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "purchase_price"],
      data.purchase_price || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "unit_price"],
      data.unit_price || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "gst_percentage"],
      data.gst_percentage || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "free_quantity"],
      data.free_quantity || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "batch_no"],
      data.batch_no
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "amount"],
      data.amount || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "packing_unit"],
      data.packing_unit || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "sale_price"],
      data.sale_price || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "discount"],
      data.discount || 0
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "expiry_date"],
      data.expiry_date
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "tablet_id"],
      data?.tablet_id
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "organization_id"],
      data?.organization_id
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "purchase_code"],
      data?.purchase_code
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "reason"],
      data.reason
    );
    form.setFieldValue(
      [`stock_adjustment_details`, key, "available_stock"],
      data?.available_stock
    );
    form.setFieldValue([`stock_adjustment_details`, key, "new_stock"], "1");
    setSelectedProducts({
      ...selectedProducts,
      [key]: data?.batch_no,
    });
  };

  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };
  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);

    setInput(updatedInput);
    setSelectedProducts({
      ...selectedProducts,
      [key]: null,
    });

    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.stock_adjustment_details[key] = null;
      setFormData(formDataCopy);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.stock_adjustment_details[key] = null;
      setFormData(formDataCopy);
    }
  };
  const handleProductSelect = (value, key) => {
    const selectedProduct = tabletListData.find(
      (product) => product.medicine_name === value
    );
    setBatchNoList(selectedProduct?.Store_details);
    setKey(key);
    const batchNo = form.getFieldValue([
      `stock_adjustment_details`,
      key,
      "batch_no",
    ]);

    const isBatchNumberSelected =
      Object.values(selectedProducts).includes(batchNo);
    if (isBatchNumberSelected) {
      form.setFieldValue([`stock_adjustment_details`, key, "batch_no"], "0");
      validateFile(key, 0);
    }
  };
  const handleBatchNoNewChange = (value, renderKey) => {
    console.log(value);
    setBatchNoNewValue(value);
    setKey(renderKey);
    form.setFieldValue(
      [`stock_adjustment_details`, renderKey, "batch_no"],
      value
    );

    // setShowExpandableColumn(false);
    // setSelectedRowKeyForNewBatch(null);
    // setAvailableData(true);
  };

  const handleAddClick = (renderKey) => {
    setKey(renderKey);
    form.setFieldValue(
      [`stock_adjustment_details`, renderKey, "batch_no"],
      batchNoNewValue
    );

    setShowExpandableColumn(false);
    setSelectedRowKeyForNewBatch(null);
    setAvailableData(true);
  };
  const columns = [
    {
      title: "product Name",
      dataIndex: "product_name",
      render: (_, render) => (
        <Form.Item
          name={[`${render.key}`, "product_name"]}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"select the  tablet"} />,
            },
          ]}
        >
          <FormSelectBox
            options={
              tabletListData?.map((pack, i) => {
                return { label: pack.medicine_name, value: pack.medicine_name };
              }) || []
            }
            placeholder="Select Product Name"
            className="sel select-box"
            onChange={(e) => {
              handleProductSelect(e, render.key);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Short Quantity",
      dataIndex: "short_quantity",
      key: "short_quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            min={1}
            name={[`${render.key}`, "short_quantity"]}
            className="input len"
            type="number"
          />
        </Form.Item>
      ),
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",
      render: (_, render) => (
        <Row>
          <Col xl={24}>
            <Form.Item
              // className="p-0 m-0"
              name={[`${render.key}`, "batch_no"]}
              rules={[
                {
                  required: true,
                  message: "Please select Batch No",
                },
                {
                  validator: (_, value) => validateFile(render.key, value),
                },
              ]}
            >
              <FormSelectBox
                value={selectedProducts[render.key]}
                options={[
                  ...batchNoList?.map((pack) => ({
                    label: pack.batch_no,
                    value: pack.batch_no,
                  })),
                  { label: "New", value: "new" },
                ]}
                placeholder="Select Medicine"
                className="sel select-box"
                onChange={(value) => handleBatchNoSelect(value, render.key)}
              />
            </Form.Item>
            {selectedRowKeyForNewBatch === render.key && (
              <Col xs={24} sm={24}>
                <Form.Item shouldUpdate className="m-0 p-0">
                  <Space.Compact className="m-0 p-0">
                    <FormInput
                      className={"m-0 "}
                      name={[`${render.key}`, "batch_no_new"]}
                      value={batchNoNewValue}
                      onChange={(e) =>
                        handleBatchNoNewChange(e.target.value, render.key)
                      }
                    />
                    <ButtonComponent
                      className="m-0  p-0"
                      icon={<PlusOutlined />}
                      size="small"
                      type="primary"
                      onClick={() => handleAddClick(render.key)}
                    />
                  </Space.Compact>
                </Form.Item>
              </Col>
            )}{" "}
            {errorMessages[render.key] && (
              <Typography.Text type="danger">
                Batch Number already selected
              </Typography.Text>
            )}
          </Col>
        </Row>
      ),
      width: 150,
    },

    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            min={1}
            name={[`${render.key}`, "purchase_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the purchase price"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      key: "sale_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            min={1}
            name={[`${render.key}`, "sale_price"]}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the sale price"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },

    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            name={[`${render.key}`, "expiry_date"]}
            className="input len"
            type="date"
            placeholder="date"
            min={disablePastDate()}
            defaultValue={currentDate}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"select the expiry date"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },

    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            name={[`${render.key}`, "reason"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="text"
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"Addnew.pharmacy.validator.reason"} />
                ),
              },
            ]}
          />
        </Form.Item>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, render) => (
        <Form.Item>
          {(!editStockAdjustment || render.key !== 0) && (
            <ButtonComponent
              icon={<DeleteFilled />}
              className="form-delete-stock"
              type="danger"
              onClick={() => deleteRow(render.key)}
            />
          )}
        </Form.Item>
      ),
    },
  ];

  const onFinish = (datas) => {
    console.log(
      "datas?.stock_adjustment_details",
      datas?.stock_adjustment_details
    );
    const dataArray = Object.values(datas.stock_adjustment_details).filter(
      (value) => value != null
    );

    const stockAdjustmentDetailsArray = Object.values(dataArray || {}).map(
      (item) => ({
        product_name: item?.product_name,
        batch_no: item?.batch_no,
        quantity: item?.quantity,
        free_quantity: item?.free_quantity || 0,
        packing_unit: item?.packing_unit || 0,
        expiry_date: item?.expiry_date,
        purchase_price: item?.purchase_price,
        sale_price: item?.sale_price,
        gst_percentage: item?.gst_percentage ? item?.gst_percentage : 0,
        amount: item?.amount || 0,
        discount: item?.discount || 0,
        tablet_id: item?.tablet_id,
        short_quantity: item?.short_quantity || 0,
        organization_id: item?.organization_id ? item?.organization_id : org_id,
        reason: item?.reason,
        purchase_code: item?.purchase_code ? item?.purchase_code : "",
        new_stock: item?.purchase_code ? "1" : "2",
        available_stock: item?.available_stock || 0,
        status: 1,
      })
    );
    const data = {
      insert_details: stockAdjustmentDetailsArray,
    };

    // if (editStockAdjustment) {
    //   dispatch(
    //     editStockAdjustmentInProgress({
    //       StoresInfo: data,
    //       id: editStockAdjustmentData[0]?.id,
    //     })
    //   );
    // } else {
    // }
    dispatch(addStockAdjustmentInProgress(data));
  };

  useEffect(() => {
    const fromData = { pagination_required: false, page: 1 };
    dispatch(getTabletsIdInProgress(fromData));
  }, []);
  useEffect(() => {
    if (editStockAdjustment) {
      const newInput = editStockAdjustmentData?.map((value, index) => {
        onChangeTablet(value, index);
        return makeRow(index);
      });

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    }
  }, [editStockAdjustmentData]);
  return (
    <Card>
      <Form
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        colon={"true"}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.List name={"stock_adjustment_details"}>
              {(fields, { add }) => (
                <ProductTable
                  addRow={addRow}
                  add={add}
                  columns={columns}
                  input={input}
                />
              )}
            </Form.List>
          </Col>
        </Row>

        <Row>
          <Col
            xl={23}
            align="right"
            className="button-alignment-pharmacy cancel-btn"
          >
            <Link to={APP_ROUTES.STOCKADJUSTMENT}>
              <ButtonComponent
                label="Cancel"
                className="btn-cancel secondary-color"
              />
            </Link>
            <ButtonComponent
              icon={<CreditCardOutlined />}
              label={editStockAdjustment ? "Save" : "Submit"}
              htmlType="submit"
              type="primary"
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default StockAdjustmentProductTable;
