import React, { useEffect, useState } from "react";

import { Card, Col, Row, Spin } from "antd";

import { Link } from "react-router-dom/cjs/react-router-dom";
import { APP_ROUTES } from "../../constants/Routes";

import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";

import ColorCard from "../../components/card/ColorCard";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultRoomBooking,
  wardDashboardCountListInProgress,
} from "../../appRedux/Ward/WardAction";
import WardTable from "./Ward/WardTable";
import BlockTable from "./Blocks/BlockTable";
import ButtonComponent from "../../components/Button/ButtonComponent";
import IntlMessages from "../../util/IntlMessages";
import BlockList from "./BookRoom/BlockList";

const WardDashboard = () => {
  const dispatch = useDispatch();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [loading, setLoading] = useState(false);
  const wardCountData = useSelector((state) => state?.ward?.wardCount);
  const bookedRoomData = useSelector((state) => state?.ward?.roomBook);
  useEffect(() => {
    dispatch(wardDashboardCountListInProgress());
  }, []);
  console.log("wardCountData", wardCountData);
  useEffect(() => {
    if (wardCountData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [wardCountData]);
  useEffect(() => {
    if (bookedRoomData?.success) {
      dispatch(defaultRoomBooking());
    }
  }, [bookedRoomData]);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={loading}>
        <Row
          gutter={[16, 16]}
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <Link to={APP_ROUTES?.AVAILABLE_ROOM}>
              <ColorCard
                count={wardCountData?.data?.count?.AvailableRooms}
                title={"Available Rooms"}
                cardColor={"gx-pink-purple-gradient"}
              />
            </Link>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <Link to={APP_ROUTES?.BOOKED_ROOMS}>
              <ColorCard
                count={wardCountData?.data?.count?.BookedRooms}
                title={"Booked Rooms"} //prescription
                cardColor={"gx-blue-cyan-gradient"}
              />
            </Link>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <Link to={APP_ROUTES?.TOTAL_ROOMS}>
              <ColorCard
                count={wardCountData?.data?.count?.TotalRooms}
                title={"Total Rooms"} // appoitments
                cardColor={"gx-green-cyan-gradient"}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Link to={APP_ROUTES.BLOCK_LIST}>
              <ButtonComponent
                icon={<PlusCircleOutlined />}
                type="primary"
                label={<IntlMessages id={"Addnew.seeblockList"} />}
              />
            </Link>
          </Col>{" "}
          {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <BlockTable />
          </Col> */}
        </Row>
      </Spin>
    </Card>
  );
};

export default WardDashboard;
