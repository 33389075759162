import { Col, Modal, Row, Spin } from "antd";
import { useState, useEffect, Fragment } from "react";
import Controls from "./Controls.jsx";
import { AgoraRTC, AgoraVideoPlayer } from "agora-rtc-react";
import { Result } from "antd";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
} from "./Settings.jsx";
import { AudioMutedOutlined } from "@ant-design/icons";
import ButtonComponent from "../../../../components/Button/ButtonComponent.js";
import { useDispatch, useSelector } from "react-redux";
import { getAgoraToken } from "../../../../appRedux/doctor/sagas/Auth.js";
import { useHistory } from "react-router-dom";
import { VideoCallInProgress } from "../../../../appRedux/doctor/actions/Auth.js";

export default function VideoCall({
  setInCall,
  channelName,
  userId = "1",
  appointmentId,
}) {
  const user = localStorage.getItem("id");
  const [users, setUsers] = useState([]);
  const [values, setValues] = useState("");
  const [newToken, setNewToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(false);
  const [video, setVideo] = useState("Video1");
  const [remoteUsermute, SetremoteUserMute] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const client = useClient();
  const history = useHistory();
  const dispatch = useDispatch();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  console.log(ready, tracks, setInCall, channelName);

  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(async () => {
    // function to initialise the SDK
    const { data } = await getAgoraToken({
      appointmentId: appointmentId,
      userId: user,
    });
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(config.appId, name, data?.token, null);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  // useEffect(() => {
  //   ready ? setLoading(false) : setLoading(true);
  //   if (channelName && client && ready && tracks) {
  //     initialize();
  //   }
  // }, [channelName, client, ready, tracks]);

  // console.log(client.on("channel-media-relay-event"), "DS");
  // const initialize = async () => {
  //   try {
  //
  //     console.log("data", data);

  //     // if (data?.token) {
  //     //   try {
  //     //     console.log("if", client);
  //     //     client &&
  //     //       client?.on("user-published", async (user, mediaType) => {
  //     //         await client?.subscribe(user, "video");
  //     //         console.log("user", user);
  //     //         if (mediaType === "video") {
  //     //           setUsers((prevUsers) => {
  //     //             return [...prevUsers, user];
  //     //           });
  //     //         }
  //     //         if (mediaType === "audio") {
  //     //           user.audioTrack.play();
  //     //         }
  //     //       });
  //     //   } catch (e) {
  //     //     console.log("eee", e);
  //     //   }
  //     // }
  //     if (data && data.token) {
  //       console.log("Token obtained successfully:", data.token);
  //       // Assuming `client` is defined somewhere else
  //       if (client) {
  //         client.on("published-user-list", (e) => {
  //           console.log("SDD", e);
  //         });
  //         client.on("user-published", async (user, mediaType) => {
  //           console.log("unpublished", user, mediaType);
  //           try {
  //             await client.subscribe(user, "video");
  //             console.log("User published:", user);
  //             if (mediaType === "video") {
  //               setUsers((prevUsers) => [...prevUsers, user]);
  //             }
  //             if (mediaType === "audio" && user.audioTrack) {
  //               user.audioTrack.play();
  //             }
  //           } catch (err) {
  //             console.error("Error subscribing to user:", err);
  //           }
  //         });
  //       } else {
  //         console.error("Client is not defined.");
  //       }
  //     } else {
  //       console.error("No token found in response data.");
  //     }

  //     client.on("user-unpublished", (user, mediaType) => {
  //       console.log("unpublished", user, mediaType);
  //       if (mediaType === "audio") {
  //         if (user.audioTrack) user.audioTrack.stop();
  //       }
  //       if (mediaType === "video") {
  //         setUsers((prevUsers) => {
  //           return prevUsers.filter((User) => User.uid !== user.uid);
  //         });
  //       }
  //     });

  //     client.on("user-left", (user) => {
  //       setUsers((prevUsers) => {
  //         return prevUsers.filter((User) => User.uid !== user.uid);
  //       });
  //     });

  //     await client.join(config.appId, channelName, data?.token, userId);
  //     console.log("tracks", tracks);
  //     if (tracks) await client.publish([tracks[0], tracks[1]]);
  //     setStart(true);
  //   } catch (error) {}
  // };

  useEffect(() => {
    setInterval(() => {
      const stats = client.getRTCStats();
      const result = new Date(stats.Duration * 1000)
        .toISOString()
        .slice(11, 19);
      setCallDuration(result);
    }, 1000);
    client.on("user-unpublished", async () => {
      SetremoteUserMute(true);
    });
    client.on("user-published", async () => {
      SetremoteUserMute(false);
    });
  }, []);
  const videoexpand = () => {
    setVideo(
      video === "Video1"
        ? "screenvideo"
        : video === "screenvideo"
        ? "Video1"
        : ""
    );
  };
  return (
    <div style={{ height: "100%" }}>
      {/* <Modal
        width={video === "Video1" ? 580 : 680}
        style={{ left: "100px" }}
        bodyStyle={{
          height: video === "Video1" ? 400 : 450,
          padding: "0px",
          paddingRight: "0px",
        }}
        closable={false}
        open={isModalOpen}
        // okButtonProps={{ style: { display: "none" } }}
        // cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
      > */}
      <Spin
        size="large"
        spinning={loading}
        tip="Please wait a moment"
        style={{ margin: "200px" }}
      />
      <div id="first">
        {users.length <= 1 ? (
          <>
            {start && tracks && (
              <>
                <AgoraVideoPlayer
                  videoTrack={tracks[1]}
                  className={
                    users.length === 1
                      ? video === "Video1"
                        ? "Video1"
                        : "video2"
                      : "Video1"
                  }
                  onClick={video === "Video1" ? null : videoexpand}
                />

                {users.length === 1 &&
                  users.map((user) => {
                    if (user.videoTrack) {
                      return (
                        <AgoraVideoPlayer
                          videoTrack={user.videoTrack}
                          key={user.uid}
                          className={video === "Video1" ? "video2" : "Video1"}
                          // id="first"
                          onClick={video === "Video1" ? videoexpand : null}
                        />
                      );
                    }
                  })}

                <h1
                  className={
                    // video === "Video1"
                    //   ? "display-time"
                    //   :
                    "full-screen-time"
                  }
                >
                  {callDuration}
                </h1>
                <div
                  id="second"
                  // style={{
                  //   marginTop: "160px",
                  // }}
                >
                  {" "}
                  {ready && tracks && (
                    <Controls
                      tracks={tracks}
                      setStart={setStart}
                      setInCall={setInCall}
                      setVideo={setVideo}
                      video={video}
                      setIsModalOpen={setIsModalOpen}
                    />
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <Result status="warning" title="more than one user is not allowed" />
        )}
      </div>
      {/* <Fragment>
          {users.length <= 1 ? (
            <Row>
              <Col xs={18}>
                {start && tracks && (
                  <Row>
                    <Col className="container">
                      <AgoraVideoPlayer
                        videoTrack={tracks[1]}
                        className={video}
                      />
                      {ready && tracks && (
                        <Controls
                          tracks={tracks}
                          setStart={setStart}
                          setInCall={setInCall}
                          setVideo={setVideo}
                          video={video}
                          setIsModalOpen={setIsModalOpen}
                          id={"second"}
                        />
                      )}

                      {users.length === 1 &&
                        users.map((user) => {
                          if (user.videoTrack) {
                            return (
                              <Col xs={12}>
                                <AgoraVideoPlayer
                                  videoTrack={user.videoTrack}
                                  key={user.uid}
                                  className={
                                    video === "Video1" ? "video2" : "screen2"
                                  }
                                  id="first"
                                />

                                <h1
                                  className={
                                    video === "Video1"
                                      ? "display-time"
                                      : "full-screen-time"
                                  }
                                >
                                  {callDuration}
                                </h1>
                                {remoteUsermute ? (
                                  <ButtonComponent
                                    className={
                                      video === "Video1"
                                        ? "video2-mute"
                                        : "video2-mute-fullscreen"
                                    }
                                    icon={<AudioMutedOutlined />}
                                    shape="circle"
                                    size="default"
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                            );
                          }
                        })}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          ) : (
            <Result
              status="warning"
              title="more than one user is not allowed"
            />
          )}
        </Fragment> */}
      {/* </Modal> */}
    </div>
    // <Modal
    //   title="Call Started"
    //   width={950}
    //   bodyStyle={{ height: 500 }}
    //   closable={false}
    //   cancelButtonProps={{ style: { display: "none" } }}
    //   // okButtonProps={{ style: { display: "none" } }}
    //   open={isModalOpen}
    //   handleOk={() => {
    //     setIsModalOpen(false);
    //   }}
    // >

    // </Modal>
  );
}
