import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Row, Col, DatePicker, Typography, Tooltip, Spin } from "antd";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { ReconciliationTwoTone, PrinterFilled, LoadingOutlined } from "@ant-design/icons";

import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import IntlMessages from "../../../util/IntlMessages";
import { patientBillingReportListInprogress } from "../../../appRedux/admin/patient/action/action";
import { currentDate, formatPrice } from "../../../util/utilz";
import Auxiliary from "../../../util/Auxiliary";

const { RangePicker } = DatePicker;

function PatientBillingReports() {
  const dispatch = useDispatch();
  const gstPharmacyReportList = useSelector((state) => state);
  const [date, setDate] = useState([]);
  const [dates, setDates] = useState();
  const pdfContentRef = useRef(null);

  const reports = gstPharmacyReportList?.patient?.getPatientBillingReport?.data?.data || [];
  const reports2 = gstPharmacyReportList?.patient?.getPatientBillingReport?.data;
  const reportLoading=gstPharmacyReportList?.patient?.getPatientBillingReport?.inProgress
console.log("reports2",gstPharmacyReportList?.patient?.getPatientBillingReport?.inProgress)
  const columns = [
    { title: "Invoice No", dataIndex: "invoice_no" },
    { title: "Date", dataIndex: "DATE" },
    { title: "Doctor Name", dataIndex: "doctor_name" },
    { title: "Patient Name", dataIndex: "patient_name" },
    { title: "Total", dataIndex: "grand_total",render: (data) => formatPrice(data), },
    { title: "Paid", dataIndex: "paid_amount" ,render: (data) => formatPrice(data),},
    { title: "Due", dataIndex: "due" ,render: (data) => formatPrice(data),}
  ];

  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      render: (record) => <span className="display-inline">{record}</span>
    },
    {
      title: "Value",
      dataIndex: "value",
      render: (record) => <span className="display-inline">{record}</span>
    }
  ];

  const input = [
    { name: "Total Bill Amount", value: `${formatPrice(reports2?.totalBiilingAmount ? reports2?.totalBiilingAmount : 0)} ₹` },
    { name: "Total Bill Paid Amount", value: `${formatPrice(reports2?.totalBillingPaidAmount ? reports2?.totalBillingPaidAmount : 0)} ₹` },
    {
      name: "Total Due",
      value: `${formatPrice(reports2?.totalBiilingAmount && reports2?.totalBillingPaidAmount
        ? Number(reports2?.totalBiilingAmount) - Number(reports2?.totalBillingPaidAmount)
        : 0)} ₹`
    },
    { name: "Total Card Amount", value: `${formatPrice(reports2?.totalCardAmount ? reports2?.totalCardAmount : 0)} ₹` },
    { name: "Total Cash Amount", value: `${formatPrice(reports2?.totalCashAmount ? reports2?.totalCashAmount : 0)} ₹` },
    { name: "Total Online Amount", value: `${formatPrice(reports2?.totalOnlineAmount ? reports2?.totalOnlineAmount : 0)} ₹` },
    { name: "Total DD Amount", value: `${formatPrice(reports2?.totalDdAmount ? reports2?.totalDdAmount : 0)} ₹` },
    { name: "Total Cheque Amount", value: `${formatPrice(reports2?.totalChequeAmount ? reports2?.totalChequeAmount : 0)} ₹` },
    { name: "Total Other Amount", value: `${formatPrice(reports2?.totalOtherAmount ? reports2?.totalOtherAmount : 0)} ₹` }
  ];

  useEffect(() => {
    const fromData = {
      pagination: false,
      pageNo: 1,
      from_date: date[0] || currentDate,
      to_date: date[1] || currentDate,
    };
    dispatch(patientBillingReportListInprogress(fromData));
    setDates(false);
  }, [dates]);

  const handleReport = (date, dates) => {
    setDates(false);
    setDate(dates);
  };

  const hadleDateScearch = () => {
    setDates(true);
  };

  const printHandler = () => {
    const newWindow = window.open("", "_blank");
    if (newWindow) {
      newWindow.document.write("<html><head><title>Print</title>");
      newWindow.document.write(
        `<style>
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          .header-tag {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          .ml-5 {
            margin-left: 5px;
          }
          .mr-7 {
            margin-right: 7px;
          }
        </style>`
      );
      newWindow.document.write("</head><body>");
      const printContent = document.createElement("div");
      printContent.id = "print-content";
      newWindow.document.body.appendChild(printContent);
      const headerTag = document.createElement("div");
      headerTag.className = "header-tag";
      headerTag.innerHTML = `
        <span class="mr-7"><ReconciliationTwoTone /></span>
        <span>Billing Reports</span>
      `;
      printContent.appendChild(headerTag);
      const dateSpan = document.createElement("span");
      dateSpan.className = "ml-5";
      dateSpan.textContent = `${date[0] || currentDate} to ${date[1] || currentDate}`;
      headerTag.appendChild(dateSpan);

      const table1 = document.createElement("div");
      table1.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <Table
          columns={columns}
          dataSource={reports || []}
          pagination={false}
          scroll={{ x: true }}
        />
      );
      printContent.appendChild(table1);

      const table2 = document.createElement("div");
      table2.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <Row className="display-class mb-20">
          <Col xl={16} lg={16} md={16} sm={12} xs={0}></Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <Table
              showHeader={false}
              columns={columns2}
              dataSource={input.filter((element) => element !== null)}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      );
      printContent.appendChild(table2);

      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.onload = () => {
        newWindow.print();
        newWindow.close();
      };
    } else {
      console.error("Failed to open new window for printing.");
    }
  };

  const handleDownloadPDF = async () => {
    const content = pdfContentRef.current;

    const options = {
      paperSize: "Letter",
      scale: 1,
      margin: { left: "1cm", right: "1cm" },
      landscape: true
    };

    const group = await drawDOM(content, options);
    const data = await exportPDF(group);
    saveAs(data, "PatientBillingReports.pdf");
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={reportLoading}>
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="display-flex mb-10 align-items">
          <Typography className="header-tag">
            <ReconciliationTwoTone className="mr-7" />
            <IntlMessages id={"Patient Billing Reports"} />
          </Typography>
          <div className="display-flex1">
            <RangePicker
              size={"large"}
              onChange={handleReport}
              defaultValue={[moment(currentDate), moment(currentDate)]}
            />
            <ButtonComponent
              label={"Search"}
              className={"ml-10"}
              onClick={hadleDateScearch}
            />
              <Tooltip placement="top" title={"Print"}>
            <ButtonComponent
              type="success"
              icon={<PrinterFilled />}
              className={"print-button"}
              onClick={printHandler}
            /></Tooltip>
              <ButtonComponent
              type="success"
              label={"PDF"}
              className={"print-button"}
              onClick={handleDownloadPDF}
            />
          </div>
        </Col>
      </Row>
      <div ref={pdfContentRef}>

           <Table
        columns={columns}
        dataSource={reports || []}
        pagination={false}
        scroll={{ x: true }}
      />
        <Row className="display-class mb-20">
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <Table
              showHeader={false}
              columns={columns2}
              dataSource={input.filter((element) => element !== null)}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    </div></Spin>
    </Auxiliary>
  );
}

export default PatientBillingReports;
