import React from "react";

import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { PlusCircleOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import "../../../styles/modules/billingPackages.less";
import { APP_ROUTES } from "../../../constants/Routes";
import Auxiliary from "../../../util/Auxiliary";
import { access } from "../../../util/utilz";

const BillingPackageHeader = () => {
  return (
    <Auxiliary>
      <Row className="bot">
        <Col span={12}>
          <h1 className="text">
            {<IntlMessages id={"Addnew.billingpackage"} />}
          </h1>
        </Col>

        <Col span={12} className="btn-main">
          {access("billingPackage", "add") && (
            <Link to={APP_ROUTES.ADDBILLINGPACKAGES}>
              <ButtonComponent
                className="buttons"
                icon={<PlusCircleOutlined />}
                type="primary"
                label={
                  <IntlMessages id={"Addnew.billingpackage.label.addnew"} />
                }
              />
            </Link>
          )}
        </Col>
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default BillingPackageHeader;
