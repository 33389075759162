import React, { useEffect, useRef, useState } from "react";
import TableComponent from "../../../components/Table/TableComponent";
import { Button, Card, Col, Form, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { LoadingOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import PatientDueHeader from "./PatientDueHeader";
import RecordPayment from "../sales/sales/RecordPayment";
import moment from "moment";

import { useLocation } from "react-router-dom";
import { render } from "less";
import {
  dueByPatientInProgress,
  slesPaymentInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";

const ViewDueDetails = () => {
  const [form] = Form.useForm();
  const formref = useRef();
  const location = useLocation();

  const [idModel, setIdModel] = useState("");
  const [paymentDataModal, setpaymentDataModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { dueByPatient, cancelUndo, addSalesPayment } = useSelector(
    (state) => state?.pharmacy
  );

  const orgId = localStorage.getItem("org_id");

  useEffect(() => {
    const formData = {
      patient_id: location?.state?.patient?.id,
      pagination: true,
      pageNo: 1,
      pageSize: 10,
    };
    dispatch(dueByPatientInProgress(formData));
    setpaymentDataModal(false);
    form.resetFields();
  }, [location?.state?.patient?.id, addSalesPayment?.success]);

  const PurchaseList = dueByPatient?.data?.data || [];
  const totalRecords = 5;
  const showModal = (id) => {
    setpaymentDataModal(true);
    setIdModel(id?.id);
    formref.current.setFieldsValue({
      date: "",
      Paid_amount: "",
      payment_type: "",
      remarks: "",
    });
  };

  const onFinishPaymentModal = (datas) => {
    datas.sales_id = idModel;
    datas.date = moment(datas.date).format("YYYY-MM-DD");
    dispatch(slesPaymentInProgress(datas));
  };
  const handlePaymentModalOk = () => {
    setpaymentDataModal(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = { page: page, orgId: orgId, pagination: true };
    // dispatch(getPurchaseListInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(PurchaseList);
    } else {
      const newData = PurchaseList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const columns = [
    {
      title: "SI NO",
      dataIndex: "sales_code",
    },
    {
      title: "Sales Date",
      dataIndex: "sales_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      key: "billNumber",
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "Address",
    },

    {
      title: "Deposite",
      dataIndex: "deposite",
      key: "Address",
    },

    {
      title: "Due",
      dataIndex: "due",
      key: "total",
      render: (_, record) => {
        return (
          <Typography className="red-text">
            {(
              Number(record?.total).toFixed(2) -
              Number(record?.deposite).toFixed(2)
            ).toFixed(2)}
          </Typography>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <ButtonComponent
                className="secondary-color"
                icon={<MoneyCollectOutlined />}
                label={"Collect"}
                onClick={() => showModal(record)}
              >
                {" "}
              </ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <div>
      <Card>
        {" "}
        <PatientDueHeader
          view={true}
          Due={dueByPatient?.data?.totalDueAmount}
          patientName={location?.state?.patient?.firstName}
          SerialNO={location?.state?.patient?.patient_serial_no}
        />
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : PurchaseList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          rowselector={handleSelectChange}
          itemList={PurchaseList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"supplierName"}
        />
        <Modal
          title={
            <Typography className="cancel-sales">Record Payment</Typography>
          }
          open={paymentDataModal}
          width={800}
          // onOk={salesHandleOk}
          // onCancel={salesHandleCancel}
          closable={false}
          footer={[
            <Button
              key="back"
              className="secondary-color"
              onClick={handlePaymentModalOk}
            >
              Close
            </Button>,
          ]}
        >
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinishPaymentModal}
            autoComplete="off"
            form={form}
            ref={formref}
          >
            <RecordPayment />
          </Form>
        </Modal>
      </Card>
    </div>
  );
};

export default ViewDueDetails;
