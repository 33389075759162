import {
  ADD_QUALIFICATION_LIST_IN_ERROR,
  ADD_QUALIFICATION_LIST_IN_PROGRESS,
  ADD_QUALIFICATION_LIST_IN_SUCCESS,
  DELETE_QUALIFICATION_LIST_IN_ERROR,
  DELETE_QUALIFICATION_LIST_IN_PROGRESS,
  DELETE_QUALIFICATION_LIST_IN_SUCCESS,
  GET_QUALIFICATION_LIST_IN_ERROR,
  GET_QUALIFICATION_LIST_IN_PROGRESS,
  GET_QUALIFICATION_LIST_IN_SUCCESS,
  GET_QUALIFICATION_UPDATE_LIST_ERROR,
  GET_QUALIFICATION_UPDATE_LIST_PROGRESS,
  GET_QUALIFICATION_UPDATE_LIST_SUCCESS,
  UPDATE_QUALIFICATION_LIST_IN_ERROR,
  UPDATE_QUALIFICATION_LIST_IN_PROGRESS,
  UPDATE_QUALIFICATION_LIST_IN_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  qualificationlist: { ...defaultStatus },
  deletequalificationlist: { ...defaultStatus },
  addqualificationlist: { ...defaultStatus },
  updatequalificationlist: { ...defaultStatus },
  getqualificationupdatelist: { ...defaultStatus },
};
const qualificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_QUALIFICATION_LIST_IN_PROGRESS:
      return {
        ...state,
        qualificationlist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
        addqualificationlist: { ...defaultStatus },
      };
    case GET_QUALIFICATION_LIST_IN_SUCCESS:
      console.log("Payload QUALIFICATION", payload);

      return {
        ...state,
        qualificationlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_QUALIFICATION_LIST_IN_ERROR:
      return {
        ...state,
        qualificationlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_QUALIFICATION_LIST_IN_PROGRESS:
      return {
        ...state,
        deletequalificationlist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
      };
    case DELETE_QUALIFICATION_LIST_IN_SUCCESS:
      return {
        ...state,
        deletequalificationlist: {
          ...defaultStatus,
          success: true,
          ...payload,
        },
      };
    case DELETE_QUALIFICATION_LIST_IN_ERROR:
      return {
        ...state,
        deletequalificationlist: {
          ...defaultStatus,
          error: true,
          ...payload,
        },
      };

    case ADD_QUALIFICATION_LIST_IN_PROGRESS:
      return {
        ...state,
        addqualificationlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_QUALIFICATION_LIST_IN_SUCCESS:
      return {
        ...state,
        addqualificationlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_QUALIFICATION_LIST_IN_ERROR:
      return {
        ...state,
        addqualificationlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_QUALIFICATION_LIST_IN_PROGRESS:
      return {
        ...state,
        updatequalificationlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_QUALIFICATION_LIST_IN_SUCCESS:
      return {
        ...state,
        updatequalificationlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_QUALIFICATION_LIST_IN_ERROR:
      return {
        ...state,
        updatequalificationlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_QUALIFICATION_UPDATE_LIST_PROGRESS:
      return {
        ...state,
        getqualificationupdatelist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_QUALIFICATION_UPDATE_LIST_SUCCESS:
      console.log("payload.data", payload.data);
      return {
        ...state,
        getqualificationupdatelist: {
          ...defaultStatus,
          ...payload.data,
          success: true,
        },
      };
    case GET_QUALIFICATION_UPDATE_LIST_ERROR:
      return {
        ...state,
        getqualificationupdatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default qualificationReducer;
