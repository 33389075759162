import React, { useEffect } from "react";
import "../../styles/modules/admin.less";
import { Card, Col, Row, Table } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import {
  MedicineBoxOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  BookOutlined,
} from "@ant-design/icons";
import TicketList from "../../components/card/TicketList";
import BillingList from "../Admin/BillingLIst";
import { useDispatch, useSelector } from "react-redux";
import { getSuperAdminDashboardInProgress } from "../../appRedux/super-admin/SuperAdminAction";

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const DashboardData = useSelector(
    (state) => state?.superAdmin?.superAdminDashboard
  );
  const CountData = DashboardData?.data?.count;
  const listData = DashboardData?.data?.count?.organizationsList;
  console.log("DashboardData", DashboardData);

  const organisationColumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Organisation Serial No",
      dataIndex: "organization_serial_no",
      key: "organization_serial_no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
  ];
  useEffect(() => {
    dispatch(getSuperAdminDashboardInProgress());
  }, []);
  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={6} md={6} sm={12} xs={24}>
          <IconWithTextCard
            icon={<IdcardOutlined />}
            title={"Over All Patients"}
            subTitle={CountData?.patientCount}
            cardColor={"card-blue"}
            image={patient}
            size={75}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={24}>
          <IconWithTextCard
            icon={<MedicineBoxOutlined />}
            title={"Over All Doctors"}
            subTitle={CountData?.doctorCount}
            cardColor={"card-orange"}
            image={doctor}
            size={75}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={24}>
          <IconWithTextCard
            icon={<UnorderedListOutlined />}
            title={"Over All Organisations"}
            subTitle={CountData?.organizationsCount}
            cardColor={"card-green"}
            image={billing}
            size={200}
            height={80}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={24}>
          <IconWithTextCard
            icon={<BookOutlined />}
            title={"Today Billing"}
            subTitle={CountData?.billingCount}
            cardColor={"card-violet"}
            image={todayBilling}
            size={80}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="px-0">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <h2 className="h4 gx-text-capitalize">Organisation List</h2>
          <Table
            showHeader={true}
            columns={organisationColumn}
            dataSource={listData}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>
        {/* <Col xl={10} lg={10} md={12} sm={12} xs={24} push={2}>
          <BillingList />
        </Col> */}
      </Row>
    </Card>
  );
};

export default SuperAdminDashboard;
