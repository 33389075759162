import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "../components/CircularProgress";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import DocmozIcon from "../assets/icons/final-logo1 1.png";
import HeartIcon from "../common/images/blood-pressure-3312513_1280.png";
import { APP_ROUTES } from "../constants/Routes";
import {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userTwitterSignIn,
  setOtp,
  hideAuthLoader,
  setVerificationOtp,
  setRegisterSuccess,
} from "../appRedux/doctor/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import { UserLoginInProgress } from "../appRedux/doctor/actions/AuthAction";

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [form] = Form.useForm();
  const emaildata = form.getFieldValue("email");

  const history = useHistory();
  const authUser1 = useSelector((state) => state?.auth?.userLogin);
  const AuthData = useSelector((state) => state?.authData?.authData);
  const OTPERROR = AuthData?.data;
  const getToken = localStorage.getItem("user_id");

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    // if (authUser1?.success && getToken !== null) {
    //   history.push("/");
    // }
  });
  useEffect(() => {
    console.log(
      "token and auth",
      getToken,
      AuthData?.success == true && getToken != null
    );

    if (AuthData?.success == true && getToken != null) {
      setTimeout(() => {
        history.push("/");
      }, 500);
    }
  }, [AuthData?.success, getToken]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    console.log("values", values);
    dispatch(showAuthLoader());
    dispatch(UserLoginInProgress(values));
    //dispatch(userSignIn(values));
  };
  // useEffect(() => {
  //   dispatch(setOtp());
  //   dispatch(setVerificationOtp());
  // });
  // useEffect(() => {
  //   dispatch(setRegisterSuccess());
  // }, []);
  console.log("AuthData?.error", AuthData);
  useEffect(() => {
    if (AuthData?.error) dispatch(hideAuthLoader());
  }, [AuthData?.error]);
  useEffect(() => {
    if (
      OTPERROR?.message ===
      "Please verify your account with the recent OTP received"
    )
      // history.push("/otpRegistration");
      history.push(`/otpVerification/${emaildata}`);
  }, [OTPERROR]);
  return (
    <div className="gx-app-login-wrap login-page">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ background: "#F6F5FB", color: "black" }}
          >
            <div className="gx-app-logo login-logo">
              <img alt="example" src={DocmozIcon} />
            </div>
            <div className="gx-app-logo-wid">
              <h1 style={{ color: "black" }}>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            {/* <div className="gx-app-logo login-  ">
              <img alt="example" src={HeartIcon} />
            </div> */}
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                // initialValue="jeevajj1720@gmail.com"
                rules={[
                  { required: true, message: "The input is not valid E-mail!" },
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                // initialValue="Jeeva@17"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Checkbox>
                  <IntlMessages id="appModule.iAccept" />
                </Checkbox>
                <span className="gx-signup-form-forgot gx-link">
                  <IntlMessages id="appModule.termAndCondition" />
                </span>
              </Form.Item>
              <Link className="gx-login-form-forgot form-forgot" to="/otpsend">
                Forgot password
              </Link>
              <Form.Item className="form-buttons">
                <Button
                  type="primary"
                  className="gx-mb-0 form-button"
                  htmlType="submit"
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                {/* <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup" className="form-link">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link> */}
              </Form.Item>
              {/* <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <FacebookOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <GithubOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <TwitterOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}
                    />
                  </li>
                </ul>
              </div>
              <span className="gx-text-light gx-fs-sm">
                {" "}
                demo user email: 'demo@example.com' and password: 'demo#123'
              </span> */}
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
