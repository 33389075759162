import React, { useEffect, useState } from "react";
import "../../styles/modules/admin.less";
import { Badge, Button, Card, Col, Row, Spin } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import {
  MedicineBoxOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  BookOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TicketList from "../../components/card/TicketList";
import BillingList from "../Admin/BillingLIst";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorDashBoardProgress } from "../../appRedux/doctor/actions/DashBoard";

const DoctorDashboardScreen = () => {
  const dispatch = useDispatch();
  const getToken = localStorage.getItem("user_id");
  const { doctorDashBoard } = useSelector((state) => state?.dashBoard);
  console.log("doctorDashBoard", doctorDashBoard);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (getToken) {
      console.log("getToken", getToken);
      dispatch(getDoctorDashBoardProgress());
    }
  }, [getToken]);

  return (
    <Card>
      {" "}
      <Spin indicator={antIcon} spinning={doctorDashBoard?.progressing}>
        <Row gutter={[16, 16]}>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<IdcardOutlined />}
              title={"Over All Patients"}
              subTitle={doctorDashBoard?.data?.count?.patientCount}
              cardColor={"card-blue"}
              image={patient}
              size={85}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<MedicineBoxOutlined />}
              title={"Over All Doctors"}
              subTitle={doctorDashBoard?.data?.count?.doctorCount}
              cardColor={"card-orange"}
              image={doctor}
              size={75}
              billingcount
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<UnorderedListOutlined />}
              title={"Over All Billing"}
              subTitle={doctorDashBoard?.data?.count?.billingcount}
              cardColor={"card-green"}
              image={billing}
              size={200}
              height={80}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<BookOutlined />}
              title={"Today Billing"}
              subTitle={doctorDashBoard?.data?.count?.todayBillingCount}
              cardColor={"card-violet"}
              image={todayBilling}
              size={80}
            />
          </Col>
        </Row>
        <Row className="px-0">
          <Col xl={11} lg={11} md={12} sm={12} xs={24}>
            <TicketList
              recentAppointments={
                doctorDashBoard?.data?.count?.recentAppointments || []
              }
            />
          </Col>
          <Col xl={2} lg={2} md={0} sm={0} xs={0}></Col>
          <Col xl={11} lg={11} md={12} sm={12} xs={24}>
            <BillingList
              recentAppointments={
                doctorDashBoard?.data?.count?.recentBillings || []
              }
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default DoctorDashboardScreen;
