import {
  ADD_BLOOD_GLUCOSE_ERROR,
  ADD_BLOOD_GLUCOSE_INPROGRESS,
  ADD_BLOOD_GLUCOSE_SUCCESS,
  ADD_BMI_ERROR,
  ADD_BMI_INPROGRESS,
  ADD_BMI_SUCCESS,
  ADD_INSULIN_ERROR,
  ADD_INSULIN_INPROGRESS,
  ADD_INSULIN_SUCCESS,
  ADD_PATIENT_HEALTH_RECORDS_DEFAULT,
  ADD_PATIENT_HEALTH_RECORDS_ERROR,
  ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  ADD_PATIENT_HEALTH_RECORDS_SUCCESS,
  ADD_VITALS_ERROR,
  ADD_VITALS_INPROGRESS,
  ADD_VITALS_SUCCESS,
  DEFAULT_BLOOD_GLUCOSE,
  DEFAULT_BMI,
  DEFAULT_INSULIN,
  DEFAULT_VITAL,
  DELETE_PATIENT_HEALTH_RECORDS_ERROR,
  DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  DELETE_PATIENT_HEALTH_RECORDS_SUCCESS,
  DOCTOR_CONSULTATION_IN_FAILURE,
  DOCTOR_CONSULTATION_IN_PROGRESS,
  DOCTOR_CONSULTATION_IN_SUCCESS,
  EDIT_PATIENT_HEALTH_RECORDS_ERROR,
  EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  EDIT_PATIENT_HEALTH_RECORDS_SUCCESS,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_ERROR,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_SUCCESS,
  GET_BLOOD_GLUCOSE_LIST_ERROR,
  GET_BLOOD_GLUCOSE_LIST_INPROGRESS,
  GET_BLOOD_GLUCOSE_LIST_SUCCESS,
  GET_BMI_LIST_ERROR,
  GET_BMI_LIST_INPROGRESS,
  GET_BMI_LIST_SUCCESS,
  GET_INSULIN_LIST_ERROR,
  GET_INSULIN_LIST_INPROGRESS,
  GET_INSULIN_LIST_SUCCESS,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_FAILURE,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_SUCCESS,
  GET_PATIENT_HEALTH_RECORDS_ERROR,
  GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_PATIENT_HEALTH_RECORDS_SUCCESS,
  GET_SCHEDULE_IN_DEFAULT,
  GET_SCHEDULE_IN_FAILURE,
  GET_SCHEDULE_IN_PROGRESS,
  GET_SCHEDULE_IN_SUCCESS,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_ERROR,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_SUCCESS,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS,
  GET_VITALS_LIST_ERROR,
  GET_VITALS_LIST_INPROGRESS,
  GET_VITALS_LIST_SUCCESS,
  GET_VITALS_LIST_TYPE_ERROR,
  GET_VITALS_LIST_TYPE_INPROGRESS,
  GET_VITALS_LIST_TYPE_SUCCESS,
  PATIENT_ADD_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_ADD_APPOINTMENT_LIST_IN_SUCCESS,
  PATIENT_DASHBOARD_ERROR,
  PATIENT_DASHBOARD_IN_PROGRESS,
  PATIENT_DASHBOARD_SUCCESS,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_SUCCESS,
  PATIENT_HEALTH_RECORDS_DEFAULT,
  PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_SUCCESS,
  PATIENT_UPCOMING__DELETE_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_SUCCESS,
} from "../../../constants/ActionTypes";

// GET PATIENT APPOINMENT LIST
export const getPatientAppoinmentListInProgress = (payload) => {
  console.log("action");
  return {
    type: GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getPatientAppoinmentListInSuccess = (payload) => {
  return {
    type: GET_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const getPatientAppoinmentListInFailure = (payload) => {
  return {
    type: GET_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE,
    payload: payload,
  };
};
// GET UPCOMING PATIENT APPOINMENT LIST
export const getUpcomingPatientAppoinmentListInProgress = (payload) => {
  return {
    type: GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getUpcomingPatientAppoinmentListInSuccess = (payload) => {
  return {
    type: GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const getUpcomingPatientAppoinmentListInFailure = (payload) => {
  return {
    type: GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE,
    payload: payload,
  };
};
//   delete
export const patientDeleteAppointmentListInProgress = (payload) => {
  return {
    type: PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const patientDeleteAppointmentListInSuccess = (payload) => {
  return {
    type: PATIENT_DELETE_APPOINTMENT_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const patientDeleteAppointmentListInFailure = (payload) => {
  return {
    type: PATIENT_DELETE_APPOINTMENT_LIST_IN_FAILURE,
    payload: payload,
  };
};
//   delete upcoming patient list
export const patientUpcomingDeleteAppointmentListInProgress = (payload) => {
  return {
    type: PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const patientUpcomingDeleteAppointmentListInSuccess = (payload) => {
  return {
    type: PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const patientUpcomingDeleteAppointmentListInFailure = (payload) => {
  return {
    type: PATIENT_UPCOMING__DELETE_APPOINTMENT_LIST_IN_FAILURE,
    payload: payload,
  };
};
//   add
export const patientAddAppointmentInProgress = (payload) => {
  return {
    type: PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const patientAddAppointmentInSuccess = (payload) => {
  return {
    type: PATIENT_ADD_APPOINTMENT_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const patientAddAppointmentInFailure = (payload) => {
  return {
    type: PATIENT_ADD_APPOINTMENT_LIST_IN_FAILURE,
    payload: payload,
  };
};
//   update
export const patientUpdateAppointmentInProgress = (payload) => {
  return {
    type: PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const patientUpdateAppointmentInSuccess = (payload) => {
  return {
    type: PATIENT_UPDATE_APPOINTMENT_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const patientUpdateAppointmentInFailure = (payload) => {
  return {
    type: PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE,
    payload: payload,
  };
};
//   get appointment by id
export const getPatientAppointmentByIdInProgress = (payload) => {
  return {
    type: GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS,
    payload: payload,
  };
};
export const getPatientAppointmentByIdInSuccess = (payload) => {
  return {
    type: GET_PATIENT_APPOINTMENT_BY_ID_IN_SUCCESS,
    payload: payload,
  };
};
export const getPatientAppointmentByIdInFailure = (payload) => {
  return {
    type: GET_PATIENT_APPOINTMENT_BY_ID_IN_FAILURE,
    payload: payload,
  };
};
//   get schedule
export const getScheduleInProgress = (payload) => {
  return {
    type: GET_SCHEDULE_IN_PROGRESS,
    payload: payload,
  };
};
export const getScheduleInSuccess = (payload) => {
  return {
    type: GET_SCHEDULE_IN_SUCCESS,
    payload: payload,
  };
};
export const getScheduleInFailure = (payload) => {
  return {
    type: GET_SCHEDULE_IN_FAILURE,
    payload: payload,
  };
};
export const getScheduleInDefault = (payload) => {
  return {
    type: GET_SCHEDULE_IN_DEFAULT,
    payload: payload,
  };
};
// doctor consultation fee
export const doctorConsultationFeeInProgress = (payload) => {
  return {
    type: DOCTOR_CONSULTATION_IN_PROGRESS,
    payload: payload,
  };
};
export const doctorConsultationFeeInSuccess = (payload) => {
  return {
    type: DOCTOR_CONSULTATION_IN_SUCCESS,
    payload: payload,
  };
};
export const doctorConsultationFeeInFailure = (payload) => {
  return {
    type: DOCTOR_CONSULTATION_IN_FAILURE,
    payload: payload,
  };
};
export const getPatientHeathRecordsInProgress = (payload) => {
  return {
    type: GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    payload: payload,
  };
};
export const getPatientHeathRecordsSuccess = (payload) => {
  return {
    type: GET_PATIENT_HEALTH_RECORDS_SUCCESS,
    payload: payload,
  };
};
export const getPatientHeathRecordsFailure = (payload) => {
  return {
    type: GET_PATIENT_HEALTH_RECORDS_ERROR,
    payload: payload,
  };
};
export const addPatientHeathRecordsInProgress = (payload) => {
  return {
    type: ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    payload: payload,
  };
};
export const addPatientHeathRecordsSuccess = (payload) => {
  return {
    type: ADD_PATIENT_HEALTH_RECORDS_SUCCESS,
    payload: payload,
  };
};
export const addPatientHeathRecordsFailure = (payload) => {
  return {
    type: ADD_PATIENT_HEALTH_RECORDS_ERROR,
    payload: payload,
  };
};
export const PatientHeathRecordsDefaultState = () => {
  return {
    type: PATIENT_HEALTH_RECORDS_DEFAULT,
  };
};
export const editPatientHeathRecordsInProgress = (payload) => {
  return {
    type: EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    payload: payload,
  };
};
export const editPatientHeathRecordsSuccess = (payload) => {
  return {
    type: EDIT_PATIENT_HEALTH_RECORDS_SUCCESS,
    payload: payload,
  };
};
export const editPatientHeathRecordsFailure = (payload) => {
  return {
    type: EDIT_PATIENT_HEALTH_RECORDS_ERROR,
    payload: payload,
  };
};
export const deletePatientHeathRecordsInProgress = (payload) => {
  return {
    type: DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    payload: payload,
  };
};
export const deletePatientHeathRecordsSuccess = (payload) => {
  return {
    type: DELETE_PATIENT_HEALTH_RECORDS_SUCCESS,
    payload: payload,
  };
};
export const deletePatientHeathRecordsFailure = (payload) => {
  return {
    type: DELETE_PATIENT_HEALTH_RECORDS_ERROR,
    payload: payload,
  };
};
export const getSinglePatientHeathRecordsInProgress = (payload) => {
  return {
    type: GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    payload: payload,
  };
};
export const getSinglePatientHeathRecordsSuccess = (payload) => {
  return {
    type: GET_SINGLE_PATIENT_HEALTH_RECORDS_SUCCESS,
    payload: payload,
  };
};
export const getSinglePatientHeathRecordsFailure = (payload) => {
  return {
    type: GET_SINGLE_PATIENT_HEALTH_RECORDS_ERROR,
    payload: payload,
  };
};
export const getAppointmentByDoctorPatientInProgress = (payload) => {
  return {
    type: GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS,
    payload: payload,
  };
};
export const getAppointmentByDoctorPatientSuccess = (payload) => {
  return {
    type: GET_APPOINTMENT_BY_DOCTOR_PATIENT_SUCCESS,
    payload: payload,
  };
};
export const getAppointmentByDoctorPatientFailure = (payload) => {
  return {
    type: GET_APPOINTMENT_BY_DOCTOR_PATIENT_ERROR,
    payload: payload,
  };
};

export const patientDashboardInProgress = (payload) => {
  return {
    type: PATIENT_DASHBOARD_IN_PROGRESS,
    payload: payload,
  };
};
export const patientDashboardSuccess = (payload) => {
  return {
    type: PATIENT_DASHBOARD_SUCCESS,
    payload: payload,
  };
};
export const patientDashboardError = (payload) => {
  return {
    type: PATIENT_DASHBOARD_ERROR,
    payload: payload,
  };
};

export const getVitalsListInprogress = (payload) => {
  console.log("action");
  return {
    type: GET_VITALS_LIST_INPROGRESS,
    payload: payload,
  };
};
export const getVitalsListSuccess = (payload) => {
  return {
    type: GET_VITALS_LIST_SUCCESS,
    payload: payload,
  };
};
export const getVitalsListError = (payload) => {
  return {
    type: GET_VITALS_LIST_ERROR,
    payload: payload,
  };
};
export const addVitalsInprogress = (payload) => {
  console.log("payload", payload);
  return {
    type: ADD_VITALS_INPROGRESS,
    payload: payload,
  };
};
export const addVitalsSuccess = (payload) => {
  return {
    type: ADD_VITALS_SUCCESS,
    payload: payload,
  };
};
export const addVitalsError = (payload) => {
  return {
    type: ADD_VITALS_ERROR,
    payload: payload,
  };
};
export const getBmiListInprogress = (payload) => {
  return {
    type: GET_BMI_LIST_INPROGRESS,
    payload: payload,
  };
};
export const getBmiListSuccess = (payload) => {
  return {
    type: GET_BMI_LIST_SUCCESS,
    payload: payload,
  };
};
export const getBmiListError = (payload) => {
  return {
    type: GET_BMI_LIST_ERROR,
    payload: payload,
  };
};
export const addBmiInprogress = (payload) => {
  return {
    type: ADD_BMI_INPROGRESS,
    payload: payload,
  };
};
export const addBmiSuccess = (payload) => {
  return {
    type: ADD_BMI_SUCCESS,
    payload: payload,
  };
};
export const addBmiError = (payload) => {
  return {
    type: ADD_BMI_ERROR,
    payload: payload,
  };
};
export const getBloodGlucoseListInprogress = (payload) => {
  return {
    type: GET_BLOOD_GLUCOSE_LIST_INPROGRESS,
    payload: payload,
  };
};
export const getBloodGlucoseListSuccess = (payload) => {
  return {
    type: GET_BLOOD_GLUCOSE_LIST_SUCCESS,
    payload: payload,
  };
};
export const getBloodGlucoseListError = (payload) => {
  return {
    type: GET_BLOOD_GLUCOSE_LIST_ERROR,
    payload: payload,
  };
};
export const addBloodGlucoseInprogress = (payload) => {
  return {
    type: ADD_BLOOD_GLUCOSE_INPROGRESS,
    payload: payload,
  };
};
export const addBloodGlucoseSuccess = (payload) => {
  return {
    type: ADD_BLOOD_GLUCOSE_SUCCESS,
    payload: payload,
  };
};
export const addBloodGlucoseError = (payload) => {
  return {
    type: ADD_BLOOD_GLUCOSE_ERROR,
    payload: payload,
  };
};
export const getInsulinListInprogress = (payload) => {
  return {
    type: GET_INSULIN_LIST_INPROGRESS,
    payload: payload,
  };
};
export const getInsulinListSuccess = (payload) => {
  return {
    type: GET_INSULIN_LIST_SUCCESS,
    payload: payload,
  };
};
export const getInsulinListError = (payload) => {
  return {
    type: GET_INSULIN_LIST_ERROR,
    payload: payload,
  };
};
export const addInsulinInprogress = (payload) => {
  return {
    type: ADD_INSULIN_INPROGRESS,
    payload: payload,
  };
};
export const addInsulinSuccess = (payload) => {
  return {
    type: ADD_INSULIN_SUCCESS,
    payload: payload,
  };
};
export const addInsulinError = (payload) => {
  return {
    type: ADD_INSULIN_ERROR,
    payload: payload,
  };
};
export const defaultVital = (payload) => {
  return {
    type: DEFAULT_VITAL,
    payload: payload,
  };
};
export const defaultBmi = (payload) => {
  return {
    type: DEFAULT_BMI,
    payload: payload,
  };
};
export const defaultBloodGlucose = (payload) => {
  return {
    type: DEFAULT_BLOOD_GLUCOSE,
    payload: payload,
  };
};
export const defaultInsulin = (payload) => {
  return {
    type: DEFAULT_INSULIN,
    payload: payload,
  };
};
export const getVitalsByTypeInprogress = (payload) => {
  return {
    type: GET_VITALS_LIST_TYPE_INPROGRESS,
    payload: payload,
  };
};
export const getVitalsByTypeSuccess = (payload) => {
  return {
    type: GET_VITALS_LIST_TYPE_SUCCESS,
    payload: payload,
  };
};
export const getVitalsByTypeError = (payload) => {
  return {
    type: GET_VITALS_LIST_TYPE_ERROR,
    payload: payload,
  };
};
