import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  LOGIN_USER_IN_PROGRESS,
  LOGOUT_USER_IN_PROGRESS,
  REGISTER_USER_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { hideAuthLoader } from "../actions/Auth";
import {
  RegisterUserError,
  RegisterUserInProgress,
  UserLoginError,
  UserLoginSuccess,
  UserLogoutSuccess,
} from "../actions/AuthAction";
import { RegisterEffect, userLoginEffect } from "./effects/AuthEffect";

function* UserLoginWatcher({ payload }) {
  try {
    let { data } = yield call(userLoginEffect, payload);
    if (data?.message) {
      yield call(Message, { type: "error", content: data?.message });

      yield put(
        UserLoginError({
          error: true,
          data: data,
        })
      );
    } else {
      yield call(Message, {
        type: "success",
        content: "Login Successfully",
      });
      console.log("data", data);
      localStorage.setItem("user_id", data?.token);
      localStorage.setItem("doctor_id", data?.user?.doctor_id);
      localStorage.setItem("patient_id", data?.user?.patient_id);
      localStorage.setItem("id", data?.user?.user_id);
      localStorage.setItem("pass", data?.user?.password);
      localStorage.setItem("role_id", data?.user?.role_id);
      localStorage.setItem("email", data?.user?.email);
      localStorage.setItem("org_id", data?.user?.organization_id);
      localStorage.setItem("pharmacy_id", data?.user?.pharmacies_id);
      localStorage.setItem("role_permision", data?.user?.access);

      yield put(
        UserLoginSuccess({
          data: data?.user,
          success: true,
        })
      );
    }
  } catch (e) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      UserLoginError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* RegisterWatcher({ payload }) {
  try {
    let { data } = yield call(RegisterEffect, payload);
    console.log("data", data);

    yield call(Message, { type: "error", content: data?.message });
    yield put(
      RegisterUserInProgress({
        error: true,
        data: data,
      })
    );
  } catch (e) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      RegisterUserError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* UserLogoutWatcher() {
  localStorage.removeItem("user_id");
  localStorage.removeItem("doctor_id");
  localStorage.removeItem("patient_id");
  localStorage.removeItem("id");
  localStorage.removeItem("pass");
  localStorage.removeItem("role_id");
  localStorage.removeItem("email");
  yield put(
    UserLogoutSuccess({
      success: false,
    })
  );
}
export default function* AuthSaga() {
  yield takeLatest(LOGIN_USER_IN_PROGRESS, UserLoginWatcher);
  yield takeLatest(REGISTER_USER_IN_PROGRESS, RegisterWatcher);
  yield takeLatest(LOGOUT_USER_IN_PROGRESS, UserLogoutWatcher);
}
