import React from "react";
import AppointmentQuestions from "./AppointmentQuestions";
import { useLocation } from "react-router-dom";

function AppointmentView() {
  const locations = useLocation();
  console.log("LOACTIONS", locations);
  return (
    <div>
      <AppointmentQuestions view={true} editData={locations?.state} />
    </div>
  );
}

export default AppointmentView;
