import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Typography,
  DatePicker,
  message,
} from "antd";
import "../../../../styles/modules/pharmacy.less";
import { APP_ROUTES } from "../../../../constants/Routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  CreditCardOutlined,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormInput from "../../../../components/Input/FormInput";
import IntlMessages from "../../../../util/IntlMessages";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import { pharmacyList } from "../../../Doctor/data";
import TextArea from "antd/lib/input/TextArea";
import PurchaseRequestProductForm from "./PurchaseRequestProductForm";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addPurchaseRequestInProgress,
  editPurchaseRequestInProgress,
  getSupplierListInProgress,
  getTabletsListInProgress,
  purchaseRequestDefaultData,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import PurchaseRequestFormTable from "./PurchaseRequestFormTable";
import { currentDate, disablePastDate } from "../../../../util/utilz";
import PurchaseFooters from "../PurchaseFooters";

const AddPurchaseRequest = ({
  editPurchase,
  purchaseRequestData,
  viewPurchaseData,
  viewData,
}) => {
  const makeRow = (counter) => ({
    key: counter,
    product_name: counter,
    tablet_id: counter,
    batch_no: counter,
    quantity: counter,
    purchase_price: counter,
    unit_price: counter,
    gst_percentag: counter,
    amount: counter,
  });
  const orgId = localStorage.getItem("org_id");

  const location = useLocation();
  const [input, setInput] = useState([makeRow(0)]);
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [key, setKey] = useState();
  const [formData, setFormData] = useState();
  const [companyNameList, setCompanyNameList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState([]);
  const supplierData = useSelector((state) => state?.pharmacy?.getSupplierList);
  const supplierList = supplierData?.data?.data;

  const values = form.getFieldsValue();
  const addPurchaseRequestData = useSelector(
    (state) => state?.pharmacy?.addPurchaseRequest
  );
  const editPurchaseRequestData = useSelector(
    (state) => state?.pharmacy?.editPurchaseRequest
  );
  const { tabletList } = useSelector((state) => state.pharmacy);

  useEffect(() => {
    let calculatedSubtotal = 0;
    let calculatedTotalGST = 0;
    const purchaseDetailsArray = values?.purchase_request_details
      ? Object.values(values?.purchase_request_details).filter(
          (value) => value != null
        )
      : [];

    purchaseDetailsArray?.forEach((product) => {
      const productGST =
        ((parseFloat(product.gst_percentage)
          ? parseFloat(product.gst_percentage)
          : 0) *
          (parseFloat(product.purchase_price)
            ? parseFloat(product.purchase_price)
            : 0) *
          (parseFloat(product.quantity) ? parseFloat(product.quantity) : 0)) /
        100;
      if (parseFloat(product.purchase_price) && parseFloat(product.quantity)) {
        calculatedSubtotal +=
          parseFloat(product.purchase_price) * parseFloat(product.quantity);
      }

      calculatedTotalGST += productGST;
    });

    let totalAmount = calculatedSubtotal + calculatedTotalGST;
    form.setFieldValue("gst_amount", calculatedTotalGST.toFixed(2));
    form.setFieldValue("sub_total", calculatedSubtotal.toFixed(2));
    form.setFieldValue("total", totalAmount.toFixed(2));
    setKey();
  }, [key]);

  const onFinish = (datas) => {
    const data = {
      ...datas,
      organization_id: orgId,
      address2: "",
      sub_total: form.getFieldValue("sub_total"),
      gst_amount: form.getFieldValue("gst_amount"),
      total: form.getFieldValue("total"),
      supplier_id: form.getFieldValue("supplier_id"),
      purchase_request_details: Object.values(
        datas?.purchase_request_details
      ).filter((value) => value != null),
    };
    const duplicateTabletBatchCombinations = {};
    data.purchase_request_details.forEach((detail) => {
      const key = `${detail.product_name}-${detail.batch_no}`;
      if (duplicateTabletBatchCombinations[key]) {
        duplicateTabletBatchCombinations[key]++;
      } else {
        duplicateTabletBatchCombinations[key] = 1;
      }
    });
    const duplicates = Object.keys(duplicateTabletBatchCombinations).filter(
      (key) => duplicateTabletBatchCombinations[key] > 1
    );
    if (duplicates.length !== 0) {
      message.error(`Duplicate tablet batch_no combinations: ${duplicates}`);
    } else {
      if (editPurchase) {
        dispatch(
          editPurchaseRequestInProgress({
            data,
            id: purchaseRequestData?.id,
          })
        );
      } else {
        dispatch(addPurchaseRequestInProgress(data));
      }
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const changeCompanyName = (value) => {
    setCompanyName(value);
    const selectedCompany = supplierList.find(
      (company) => company.company_name === value
    );

    form.setFieldsValue({
      supplier_id: selectedCompany?.id,
      address1: selectedCompany?.address,
      address2: selectedCompany?.address2,
      mobile: selectedCompany?.mobile,
      city: selectedCompany?.city,
      state: selectedCompany?.state,
      zipcode: selectedCompany?.zipcode,
    });
  };

  useEffect(() => {
    if (editPurchase) {
      console.log("purchaseRequestData", purchaseRequestData);
      form.setFieldValue("date", purchaseRequestData?.date);
      form.setFieldValue(
        "company_name",
        purchaseRequestData?.Supplier?.company_name
      );
      form.setFieldValue("purchaseCode", purchaseRequestData?.purchaseCode);
      form.setFieldValue("purchaseType", purchaseRequestData?.purchaseType);
      form.setFieldValue("address1", purchaseRequestData?.address1);
      form.setFieldValue("address2", purchaseRequestData?.address2);
      form.setFieldValue("city", purchaseRequestData?.city);
      form.setFieldValue("state", purchaseRequestData?.state);
      form.setFieldValue("zipcode", purchaseRequestData?.zipcode);
      form.setFieldValue("mobile", purchaseRequestData?.mobile);
      form.setFieldValue("gst_amount", purchaseRequestData.gst_amount);
      form.setFieldValue("sub_total", purchaseRequestData.sub_total);
      form.setFieldValue("total", purchaseRequestData.total);
      form.setFieldValue("supplier_id", purchaseRequestData.supplier_id);

      const newInput = purchaseRequestData?.purchase_request_details?.map(
        (value, index) => {
          onChangeTablet(value, index);
          return makeRow(index);
        }
      );

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    } else if (viewData) {
      form.setFieldValue("date", viewPurchaseData?.date);
      form.setFieldValue(
        "company_name",
        viewPurchaseData?.Supplier?.company_name
      );
      form.setFieldValue("purchaseCode", viewPurchaseData?.purchaseCode);
      form.setFieldValue("purchaseType", viewPurchaseData?.purchaseType);
      form.setFieldValue("address1", viewPurchaseData?.address1);
      form.setFieldValue("address2", viewPurchaseData?.address2);
      form.setFieldValue("city", viewPurchaseData?.city);
      form.setFieldValue("state", viewPurchaseData?.state);
      form.setFieldValue("zipcode", viewPurchaseData?.zipcode);
      form.setFieldValue("mobile", viewPurchaseData?.mobile);
      form.setFieldValue("gst_amount", viewPurchaseData.gst_amount);
      form.setFieldValue("sub_total", viewPurchaseData.sub_total);
      form.setFieldValue("total", viewPurchaseData.total);
      const newInput = viewPurchaseData?.purchase_request_details?.map(
        (value, index) => {
          onChangeTablet(value, index, viewData);
          return makeRow(index);
        }
      );

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    }
  }, [editPurchase, viewData]);
  useEffect(() => {
    dispatch(getSupplierListInProgress(false));
    dispatch(purchaseRequestDefaultData());
    const fromData = { pagination_required: false, page: 1 };
    dispatch(getTabletsListInProgress(fromData));
    form.setFieldValue("date", currentDate);
  }, []);

  useEffect(() => {
    if (supplierData?.success) {
      const companyList =
        supplierList &&
        supplierList?.map((item) => {
          return { label: item.company_name, value: item.company_name };
        });
      setCompanyNameList(companyList);
    }
  }, [supplierData]);

  useEffect(() => {
    if (addPurchaseRequestData?.success || editPurchaseRequestData?.success) {
      history.push("/purchase/request");
    } else if (
      addPurchaseRequestData?.inProgress ||
      editPurchaseRequestData?.inProgress
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addPurchaseRequestData, editPurchaseRequestData]);
  const onChangeTablet = (data, key, view) => {
    form.setFieldValue(
      [`purchase_request_details`, key, "product_name"],
      data?.medicine_name || data.product_name
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "tablet_id"],
      view
        ? data?.medicine_name || data.product_name
        : data?.tablet_id || data?.id
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "quantity"],
      data.quantity || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "purchase_price"],
      data.price || data.purchase_price || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "unit_price"],
      data.unit_price || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "gst_percentage"],
      data.gst_percentage || data.gst_percent || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "batch_no"],
      data.batch_no
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "amount"],
      data.amount || 0
    );
  };
  useEffect(() => {
    if (Number(key) >= 0) {
      const quantity = form.getFieldValue([
        `purchase_request_details`,
        key,
        "quantity",
      ]);
      const purchase_price = form.getFieldValue([
        `purchase_request_details`,
        key,
        "purchase_price",
      ]);
      const totalAmount = Number(quantity) * Number(purchase_price);
      if (Number(totalAmount)) {
        form.setFieldValue(
          [`purchase_request_details`, key, "amount"],
          totalAmount.toFixed(2)
        );
      } else if (Number(totalAmount) === 0) {
        form.setFieldValue([`purchase_request_details`, key, "amount"], 0.0);
      }

      setKey();
    }
  }, [key]);
  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };
  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);

    setInput(updatedInput);
    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.purchase_request_details[key] = null;
      setFormData(formDataCopy);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.purchase_request_details[key] = null;
      setFormData(formDataCopy);
    }
    setKey(key);
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "tablet_id",
      render: (_, render) => (
        <>
          {" "}
          {viewData ? (
            <Form.Item shouldUpdate>
              {" "}
              <FormInput
                name={[`${render.key}`, "tablet_id"]}
                onChange={(e) => {
                  setKey(render.key);
                }}
                className="input len"
                type="text"
                readOnly={true}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Enter the  batch no"} />,
                  },
                ]}
              />{" "}
            </Form.Item>
          ) : (
            <Form.Item
              name={[`${render.key}`, "tablet_id"]}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"select the  tablet"} />,
                },
              ]}
            >
              <FormSelectBox
                editMode="true"
                forFilter="true"
                showSearch="true"
                options={
                  tabletList?.data?.data?.map((pack, i) => {
                    return { label: pack.medicine_name, value: pack.id };
                  }) || []
                }
                placeholder="Select"
                className="sel select-box"
                onChange={(e) => {
                  const ids = tabletList?.data?.data?.find((id) => {
                    if (id.id === e) {
                      return id;
                    }
                  });

                  onChangeTablet(ids, render.key);
                  setKey(render.key);
                }}
              />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            name={[`${render.key}`, "batch_no"]}
            onChange={(e) => {
              setKey(render.key);
            }}
            className="input len"
            type="text"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the  batch no"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={1}
            name={[`${render.key}`, "purchase_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the purchase price"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={0}
            name={[`${render.key}`, "unit_price"]}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "GST (%)",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={0}
            name={[`${render.key}`, "gst_percentage"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the gst "} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            name={[`${render.key}`, "amount"]}
            readOnly
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record) => (
        <>
          {!viewData && (
            <ButtonComponent
              icon={<DeleteFilled />}
              className="btn-edits"
              type="danger"
              onClick={() => deleteRow(record.key)}
            />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editPurchase ? (
                    <IntlMessages id={"label.editPurchaseRequest"} />
                  ) : viewData ? (
                    <IntlMessages id={"label.viewPurchaseRequest"} />
                  ) : (
                    <IntlMessages id={"label.addPurchaseRequest"} />
                  )}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography className="subheading mb-20">
                  {
                    <IntlMessages
                      id={"Addnew.purchaseRequest.supplierSection"}
                    />
                  }
                </Typography>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {viewData ? (
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"company_name"}
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.companyName"}
                          />
                        }
                        readOnly={viewData}
                      />
                    ) : (
                      <Form.Item
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.companyName"}
                          />
                        }
                        name={"company_name"}
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.pharmacy.validator.companyName"}
                              />
                            ),
                          },
                        ]}
                      >
                        <FormSelectBox
                          placeholder="Search Purchase Request/ID"
                          editMode="true"
                          forFilter="true"
                          showSearch="true"
                          value={companyName}
                          options={companyNameList}
                          onChange={changeCompanyName}
                          className="responsive-select"
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.address1"} />
                      }
                      name="address1"
                    >
                      <TextArea rows={3} readOnly={viewData} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"address2"}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.address2"} />
                      }
                      readOnly={viewData}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"city"}
                      readOnly={viewData}
                      label={<IntlMessages id={"Addnew.pharmacy.label.city"} />}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"state"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.state"} />
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"zipcode"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.zipCode"} />
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p className="subheading">
                  {
                    <IntlMessages
                      id={"Addnew.purchaseRequest.purchaseSection"}
                    />
                  }
                </p>
                <Row>
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"purchaseCode"}
                      readOnly={viewData}
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.purchaseRequestCode"}
                        />
                      }
                    />
                  </Col> */}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {viewData ? (
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"date"}
                        label={
                          <IntlMessages id={"Addnew.pharmacy.label.date"} />
                        }
                        readOnly={viewData}
                      />
                    ) : (
                      <Form.Item
                        name={"date"}
                        label={
                          <IntlMessages id={"Addnew.pharmacy.label.date"} />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Medicine.validator.add_date"}
                              />
                            ),
                          },
                        ]}
                      >
                        <FormInput
                          type="date"
                          placeholder="date"
                          name="date"
                          min={disablePastDate()}
                          defaultValue={currentDate}
                          labelCol={"24"}
                          wrapperCol={"24"}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"mobile"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.mobile"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.pharmacy.validator.mobile"}
                            />
                          ),
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit mobile number!",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {<IntlMessages id={"label.productInformation"} />}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.List name={"purchase_request_details"}>
                  {(fields, { add }) => (
                    <PurchaseRequestFormTable
                      addRow={addRow}
                      columns={columns}
                      input={input}
                      viewData={viewData}
                    />
                  )}
                </Form.List>
                {input.length === 0 && (
                  <Typography className="red-text">
                    select the product informations !
                  </Typography>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="footer-total">
                <PurchaseFooters
                  subtotal={form.getFieldValue("sub_total") || 0}
                  totalGST={form.getFieldValue("gst_amount") || 0}
                  total={form.getFieldValue("total") || 0}
                />
              </Col>
            </Row>
            <Row className="mt-10">
              <Col
                xs={24}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.PURCHASEREQUEST}>
                  <ButtonComponent
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>
                {!viewData && (
                  <ButtonComponent
                    icon={<CreditCardOutlined />}
                    label={editPurchase ? "Save" : "Submit"}
                    htmlType="submit"
                    type="primary"
                    disabled={input.length === 0}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddPurchaseRequest;
