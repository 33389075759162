import { useState } from "react";
import {
  FullscreenOutlined,
  EllipsisOutlined,
  VideoCameraOutlined,
  AudioOutlined,
  AudioMutedOutlined,
} from "@ant-design/icons";
import { Col, Row, Button } from "antd";
import { useClient } from "./Settings";
import { ReactComponent as CallIcon } from "../../../../assets/icons/phone-solid.svg";
import { ReactComponent as Videomute } from "../../../../assets/icons/video-slash-solid.svg";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { useHistory } from "react-router-dom";
export default function Controls(props) {
  const client = useClient();
  const history = useHistory();
  const { tracks, setStart, setInCall, setVideo, video, setIsModalOpen } =
    props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };
  const videoexpand = () => {
    setVideo(
      video === "Video1"
        ? "screenvideo"
        : video === "screenvideo"
        ? "Video1"
        : ""
    );
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    setIsModalOpen(false);
    history.push("/appoinment/allappointments");
  };

  return (
    <div
      style={{
        marginRight: "80px",
      }}
    >
      <ButtonComponent
        onClick={videoexpand}
        className="mute"
        shape="circle"
        size="default"
        icon={<FullscreenOutlined />}
      />

      <ButtonComponent
        onClick={() => mute("audio")}
        className={trackState.audio ? "mute" : "mute"}
        shape="circle"
        size="default"
        icon={trackState.audio ? <AudioOutlined /> : <AudioMutedOutlined />}
      />

      <ButtonComponent
        onClick={() => leaveChannel()}
        className="video-leave video-btn"
        shape="circle"
        size="default"
        icon={<CallIcon />}
        // style={{ fontSize: "12px" }}
      />

      <ButtonComponent
        onClick={() => mute("video")}
        className={trackState.video ? "mute" : "mute"}
        shape="circle"
        size="default"
        icon={trackState.video ? <VideoCameraOutlined /> : <Videomute />}
      ></ButtonComponent>

      <ButtonComponent
        className="mute"
        shape="circle"
        size="default"
        icon={<EllipsisOutlined />}
      />
    </div>
  );
}
