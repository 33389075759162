import axios from "axios";

const holidayInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getHolidayListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id");
  console.log("formDataformDataformDataformData", data);
  return holidayInstance.request({
    url: "/holiday/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
    params: {
      pageNo: data,
      pageSize: 10,
      isPrev: 0,
      pagination_required: true,
    },
  });
};

export const deleteHolidayListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id");
  return holidayInstance.request({
    url: `/holiday/delete/${data}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

export const addHolidayListEffect = (formData, token) => {
  const tokens = localStorage.getItem("user_id");
  return holidayInstance.request({
    url: "/holiday/add/",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${tokens}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const updateHolidayListEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");
  return holidayInstance.request({
    url: `/holiday/update/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const getHolidayUpdateListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id");
  return holidayInstance.request({
    url: `/holiday/details/${data}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
