import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import TableComponent from "../../../components/Table/TableComponent";

import {
  deleteQualificationListInProgress,
  getQualificationListInProgress,
} from "../../../appRedux/doctor/actions/Qualification";

const QualificationTable = () => {
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sortDirections: ["ascend", "descend"],
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link to={`/qualification/edit/${record.id}`}>
                {" "}
                <ButtonComponent type="primary" icon={<EditOutlined />} />
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handlePage = (page) => {
    dispatch(getQualificationListInProgress(page));
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteQualificationListInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(qualificationList);
    } else {
      const newData = qualificationList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const dispatch = useDispatch();

  const qualificationData = useSelector((state) => state.qualification);
  const qualificationStatus = qualificationData?.qualificationlist;
  const qualificationList = qualificationData?.qualificationlist?.data?.data;
  const totalRecords = qualificationStatus?.data?.totalRecords;
  const deleteQualification = useSelector(
    (state) => state.qualification.deletequalificationlist
  );
  console.log("deleteQualification", deleteQualification);
  useEffect(() => {
    if (deleteQualification?.success) {
      const page = 1;
      dispatch(getQualificationListInProgress(page));
    }
  }, [deleteQualification?.success]);
  useEffect(() => {
    if (qualificationStatus?.progressing) {
      setLoading(true);
    } else if (qualificationStatus?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [qualificationStatus?.progressing, qualificationStatus?.success]);
  useEffect(() => {
    const page = 1;
    dispatch(getQualificationListInProgress(page));
  }, []);

  return (
    <Spin indicator={antIcon} spinning={loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : qualificationList}
        itemList={qualificationList}
        filteredUsers={filteredUsers}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        setFilteredUsers={setFilteredUsers}
        field={"name"}
        rowselector={handleSelectChange}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data ?</p>
      </Modal>
    </Spin>
  );
};

export default QualificationTable;
