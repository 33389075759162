import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addRoomInProgress,
  addWardInProgress,
  editRoomInProgress,
  editWardInProgress,
} from "../../../appRedux/Ward/WardAction";

const AddRoom = ({ editRoom, editRoomData }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const org_id = localStorage.getItem("org_id");
  const wardData = location.state?.id;

  const [roomTypeValue, setRoomTypeValue] = useState("2");
  const addRoomData = useSelector((state) => state?.ward?.addRoom);
  const editRoomResponse = useSelector((state) => state?.ward?.editRoom);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (datas) => {
    console.log("datas", datas);

    if (editRoom) {
      const payload = {
        block_id: editRoomData?.Block?.id,
        ward_id: editRoomData?.ward_id,
        room_no: datas?.room_no,
        room_type: datas?.room_type,
        room_status: "1",
        room_incharge_name: datas?.room_incharge_name,
        organization_id: org_id,
        ...(datas.room_type === "1"
          ? { ac_room_price: datas.ac_room_price }
          : { non_ac_room_price: datas.non_ac_room_price }),
      };
      dispatch(editRoomInProgress({ id: editRoomData?.id, payload }));
    } else {
      const payload = {
        block_id: wardData?.block_id,
        ward_id: wardData?.id,
        room_no: datas?.room_no,
        room_type: datas?.room_type,
        room_status: "1",
        room_incharge_name: datas?.room_incharge_name,
        organization_id: org_id,
        ...(datas.room_type === "1"
          ? { ac_room_price: datas.ac_room_price }
          : { non_ac_room_price: datas.non_ac_room_price }),
      };
      dispatch(addRoomInProgress(payload));
    }
    if (addRoomData?.inProgress || editRoomResponse?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const [Loading, setLoading] = useState(false);

  const changeLanguage = (value) => {
    setRoomTypeValue(value);
    if (value === "1") {
      form.setFieldValue("ac_room_price", wardData?.ac_room_price);
    } else {
      form.setFieldValue("non_ac_room_price", wardData?.non_ac_room_price);
    }
  };

  const roomTypeList = [
    { label: "AC", value: "1" },
    { label: "NON-AC", value: "2" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (editRoom) {
      form.setFieldsValue(editRoomData);
    }
  }, [editRoom]);

  const addDefaultRow = useRef(null);
  useEffect(() => {
    if (addDefaultRow.current) {
      addDefaultRow.current();
    }
  }, []);
  console.log("wardData", wardData);
  useEffect(() => {
    if (addRoomData?.success || editRoomResponse?.success) {
      // history.push("/ward");
      history.push({
        pathname: "/rooms",
        state: {
          wardData: editRoom
            ? {
                id: editRoomData?.Ward?.id,
                ward_name: editRoomData?.Ward?.ward_name,
                block_id: editRoomData?.Block?.id,
                block_name: editRoomData?.Block?.block_name,
                editRoom: "true",
              }
            : wardData,
        },
      });
    }
  }, [addRoomData, editRoomResponse]);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {!editRoom ? (
                    <IntlMessages id={"label.addRoom"} />
                  ) : (
                    <IntlMessages id={"label.editRoom"} />
                  )}
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"room_no"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.roomNumber"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomNumber"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"room_incharge_name"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.roomInchargeName"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomInchargeName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.roomType"} />}
                      name={"room_type"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomType"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select room Type"
                        value={roomTypeValue}
                        options={roomTypeList}
                        onChange={changeLanguage}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                  {roomTypeValue === "1" ? (
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="number"
                        name={"ac_room_price"}
                        readOnly={true}
                        label={
                          <IntlMessages id={"Addnew.ward.label.acRoomPrice"} />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.ward.validator.acRoomPrice"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                  ) : (
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="number"
                        readOnly={true}
                        name={"non_ac_room_price"}
                        label={
                          <IntlMessages
                            id={"Addnew.ward.label.nonAcRoomPrice"}
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.ward.validator.nonAcRoomPrice"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link
                  to={{
                    pathname: `/rooms`,
                    state: {
                      wardData: editRoom
                        ? {
                            id: editRoomData?.Ward?.id,
                            ward_name: editRoomData?.Ward?.ward_name,
                            block_id: editRoomData?.Block?.id,
                            block_name: editRoomData?.Block?.block_name,
                            editRoom: "true",
                          }
                        : wardData,
                    },
                  }}
                >
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editRoom ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddRoom;
