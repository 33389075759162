import moment from "moment";

export const getErrorMessageFromAPI = ({ response }) => {
  if (response?.data?.message) {
    return response?.data?.message?.message
      ? response?.data?.message?.message
      : response?.data?.message;
  } else if (response?.data?.errorFields) {
    return Object.keys(response?.data?.errorFields).length === 0
      ? "Kindly check later"
      : response?.data?.errorFields;
  } else {
    return response?.data;
  }
};

export const getStatusFromAPI = ({ response }) => {
  if (response?.data?.status === "pending") {
    console.log("Pending");
  } else if (response?.data?.status === "200") {
    console.log("Response");
  }
};

export const getTokenfromLocalStorage = () => {
  return localStorage.getItem("user_id");
};

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};
const generateCsvContent = (dataSource, columns) => {
  // Filter out columns titled "Options"
  const exportableColumns = columns.filter((col) => col.title !== "Options");
  const headers = exportableColumns.map((col) => col.title).join(",");

  const rows = dataSource.map((row) => {
    return exportableColumns
      .map((col) => {
        if (col.dataIndex === "room_type") {
          return row.room_type === 1 ? "AC ROOM" : "NON-AC ROOM";
        } else {
          return row[col.dataIndex];
        }
      })
      .join(",");
  });

  // Combine headers and rows
  return [headers, ...rows].join("\n");
};
export const exportToCsv = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const currentDate = moment().format("YYYY-MM-DD");

export const access = (menu, type) => {
  const role_permision = JSON.parse(localStorage.getItem("role_permision"));
  if (role_permision === null) {
    const roleid = localStorage.getItem("role_id");
    if (Number(roleid) === 1) {
      return true;
    } else {
      return false;
    }
  }
  return role_permision[menu]?.[type];
};

export const formatDate = (date) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  } else {
    return "In Valid Date";
  }
};
export const validateDateFormat = (_, value) => {
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!value || dateFormatRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid date");
};
export const formatPrice = (price) => {
  if (typeof price !== "number") {
    return "Invalid price";
  }
  return price.toFixed(2);
};
