import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  ADD_ORGANISATION_IN_PROGRESS,
  DELETE_ORGANISATION_IN_PROGRESS,
  GET_ORGANISATION_NAME_IN_PROGRESS,
  REQUEST_ORGANISATION_IN_PROGRESS,
  UPDATE_ORGANISATION_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";

import {
  addOrgError,
  addOrgSuccess,
  deleteOrgError,
  deleteOrgSuccess,
  getOrgNameError,
  getOrgNameSuccess,
  requestOrgError,
  requestOrgSuccess,
  updateOrgError,
  updateOrgSuccess,
} from "../actions/organisation";
import {
  AddOrganisationEffect,
  DeleteOrganisationEffect,
  OrganisationEffect,
  UpdateOrganisationEffect,
  getOrganisationNameEffect,
} from "./effects/OrganisationEffect";

function* OrganisationWatcher({ payload }) {
  try {
    let { data } = yield call(OrganisationEffect, payload);

    yield put(
      requestOrgSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      requestOrgError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* GetOrganisationNameWatcher({ payload }) {
  try {
    let { data } = yield call(getOrganisationNameEffect, payload);

    yield put(
      getOrgNameSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getOrgNameError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* AddOrganisationWatcher({ payload }) {
  console.log(" data.payload", payload);
  try {
    let { data } = yield call(AddOrganisationEffect, payload);
    console.log(" data.message", data);
    yield put(
      addOrgSuccess({
        data: data.OrganizationsInfo,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addOrgError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* DeleteOrganisationWatcher({ payload }) {
  try {
    let { data } = yield call(DeleteOrganisationEffect, payload);

    yield put(
      deleteOrgSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteOrgError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* UpdateOrganisationWatcher({ payload }) {
  try {
    let { data } = yield call(UpdateOrganisationEffect, payload);
    console.log("dataaa", data);
    yield put(
      updateOrgSuccess({
        data: data.organizationsInfo,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateOrgError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

export default function* OrganisationSaga() {
  yield takeLatest(REQUEST_ORGANISATION_IN_PROGRESS, OrganisationWatcher);
  yield takeLatest(
    GET_ORGANISATION_NAME_IN_PROGRESS,
    GetOrganisationNameWatcher
  );
  yield takeLatest(ADD_ORGANISATION_IN_PROGRESS, AddOrganisationWatcher);
  yield takeLatest(DELETE_ORGANISATION_IN_PROGRESS, DeleteOrganisationWatcher);
  yield takeLatest(UPDATE_ORGANISATION_IN_PROGRESS, UpdateOrganisationWatcher);
}
