import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Typography, Tooltip } from "antd";
import {
  DeleteFilled,
  LoadingOutlined,
  PrinterFilled,
  EditFilled,
  EyeFilled,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";
import TableComponent from "../../components/Table/TableComponent";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { answerType, diseasesType } from "./data";
import {
  deleteQuestionAnswerInProgress,
  getQuestionAnswerListInProgress,
} from "../../appRedux/doctor/actions/appointmentQuestions";
import { access } from "../../util/utilz";

const AppointmentQuestionTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { getListQuestionAnswer, deleteQuestionAnswer } = useSelector(
    (state) => state?.appointmentQuestions
  );
  console.log("getListQuestionAnswer", deleteQuestionAnswer);

  const itemTransferList = getListQuestionAnswer?.data?.data;
  const itemTransferLists = getListQuestionAnswer?.data?.data;

  const totalRecords = getListQuestionAnswer?.data?.totalRecords;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteQuestionAnswerInProgress({ id: deleteId }));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Diseases Name",
      dataIndex: "disease",
      sortDirections: ["ascend", "descend"],
      key: "personName",
      sorter: (a, b) => {
        if (a && b && a.personName && b.personName) {
          return a.personName.localeCompare(b.personName);
        }
        return 0;
      },
      // render: (text, record) => {
      //   return text ? diseasesType.find((val) => val.value === text).label : "";
      // },
    },
    {
      title: "Question",
      dataIndex: "questions",
      sortDirections: ["ascend", "descend"],
      key: "contactNumber",
      sorter: (a, b) => {
        if (a && b && a.contactNumber && b.contactNumber) {
          return a.contactNumber.localeCompare(b.contactNumber);
        }
        return 0;
      },
    },
    {
      title: "Answer Method",
      dataIndex: "answers_type",
      sortDirections: ["ascend", "descend"],
      key: "total_items",
      render: (text, record) => {
        return answerType.find((val) => val.value === text).label;
      },
      sorter: (a, b) => {
        if (a && b && a.total_items && b.total_items) {
          return a.total_items.localeCompare(b.total_items);
        }
        return 0;
      },
    },
    {
      title: "Answer",
      dataIndex: "answers",
      sortDirections: ["ascend", "descend"],
      key: "total_quantity",
      render: (answers) =>
        answers
          ? answers.map((ans, i) => {
              return (
                <Typography key={i} className="text-warp">{`${i + 1}. ${
                  ans?.answer
                }`}</Typography>
              );
            })
          : "",
      sorter: (a, b) => {
        if (a && b && a.total_quantity && b.total_quantity) {
          return a.total_quantity.localeCompare(b.total_quantity);
        }
        return 0;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("appoinmentquestions", "view") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"View"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/viewappointmentquestions`,
                      state: { question: record },
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      icon={<EyeFilled />}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("appoinmentquestions", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  <Link
                    to={{
                      pathname: `/appointmentquestionscreen/editappointmentquestions`,
                      state: { question: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditFilled />}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("appoinmentquestions", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteFilled />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const payLoad = {
      pagination: true,
      page: page,
    };
    dispatch(getQuestionAnswerListInProgress(payLoad));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(itemTransferList);
    } else {
      const newData = itemTransferList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payLoad = {
      pagination: true,
      page: 1,
    };
    dispatch(getQuestionAnswerListInProgress(payLoad));
  }, [deleteQuestionAnswer?.success]);

  return (
    <Spin
      indicator={antIcon}
      spinning={
        deleteQuestionAnswer?.progressing || getListQuestionAnswer?.progressing
      }
    >
      <TableComponent
        columns={columns}
        dataSource={itemTransferLists}
        rowselector={handleSelectChange}
        itemList={itemTransferList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        field={"person_name"}
        type={"appointmentQuestions"}
        documentTitle="Appointment Questions"
      />

      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default AppointmentQuestionTable;
