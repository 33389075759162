import {
  REQUEST_ORGANISATION_IN_PROGRESS,
  ADD_ORGANISATION_FAILURE,
  ADD_ORGANISATION_IN_PROGRESS,
  ADD_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_FAILURE,
  DELETE_ORGANISATION_IN_PROGRESS,
  DELETE_ORGANISATION_SUCCESS,
  GETDATA_ORGANISATION_FAILURE,
  REQUEST_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILURE,
  UPDATE_ORGANISATION_IN_PROGRESS,
  UPDATE_ORGANISATION_SUCCESS,
  GET_ORGANISATION_NAME_IN_PROGRESS,
  GET_ORGANISATION_NAME__SUCCESS,
  GET_ORGANISATION_NAME__FAILURE,
  REQUEST_ORGANISATION_FAILURE,
} from "../../../constants/ActionTypes";

export const requestOrgInProgress = (payload) => {
  return {
    type: REQUEST_ORGANISATION_IN_PROGRESS,
    payload: payload,
  };
};
export const requestOrgSuccess = (payload) => {
  return {
    type: REQUEST_ORGANISATION_SUCCESS,
    payload: payload,
  };
};
export const requestOrgError = () => {
  return {
    type: REQUEST_ORGANISATION_FAILURE,
  };
};

export const addOrgInProgress = (payload) => {
  return {
    type: ADD_ORGANISATION_IN_PROGRESS,
    payload: payload,
  };
};
export const addOrgSuccess = (payload) => {
  return {
    type: ADD_ORGANISATION_SUCCESS,
    payload: payload,
  };
};
export const addOrgError = () => {
  return {
    type: ADD_ORGANISATION_FAILURE,
  };
};
export const deleteOrgInProgress = (payload) => {
  return {
    type: DELETE_ORGANISATION_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteOrgSuccess = (payload) => {
  return {
    type: DELETE_ORGANISATION_SUCCESS,
    payload: payload,
  };
};

export const deleteOrgError = () => {
  return {
    type: DELETE_ORGANISATION_FAILURE,
  };
};

export const updateOrgInProgress = (payload) => {
  return {
    type: UPDATE_ORGANISATION_IN_PROGRESS,
    payload: payload,
  };
};
export const updateOrgSuccess = (payload) => {
  return {
    type: UPDATE_ORGANISATION_SUCCESS,
    payload: payload,
  };
};

export const updateOrgError = () => {
  return {
    type: UPDATE_ORGANISATION_FAILURE,
  };
};
export const getOrgNameInProgress = (payload) => {
  return {
    type: GET_ORGANISATION_NAME_IN_PROGRESS,
    payload: payload,
  };
};
export const getOrgNameSuccess = (payload) => {
  return {
    type: GET_ORGANISATION_NAME__SUCCESS,
    payload: payload,
  };
};

export const getOrgNameError = () => {
  return {
    type: GET_ORGANISATION_NAME__FAILURE,
  };
};
