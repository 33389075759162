import { call, put, takeEvery } from "redux-saga/effects";
import Message from "../../../components/Message/Message";

import {
  FORGET_PASSWORD_IN_PROGRESS,
  GET_DESIGNATION_LIST_IN_PROGRESS,
  GET_ORG_LIST_IN_PROGRESS,
  GET_SPECIALIZATION_LIST_IN_PROGRESS,
  OTP_SEND_IN_PROGRESS,
  VERIFY_OTP_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  ForgetPasswordError,
  ForgetPasswordSuccess,
  getDesignationListError,
  getDesignationListSuccess,
  getOrgListError,
  getOrgListSuccess,
  getSpecializationListError,
  getSpecializationListSuccess,
  hideAuthLoader,
  OtpSendError,
  OtpSendSuccess,
  VerifyOtpError,
  VerifyOtpSuccess,
} from "../actions/Auth";
import {
  DesignationListEffect,
  OrgListEffect,
  SpecializationListEffect,
} from "./effects/OrganisationEffect";

import {
  OtpSendEffect,
  UpdatePasswordEffect,
  VerifyOtpEffect,
} from "./effects/RegisterEffect";

function* OtpSendWatcher({ payload }) {
  try {
    let { data } = yield call(OtpSendEffect, payload);

    yield put(
      OtpSendSuccess({
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      OtpSendError({
        error: true,
        message: e["message"],
      })
    );

    yield call(Message, { type: "error", content: "Incorrect Email ID" });
    yield put(hideAuthLoader());
  }
}

function* VerifyOtpWatcher({ payload }) {
  try {
    let { data } = yield call(VerifyOtpEffect, payload);

    yield put(
      VerifyOtpSuccess({
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      VerifyOtpError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, { type: "error", content: "Enter Correct OTP" });

    yield put(hideAuthLoader());
  }
}

function* UpdatePasswordWatcher({ payload }) {
  try {
    console.log("ForgetPassword",payload)
    let { data } = yield call(UpdatePasswordEffect, payload);

    yield put(
      ForgetPasswordSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    if(payload.handleSuccess){
      payload.handleSuccess()
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      ForgetPasswordError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(hideAuthLoader());
  }
}

export default function* RegisterSaga() {
  yield takeEvery(OTP_SEND_IN_PROGRESS, OtpSendWatcher);
  yield takeEvery(VERIFY_OTP_IN_PROGRESS, VerifyOtpWatcher);
  yield takeEvery(FORGET_PASSWORD_IN_PROGRESS, UpdatePasswordWatcher);
}
