import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_USER_PROFILE_PROGRESS,
  UPDATE_USER_PROFILE_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  getUserProfileInError,
  getUserProfileInSuccess,
  updateUserProfileError,
  updateUserProfileSuccess,
} from "../actions/Profile";
import {
  getUserProfileEffect,
  updateUserProfileEffect,
} from "./effects/Profile";
import Message from "../../../components/Message/Message";

function* GetUserProfileSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getUserProfileEffect, payload, token);

    yield put(
      getUserProfileInSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getUserProfileInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* UpdateUserProfileSaga({ payload }) {
  try {
    console.log("payload", payload?.handleSuccess);
    const token = localStorage.getItem("user_id");

    let { data } = yield call(updateUserProfileEffect, payload, token);

    yield put(
      updateUserProfileSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    if (payload?.handleSuccess) {
      payload?.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    console.log("error", e);
    yield put(
      updateUserProfileError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export function* ProfileSaga() {
  yield takeLatest(GET_USER_PROFILE_PROGRESS, GetUserProfileSaga);
  yield takeLatest(UPDATE_USER_PROFILE_PROGRESS, UpdateUserProfileSaga);
}
