import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Tooltip, Typography } from "antd";
import {
  DeleteFilled,
  LoadingOutlined,
  PrinterFilled,
  EditFilled,
  EyeFilled,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import TableComponent from "../../../components/Table/TableComponent";
import {
  defaultItemTransfer,
  deleteItemTransferInProgress,
  getitemTransferListInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import Menubar from "../Menubar";
import moment from "moment";
import { access } from "../../../util/utilz";

const ItemTransferTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const itemTransferData = useSelector(
    (state) => state.pharmacy?.itemTransferList
  );
  const addItemTransferData = useSelector(
    (state) => state.pharmacy?.addItemTransfer
  );
  const deleteItemTransferData = useSelector(
    (state) => state.pharmacy?.deleteItemTransfer
  );
  const itemTransferList = itemTransferData?.data?.data;

  const totalRecords = itemTransferData?.data?.totalRecords;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
    console.log("idd", id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteItemTransferInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Person Name",
      dataIndex: "person_name",
      sortDirections: ["ascend", "descend"],
      key: "personName",
      sorter: (a, b) => {
        if (a && b && a.personName && b.personName) {
          return a.personName.localeCompare(b.personName);
        }
        return 0;
      },
    },
    {
      title: "Contact Number",
      dataIndex: "person_contact",
      sortDirections: ["ascend", "descend"],
      key: "contactNumber",
      sorter: (a, b) => {
        if (a && b && a.contactNumber && b.contactNumber) {
          return a.contactNumber.localeCompare(b.contactNumber);
        }
        return 0;
      },
    },
    {
      title: "Medicines",
      dataIndex: "total_items",
      sortDirections: ["ascend", "descend"],
      key: "total_items",
      render: (text, record) => {
        return text.length === 0 ? 0 : text;
      },
      sorter: (a, b) => {
        if (a && b && a.total_items && b.total_items) {
          return a.total_items.localeCompare(b.total_items);
        }
        return 0;
      },
    },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      sortDirections: ["ascend", "descend"],
      key: "total_quantity",
      render: (text, record) => {
        return text.length === 0 ? 0 : text;
      },
      sorter: (a, b) => {
        if (a && b && a.total_quantity && b.total_quantity) {
          return a.total_quantity.localeCompare(b.total_quantity);
        }
        return 0;
      },
    },
    // {
    //   title: "Transfer Type ",
    //   dataIndex: "transferType",
    //   sortDirections: ["ascend", "descend"],
    //   key: "transferType",
    //   sorter: (a, b) => {
    //     if (a && b && a.transferType && b.transferType) {
    //       return a.transferType.localeCompare(b.transferType);
    //     }
    //     return 0;
    //   },
    // },
    {
      title: "Description",
      dataIndex: "description",
      sortDirections: ["ascend", "descend"],
      key: "description",
      sorter: (a, b) => {
        if (a && b && a.description && b.description) {
          return a.description.localeCompare(b.description);
        }
        return 0;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      sortDirections: ["ascend", "descend"],
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      key: "date",
      sorter: (a, b) => {
        if (a && b && a.date && b.date) {
          return a.date.localeCompare(b.date);
        }
        return 0;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Tooltip placement="top" title={"View"}>
                <Link
                  to={{
                    pathname: `/itemtransfer/view`,
                    state: { itemData: record, type: "view" },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeFilled />}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("itemTransfer", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/itemtransfer/edit`,
                      state: { itemTransfer: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditFilled />}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Print"}>
                {" "}
                <Link
                  to={{
                    pathname: `/itemtransfer/view`,
                    state: { itemData: record, type: "print" },
                  }}
                >
                  <ButtonComponent
                    icon={<PrinterFilled />}
                    type="primary"
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>{" "}
            {access("itemTransfer", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteFilled />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleDelete = (id) => {
    const i =
      itemTransferList && itemTransferList.filter((val) => val.id === id);
    console.log("i", i);
    // dispatch(patientDeleteListInProgress(i[0].id));
  };

  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: "10", pageNo: page };
    dispatch(getitemTransferListInProgress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(itemTransferList);
    } else {
      const newData = itemTransferList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payload = { pagination: true, pageSize: "10", pageNo: 1 };
    dispatch(getitemTransferListInProgress(payload));
    dispatch(defaultItemTransfer());
  }, []);

  useEffect(() => {
    if (itemTransferData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [itemTransferData]);
  useEffect(() => {
    if (deleteItemTransferData?.success) {
      const payload = { pagination: true, pageSize: "10", pageNo: 1 };
      dispatch(getitemTransferListInProgress(payload));
    }
  }, [deleteItemTransferData]);
  useEffect(() => {
    if (addItemTransferData?.success) {
      dispatch(defaultItemTransfer());
    }
  }, [addItemTransferData]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      {access("itemTransfer", "view") && (
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : itemTransferList}
          rowselector={handleSelectChange}
          itemList={itemTransferList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          field={"person_name"}
          documentTitle={"Item Transfer List"}
        />
      )}

      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default ItemTransferTable;
