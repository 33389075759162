import { call, put, takeLatest } from "@redux-saga/core/effects";

import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";
import { getDashboardListEffect } from "./saga/effect/dashboardEffect";
import {
  getDashboradListInError,
  getDashboradListInSuccess,
} from "./action/dashboardAction";
import { GET_DASHBOARD_IN_PROGRESS } from "../../../constants/ActionTypes";

function* getDashboardList({ payload }) {
  try {
    console.log("payload", payload);
    const { data } = yield call(getDashboardListEffect, payload);

    yield put(
      getDashboradListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getDashboradListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}

export default function* adminDashboardSaga() {
  yield takeLatest(GET_DASHBOARD_IN_PROGRESS, getDashboardList);
}
