import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddSales from "./AddSales";
import { useDispatch, useSelector } from "react-redux";
import { getSalesInProgress } from "../../../../appRedux/Pharmacy/PharmacyAction";

const EditSales = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [locationLoading, setLocationLoading] = useState(true);
  const { getSales, updateSalesPayment, addSalesPayment, getTabletsId } =
    useSelector((state) => state?.pharmacy);
  console.log("location.state?.salesData", location.state?.salesData);
  const salesData = location.state?.salesData;
  // location.state?.table === "table" && locationLoading
  // ? location.state?.salesData
  // : getSales?.data?.data || "";
  // useEffect(() => {
  //   if (location.state?.salesData?.id) {
  //     dispatch(getSalesInProgress(location.state?.salesData?.id));
  //   }
  // }, [getTabletsId?.data?.response?.data]);
  // useEffect(() => {
  //   if (addSalesPayment?.success || updateSalesPayment?.success) {
  //     dispatch(getSalesInProgress(location.state?.salesData?.id));
  //     setLocationLoading(false);
  //   }
  // }, [addSalesPayment?.success, updateSalesPayment?.success]);
  let transformedSalesData = {};
  if (salesData?.addSales) {
    transformedSalesData = {
      type: salesData.datas.salesType,
      patientName: salesData?.datas.patient,
      patientMobile: salesData?.datas.patientMobile,
      doctorName: salesData?.datas.doctor,
      doctorId: salesData?.datas.doctorId,
      medicine: salesData?.datas.productName0,
      due: salesData?.updatedProduct?.due,
      date: salesData.datas.salesDate,
      subTotal: salesData.updatedProduct.subtotal,
      gst: salesData.updatedProduct.totalGST,
      total: salesData.updatedProduct.total,
      oldDueAmount: salesData?.updatedProduct?.oldDueAmount,
      discount: salesData?.updatedProduct?.totalDiscount,
      discountPercentage: salesData?.updatedProduct?.discountPercentage,
      productInformation: salesData.updatedProduct.products.map((product) => ({
        productName: product.productName,
        batchNo: product.batchNo,
        quantity: product.quantity,
        returnQuantity: product.returnQuantity,
        purchasePrice: product.purchasePrice,
        unitPrice: product.unitPrice,
        gst: product.gst,
        amount: product.total,
        salesPrice: product.salesPrice,
        expiryDate: product.expiryDate,
        total: product?.total,
      })),
    };
  }
  return <AddSales salesData={salesData} editSales="true" />;
};

export default EditSales;
