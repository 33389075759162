import React, { useEffect, useState } from "react";
import { Button, Form, Input, Col, Row } from "antd";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { message } from "antd/lib/index";

import {
  hideAuthLoader,
  OtpSendInProgress,
  showAuthLoader,
} from "../appRedux/doctor/actions/Auth";
import CircularProgress from "../components/CircularProgress";
import IntlMessages from "../util/IntlMessages";

const FormItem = Form.Item;

const Otpsend = (props) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser, registerdata } =
    useSelector(({ auth }) => auth);

  const sendOtpData = useSelector((state) => state.register);
  const OtpSuccess = sendOtpData?.otpsend?.success;
  console.log("OtpSuccess", OtpSuccess);
  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    setEmail(values.email);
    let formData = new URLSearchParams();

    formData.append("email", values.email);
    formData.append("subject_type", 2);
    dispatch(OtpSendInProgress(formData));
  };

  useEffect(() => {
    if (OtpSuccess === true) history.push(`/otpVerification/${email}`);
  }, [OtpSuccess]);
  useEffect(() => {
    dispatch(hideAuthLoader());
  }, []);
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.otpsend" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.byotpsend" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getotp" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem>
                    <Button
                      type="primary"
                      className="gx-mb-4"
                      htmlType="submit"
                    >
                      SEND OTP
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default Otpsend;
