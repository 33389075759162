import React from "react";
import { Col, Row, Divider } from "antd";

import { Link } from "react-router-dom";
import "../../styles/modules/patient.less";

import ButtonComponent from "../Button/ButtonComponent";
import { access } from "../../util/utilz";

const PageHeader = ({
  header,
  link,
  buttonLabel,
  icon,
  module,
  accessData,
}) => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{header}</p>
        </Col>
        <Col>
          {accessData ? (
            <>
              {access(module, "add") && (
                <Link to={link}>
                  <ButtonComponent
                    type="primary"
                    label={buttonLabel}
                    icon={icon}
                  />
                </Link>
              )}
            </>
          ) : (
            <Link to={link}>
              <ButtonComponent type="primary" label={buttonLabel} icon={icon} />
            </Link>
          )}
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default PageHeader;
