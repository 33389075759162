import {
  FAMILY_MEMBER_LIST_ERROR,
  FAMILY_MEMBER_LIST_IN_PROGRESS,
  FAMILY_MEMBER_LIST_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  familyMemebrlist: { ...defaultStatus },
};
const familyMemebrReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAMILY_MEMBER_LIST_IN_PROGRESS:
      return {
        ...state,
        familyMemebrlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case FAMILY_MEMBER_LIST_SUCCESS:
      console.log("FAMILY_MEMBER_LIST_SUCCESS", payload);

      return {
        ...state,
        familyMemebrlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case FAMILY_MEMBER_LIST_ERROR:
      return {
        ...state,
        familyMemebrlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default familyMemebrReducer;
