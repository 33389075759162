import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  DELETE_SPECIALIZATION_IN_PROGRESS,
  ADD_SPECIALIZATION_IN_PROGRESS,
  GETDATA_SPECIALIZATION_IN_PROGRESS,
  GET_SPECIALIZATION_IN_PROGRESS,
  UPDATE_SPECIALIZATION_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { data } from "../../../screens/Doctor/data";
import { getErrorMessageFromAPI } from "../../../util/utilz";

import {
  addSpecializationError,
  addSpecializationSuccess,
  deleteSpecializationError,
  deleteSpecializationSuccess,
  getDataSpecializationError,
  getDataSpecializationSuccess,
  getSpecializationError,
  getSpecializationSuccess,
  updateSpecializationError,
  updateSpecializationSuccess,
} from "../actions/Specialization";

import {
  AddSpecializationEffect,
  DeleteSpecializationEffect,
  GetDataSpecializationEffect,
  SpecializationEffect,
  UpdateSpecializationEffect,
} from "./effects/SpecailizationEffect";

function* SpecializationWatcher({ payload }) {
  try {
    let { data } = yield call(SpecializationEffect, payload);

    yield put(
      getSpecializationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getSpecializationError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* AddSpecializationWatcher({ payload }) {
  try {
    let { data } = yield call(AddSpecializationEffect, payload);

    yield put(
      addSpecializationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addSpecializationError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    // yield call(Message, { type: "error", content: data.message });
  }
}

function* DeleteSpecializationWatcher({ payload }) {
  try {
    let { data } = yield call(DeleteSpecializationEffect, payload);

    yield put(
      deleteSpecializationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteSpecializationError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    //yield call(Message, { type: "error", content: data.message });
  }
}
function* GetDataSpecializationWatcher({ payload }) {
  try {
    let { data } = yield call(GetDataSpecializationEffect, payload);
    console.log("data", data);
    yield put(
      getDataSpecializationSuccess({
        data: data.data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getDataSpecializationError({
        authError: true,
        message: e["message"],
      })
    );
  }
}

function* UpdateSpecializationWatcher({ payload }) {
  try {
    let { data } = yield call(UpdateSpecializationEffect, payload);

    yield put(
      updateSpecializationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      updateSpecializationError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, { type: "success", content: data.message });
  }
}
export default function* SpecializationSaga() {
  yield takeLatest(GET_SPECIALIZATION_IN_PROGRESS, SpecializationWatcher);
  yield takeLatest(ADD_SPECIALIZATION_IN_PROGRESS, AddSpecializationWatcher);
  yield takeLatest(
    DELETE_SPECIALIZATION_IN_PROGRESS,
    DeleteSpecializationWatcher
  );
  yield takeLatest(
    GETDATA_SPECIALIZATION_IN_PROGRESS,
    GetDataSpecializationWatcher
  );
  yield takeLatest(
    UPDATE_SPECIALIZATION_IN_PROGRESS,
    UpdateSpecializationWatcher
  );
}
