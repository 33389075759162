// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../common/images/doctorimage.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-form {
  height: 80%;
  overflow: scroll;
  overflow-x: hidden;
}
.form-forgot {
  padding-bottom: 5px !important;
}
.form-button {
  width: 48% !important;
  margin-right: 2rem !important;
}
.form-buttons {
  width: 100% !important;
  margin-top: 15px !important;
}
.form-link {
  width: 48% !important;
  margin-left: 2rem !important;
}
.login-page {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: left;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100% 105%;
}
.login-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem !important;
}
.sign-up-logo {
  margin: 14px auto 0;
}
.dashboard-logo {
  margin-left: 10px !important;
  margin-top: 5px !important;
  height: 60px !important;
}
.dashbord-logo-content {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/modules/auth.less"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B;AACA;EACE,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,qBAAqB;EACrB,4BAA4B;AAC9B;AACA;EACE,yDAA4D;EAC5D,yBAAyB;EACzB,4BAA4B;EAC5B,8BAA8B;EAC9B,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;AAChC;AACA;EACE,mBAAmB;AACrB;AACA;EACE,4BAA4B;EAC5B,0BAA0B;EAC1B,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".signup-form {\n  height: 80%;\n  overflow: scroll;\n  overflow-x: hidden;\n}\n.form-forgot {\n  padding-bottom: 5px !important;\n}\n.form-button {\n  width: 48% !important;\n  margin-right: 2rem !important;\n}\n.form-buttons {\n  width: 100% !important;\n  margin-top: 15px !important;\n}\n.form-link {\n  width: 48% !important;\n  margin-left: 2rem !important;\n}\n.login-page {\n  background-image: url(\"../../common/images/doctorimage.jpg\");\n  background-position: left;\n  background-repeat: no-repeat;\n  background-origin: content-box;\n  background-size: 100% 105%;\n}\n.login-logo {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 2rem !important;\n}\n.sign-up-logo {\n  margin: 14px auto 0;\n}\n.dashboard-logo {\n  margin-left: 10px !important;\n  margin-top: 5px !important;\n  height: 60px !important;\n}\n.dashbord-logo-content {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
