// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  color: white !important;
}
.card-icon {
  color: whitesmoke;
}
.card-blue {
  background-color: #13c2c2;
}
.card-green {
  background-color: #20c997;
}
.card-orange {
  background-color: #fa8c16;
}
.card-violet {
  background-color: #b767cb;
}
.headingColorBlue {
  color: #13c2c2;
}
.headingColorGreen {
  color: #20c997;
}
.headingColorOrange {
  color: #fa8c16;
}
@media only screen and (max-width: 2560px) and (min-width: 576px) {
  .image-width {
    width: 110px !important;
    position: relative;
    margin-right: 25px;
  }
}
@media only screen and (max-width: 575px) and (min-width: 320px) {
  .image-width {
    width: 50px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/card/card.less"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE;IACE,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;EACpB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".card-header {\n  color: white !important;\n}\n.card-icon {\n  color: whitesmoke;\n}\n.card-blue {\n  background-color: #13c2c2;\n}\n.card-green {\n  background-color: #20c997;\n}\n.card-orange {\n  background-color: #fa8c16;\n}\n.card-violet {\n  background-color: #b767cb;\n}\n.headingColorBlue {\n  color: #13c2c2;\n}\n.headingColorGreen {\n  color: #20c997;\n}\n.headingColorOrange {\n  color: #fa8c16;\n}\n@media only screen and (max-width: 2560px) and (min-width: 576px) {\n  .image-width {\n    width: 110px !important;\n    position: relative;\n    margin-right: 25px;\n  }\n}\n@media only screen and (max-width: 575px) and (min-width: 320px) {\n  .image-width {\n    width: 50px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
