import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { addScheduleInProgress } from "../../../appRedux/doctor/actions/Schedule";
import ScheduleForm from "./ScheduleForm";

const AddSchedule = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const scheduleData = useSelector((state) => state.schedule);

  const doctorId = localStorage.getItem("doctor_id");
  console.log("scheduleData", scheduleData);
  const onFinish = (datas) => {
    console.log("DATA", datas);
    var formatted_start_time = moment(datas.start_time.toString()).format(
      "HH:mm:ss"
    );
    var formatted_end_time = moment(datas.end_time.toString()).format(
      "HH:mm:ss"
    );

    let formData = new URLSearchParams();
    formData.append("doctor_id", doctorId);
    formData.append("week_day_id", datas.week_day_id);
    formData.append("start_time", formatted_start_time);
    formData.append("end_time", formatted_end_time);
    formData.append("interval", datas.interval);
    dispatch(addScheduleInProgress(formData));
  };

  useEffect(() => {
    if (scheduleData?.addSchedule?.success === true) {
      history.push("/schedule");
    }
  }, [scheduleData?.addSchedule]);

  // useEffect(() => {
  //   if (scheduleData?.addSchedule?.inProgress === true) {
  //     alert(scheduleData?.addSchedule?.inProgress === true);
  //     setLoading(true);
  //   } else if (scheduleData?.addSchedule?.success === true) {
  //     alert(scheduleData?.addSchedule?.success === true);
  //     setLoading(false);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [scheduleData?.addSchedule?.inProgress === true]);
  return (
    <div>
      <Spin
        size="large"
        spinning={loading}
        tip="Please wait a moment"
        style={{ margin: "20px" }}
      />
      <ScheduleForm
        onFinish={onFinish}
        title={"Addnew.schedule.label.schedule"}
        buttonName={"Submit"}
      />
    </div>
  );
};

export default AddSchedule;
