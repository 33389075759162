import { Card, Col, Form, message, Row, Spin } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  getQualificationUpdateListInProgress,
  updateQualificationListInProgress,
} from "../../../appRedux/doctor/actions/Qualification";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
const QualificationEditForm = () => {
  const [double, setDouble] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const { getqualificationupdatelist } = useSelector(
    (state) => state.qualification
  );
  const getQualificationUpdatelists = getqualificationupdatelist?.data;
  console.log("Qualification update get list", getQualificationUpdatelists);
  const { updatequalificationlist } = useSelector(
    (state) => state.qualification
  );
  const updatelists = updatequalificationlist.data;

  // updatequalificationlist
  const qualificationData = useSelector((state) => state.qualification);
  const qualificationStatus = qualificationData?.updatequalificationlist;

  const [number, setNumber] = useState();
  const changeItem = (value) => {
    setNumber(value);
  };
  const dispatch = useDispatch();

  const id = useParams();
  const onFinish = (datas) => {
    console.log("DATA", datas);
    let formData = new URLSearchParams();
    formData.append("name", datas.name);
    formData.append("description", datas.description);
    dispatch(updateQualificationListInProgress({ formData, id: id.id }));
  };
  useEffect(() => {
    form.setFieldsValue(getQualificationUpdatelists);
  }, [getQualificationUpdatelists]);

  // useEffect(() => {
  //     console.log("set field getupdatelist",getQualificationUpdatelists);
  //     form.setFieldsValue(getQualificationUpdatelists);
  //   });

  useEffect(() => {
    dispatch(getQualificationUpdateListInProgress(id.id));
  }, [updatelists]);

  const Back = () => {
    history.push("/qualification");
  };

  useEffect(() => {
    if (getqualificationupdatelist?.progressing === true) {
      setLoading(true);
    } else if (getqualificationupdatelist?.success === true) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [
    getqualificationupdatelist?.progressing === true,
    getqualificationupdatelist?.success === true,
  ]);

  useEffect(() => {
    if (qualificationStatus?.progressing === true) {
      setDouble(true);
    } else if (qualificationStatus?.success === true) {
      setDouble(false);
    } else {
      setDouble(false);
    }
  }, [
    qualificationStatus?.progressing === true,
    qualificationStatus?.success === true,
  ]);
  useEffect(() => {
    if (qualificationStatus?.success) {
      history.push("/qualification");
    }
  }, [qualificationStatus?.success]);
  return (
    <Spin size="large" spinning={loading} tip="Loading...">
      <Auxiliary>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Qualification"
                  name="name"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"Addnew.qualification.label.name"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.qualification.validator.name"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Description"
                  name="description"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages
                      id={"Addnew.qualification.label.description"}
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.qualification.validator.description"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="space">
              <Col className="btn-pos">
                <ButtonComponent type="danger" onClick={Back} label="Back" />
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  label="Update"
                  loading={double}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Auxiliary>
    </Spin>
  );
};
export default QualificationEditForm;
