import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Tooltip, Typography } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  TabletsByOrgInProgress,
  deleteTabletInProgress,
  getTabletInProgress,
  getTabletsListInProgress,
} from "../../../appRedux/doctor/actions/Tablets";
import { getDoctorInProgress } from "../../../appRedux/doctor/actions/doctor";
import { useHistory } from "react-router-dom";

const TabletTable = (props) => {
  const updateSucess = props?.location?.state;
  console.log("const EditData = props.location.state;", updateSucess);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setId] = useState("");
  const TabletData = useSelector((state) => state?.tablets);
  const updateData = useSelector((state) => state?.tablets?.updateTablet);
  const Tablet_List_Data =
    TabletData?.getTablet?.data?.data || TabletData?.TabletOrg?.data?.data;
  const delete_tablet = TabletData?.deleteTablet;
  const totalRecords =
    TabletData?.getTablet?.data?.totalRecords ||
    TabletData?.TabletOrg?.data?.totalRecords;
  const [filteredUsers, setFilteredUsers] = useState("");
  const roleID = parseInt(localStorage.getItem("role_id"));
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteTabletInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handlePage = (page) => {
    console.log(" handlePage", page);
    if (roleID === 2) {
      dispatch(TabletsByOrgInProgress(page));
    } else {
      dispatch(getTabletInProgress(page));
    }
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(Tablet_List_Data);
    } else {
      const newData = Tablet_List_Data.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const columns = [
    {
      title: "category",
      dataIndex: "category",
    },
    {
      title: "Medicine Name",
      dataIndex: "medicine_name",
    },
    {
      title: "company",
      dataIndex: "company",
    },
    {
      title: "product_type",
      dataIndex: "product_type",
    },
    {
      title: "quantity",
      dataIndex: "quantity",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Tooltip placement="top" title={"Edit"}>
                {" "}
                <Link
                  to={{
                    pathname: "/tablet/edit",
                    state: record,
                  }}
                >
                  <ButtonComponent type="primary" icon={<EditOutlined />} />
                </Link>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Delete"}>
                <Typography>
                  {" "}
                  <ButtonComponent
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={() => showModal(record.id)}
                  ></ButtonComponent>
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  console.log("updateData", updateData);
  useEffect(() => {
    const page = 1;
    if (roleID === 2) {
      dispatch(TabletsByOrgInProgress(page));
    } else {
      dispatch(getTabletInProgress(page));
    }
  }, [delete_tablet?.success]);

  // useEffect(() => {
  //   const page = 1;
  //   if (delete_tablet?.success) {
  //     if (roleID === 2) {
  //       dispatch(TabletsByOrgInProgress(page));
  //     } else {
  //       dispatch(getTabletInProgress(page));
  //     }
  //   }
  // }, [delete_tablet?.success]);

  return (
    <Spin
      size="large"
      spinning={delete_tablet?.inProgress}
      tip="Please wait a moment"
      style={{ margin: "20px" }}
    >
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : Tablet_List_Data}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        itemList={Tablet_List_Data}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"medicine_name"}
        rowselector={handleSelectChange}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data ?</p>
      </Modal>
    </Spin>
  );
};

export default TabletTable;
