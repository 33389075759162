import React, { useEffect, useState } from "react";

import { Row, Col, Spin } from "antd";
import {
  WomanOutlined,
  ManOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";
import { doctorListInProgress } from "../../../appRedux/doctor/actions/doctor";

const DoctorListTable = () => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "FirstName",
      dataIndex: "firstName",
    },
    {
      title: "LastName",
      dataIndex: "lastName",
    },
    {
      title: "DOB",
      dataIndex: "dob",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "OrganizationName",
      dataIndex: "organization_name",
    },
    // {
    //   title: "Address1",
    //   dataIndex: "address1",
    // },
    // {
    //   title: "Address2",
    //   dataIndex: "address2",
    // },

    // {
    //   title: "City",
    //   dataIndex: "city",
    // },
    // {
    //   title: "State",
    //   dataIndex: "state",
    // },
    // {
    //   title: "role_id",
    //   dataIndex: "role_id",
    // },
    // {
    //   title: "user_id",
    //   dataIndex: "user_id",
    // },
    // {
    //   title: "Country Code",
    //   dataIndex: "country_code",
    // },
    // {
    //   title: "organization_id",
    //   dataIndex: "organization_id",
    // },
  ];
  const dispatch = useDispatch();
  const doctorData = useSelector((state) => state?.doctorList?.doctorList);
  const [filteredUsers, setFilteredUsers] = useState("");
  const [Loading, setLoading] = useState(false);
  const DoctorList = doctorData?.data?.data;
  const totalRecords = doctorData?.data?.totalRecords;

  const handlePage = (page) => {
    dispatch(doctorListInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(DoctorList);
    } else {
      const newData = DoctorList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    const page = 1;
    dispatch(doctorListInProgress(page));
  }, []);
  console.log("doctorData", doctorData);
  useEffect(() => {
    if (doctorData?.inProgress) {
      setLoading(true);
    } else if (doctorData?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [doctorData?.inProgress]);
  return (
    <div>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : DoctorList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          itemList={DoctorList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"firstName"}
          type="doctor"
          rowselector={handleSelectChange}
        />
      </Spin>
    </div>
  );
};

export default DoctorListTable;
