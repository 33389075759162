import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/Routes";

const DesignationForm = ({
  title,
  onFinish,
  buttonName,
  form,
  editDataName,
}) => {
  return (
    <Card>
      <h1>{<IntlMessages id={title} />}</h1>
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        colon={"true"}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {" "}
            <FormInput
              labelCol={"4"}
              wrapperCol={"24"}
              type="text"
              placeholder="Name"
              name={"Name"}
              label={<IntlMessages id={"Addnew.org.label.name"} />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"Addnew.org.validator.name"} />,
                },
              ]}
            />
          </Col>
        </Row>

        <Row className="button-row">
          <Col className="register-button">
            {" "}
            <Link to={APP_ROUTES.DESIGNATION}>
              <ButtonComponent type="danger" label="Cancel" style={{marginRight:"10px"}} />
            </Link>
            <ButtonComponent
              type="primary"
              label={buttonName}
              htmlType="submit"
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default DesignationForm;
