import axios from "axios";

const dashBoardInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getDashboardListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  const org_id=localStorage.getItem("org_id");

  return dashBoardInstance.request({
    url: `/user/admindashboard`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
       organization_id :org_id
    },
  });
};
