import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Tag, Typography, Tooltip } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { patientListInProgress } from "../../../../appRedux/admin/patient/action/action";
import TableComponent from "../../../../components/Table/TableComponent";
import {
  deletePurchaseRequestInProgress,
  getPurchaseRequestListInProgress,
  purchaseRequestDefaultData,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import moment from "moment";
import { access } from "../../../../util/utilz";

const PurchaseRequestTable = () => {
  const orgId = localStorage.getItem("org_id");
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const purchaseRequestListData = useSelector(
    (state) => state.pharmacy?.purchaseRequestList
  );
  const deletePurchaseRequestData = useSelector(
    (state) => state.pharmacy?.deletePurchaseRequest
  );
  const purchaseRequestList = purchaseRequestListData.data?.data;
  console.log("purchaseRequestList", purchaseRequestList);
  const totalRecords = purchaseRequestListData.data?.totalRecords;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const columns = [
    {
      title: "Purchase  Code",
      dataIndex: "purchase_request_code",
    },
    {
      title: "Order Date",
      dataIndex: "date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },

    {
      title: "Supplier Company Name",
      dataIndex: "company_name",
      render: (_, record) => record.Supplier.company_name,
      key: "company_name",
    },

    {
      title: "Contact Person",
      dataIndex: "contact_person",
      render: (_, record) => record.Supplier.contact_person,
      key: "contact_person",
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (total) => (total ? total : 0),

      key: "total",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag className="sharp-edge-button" color="#87d068">
          {status === 1 ? "Pending" : status === 2 ? "Submitted" : "Canceled"}
        </Tag>
      ),
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"View"}>
                {" "}
                <Link
                  to={{
                    pathname: `/purchase/request/view`,
                    state: { purchaseRequestData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("purchaseRequest", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  <Link
                    to={{
                      pathname: `/purchase/request/edit`,
                      state: { supplierData: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditOutlined />}
                      // onClick={()=>editPatient(record.id)}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}{" "}
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Print"}>
                {" "}
                <Link
                  to={{
                    pathname: "/print",
                    state: { purchaseRequestData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<PrinterOutlined />}
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("purchaseRequest", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deletePurchaseRequestInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const payload = {
      organization_id: orgId,
      pageNo: page,
      pageSize: 10,
      pagination_required: true,
    };
    dispatch(getPurchaseRequestListInProgress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(purchaseRequestList);
    } else {
      const newData = purchaseRequestList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payload = {
      organization_id: orgId,
      pageNo: 1,
      pageSize: 10,
      pagination_required: true,
    };
    dispatch(getPurchaseRequestListInProgress(payload));
    dispatch(purchaseRequestDefaultData());
  }, []);
  useEffect(() => {
    if (purchaseRequestListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [purchaseRequestListData]);
  useEffect(() => {
    if (deletePurchaseRequestData?.success) {
      const payload = {
        organization_id: orgId,
        pageNo: 1,
        pageSize: 10,
        pagination_required: true,
      };
      dispatch(getPurchaseRequestListInProgress(payload));
    }
  }, [deletePurchaseRequestData]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      {access("purchaseRequest", "view") && (
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : purchaseRequestList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          rowselector={handleSelectChange}
          itemList={purchaseRequestList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"purchase_request_code"}
          documentTitle={"Purchase Request List"}
        />
      )}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <Typography>Are you sure, you want to delete the Data</Typography>
      </Modal>
    </Spin>
  );
};

export default PurchaseRequestTable;
