import React, { useState } from "react";

import "../../../styles/modules/pharmacy.less";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddSupplier from "./AddSupplier";
import BillingInvoice from "./BillingInvoice";

const EditSupplier = () => {
  const location = useLocation();
  const supplierData = location.state?.supplierData;
  console.log("location.state", supplierData);
  return (
    <>
      <AddSupplier supplierData={supplierData} editSupplier="true" />
    </>
  );
};

export default EditSupplier;
