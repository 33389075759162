
import { Form } from "antd";
import React from "react";
import FormInput from "../../../../components/Input/FormInput";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import IntlMessages from "../../../../util/IntlMessages";
import { appoinment_status } from "../../data";
const FinalInfo = ({form}) => {
 
  return (
    <div className="final-info-container">
      
      {/* {"Addnew.appoinment.label.reason"} */}
      {/* <TimeSlot form={form} />

<TimeSlotDates form={form}/>
         <Row>
         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <FormInput
        labelCol={"24"}
        wrapperCol={"24"} 
        type="date" placeholder="date" name="date" 
          label={<IntlMessages id={"Addnew.appointment.label.date"} />}
          rules={[{required: true, message:(<IntlMessages id={"Addnew.appointment.validator.date"} />),},]} />
      </Col>
      
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <FormInput
        labelCol={"24"}
        wrapperCol={"24"}
        type="text" placeholder="patient" name="patient_id" 
          label={<IntlMessages id={ "Addnew.appoinment.label.patient"} />}
          rules={[{required: true, message:(<IntlMessages id={"Addnew.appoinment.validator.patient"} />),},]} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <DoctorSelectAppointment 
          margin="mb-30"
          label={"Doctor"}
          name={"doctor_id"}
           placeholder={"Enter your doctor"}
          editMode={true}
            rules={[
              {
               required: true,
              message: "Please enter your Doctor",
              },
               ]}
             />
        </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
           <FormInput
           labelCol={"24"}
           wrapperCol={"24"}
            type="text" placeholder="reason" name="reason" 
          label={<IntlMessages id={"Addnew.appoinment.label.reason"} />}
          rules={[{required: true, message:(<IntlMessages id={"Addnew.appoinment.validator.reason"} />),},]} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormInput
          labelCol={"24"}
          wrapperCol={"24"} 
          type="text" placeholder="symptoms" name="symptoms" 
          label={<IntlMessages id={ "Addnew.appoinment.label.symptoms"} />}
          rules={[{required: true, message:(<IntlMessages id={"Addnew.appointment.validator.symptoms"} />),},]} />
        </Col>
        </Row>
                <Row className='space'>
                    <Col className='btn-pos'>
                        <ButtonComponent type="danger" htmlType="reset" label="Cancel" />
                        <ButtonComponent type="primary" htmlType="Submit"  label="Save" />
                    </Col>
                </Row> */}
    </div>
  );
};

export default FinalInfo;
