import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddPackage from "./AddPackage";

const EditPackage = () => {
  const location = useLocation();
  const PackageData = location.state?.packageData;
  console.log("PackageData", location.state?.packageData);

  return <AddPackage editPackageData={PackageData} editPackage={"true"} />;
};

export default EditPackage;
