import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_HOLIDAYS_LIST_PROGRESS,
  DELETE_HOLIDAYS_LIST_PROGRESS,
  GET_HOLIDAYS_LIST_PROGRESS,
  GET_HOLIDAY_UPDATE_LIST_PROGRESS,
  UPDATE_HOLIDAYS_LIST_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI, getStatusFromAPI } from "../../../util/utilz";
import {
  addHolidaysListInError,
  addHolidaysListInSuccess,
  deleteHolidaysListInError,
  deleteHolidaysListInSuccess,
  getHolidaysListInError,
  getHolidaysListInSuccess,
  getHolidayUpdateListError,
  getHolidayUpdateListSuccess,
  updateHolidaysListInError,
  updateHolidaysListInSuccess,
} from "../actions/Holiday";
import {
  addHolidayListEffect,
  deleteHolidayListEffect,
  getHolidayListEffect,
  getHolidayUpdateListEffect,
  updateHolidayListEffect,
} from "./effects/Holiday";

function* getHolidayList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const { data, status } = yield call(getHolidayListEffect, payload, token);
    console.log("get holiday list saga response", status);
    if (status == "500") {
      message.loading("Please try again later!");
    }
    yield put(
      getHolidaysListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getHolidaysListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    // message.error(getErrorMessageFromAPI(e));
  }
}

function* deleteHolidayList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(deleteHolidayListEffect, payload, token);

    yield put(
      deleteHolidaysListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteHolidaysListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* addHolidayList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addHolidayListEffect, payload, token);
    yield put(
      addHolidaysListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addHolidaysListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* UpdateHolidaySaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(updateHolidayListEffect, payload, token);
    yield put(
      updateHolidaysListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      updateHolidaysListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* GetHolidayUpdateList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getHolidayUpdateListEffect, payload, token);
    console.log("get holiday update list saga", data);

    yield put(
      getHolidayUpdateListSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getHolidayUpdateListError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export function* HolidaySaga() {
  yield takeLatest(GET_HOLIDAYS_LIST_PROGRESS, getHolidayList);
  yield takeLatest(DELETE_HOLIDAYS_LIST_PROGRESS, deleteHolidayList);
  yield takeLatest(ADD_HOLIDAYS_LIST_PROGRESS, addHolidayList);
  yield takeLatest(UPDATE_HOLIDAYS_LIST_PROGRESS, UpdateHolidaySaga);
  yield takeLatest(GET_HOLIDAY_UPDATE_LIST_PROGRESS, GetHolidayUpdateList);
}
