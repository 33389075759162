import React, { useEffect, useState } from "react";
import { Row, Col, Form, Spin, Modal } from "antd";
import "../../../styles/modules/pharmacy.less";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  addTabletCategoryInProgress,
  defaultTabletCategory,
  editTabletCategoryInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
const AddMedicineCategory = ({ editMedicine, medicineListData }) => {
  const orgId = localStorage.getItem("org_id");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [isModalOpen, setisModalOpen] = useState(true);
  const AddMedicineCategoryData = useSelector(
    (state) => state?.pharmacy?.addTabletCategory
  );
  const editMedicineCategoryData = useSelector(
    (state) => state?.pharmacy?.editTabletCategory
  );
  console.log("AddMedicineCategoryData", AddMedicineCategoryData?.success);
  const onFinish = (datas) => {
    if (editMedicine) {
      const formData = {
        category: datas.category,
        description: datas.description,
        organization_id: orgId,
        id: medicineListData?.id,
      };
      dispatch(editTabletCategoryInProgress(formData));
    } else {
      const formData = {
        category: datas.category,
        description: datas.description,
        organization_id: orgId,
      };
      dispatch(addTabletCategoryInProgress(formData));
    }
    if (
      AddMedicineCategoryData?.inProgress ||
      editMedicineCategoryData?.inProgress
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };
  console.log("editMedicineCategoryData?.success", editMedicineCategoryData);
  const onCancel = () => {
    setisModalOpen(false);
    history.push("/medicinecategory");
  };
  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (editMedicine) {
      form.setFieldValue("description", medicineListData?.description);
      form.setFieldValue("category", medicineListData?.category);
    }
  }, [medicineListData]);
  useEffect(() => {
    if (AddMedicineCategoryData?.success || editMedicineCategoryData?.success) {
      history.push("/medicinecategory");
      setisModalOpen(false);
    }
  }, [AddMedicineCategoryData, editMedicineCategoryData]);
  console.log("sdf", AddMedicineCategoryData, editMedicineCategoryData);
  return (
    <Modal
      title={
        editMedicine ? "Edit Medicine Category" : "Create Medicine Category"
      }
      open={isModalOpen}
      width={1000}
      okText={false}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onCancel}
    >
      <Spin
        indicator={antIcon}
        spinning={
          AddMedicineCategoryData?.inProgress ||
          editMedicineCategoryData?.inProgress
        }
      >
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row className="register-row">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"category"}
                label={<IntlMessages id={"Addnew.pharmacy.label.category"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.pharmacy.validator.category"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"description"}
                label={
                  <IntlMessages id={"Addnew.pharmacy.label.description"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.pharmacy.validator.description"}
                      />
                    ),
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length < 5) {
                        return Promise.reject(
                          new Error(
                            "Description should have at least 5 characters."
                          )
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              />
            </Col>
            <Col xl={23} align="right" className="button-alignment-pharmacy">
              <ButtonComponent
                icon={<CreditCardOutlined />}
                label="Submit"
                htmlType="submit"
                type="primary"
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddMedicineCategory;
