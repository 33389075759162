import React from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "../constants/Routes";
import DashboardScreen from "../screens/Dashboard/DashboardScreen";
import DoctorEditScreen from "../screens/Doctor/component/DoctorEditScreen";
import DoctorScreen from "../screens/Doctor/DoctorScreen";
import ReferenceDoctorScreen from "../screens/ReferenceDoctor/ReferenceDoctorScreen";
import RefDoctorEdit from "../screens/ReferenceDoctor/component/RefDoctorEdit";
import DoctorAddNewScreen from "../screens/Doctor/DoctorAddNewScreen";
import ReferenceDoctorAddNew from "../screens/ReferenceDoctor/ReferenceDoctorAddNew";
import AddNewPatient from "../screens/Patient/AddNewPatient";
import BillingPackageScreen from "../screens/BillingPackages/BillingPackageScreen";
import BillingPackageAddNew from "../screens/BillingPackages/BillingPackageAddNew";
import BillingPackageEdit from "../screens/BillingPackages/component/BillingPackageEdit";
import AllAppoinments from "../screens/Appoinment/AllApoinments";
import AddNewAppoinment from "../screens/Appoinment/AddNewAppoinment";
// import TodayAppoinments from "../screens/Appoinment/TodaysAppoinments";
import UpcomingAppoinments from "../screens/Appoinment/UpcomingAppoinments.jsx";
import RequestAppoinments from "../screens/Appoinment/RequestAppoinments";
import AppoinmentCalendar from "../screens/Appoinment/AppoinmentCalender";
import JoinCall from "../screens/Appoinment/JoinCall";
import Telemedicine from "../screens/Telemedicine/Telemedicine";
import Symptoms from "../screens/Telemedicine/Symptoms";
import OrganisationScreen from "../screens/Organisation/OrganisationScreen";
import AddnewOrganisation from "../screens/Organisation/components/AddnewOrganisation";
import EditOrganisation from "../screens/Organisation/components/EditOrganisation";
import DesignationScreen from "../screens/Designation/DesignationScreen";
import AddDesignation from "../screens/Designation/components/AddDesignation";
import SpecializationScreen from "../screens/Specialization/SpecializationScreen";
import AddNewSpecialization from "../screens/Specialization/components/AddNewSpecialization";
import EditSpecialization from "../screens/Specialization/components/EditSpecialization";
import EditDesignation from "../screens/Designation/components/EditDesignation";
import EditPatient from "../screens/Patient/components/EditPatient";
import PatientAppoinments from "../screens/Appoinment/PatientAppointment";
import TodayAppointment from "../screens/Appoinment/TodayAppointment";
import AppointmentBookForm from "../screens/Appoinment/components/AppointmentBookForm";
import EditPatientAppointment from "../screens/Appoinment/EditPatientAppointment";
import QualificationScreen from "../screens/Qualification/QualificationScreen";
import QualificationAddForm from "../screens/Qualification/Components/QualificationAddForm";
import QualificationEditForm from "../screens/Qualification/Components/QualificationEditForm";
import ProfileScreen from "../screens/Profile/ProfileScreen";
import ScheduleScreen from "../screens/Schedules/ScheduleScreen";
import AddSchedule from "../screens/Schedules/components/AddSchedule";
import { TodayAppoinment } from "../screens/Appoinment/data";
import AppoinmentType from "../screens/Appoinment/components/AllAppoinments/AppoinmentType";
import HolidaysScreen from "../screens/Holidays/HolidaysScreen";
import HolidayAddForm from "../screens/Holidays/Components/HolidaysAddForm";
import HolidayEditForm from "../screens/Holidays/Components/HolidaysEditForm";
import UsersScreen from "../screens/All Users/UsersScreen";
import PatientScreen1 from "../screens/Patient/PatientScreen";
import DoctorAppoinment from "../screens/Appoinment/DoctorAppointment";
import PrescriptionScreen from "../screens/Prescription/PrescriptionScreen";
import EditSchedule from "../screens/Schedules/components/EditSchedule";
import ConfirmPasswordScreen from "../screens/ConfirmPassword/ConfirmPasswordScreen";
import PrescriptionAddForm from "../screens/Prescription/Components/PrescriptionAddForm";
import PatientTable from "../screens/Patient/components/PatientTable/PatientTable";
import PatientScreen from "../screens/Patient/PatientScreen";
import DoctorListScreen from "../screens/DoctorModule/DoctorList/DoctorListScreen";
import LogoutScreen from "../screens/Logout/LogoutScreen";
import ViewSchedule from "../screens/Schedules/components/ViewSchedule";
import PrescriptionData from "../screens/Prescription/Components/PrescriptionData";
import ConsultationScreen from "../screens/Consultation/ConsiltationScreen";
import Exportcsv from "../screens/Patient/CSVdownload";
import TabletScreen from "../screens/Tablets/TabletScreen";
import AddTablets from "../screens/Tablets/components/AddTablets";

import EditTablet from "../screens/Tablets/components/EditTablet";
import UserUpdateForm from "../screens/All Users/Components/UserUpdateForm";
import PatientEditScreen from "../screens/Patient/PatientEditScreen";
import DoctorAppoinments from "../screens/DoctorAppoinments/DoctorAppoinments";
import SuppliersScreen from "../screens/Pharmacy/suppliers/SuppliersScreen.jsx";
import SalesScreen from "../screens/Pharmacy/sales/sales/SalesScreen.jsx";
import PurchaseScreen from "../screens/Pharmacy/purchase/purchase/PurchaseScreen.jsx";
import PurchaseRequestScreen from "../screens/Pharmacy/purchase/PurchaseRequest/PurchaseRequestScreen.jsx";

import AddSupplier from "../screens/Pharmacy/suppliers/AddSupplier.jsx";
import EditSupplier from "../screens/Pharmacy/suppliers/EditSupplier.jsx";
import AddPurchaseRequest from "../screens/Pharmacy/purchase/PurchaseRequest/AddPurchaseRequest.jsx";
import EditPurchaseRequest from "../screens/Pharmacy/purchase/PurchaseRequest/EditPurchaseRequest.jsx";
import AddPurchase from "../screens/Pharmacy/purchase/purchase/AddPurchase.jsx";
import EditPurchase from "../screens/Pharmacy/purchase/purchase/EditPurchase.jsx";
import PurchaseReturnScreen from "../screens/Pharmacy/purchase/purchaseReturn/PurchaseReturnScreen.jsx";
import AddPurchaseReturn from "../screens/Pharmacy/purchase/purchaseReturn/AddPurchaseReturn.jsx";
import EditPurchaseReturn from "../screens/Pharmacy/purchase/purchaseReturn/EditPurchaseReturn.jsx";
import ViewPurchase from "../screens/Pharmacy/purchase/purchase/ViewPurchase.jsx";
import ViewPurchaseRequest from "../screens/Pharmacy/purchase/PurchaseRequest/ViewPurchaseRequest.jsx";
import ViewPurchaseReturn from "../screens/Pharmacy/purchase/purchaseReturn/ViewPurchaseReturn.jsx";
import ViewSupplier from "../screens/Pharmacy/suppliers/ViewSupplier.jsx";
import AddSales from "../screens/Pharmacy/sales/sales/AddSales.jsx";
import EditSales from "../screens/Pharmacy/sales/sales/EditSales.jsx";
import SalesReturnScreen from "../screens/Pharmacy/sales/salesReturn/SalesReturnScreen.jsx";
import AddSalesReturn from "../screens/Pharmacy/sales/salesReturn/AddSalesReturn.jsx";
import EditSalesReturn from "../screens/Pharmacy/sales/salesReturn/EditSalesReturn.jsx";
import ViewSales from "../screens/Pharmacy/sales/sales/ViewSales.jsx";
import ViewSalesReturn from "../screens/Pharmacy/sales/salesReturn/ViewSalesReturn.jsx";
import MedicineListScreen from "../screens/Pharmacy/MedicineList/MedicineListScreen.jsx";
import AddMedicine from "../screens/Pharmacy/MedicineList/AddMedicine.jsx";
import EditMedicineList from "../screens/Pharmacy/MedicineList/EditMedicineList.jsx";
import MedicineCategoryScreen from "../screens/Pharmacy/MedicineCategory/MedicineCategoryScreen.jsx";
import AddMedicineCategory from "../screens/Pharmacy/MedicineCategory/AddMedicineCategory.jsx";
import EditMedicineCategory from "../screens/Pharmacy/MedicineCategory/EditMedicineCategory.jsx";
import ItemTransferScreen from "../screens/Pharmacy/ItemTransfer/ItemTransferScreen.jsx";
import AddItemTransfer from "../screens/Pharmacy/ItemTransfer/AddItemTransfer.jsx";
import EditItemTransfer from "../screens/Pharmacy/ItemTransfer/EditItemTransfer.jsx";
import ViewItemTransfer from "../screens/Pharmacy/ItemTransfer/ViewItemTransfer.jsx";
import ExpiryStockTable from "../screens/Pharmacy/ExpiryStock/ExpiryStockTable.jsx";
import StockadjustmentScreen from "../screens/Pharmacy/StockAdjustment/StockAdjustmentScreen.jsx";
import AddStockAdjustment from "../screens/Pharmacy/StockAdjustment/AddStockAdjustment.jsx";
import EditStockAdjustment from "../screens/Pharmacy/StockAdjustment/EditStockAdjustment.jsx";
import ChangePassword from "../screens/Pharmacy/Profile/ChangePassword.jsx";
import PharmacyProfileScreen from "../screens/Pharmacy/Profile/PharmacyProfileScreen.jsx";
import BillingScreen from "../screens/BillingScreen/BillingScreen.jsx";
import NewBillingPayment from "../screens/BillingScreen/component/NewBillingPayment.jsx";
import InvoiceBilling from "../screens/BillingScreen/component/InvoiceBilling.jsx";
import EditForm from "../screens/BillingScreen/component/EditForm.jsx";
import AddFrom from "../screens/BillingScreen/component/AddFrom.jsx";
import EditPatientHealthRecord from "../screens/HealthRecords/Patient/EditPatientHealthRecord.jsx";
import AddPatientHealthRecord from "../screens/HealthRecords/Patient/AddPatientHealthRecord.jsx";
import PatientHealthRecordScreen from "../screens/HealthRecords/Patient/PatientHealthRecordScreen.jsx";
import AdminDashboardScreen from "../screens/Admin/AdminDashboardScreen.jsx";
import ViewPatient from "../screens/DoctorModule/Patient/ViewPatient.jsx";
import ViewHealthRecord from "../screens/DoctorModule/Patient/ViewHealthRecord.jsx";
import DoctorHistory from "../screens/Doctor/component/DoctorHistory.jsx";
import ViewAppoinment from "../screens/DoctorModule/Patient/ViewAppoinment.jsx";
import PrintPurchase from "../screens/Pharmacy/purchase/PurchaseRequest/PrintPurchase.jsx";
import BulkMedicine from "../screens/Pharmacy/MedicineList/BulkMedicine.jsx";
import PrintPurchaseReturn from "../screens/Pharmacy/purchase/purchaseReturn/PrintPurchaseReturn.jsx";
import SubpackagesScreen from "../screens/BillingScreen/SubpackagesScreen.jsx";
import RolesScreen from "../screens/Admin/roles/RolesScreen.jsx";
import AddRoles from "../screens/Admin/roles/AddRoles.jsx";
import TeamMember from "../screens/TeamMember/TeamMember.jsx";
import TeamMemberForm from "../screens/TeamMember/components/TeamMemberForm.jsx";
import PatientDue from "../screens/Pharmacy/PatientDue/PatientDue.jsx";
import viewDue from "../screens/Pharmacy/PatientDue/ViewDueDetails.jsx";
import PackageTable from "../screens/Admin/Package/PackageTable.jsx";
import AddPackage from "../screens/Admin/Package/AddPackage.jsx";
import EditPackage from "../screens/Admin/Package/EditPackage.jsx";
import HospitalTable from "../screens/Admin/Hospital/HospitalTable.jsx";
import AddHospital from "../screens/Admin/Hospital/AddHospital.jsx";
import EditHospital from "../screens/Admin/Hospital/EditHospital.jsx";
import APPOINMENTQUESTIONS from "../screens/AppointmentQuestions/AppointmentQuestions.jsx";
import APPOINMENTQUESTIONSCREEN from "../screens/AppointmentQuestions/AppointmentquestionScreen.jsx";
import APPOINMENTQUESTIONSEDIT from "../screens/AppointmentQuestions/AppointmentQuestionsEdit.jsx";
import APPOINMENTQUESTIONSVIEW from "../screens/AppointmentQuestions/AppointmentView.jsx";
import AppointmentAnswerModel from "../screens/Appoinment/AppointmentAnswerModel.jsx";
import PaymentTable from "../screens/Admin/Payment/PaymentTable.jsx";
import PaymentHistory from "../screens/Admin/Payment/PaymentHistory.jsx";
import PaymentInvoice from "../screens/Admin/Payment/PaymentInvoice.jsx";
import BulkPurchase from "../screens/Admin/Package/BulkPurchase.jsx";
import SubscriptionTable from "../screens/Admin/Subscription/SubscriptionTable.jsx";
import AddSubscription from "../screens/Admin/Subscription/AddSubscription.jsx";
import EditSubscription from "../screens/Admin/Subscription/EditSubscription.jsx";
import WardTable from "../screens/WardManagement/Ward/WardTable.jsx";
import AddWard from "../screens/WardManagement/Ward/AddWard.jsx";
import EditWard from "../screens/WardManagement/Ward/EditWard.jsx";
import BlockTable from "../screens/WardManagement/Blocks/BlockTable.jsx";
import AddBlock from "../screens/WardManagement/Blocks/AddBlock.jsx";
import WardDashboard from "../screens/WardManagement/WardDashboard.jsx";
import EditBlock from "../screens/WardManagement/Blocks/EditBlock.jsx";
import RoomsTable from "../screens/WardManagement/Rooms/RoomsTable.jsx";
import AddRoom from "../screens/WardManagement/Rooms/AddRoom.jsx";
import EditRoom from "../screens/WardManagement/Rooms/EditRoom.jsx";
import BookRoomScreen from "../screens/WardManagement/BookRoom/BookRoomScreen.jsx";
import AvailableRoomListTable from "../screens/WardManagement/BookRoom/AvailableRoomList.jsx";
import BookedRoomsList from "../screens/WardManagement/BookRoom/BookedRoomList.jsx";
import TotalRoomsList from "../screens/WardManagement/BookRoom/TotalRoomsList.jsx";
import ShiftRoom from "../screens/WardManagement/ShiftRoom.jsx";
import DischargeRoom from "../screens/WardManagement/DischargeRoom.jsx";
import HealthManagementScreen from "../screens/HealthManagement/HealthManagementScreen.jsx";
import VitalScreen from "../screens/HealthManagement/AddVitals.jsx";
import BMIScreen from "../screens/HealthManagement/BMIScreen.jsx";
import BloodGlucoseScreen from "../screens/HealthManagement/BloodGlucoseScreen.jsx";
import InsulinScreen from "../screens/HealthManagement/InsulinScreen.jsx";
import BulkMedicineCategory from "../screens/Pharmacy/MedicineCategory/BulkMedicineCategory.jsx";
import PharmacyReports from "../screens/Pharmacy/Reports/PharmacyReports.jsx";
import { access } from "../util/utilz.js";
import AccessDenied from "../screens/AccessDenied.js";
import VitalsList from "../screens/HealthManagement/VitalsList.jsx";
import AddVitals from "../screens/HealthManagement/AddVitals.jsx";
import PatientBillingReports from "../screens/Pharmacy/Reports/PatientBillingReports.jsx";
import AvabileStockList from "../screens/Pharmacy/ExpiryStock/AvabileStockList.jsx";

const App = ({ match }) => {
  const roleId = localStorage.getItem("role_id");
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          exact
          path={`${APP_ROUTES.UPDATEUSER}`}
          component={UserUpdateForm}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWDUEDETAILS}`}
          component={viewDue}
        />
        <Route exact path={`${APP_ROUTES.PATIENTDUE}`} component={PatientDue} />
        <Route exact path={`${APP_ROUTES.PROFILE}`} component={ProfileScreen} />
        <Route
          exact
          path={`${APP_ROUTES.PATIENT}`}
          component={access("patients", "view") ? PatientScreen : AccessDenied}
        />
        <Route exact path={`${APP_ROUTES.EXPORTCSV}`} component={Exportcsv} />
        <Route
          exact
          path={`${APP_ROUTES.DASHBOARD}`}
          component={DashboardScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDPRESCRIPTION}`}
          component={
            Number(roleId) === 2
              ? PrescriptionAddForm
              : access("prescription", "add")
              ? PrescriptionAddForm
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.PRESCRIPTIONDATA}`}
          component={PrescriptionData}
        />
        <Route
          exact
          path={`${APP_ROUTES.HOLIDAYS}`}
          component={access("holidays", "view") ? HolidaysScreen : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWHOLIDAYS}`}
          component={access("holidays", "add") ? HolidayAddForm : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITHOLIDAYS}`}
          component={
            access("holidays", "edit") ? HolidayEditForm : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.QUALIFICATION}`}
          component={QualificationScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWQUALIFICATION}`}
          component={QualificationAddForm}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITQUALIFICATION}`}
          component={QualificationEditForm}
        />{" "}
        {/* <Route
          exact
          path={`${APP_ROUTES.ORGANISATION}`}
          component={OrganisationScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWORGANISATION}`}
          component={AddnewOrganisation}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITORGANISATION}`}
          component={EditOrganisation}
        /> */}
        <Route
          exact
          path={`${APP_ROUTES.DESIGNATION}`}
          component={DesignationScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWDESIGNATION}`}
          component={AddDesignation}
        />
        <Route exact path={`${APP_ROUTES.GETUSERS}`} component={UsersScreen} />
        <Route
          exact
          path={`${APP_ROUTES.EDITDESIGNATION}`}
          component={EditDesignation}
        />
        <Route
          exact
          path={`${APP_ROUTES.SPECIALIZATION}`}
          component={SpecializationScreen}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWSPECIALIZATION}`}
          component={AddNewSpecialization}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITPATIENT}`}
          component={
            access("patients", "edit") ? PatientEditScreen : AccessDenied
          }
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.CANCELPATIENTUPDATE}`}
          component={PatientTable}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITPATIENTAPPOINTMENT}`}
          component={EditPatientAppointment}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITSPECIALIZATION}`}
          component={EditSpecialization}
        />
        <Route
          exact
          path={`${APP_ROUTES.DOCTOR}`}
          component={access("doctors", "view") ? DoctorScreen : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWDOCTORHISTORY}`}
          component={DoctorHistory}
        />
        <Route
          exact
          path={`${APP_ROUTES.DOCTORADDNEW}`}
          component={
            access("doctors", "add") ? DoctorAddNewScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.SCHEDULE}`}
          component={access("schedule", "view") ? ScheduleScreen : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDSCHEDULE}`}
          component={access("schedule", "add") ? AddSchedule : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITSCHEDULE}`}
          component={access("schedule", "edit") ? EditSchedule : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWSCHEDULE}`}
          component={access("schedule", "view") ? ViewSchedule : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.REFERENCEDOCTOR}`}
          component={ReferenceDoctorScreen}
        />
        <Route
          path={`${APP_ROUTES.DOCTORAPPOINTMENT}`}
          exact
          component={DoctorAppoinment}
        />
        <Route
          exact
          path={`${APP_ROUTES.REFERENCEDOCTORADDNEW}`}
          component={ReferenceDoctorAddNew}
        />
        <Route
          exact
          path={`${APP_ROUTES.DOCTOREDIT}`}
          component={
            access("doctors", "edit") ? DoctorEditScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.DOCTOREDIT}`}
          component={
            access("doctors", "edit") ? DoctorEditScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.REFERENCEDOCTOREDIT}`}
          component={RefDoctorEdit}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWPATIENT}`}
          component={access("patients", "add") ? AddNewPatient : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.BILLINGPACKAGES}`}
          component={
            access("billingPackage", "view")
              ? BillingPackageScreen
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDBILLINGPACKAGES}`}
          component={
            access("billingPackage", "add")
              ? BillingPackageAddNew
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITBILLINGPACKAGES}`}
          component={
            access("billingPackage", "edit") ? BillingPackageEdit : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENT}`}
          component={AllAppoinments}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDNEWAPPOINMENT}`}
          component={AddNewAppoinment}
        />
        <Route
          exact
          path={`${APP_ROUTES.PATIENTAPPOINMENTS}`}
          component={PatientAppoinments}
        />
        <Route
          path={`${APP_ROUTES.APPOINMENTSUPCOMING}`}
          component={UpcomingAppoinments}
        />
        <Route
          exact
          path={`${APP_ROUTES.TODAYAPPOINMENT}`}
          component={TodayAppointment}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDPATIENTAPPOINTMENTS}`}
          component={AppointmentBookForm}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDAPPOINTMENT}`}
          component={AppoinmentType}
        />
        <Route
          exact
          path={`${APP_ROUTES.PREVIOUSADDAPPOINTMENT}`}
          component={AppoinmentType}
        />
        <Route
          exact
          path={`${APP_ROUTES.DOCTORAPPOINTMENT}`}
          component={DoctorAppoinment}
        />
        {/* <Route
          path={`${APP_ROUTES.APPOINMENTSTODAY}`}
          component={TodayAppoinments}
        /> */}
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENTSREQUEST}`}
          component={RequestAppoinments}
        />
        <Route
          path={`${APP_ROUTES.APPOINMENTSCALENDER}`}
          component={AppoinmentCalendar}
        />
        <Route exact path={`${APP_ROUTES.VIDEOCALL}`} component={JoinCall} />{" "}
        <Route
          exact
          path={`${APP_ROUTES.TELEMEDICINE}`}
          component={Telemedicine}
        />
        <Route
          exact
          path={`${APP_ROUTES.PATIENT1}`}
          component={access("patients", "view") ? PatientScreen1 : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.PRESCRIPTION}`}
          component={PrescriptionScreen}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.CONFIRMPASSWORD}`}
          component={ConfirmPasswordScreen}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.DOCTORLIST}`}
          component={DoctorListScreen}
        />{" "}
        <Route exact path={`${APP_ROUTES.LOGOUT}`} component={LogoutScreen} />
        <Route exact path={`${APP_ROUTES.SYMPTOMS}`} component={Symptoms} />
        <Route
          exact
          path={`${APP_ROUTES.CONSULTATION}`}
          component={ConsultationScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.TABLETLIST}`}
          component={
            access("medicineList", "view") ? TabletScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDTABLET}`}
          component={access("medicineList", "add") ? AddTablets : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITTABLET}`}
          component={access("medicineList", "edit") ? EditTablet : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.DOCTORAPPOINMENTLIST}`}
          component={DoctorAppoinments}
        />
        <Route
          exact
          path={`${APP_ROUTES.SUPPLIERS}`}
          component={
            access("suppliers", "view") ? SuppliersScreen : AccessDenied
          }
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.ADDSUPPLIER}`}
          component={access("suppliers", "add") ? AddSupplier : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITSUPPLIER}`}
          component={access("suppliers", "edit") ? EditSupplier : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.VIEWSUPPLIER}`}
          component={ViewSupplier}
        />
        <Route
          exact
          path={`${APP_ROUTES.SALES}`}
          component={access("sales", "view") ? SalesScreen : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.SALESRETURN}`}
          component={
            access("salesReturn", "view") ? SalesReturnScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDSALESRETURN}`}
          component={
            access("salesReturn", "add") ? AddSalesReturn : AccessDenied
          }
        />
        {/* <Route
          exact
          path={`${APP_ROUTES.SALESRETURN}`}
          component={SalesReturnScreen}
        />{" "} */}
        <Route
          exact
          path={`${APP_ROUTES.EDITSALESRETURN}`}
          component={
            access("salesReturn", "edit") ? EditSalesReturn : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWSALESRETURN}`}
          component={ViewSalesReturn}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDSALES}`}
          component={access("sales", "add") ? AddSales : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITSALES}`}
          component={access("sales", "edit") ? EditSales : AccessDenied}
        />{" "}
        <Route exact path={`${APP_ROUTES.VIEWSALES}`} component={ViewSales} />
        <Route
          exact
          path={`${APP_ROUTES.PURCHASE}`}
          component={access("purchase", "view") ? PurchaseScreen : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.ADDPURCHASE}`}
          component={access("purchase", "add") ? AddPurchase : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWPURCHASE}`}
          component={access("purchase", "view") ? ViewPurchase : AccessDenied}
        />
        {/* <Route
          exact
          path={`${APP_ROUTES.VIEWPHARMACY}`}
          component={ViewPurchase}
        /> */}
        <Route
          exact
          path={`${APP_ROUTES.EDITPURCHASE}`}
          component={access("purchase", "edit") ? EditPurchase : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.PURCHASEREQUEST}`}
          component={
            access("purchaseRequest", "view")
              ? PurchaseRequestScreen
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDPURCHASEREQUEST}`}
          component={
            access("purchaseRequest", "add") ? AddPurchaseRequest : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITPURCHASEREQUEST}`}
          component={
            access("purchaseRequest", "edit")
              ? EditPurchaseRequest
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWPURCHASEREQUEST}`}
          component={ViewPurchaseRequest}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDPURCHASE}`}
          component={access("purchase", "add") ? AddPurchase : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.PURCHASERETURN}`}
          component={
            access("purchaseReturn", "view")
              ? PurchaseReturnScreen
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDPURCHASERETURN}`}
          component={
            access("purchaseReturn", "add") ? AddPurchaseReturn : AccessDenied
          }
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITPURCHASERETURN}`}
          component={
            access("purchaseReturn", "edit") ? EditPurchaseReturn : AccessDenied
          }
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.VIEWPURCHASERETURN}`}
          component={ViewPurchaseReturn}
        />
        <Route
          exact
          path={`${APP_ROUTES.MEDICINELIST}`}
          component={
            access("medicineList", "view") ? MedicineListScreen : AccessDenied
          }
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.ADDMEDICINE}`}
          component={access("medicineList", "add") ? AddMedicine : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITMEDICINE}`}
          component={
            access("medicineList", "edit") ? EditMedicineList : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.MEDICINECATEGORY}`}
          component={
            access("medicineCategory", "view")
              ? MedicineCategoryScreen
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDMEDICINECATEGORY}`}
          component={
            access("medicineCategory", "add")
              ? AddMedicineCategory
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITMEDICINECATEGORY}`}
          component={
            access("medicineCategory", "edit")
              ? EditMedicineCategory
              : AccessDenied
          }
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.ITEMTRANSFER}`}
          component={
            access("itemTransfer", "view") ? ItemTransferScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDITEMTRANSFER}`}
          component={
            access("itemTransfer", "add") ? AddItemTransfer : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITITEMTRANSFER}`}
          component={
            access("itemTransfer", "edit") ? EditItemTransfer : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWITEMTRANSFER}`}
          component={ViewItemTransfer}
        />
        <Route
          exact
          path={`${APP_ROUTES.EXPIRYSTOCK}`}
          component={ExpiryStockTable}
        />
        <Route
          exact
          path={`${APP_ROUTES.STOCKADJUSTMENT}`}
          component={
            access("stockAdjustment", "view")
              ? StockadjustmentScreen
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDSTOCKADJUSTMENT}`}
          component={
            access("stockAdjustment", "add") ? AddStockAdjustment : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITSTOCKADJUSTMENT}`}
          component={
            access("stockAdjustment", "edit")
              ? EditStockAdjustment
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.PHARMACYPROFILE}`}
          component={PharmacyProfileScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.PHARMACYCHANGEPASSWORD}`}
          component={ChangePassword}
        />
        <Route
          exact
          path={`${APP_ROUTES.BILLINGS}`}
          component={
            access("billingPayment", "view") ? BillingScreen : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.SUBPACKAGECATEGORY}`}
          component={SubpackagesScreen}
        />
        <Route exact path={`${APP_ROUTES.ROLES}`} component={RolesScreen} />
        <Route exact path={`${APP_ROUTES.ADDROLES}`} component={AddRoles} />
        <Route
          exact
          path={`${APP_ROUTES.INVOICEBILLING}`}
          component={InvoiceBilling}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDBILLINGS}`}
          component={access("billingPayment", "add") ? AddFrom : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITBILLINGS}`}
          component={access("billingPayment", "edit") ? EditForm : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.PATIENTHEALTHRECORD}`}
          component={PatientHealthRecordScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.TEAMMEMBERS}`}
          component={TeamMember}
        />
        <Route
          exact
          path={`${APP_ROUTES.TEAMMEMBERSFORM}`}
          component={TeamMemberForm}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDPATIENTHEALTHRECORD}`}
          component={AddPatientHealthRecord}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITPATIENTHEALTHRECORD}`}
          component={EditPatientHealthRecord}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADMINDASHBOARD}`}
          component={AdminDashboardScreen}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWPATIENT1}`}
          component={ViewPatient}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWPATIENTHEALTHRECORD1}`}
          component={ViewHealthRecord}
        />
        <Route
          exact
          path={`${APP_ROUTES.VIEWPATIENTAPPOINTMENT1}`}
          component={ViewAppoinment}
        />
        <Route
          exact
          path={`${APP_ROUTES.PRINTPURCHASE}`}
          component={PrintPurchase}
        />
        <Route
          exact
          path={`${APP_ROUTES.BULKMEDICINE}`}
          component={BulkMedicine}
        />
        <Route
          exact
          path={`${APP_ROUTES.PRINTPURCHASERETURN}`}
          component={PrintPurchaseReturn}
        />
        <Route
          exact
          path={`${APP_ROUTES.HOSPITAL}`}
          component={roleId === "6" ? HospitalTable : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADDHOSPITAL}`}
          component={roleId === "6" ? AddHospital : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDITHOSPITAL}`}
          component={roleId === "6" ? EditHospital : AccessDenied}
        />{" "}
        <Route exact path={`${APP_ROUTES.PACKAGES}`} component={PackageTable} />{" "}
        <Route exact path={`${APP_ROUTES.ADDPACKAGE}`} component={AddPackage} />{" "}
        <Route
          exact
          path={`${APP_ROUTES.EDITPACKAGE}`}
          component={EditPackage}
        />
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENTQUESTIONS}`}
          component={
            access("appoinmentquestions", "add")
              ? APPOINMENTQUESTIONS
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENTQUESTIONSCREEN}`}
          component={
            access("appoinmentquestions", "view")
              ? APPOINMENTQUESTIONSCREEN
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENTQUESTIONSEDIT}`}
          component={
            access("appoinmentquestions", "edit")
              ? APPOINMENTQUESTIONSEDIT
              : AccessDenied
          }
        />
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENTQUESTIONSVIEW}`}
          component={APPOINMENTQUESTIONSVIEW}
        />
        <Route
          exact
          path={`${APP_ROUTES.PATIENT_BILLING_REPORTS}`}
          component={PatientBillingReports}
        />
        <Route
          exact
          path={`${APP_ROUTES.APPOINMENTANSWER}`}
          component={AppointmentAnswerModel}
        />
        <Route
          exact
          path={`${APP_ROUTES.PAYMENT}`}
          component={roleId === "6" ? PaymentTable : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.PAYMENT_HISTORY}`}
          component={roleId === "6" ? PaymentHistory : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.PAYMENT_INVOICE}`}
          component={roleId === "6" ? PaymentInvoice : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.BULKPURCHASE}`}
          component={BulkPurchase}
        />
        <Route
          exact
          path={`${APP_ROUTES.SUBSCRIPTION}`}
          component={roleId === "6" ? SubscriptionTable : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.ADD_SUBSCRIPTION}`}
          component={roleId === "6" ? AddSubscription : AccessDenied}
        />
        <Route
          exact
          path={`${APP_ROUTES.EDIT_SUBSCRIPTION}`}
          component={roleId === "6" ? EditSubscription : AccessDenied}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.WARD_MANAGEMENT}`}
          component={WardDashboard}
        />
        <Route exact path={`${APP_ROUTES.BLOCK_LIST}`} component={BlockTable} />
        <Route exact path={`${APP_ROUTES.ADD_BLOCK}`} component={AddBlock} />
        <Route exact path={`${APP_ROUTES.EDIT_BLOCK}`} component={EditBlock} />
        <Route exact path={`${APP_ROUTES.WARD_LIST}`} component={WardTable} />
        <Route exact path={`${APP_ROUTES.ADD_WARD}`} component={AddWard} />
        <Route exact path={`${APP_ROUTES.EDIT_WARD}`} component={EditWard} />
        <Route exact path={`${APP_ROUTES.ROOM_LIST}`} component={RoomsTable} />
        <Route exact path={`${APP_ROUTES.ADD_ROOM}`} component={AddRoom} />
        <Route
          exact
          path={`${APP_ROUTES.EDIT_ROOM}`}
          component={EditRoom}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.AVAILABLE_ROOM}`}
          component={AvailableRoomListTable}
        />
        <Route
          exact
          path={`${APP_ROUTES.BOOK_ROOM}`}
          component={BookRoomScreen}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.BOOKED_ROOMS}`}
          component={BookedRoomsList}
        />{" "}
        <Route
          exact
          path={`${APP_ROUTES.TOTAL_ROOMS}`}
          component={TotalRoomsList}
        />
        <Route exact path={`${APP_ROUTES.SHIFT_ROOMS}`} component={ShiftRoom} />
        <Route
          exact
          path={`${APP_ROUTES.DISCHARGE_ROOM}`}
          component={DischargeRoom}
        />
        <Route
          exact
          path={`${APP_ROUTES.HEALTH_MANAGEMENT}`}
          component={HealthManagementScreen}
        />
        <Route exact path={`${APP_ROUTES.VITAL}`} component={VitalsList} />
        <Route exact path={`${APP_ROUTES.ADD_VITAL}`} component={AddVitals} />
        <Route exact path={`${APP_ROUTES.BMI}`} component={BMIScreen} />
        <Route
          exact
          path={`${APP_ROUTES.BLOOD_GLUCOSE}`}
          component={BloodGlucoseScreen}
        />{" "}
        <Route exact path={`${APP_ROUTES.INSULIN}`} component={InsulinScreen} />{" "}
        <Route
          exact
          path={`${APP_ROUTES.BULKMEDICINECATEGORY}`}
          component={BulkMedicineCategory}
        />
        <Route
          exact
          path={`${APP_ROUTES.PHARMACYREPORTS}`}
          component={PharmacyReports}
        />
        <Route
          exact
          path={`${APP_ROUTES.AVABILE_STOCK_LISTS}`}
          component={
            access("billingPayment", "edit")
              ? AvabileStockList
              : AvabileStockList
          }
        />
      </Switch>
    </div>
  );
};

export default App;
