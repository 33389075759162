import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getDataDesignationInProgress,
  updateDesignationInProgress,
} from "../../../appRedux/doctor/actions/Designation";

import DesignationForm from "./DesignationForm";

const EditDesignation = (props) => {
  const EditData = props.location.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const designationData = useSelector((state) => state.designation);
  const UpdatedesignationData = designationData?.updateDesignation;
  console.log("UpdatedesignationData", UpdatedesignationData);
  const id = useParams();
  const onFinish = (datas) => {
    console.log("DATA", datas);
    let formData = new URLSearchParams();
    formData.append("Name", datas.Name);

    dispatch(updateDesignationInProgress({ formData, id: EditData.id }));
  };
  useEffect(() => {
    form.setFieldsValue(EditData);
  });
  useEffect(() => {
    if (UpdatedesignationData?.success) {
      history.push("/designation");
    }
  }, [UpdatedesignationData]);
  return (
    <DesignationForm
      onFinish={onFinish}
      title={"Addnew.desg.label.editdesignation"}
      buttonName={"Update"}
      form={form}
    />
  );
};

export default EditDesignation;
