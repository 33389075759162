import React from "react";
import { useEffect } from "react";
import Auxiliary from "../../../../util/Auxiliary";
import PurchaseTable from "./PurchaseTable";
import PurchaseHeader from "./PurchaseHeader";
import { Card } from "antd";

const PurchaseScreen = () => {
  return (
    <Card>
      <PurchaseHeader />
      <PurchaseTable />
    </Card>
  );
};

export default PurchaseScreen;
