import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Tooltip, Typography } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import TableComponent from "../../../components/Table/TableComponent";
import {
  defaultBulkMedicineCategory,
  defaultTabletCategory,
  deleteTabletCategoryInProgress,
  getTabletCategoryListInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { access } from "../../../util/utilz";

const MedicineCategoryTable = () => {
  const orgId = localStorage.getItem("org_id");
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const medicineCategoryData = useSelector(
    (state) => state?.pharmacy?.tabletCategoryList
  );
  console.log("medicineCategoryData", medicineCategoryData);
  const categoryList = medicineCategoryData.data?.data;
  const totalRecords = medicineCategoryData.data?.totalRecords;
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("medicineCategory", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/medicinecategory/edit`,
                      state: { medicineList: record },
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      icon={<EditOutlined />}
                      // onClick={()=>editPatient(record.id)}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("medicineCategory", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteTabletCategoryInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const pageNo = page;
    const formData = {
      pageNo: pageNo,
      orgId: orgId,
      pagination: "true",
      pageSize: 10,
    };
    dispatch(getTabletCategoryListInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(categoryList);
    } else {
      const newData = categoryList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  console.log("orgIdorgIdorgIdorgId", orgId);
  useEffect(() => {
    const pageNo = 1;
    const formData = {
      pageNo: pageNo,
      orgId: orgId,
      pagination: "true",
      pageSize: 10,
    };
    dispatch(getTabletCategoryListInProgress(formData));
    dispatch(defaultTabletCategory());
    dispatch(defaultBulkMedicineCategory());
  }, []);
  console.log("medicineCategoryData", medicineCategoryData);
  useEffect(() => {
    if (medicineCategoryData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [medicineCategoryData]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : categoryList}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        rowselector={handleSelectChange}
        itemList={categoryList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"category"}
        documentTitle={"Medicine Category List"}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default MedicineCategoryTable;
