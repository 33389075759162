import { Card, Col, Form, Row } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { patientAddAppointmentInProgress } from '../../../appRedux/Patient/Action/Action'
import ButtonComponent from '../../../components/Button/ButtonComponent'
import FormInput from '../../../components/Input/FormInput'
import FormSelectBox from '../../../components/SelectBox/SelectBox'
import IntlMessages from '../../../util/IntlMessages'
import DoctorSelectAppointment from '../DoctorSelectBox'
import ScheduleSelectAppointment from '../ScheduleSelectBox'
import SelectorsAppointment from '../Selectors'

const AppointmentBookForm=({ title, onFinish, buttonName })=> {

  const Submit = (data) => {
    console.log("Formdata",data)
}
  return (

     <Form name="basic" labelCol={{span: 24}} wrapperCol={{span: 24}} initialValues={{remember: true}}
             autoComplete="off" onFinish={Submit}>
     
            </Form>
  
    
  )
}

export default AppointmentBookForm;