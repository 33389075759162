import React, { useEffect, useRef, useState } from "react";

import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AddPurchaseRequest from "./AddPurchaseRequest";

const ViewPurchaseRequest = () => {
  const location = useLocation();
  const purchaseData = location.state?.purchaseRequestData;
  console.log("purchaseRequestData", purchaseData);
  return <AddPurchaseRequest viewPurchaseData={purchaseData} viewData="true" />;
};

export default ViewPurchaseRequest;
