import { Col, Row, Table, Typography } from "antd";
import React from "react";
import IntlMessages from "../../../util/IntlMessages";

const SalesFooter = ({
  subtotal,
  Due,
  discount,
  total,
  receivedAmount,
  NeedToPay,
  gstAmount,
  gstValue,
}) => {
  const columns = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "value",
      dataIndex: "value",
    },
  ];
  const input = [
    subtotal
      ? {
          name: <IntlMessages id={"Addnew.purchaseRequest.subTotal"} />,
          value: `${subtotal} ₹`,
        }
      : null,

    gstAmount
      ? {
          name: <IntlMessages id={"Addnew.purchaseRequest.gstAmount"} />,
          value: `${gstAmount} ₹`,
        }
      : null,
    gstValue
      ? {
          name: <IntlMessages id={"Addnew.purchaseRequest.gstvalue"} />,
          value: `${gstValue} ₹`,
        }
      : null,
    discount
      ? {
          name: "Discount",
          value: `${discount} ₹`,
        }
      : null,
    total
      ? {
          name: <IntlMessages id={"Addnew.purchaseRequest.total"} />,
          value: `${total} ₹`,
        }
      : null,
    receivedAmount
      ? {
          name: "Amount Received",
          value: `${receivedAmount} ₹`,
        }
      : null,
    Due
      ? {
          name: "Due Amount",
          value: `${Due} ₹`,
        }
      : null,
    NeedToPay
      ? {
          name: "Need To Pay",
          value: `${NeedToPay} ₹`,
        }
      : null,
  ];

  return (
    <div>
      <Table
        showHeader={false}
        columns={columns}
        dataSource={input.filter((element) => element !== null)}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default SalesFooter;
