import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Tooltip, Typography, Spin } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import Auxiliary from "../../../util/Auxiliary";
import TableComponent from "../../../components/Table/TableComponent";

import {
  cancelPrescriptionInProgress,
  prescriptionListInProgress,
  seenPrescriptionInProgress,
} from "../../../appRedux/doctor/actions/Prescription";
import { APP_ROUTES } from "../../../constants/Routes";
import { Link, useHistory } from "react-router-dom";

const PrescriptionTable = (props) => {
  const role_id = localStorage.getItem("role_id");
  console.log("role_id", Number(role_id) === 2);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setId] = useState("");
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
    console.log("idd", id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(cancelPrescriptionInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSeen = (record) => {
    console.log("record", record);
    if (Number(role_id) === 4 && Number(record?.pharmacy_status) === 2) {
      dispatch(seenPrescriptionInProgress({ prescriptionId: record?.id }));
    }
    history.push({
      pathname: `${APP_ROUTES.PRESCRIPTIONDATA}`,
      state: { record, prescriptions: "prescriptions" },
    });

    // ;
  };
  const columns = [
    {
      title: "patient Name",
      dataIndex: "patient_name",

      // render: (prescription_details) =>
      //   prescription_details.map((service) => service.tablet_name).join(),
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",

      // render: (prescription_details) =>
      //   prescription_details.map((service) => service.tablet_name).join(),
    },

    {
      title: "Pharmacy Status",
      dataIndex: "pharmacy_status",
      render: (ps) => {
        return (
          <>
            {Number(ps) === 1
              ? "not sent"
              : Number(ps) === 2
              ? "doctor to Pharmacy"
              : Number(ps) === 3
              ? "Pharmacy seen"
              : "Tablets get by patients"}
          </>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {" "}
            <Col className="button-edit">
              {/* <Link
                to={{
                  pathname: `${APP_ROUTES.PRESCRIPTIONDATA}`,
                  state: { record },
                }}
              > */}
              <Tooltip placement="top" title={"View"}>
                <Typography>
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    onClick={() => handleSeen(record)}
                  />
                </Typography>{" "}
              </Tooltip>

              {/* </Link> */}
            </Col>
            {Number(role_id) === 2 && (
              <>
                <Col className="button-edit">
                  <Tooltip placement="top" title={"Edit"}>
                    <ButtonComponent
                      type="primary"
                      className={"secondary-color"}
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                </Col>
                <Col className="button-edit">
                  <Tooltip placement="top" title={"Delete"}>
                    <Typography>
                      <ButtonComponent
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={() => showModal(record.id)}
                      ></ButtonComponent>
                    </Typography>
                  </Tooltip>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const prescriptionData = useSelector((state) => state?.prescription);
  const deletePrescription = prescriptionData?.cancelPrescription;
  const prescriptionList = prescriptionData?.prescriptionList?.data?.data;
  const totalRecords = prescriptionData?.prescriptionList?.data?.totalRecords;
  console.log("prescriptionData", prescriptionData?.prescriptionList);
  const handlePage = (page) => {
    dispatch(prescriptionListInProgress(page));
  };
  useEffect(() => {
    const page = 1;
    dispatch(prescriptionListInProgress(page));
  }, []);
  useEffect(() => {
    if (deletePrescription?.success) dispatch(prescriptionListInProgress());
  }, [deletePrescription]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Auxiliary>
      {" "}
      <Spin
        indicator={antIcon}
        spinning={prescriptionData?.prescriptionList?.progressing}
      >
        <TableComponent
          columns={columns}
          dataSource={prescriptionList}
          tableLayout={"horizontal"}
          itemList={prescriptionList}
          documentTitle="Prescription List"
        />
        <Modal
          title="Delete Data"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
        >
          <p>Are you sure, you want to delete the Data</p>
        </Modal>
      </Spin>
    </Auxiliary>
  );
};

export default PrescriptionTable;
