import axios from "axios";

const familyMemberInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getFamilyMemberListEffect = (patient_id) => {
  const token = localStorage.getItem("user_id");
  return familyMemberInstance.request({
    url: `/familyMember/allListByPatientId?pagination_required=false&pageNo=1&pagesize=10&patient_id=${patient_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
