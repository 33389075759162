import { call, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
  ADD_DOCTOR_LIST_PROGRESS,
  DELETE_DOCTOR_LIST_PROGRESS,
  DOCTOR_CSV_UPLOAD_IN_PROGRESS,
  GET_DOCTOR_LIST_PROGRESS,
  GET_DOCTOR_UPDATE_LIST_PROGRESS,
  UPDATE_DOCTOR_LIST_PROGRESS,
} from "../../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../../util/utilz";
import {
  addDoctorsListEffect,
  deleteDoctorsListEffect,
  doctorFileUploadEffect,
  getDoctorlistUpdateEffect,
  getDoctorsListEffect,
  updateDoctorsListEffect,
} from "../../../admin/Doctor/sagas/effects/doctorEffect";
import {
  addDoctorListInError,
  addDoctorListInSuccess,
  deleteDoctorListInError,
  deleteDoctorListInSuccess,
  doctorCSVUploadError,
  doctorCSVUploadSuccess,
  getDataUpdateError,
  getDataUpdateSuccess,
  getDoctorListInError,
  getDoctorListInSuccess,
  updateDoctorListInError,
  updateDoctorListInSuccess,
} from "../actions/Doctors";
import Message from "../../../../components/Message/Message";

function* getDoctorList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const { data, status } = yield call(getDoctorsListEffect, payload, token);

    yield put(
      getDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorListInError({ message: getErrorMessageFromAPI(e), error: true })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addDoctorList({ payload }) {
  try {
    console.log("payload", payload.formData);
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addDoctorsListEffect, payload.formData, token);

    yield put(
      addDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
    setTimeout(() => {
      message.info("Password will be shared on your registered email-id");
    }, 3500);
  } catch (e) {
    yield put(
      addDoctorListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* doctorFileUpload({ payload }) {
  const token = localStorage.getItem("user_id");
  try {
    let { data } = yield call(doctorFileUploadEffect, payload, token);
    console.log("csv saga", data);
    yield put(
      doctorCSVUploadSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      doctorCSVUploadError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deleteDoctorList({ payload }) {
  try {
    console.log("payload",payload)
    const token = localStorage.getItem("user_id");
    let { data } = yield call(deleteDoctorsListEffect, payload, token);

    yield put(
      deleteDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    if(payload.handleSuccess){
      payload.handleSuccess()
    }
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteDoctorListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* UpdatedoctorSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data, status } = yield call(updateDoctorsListEffect, payload, token);
    if (status == "500") {
      message.loading("Please try again later!");
    } else if (status == "pending") {
      message.info("Please wait a moment!");
    }
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield put(
      updateDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      updateDoctorListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* GetDoctorUpdateList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getDoctorlistUpdateEffect, payload, token);

    yield put(
      getDataUpdateSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDataUpdateError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export function* DoctorSaga() {
  yield takeLatest(GET_DOCTOR_LIST_PROGRESS, getDoctorList);
  yield takeLatest(ADD_DOCTOR_LIST_PROGRESS, addDoctorList);
  yield takeLatest(DELETE_DOCTOR_LIST_PROGRESS, deleteDoctorList);
  yield takeLatest(UPDATE_DOCTOR_LIST_PROGRESS, UpdatedoctorSaga);
  yield takeLatest(GET_DOCTOR_UPDATE_LIST_PROGRESS, GetDoctorUpdateList);
  yield takeLatest(DOCTOR_CSV_UPLOAD_IN_PROGRESS, doctorFileUpload);
}
