import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import "../../../../styles/modules/pharmacy.less";
import { pharmacyList } from "../../../Doctor/data";
import moment from "moment";
import SalesList from "./SalesList";
import FormInput from "../../../../components/Input/FormInput";

const SalesProductForm = ({
  salesInformationData,
  onFinish,
  editSales,
  paymentData,
  form,
  setUpdatedProduct,
  setDiscount,
  wallet,
}) => {
  console.log("wallet", wallet);
  return (
    <>
      <Row className="margin-row" justify="end">
        <Col xl={24} xs={24} lg={24} md={24} sm={24}>
          <Row gutter={[4, 4]} className="margin-row" justify="end">
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                readOnly
                wrapperCol={24}
                labelCol={18}
                type="number"
                min={0}
                label="subTotal"
                name={"sub_total"}
              />
            </Col>
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Enter the discount"} />,
                  },
                ]}
                wrapperCol={24}
                labelCol={18}
                label="Discount %"
                type="number"
                name="discount"
                min={0}
                onChange={(e) => {
                  setDiscount(e);
                }}
              />
            </Col>
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                wrapperCol={24}
                labelCol={18}
                label="Discount"
                name="discount_amount"
                readOnly
                type="number"
                min={0}
              />
            </Col>
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                wrapperCol={24}
                labelCol={18}
                label="Total"
                readOnly
                type="number"
                name="total"
                min={0}
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                readOnly
                wrapperCol={24}
                labelCol={18}
                label="Old Due Amount"
                type="number"
                min={0}
                name="old_due"
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                readOnly
                wrapperCol={24}
                labelCol={18}
                label={`Wallet Amount  ${
                  wallet ? "(" + wallet + " " + "₹" + ")" : ""
                }`}
                type="number"
                min={0}
                name="wallet_amount"
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                readOnly
                wrapperCol={24}
                labelCol={18}
                label="Deposit"
                type="number"
                min={0}
                name="deposite"
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                wrapperCol={24}
                labelCol={18}
                label="Due"
                readOnly
                type="number"
                min={0}
                name={"due"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SalesProductForm;
