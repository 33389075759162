import { Row, Col, Typography, Table } from "antd";
import React from "react";
import { useEffect } from "react";
import "../../styles/modules/pharmacy.less";
import ButtonComponent from "../../components/Button/ButtonComponent";
import Logo from "../../assets/images/DocMotez/logo.png";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  PhoneFilled,
  PhoneOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPharmacyProfileInProgress } from "../../appRedux/Pharmacy/PharmacyAction";
import moment from "moment";

const ViewPharmacy = ({ PurchaseType, data, columns, viewTable }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const goBackHandler = () => {
    history.goBack();
  };
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const { pharmacyProfile } = useSelector((state) => state?.pharmacy);
  const profileData = pharmacyProfile?.data?.data;
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);
  console.log("pharmacyProfile", pharmacyProfile?.data?.data?.profile_image);
  return (
    <Row justify="center" align="middle">
      <Col xl={23} lg={23} md={23} sm={24} xs={24}>
        <Row gutter={[0, 16]}>
          <Col
            xl={18}
            lg={18}
            md={14}
            sm={14}
            xs={24}
            className="hide-on-print"
          >
            {/* <Typography className="view-heading view-grid ">
              Supplier
            </Typography> */}
          </Col>
          <Col xl={6} lg={6} md={10} sm={10} xs={24} className="hide-on-print">
            <Row justify="end" className="view-grid  margin-row">
              <ButtonComponent
                type="success"
                icon={<PrinterFilled />}
                onClick={() => window.print()}
                className={"print-button"}
              ></ButtonComponent>

              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            </Row>
          </Col>
          <Col xl={4} lg={4} md={4} xs={4} sm={3} align="top">
            <img src={pharmacyProfile?.data?.data?.profile_image} width={150} />
          </Col>
          <Col xl={12} lg={12} md={12} xs={20} sm={13}>
            <Row className="margin-row" justify="center" gutter={[0, 10]}>
              <Col xl={20} lg={20} md={20} xs={24} sm={24} align="center">
                <Typography align="center" className="view-name">
                  {profileData?.organization_name}
                </Typography>
              </Col>
              <Col xl={20} lg={20} md={20} xs={24} sm={24}>
                <Typography align="center" justify="center">
                  <EnvironmentFilled /> {profileData?.address},{" "}
                  {profileData?.city},{profileData?.state}
                </Typography>
              </Col>
              <Col xl={20} lg={20} md={20} xs={24} sm={24}>
                <Typography align="center">
                  <PhoneFilled />
                  {profileData?.contact}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={8}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-5">
                <Typography className="view-list-details">Date</Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-5">
                <Typography className="view-list-details no-wrap">
                  : {moment(data?.createdAt).format("DD-MM-YYYY")}
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-5">
                <Typography className="view-list-details">Timing</Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-5">
                <Typography className="view-list-details no-wrap">
                  : {moment(data?.createdAt).format("hh:mm a")}
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-5">
                <Typography className="view-list-details no-wrap">
                  DL Number
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="my-5">
                <Typography className="view-list-details no-wrap">
                  : {profileData?.DL_license_no}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewPharmacy;
