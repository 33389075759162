import React, { useState } from "react";
import { Col, Row, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import { access } from "../../../util/utilz";

const MedicineCategoryHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"label.medicineCategory"} />}
          </p>
        </Col>
        <Col>
          {access("medicineCategory", "add") && (
            <>
              {" "}
              <Link to={APP_ROUTES.ADDMEDICINECATEGORY}>
                <ButtonComponent
                  type="primary"
                  label={<IntlMessages id={"Addnew.createMedicineCategory"} />}
                  icon={<PlusCircleOutlined />}
                />
              </Link>{" "}
              <Link to={APP_ROUTES.BULKMEDICINECATEGORY}>
                <ButtonComponent
                  type="primary"
                  label={<IntlMessages id={"Addnew.bulkMedicineCategory"} />}
                  icon={<PlusCircleOutlined />}
                  className="mr-20 secondary-color"
                />
              </Link>
            </>
          )}
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default MedicineCategoryHeader;
