import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import {
  addSubscriptionInProgress,
  editSubscriptionInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import { useDispatch, useSelector } from "react-redux";
import { getGstListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";

const AddSubscription = ({ editSubscription, editSubscriptionData }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [periodValue, setperiodValue] = useState("");
  const [gstValue, setGstValue] = useState();
  const [price, setPrice] = useState("0");
  const [subscriptionValue, setSubsriptionValue] = useState("");
  const addData = useSelector((state) => state.superAdmin?.addSubscription);
  const editData = useSelector((state) => state.superAdmin?.editSubscription);
  const gstData = useSelector((state) => state.pharmacy?.gstList);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    console.log("datas", datas);
    if (editSubscription) {
      const formData = {
        name: datas?.name,
        plans:
          subscriptionValue === "Free Trial"
            ? "1"
            : subscriptionValue === "Platinum"
            ? "2"
            : "3",
        pricing: datas?.pricing,
        periods: datas?.periods,
        gst: gstValue,
        toatl: datas?.total,
      };

      dispatch(
        editSubscriptionInProgress({ formData, id: editSubscriptionData?.id })
      );
    } else {
      const formData = {
        name: datas?.name,
        // free_trial: subscriptionValue === "Free Trial" ? "1" : "",
        // premium: subscriptionValue === "Premium" ? "1" : "",
        // platinum: subscriptionValue === "Platinum" ? "1" : "",
        plans:
          subscriptionValue === "Free Trial"
            ? "1"
            : subscriptionValue === "Platinum"
            ? "2"
            : "3",
        pricing: datas?.pricing,
        periods: datas?.periods,
        gst: gstValue,
        toatl: datas?.total,
      };
      console.log("formData-addSubscriptionInProgress", formData);
      dispatch(addSubscriptionInProgress(formData));
    }
  };

  const [Loading, setLoading] = useState(false);
  const changeSubscription = (value) => {
    setSubsriptionValue(value);
  };
  const changePeriod = (value) => {
    setperiodValue(value);
  };
  const frontendList = [
    { label: "Free Trial", value: "Free Trial" },
    { label: "Platinum", value: "Platinum" },
    { label: "Premium", value: "Premium" },
  ];
  const periodList = [
    { label: "6 months", value: "6months" },
    { label: "1 year", value: "1years" },
    { label: "2 years", value: "2years" },
    { label: "3 years", value: "3years" },
    { label: "4 years", value: "4years" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const changeGst = (value, option) => {
    setGstValue(value);
    // const price = form.getFieldValue("pricing");
    // let gst = 0;
    // const gstValue = option?.children.replace("%", "");
    // gst = (price * gstValue) / 100;
    // form.setFieldValue("total", Number(gst) + Number(price));
    // console.log("gst amount", gst, price, option);
  };
  useEffect(() => {
    const gstValue = form.getFieldValue("gst");
    const gstPercentage = gstData?.data?.data.find(
      (item) => item.id === gstValue
    );
    const price = form.getFieldValue("pricing");
    let gst = 0;

    gst = (price * gstPercentage?.gst?.replace("%", "")) / 100;
    form.setFieldValue("total", Number(gst || 0) + Number(price || 0));
  }, [gstValue, price]);
  useEffect(() => {
    if (editSubscription) {
      form.setFieldsValue(editSubscriptionData);
      form.setFieldValue("total", editSubscriptionData?.toatl);
      form.setFieldValue("gst", editSubscriptionData?.gst);
      if (editSubscriptionData?.plans === 2) {
        setSubsriptionValue("Platinum");
        form.setFieldValue("subscription_type", "Platinum");
      } else if (editSubscriptionData?.plans === 3) {
        setSubsriptionValue("Premium");
        form.setFieldValue("subscription_type", "Premium");
      } else if (editSubscriptionData?.plans === 1) {
        setSubsriptionValue("Free Trial");
        form.setFieldValue("subscription_type", "Free Trial");
      }
    }
  }, [editSubscription]);
  console.log("editSubscriptionData", editSubscriptionData);
  useEffect(() => {
    if (editData?.success || addData?.success) {
      history.push("/subscription");
    }
  }, [editData, addData]);
  useEffect(() => {
    dispatch(getGstListInProgress());
  }, []);
  return (
    <>
      <Spin
        indicator={antIcon}
        spinning={addData?.inProgress || editData?.inProgress}
      >
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {!editSubscription ? (
                    <IntlMessages id={"label.addSubscription"} />
                  ) : (
                    <IntlMessages id={"label.editSubscription"} />
                  )}
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"name"}
                  label={<IntlMessages id={"Addnew.admin.label.name"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.admin.validator.name"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  label={
                    <IntlMessages id={"Addnew.admin.label.subscription_type"} />
                  }
                  name={"subscription_type"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.admin.validator.subscription_type"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    //   placeholder="Select Package"
                    value={subscriptionValue}
                    options={frontendList}
                    onChange={changeSubscription}
                  />
                </Form.Item>
              </Col>{" "}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  label={<IntlMessages id={"Addnew.admin.label.period"} />}
                  name={"periods"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.admin.validator.period"} />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    //   placeholder="Select Package"
                    value={periodValue}
                    options={periodList}
                    onChange={changePeriod}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"pricing"}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  label={<IntlMessages id={"Addnew.admin.label.price"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.admin.validator.price"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                {" "}
                <Form.Item
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  label={<IntlMessages id={"Addnew.admin.label.gst"} />}
                  name={"gst"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.admin.validator.gst"} />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select GST"
                    value={gstValue}
                    options={
                      gstData?.data?.data?.map((pack, i) => ({
                        label: pack.gst,
                        value: pack.id,
                        key: pack.id,
                      })) || []
                    }
                    onChange={changeGst}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"total"}
                  disabled={true}
                  label={<IntlMessages id={"Addnew.admin.label.total"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.admin.validator.total"} />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.SUBSCRIPTION}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editSubscription ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddSubscription;
