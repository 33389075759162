import React from "react";
import { Avatar, Badge, Card, Col, Row } from "antd";
import { pharmacyList } from "../../screens/Doctor/data";
import { formatDate } from "../../util/utilz";

const getStatus = (status) => {
  //   const statusData = pharmacyList.filter(
  //     (taskStatus, index) => status === taskStatus.id
  //   )[0];
  return <></>;
};
const color = { 1: "green", 2: "cyan", 3: "volcano", 4: "purple", 5: "red" };

const TicketItem = ({ data, index }) => {
  console.log("data", index, data);
  const {
    id,
    specialization_name,
    avatar,
    doctor,
    appointment_status,
    appointment_status_name,
    patient_name,
    date,
  } = data;
  console.log("appointment_status", appointment_status);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div key={"TicketItem" + id} className="my-10">
      <Row>
        <Col xl={4} lg={4} md={4} sm={4} xs={5}>
          <Avatar
            size={40}
            style={{
              backgroundColor: "#fde3cf",
              color: "#f56a00",
            }}
          >
            {index + 1}
          </Avatar>
        </Col>
        <Col xl={16} lg={16} md={16} sm={16} xs={15}>
          <div className="gx-task-item-content-left">
            <h5 className="gx-text-truncate gx-task-item-title">
              {patient_name}
            </h5>
            <p key={id} className="gx-text-grey gx-fs-sm gx-mb-0">
              {specialization_name}
            </p>
            <p key={id} className="gx-text-grey gx-fs-sm gx-mb-0">
              {formatDate(date)}
            </p>
          </div>
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={4}>
          <Badge.Ribbon
            text={capitalizeFirstLetter(appointment_status_name)}
            color={color[Number(appointment_status)]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TicketItem;
