import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { Col, Row, Table } from "antd";
import Typography from "antd/lib/typography/Typography";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { ArrowLeftOutlined, PrinterFilled } from "@ant-design/icons";
import "../../../styles/modules/pharmacy.less";
import TableComponent from "../../../components/Table/TableComponent";
import { useHistory } from "react-router";
import { getPharmacyProfileInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";

const ViewItemTransfer = (viewData) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [printingDone, setPrintingDone] = useState(false);
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const ViewItemTransferData = location.state?.itemData;

  const pharmacyProfile = useSelector(
    (state) => state?.pharmacy?.pharmacyProfile?.data?.data
  );
  console.log(
    "ViewItemTransferData?.itemTransferInformations",
    ViewItemTransferData?.itemTransferInformations
  );
  const history = useHistory();
  const goBackHandler = () => {
    history.goBack();
  };
  const ItemTransferList = ViewItemTransferData?.itemTransferInformations;
  const columns = [
    {
      title: " Name",
      dataIndex: ["Tablet", "medicine_name"],
      key: "personName",
    },

    {
      title: "Quantity",
      dataIndex: "quanity",
      key: "quanity",
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batchNo",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
    },
  ];
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);
  useEffect(() => {
    if (pharmacyProfile && !printingDone) {
      if (location.state?.type === "print") {
        window.print();
        setPrintingDone(true);
      }
    }
  }, [pharmacyProfile, printingDone]);
  return (
    <Row justify="center" align="middle" gutter={[0, 16]}>
      <Col xl={22} lg={23} md={23} xs={23} sm={23}>
        <Row justify={"space-between"}>
          <Col>
            <Typography className="header-tag">
              <IntlMessages id={"label.viewItemTransfer"} />
            </Typography>
          </Col>
          <Col>
            {/* <ButtonComponent
              icon={<PrinterFilled />}
              size="large"
              className="secondary-color"
              // onClick={()=>editPatient(record.id)}
            ></ButtonComponent>{" "} */}
            {location.state?.type === "view" && (
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            )}
          </Col>
        </Row>
      </Col>
      <Col xl={20} lg={23} md={23} xs={23} sm={23}>
        <Row justify={"space-between"}>
          <Col xl={12} lg={12} md={12} xs={24} sm={24} className="py-4">
            <Row gutter={[0, 16]}>
              <Col xl={10} lg={10} md={12} xs={12} sm={6}>
                <Typography className="view-list-details py-2">
                  Person Name
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={18}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.person_name}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={6}>
                <Typography className="view-list-details py-2">
                  Description
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={18}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.description}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={6}>
                <Typography className="view-list-details py-2">
                  Item Transfer From
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={18}>
                <Typography className="view-list-details py-2">
                  {pharmacyProfile?.firstName}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24} sm={24} className="py-4">
            <Row gutter={[0, 16]}>
              <Col xl={10} lg={10} md={12} xs={12} sm={6}>
                <Typography className="view-list-details py-2">
                  Contact No
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={18}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.person_contact}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={6}>
                <Typography className="view-list-details py-2">Date</Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={18}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.date}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={6}>
                <Typography className="view-list-details py-2">
                  Item Transfer To
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={18}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.item_transfer_to === 1
                    ? "Hospital"
                    : "Pharmacy"}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xl={22} lg={22} md={22} xs={24} sm={24}>
        <Table
          columns={columns}
          dataSource={ItemTransferList}
          scroll={{ x: true }}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default ViewItemTransfer;
