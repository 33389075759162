import { Row, Col, Typography, Table, Form, Card } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../../../styles/modules/superAdmin.less";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../../assets/images/DocMotez/logo.png";

import moment from "moment";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  PhoneFilled,
} from "@ant-design/icons";

import SalesFooter from "../../Pharmacy/sales/SalesFooter";
import { useDispatch, useSelector } from "react-redux";
import { getGstListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";

const PaymentInvoice = ({ selectedHospital }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [gst, setGst] = useState();
  const hospitalDetails = location.state?.paymentData;
  const hospitalPersonalDetails = location.state?.hospitalData;
  const goBackHandler = () => {
    history.goBack();
  };

  const printRef = useRef();

  const downloadPDF = async () => {
    const input = printRef.current;
    const canvas = await html2canvas(input, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "pt", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("invoice.pdf");
  };
  const currentDate = moment().format("DD/MM/YYYY");
  const currentTime = moment().format("hh:mm A");

  const fullColumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Paid date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Paid Amount",
      dataIndex: "paid",
      key: "paid",
    },
  ];
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Paid date",
      dataIndex: "paid_date",
      key: "paid_date",
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
    },
  ];
  const hospitalList = hospitalDetails?.subscriptionPaymentsDetails;

  const gstData = useSelector((state) => state.pharmacy?.gstList);
  useEffect(() => {
    dispatch(getGstListInProgress());
  }, []);
  useEffect(() => {
    const gst = gstData?.data?.data.find(
      (item) => item.id === hospitalPersonalDetails?.Subscription?.gst
    )?.gst;
    const gstAmount =
      (hospitalPersonalDetails?.Subscription?.pricing * gst?.replace("%", "")) /
      100;
    setGst(gstAmount);
  }, [gstData?.success]);
  return (
    <Card className="mx-20">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row className="print-button-invoice mx-20">
            <Col>
              <ButtonComponent
                type="success"
                icon={<DownloadOutlined />}
                onClick={() => downloadPDF()}
                className={"print-button"}
              ></ButtonComponent>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            </Col>
          </Row>
          <Row ref={printRef} className="mt-20">
            {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}> */}
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row className="invoice-logo">
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <img src={logo} width={150} alt="image" />
                </Col>
                <Col xl={7} lg={6} md={10} sm={10} xs={24}>
                  <Row>
                    <Col
                      xl={16}
                      lg={20}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography className="invoice-header my-10">
                        INVOICE
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-20">
                    <Col xl={6} lg={6} md={8} sm={10} xs={10}>
                      <Typography className="list-header1">DATE</Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col xl={15} lg={13} md={10} sm={10} xs={10}>
                      <Typography className="list-item">
                        {currentDate}
                      </Typography>
                    </Col>
                  </Row>{" "}
                  <Row className="my-20">
                    <Col xl={6} lg={6} md={8} sm={10} xs={10}>
                      <Typography className="list-header1">TIME</Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col xl={15} lg={13} md={10} sm={10} xs={10}>
                      <Typography className="list-item">
                        {currentTime}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              xl={20}
              lg={24}
              md={24}
              xs={24}
              sm={24}
              align="top"
              justify="center"
            >
              <Row
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}
                justify="center"
                gutter={[0, 10]}
              >
                <Col xl={20} lg={20} md={20} xs={24} sm={24} align="center">
                  <Typography align="center" className="view-name">
                    {hospitalPersonalDetails?.Organization?.name}
                  </Typography>
                </Col>
                <Col xl={20} lg={20} md={20} xs={24} sm={24}>
                  <Typography align="center" justify="center">
                    <EnvironmentFilled />
                    {hospitalPersonalDetails?.Organization?.street},
                    {hospitalPersonalDetails?.Organization?.address},
                    {hospitalPersonalDetails?.Organization?.city},
                    {hospitalPersonalDetails?.Organization?.city},
                    {hospitalPersonalDetails?.Organization?.state},
                    {hospitalPersonalDetails?.Organization?.country}
                  </Typography>
                </Col>
                <Col xl={20} lg={20} md={20} xs={24} sm={24}>
                  <Typography align="center">
                    <PhoneFilled />
                    {hospitalPersonalDetails?.Organization?.contact}
                  </Typography>
                </Col>
              </Row>
            </Col>
            {/* </Col> */}
            <Col
              xl={22}
              lg={22}
              md={24}
              sm={24}
              xs={24}
              // display="flex"
              // justify="center"
              className="justify-center"
            >
              <Row className="mt-20 justify-center">
                <Col xl={12} lg={12} md={24} sm={24} xs={24} className="ml-5">
                  <Row>
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">
                        Hospital Name
                      </Typography>
                    </Col>
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {hospitalPersonalDetails?.Organization?.name}
                      </Typography>
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">Email</Typography>
                    </Col>{" "}
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {hospitalPersonalDetails?.Organization?.email}
                      </Typography>
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">
                        Alternative Mobile{" "}
                      </Typography>
                    </Col>{" "}
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {
                          hospitalPersonalDetails?.Organization
                            ?.alternate_contact
                        }
                      </Typography>
                    </Col>{" "}
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">
                        License Number
                      </Typography>
                    </Col>{" "}
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {hospitalPersonalDetails?.Organization?.license_no}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xl={10} lg={12} md={24} sm={24} xs={24}>
                  <Row>
                    {/* <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">
                        License Number
                      </Typography>
                    </Col>
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {hospitalPersonalDetails?.Organization?.license_no}
                      </Typography>
                    </Col> */}
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">
                        currency Code
                      </Typography>
                    </Col>
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {" "}
                        {hospitalPersonalDetails?.Organization?.currency_code}
                      </Typography>
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">
                        Plan Name
                      </Typography>
                    </Col>{" "}
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {hospitalPersonalDetails?.Subscription?.name}
                      </Typography>
                    </Col>{" "}
                    <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                      <Typography className="list-header1">Period</Typography>
                    </Col>{" "}
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={0.5}
                      xs={0.5}
                      className="my-10"
                    >
                      <Typography className="list-header1">:</Typography>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="my-10"
                    >
                      <Typography className="list-item">
                        {hospitalPersonalDetails?.Subscription?.periods}
                      </Typography>
                    </Col>{" "}
                  </Row>
                </Col>
              </Row>
            </Col>{" "}
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="mt-30 mb-10"
            >
              <Typography className="list-header1">Details:</Typography>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Table
                columns={
                  location.state?.completeInvoice === "true"
                    ? fullColumn
                    : columns
                }
                dataSource={
                  location.state?.completeInvoice === "true"
                    ? hospitalPersonalDetails?.subscriptionPayments
                    : hospitalList || []
                }
                pagination={false}
                className="responsive-table"
              />
            </Col>
            <Col xl={24}>
              <Row className="invoice-footer">
                {/* <Col xl={18} style={{ border: "2px solid black" }}>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xl={10} style={{ border: "2px solid black" }}>
                      jjjjjjjjj
                    </Col>
                  </Row>
                </Col> */}
                <Col xl={10}>
                  <SalesFooter
                    // subtotal={hospitalDetails?.subscriptionPayments[0]?.paid}
                    total={hospitalPersonalDetails?.Subscription?.toatl}
                    gstAmount={gst}
                    receivedAmount={
                      location.state?.completeInvoice === "true"
                        ? 0
                        : hospitalDetails?.paid
                    }
                    Due={hospitalPersonalDetails?.pendingAmount}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="mt-20"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography className="list-header1">
                If you have any doubt in this Invoice , please contact
              </Typography>{" "}
            </Col>{" "}
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="mt-20"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography>Name</Typography>{" "}
            </Col>{" "}
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="mt-20"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography className="list-header1">THANK YOU !</Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentInvoice;
