import axios from "axios";

const rolesInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const addRolesInstanceListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return rolesInstance.request({
    url: `/accessRoles/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getRolesInstanceListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return rolesInstance.request({
    url: `/accessRoles/list?pagination_required=${formData?.pagination_required}&pageNo=${formData?.pageNo}&pagesize=${formData?.pagesize}`,
    method: "GET",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteRolesInstanceListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return rolesInstance.request({
    url: `/accessRoles/delete/${formData.id}`,
    method: "DELETE",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateRolesInstanceListEffect = (formData) => {
  console.log("formData", formData);
  const token = localStorage.getItem("user_id");
  return rolesInstance.request({
    url: `/accessRoles/update/${formData[0].id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
