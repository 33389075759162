import axios from "axios";

const designationInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});
const DesignationEffect = (page) => {
  return designationInstance.request({
    url: "/designations/list",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      pageNo: page,
      pageSize: 10,
      pagination_required: true,
    },
  });
};

const AddDesignationEffect = (formData, token) => {
  return designationInstance.request({
    url: "/designations/add",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const DeleteDesignationEffect = (formData, token) => {
  return designationInstance.request({
    url: `/designations/delete/${formData}`,
    method: "DELETE",

    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const GetDataDesignationEffect = (formData, token) => {
  return designationInstance.request({
    url: `/designations/details/${formData}`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const UpdateDesignationEffect = ({ id, formData }, token) => {
  console.log("ifffffffff", id, formData, token);
  return designationInstance.request({
    url: `/designations/update/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export {
  DesignationEffect,
  AddDesignationEffect,
  DeleteDesignationEffect,
  GetDataDesignationEffect,
  UpdateDesignationEffect,
};
