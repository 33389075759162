import React, { useEffect } from "react";
import { Button, Form, Input, Col, Row } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { message } from "antd/lib/index";

import {
  ForgetPasswordInProgress,
  hideAuthLoader,
  showAuthLoader,
} from "../appRedux/doctor/actions/Auth";
import CircularProgress from "../components/CircularProgress";
import IntlMessages from "../util/IntlMessages";

const FormItem = Form.Item;

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let slug = useParams();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const updatePasswordData = useSelector((state) => state.register);
  const updatePasswordSuccess = updatePasswordData?.forgetpassword?.success;
  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    console.log("valyues", values);
    let formData = new URLSearchParams();

    formData.append("email", slug.email);
    formData.append("password", values.password);
    dispatch(ForgetPasswordInProgress(formData));
  };
  useEffect(() => {
    dispatch(hideAuthLoader());
  }, []);
  useEffect(() => {
    if (updatePasswordSuccess === true) {
      history.push("/signin");
    }
  }, [updatePasswordSuccess]);
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.updatepassword" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.byupdatepassword" />
              </p>
              {/* <p>
                <IntlMessages id="app.userAuth.newpassword" />
              </p> */}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem>
                    <Button
                      type="primary"
                      className="gx-mb-4"
                      htmlType="submit"
                    >
                      change password
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
