import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../util/utilz";

let consultationInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const AddConsultationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return consultationInstance.request({
    url: "/consultantFee/add",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const UpdateConsultationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return consultationInstance.request({
    url: `/consultantFee/update/${formData?.consultationId}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const GetConsultationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return consultationInstance.request({
    url: `/consultantFee/details?doctor_id=${formData}`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
