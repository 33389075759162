import React from "react";
import { useEffect } from "react";
import Auxiliary from "../../util/Auxiliary";
import PatientDashboardScreen from "./PatientDashboard";
import DoctorDashboardScreen from "./DoctorDashboard";
import AdminDashboardScreen from "../Admin/AdminDashboardScreen";
import PharmacyDashboardScreen from "./PharmacyDashboard";
import SuperAdminDashboard from "./SuperAdminDashnoard";

const DashboardScreen = () => {
  const roleId = localStorage.getItem("role_id");

  return (
    <Auxiliary>
      {roleId === "3" ? (
        <PatientDashboardScreen />
      ) : roleId === "2" ? (
        <DoctorDashboardScreen />
      ) : roleId === "1" ? (
        <AdminDashboardScreen />
      ) : roleId === "4" ? (
        <PharmacyDashboardScreen />
      ) : (
        <SuperAdminDashboard />
      )}
    </Auxiliary>
  );
};

export default DashboardScreen;
