import { Button, Modal, Typography } from "antd";
import React from "react";
import TableComponent from "../../../../components/Table/TableComponent";

function PrescriptionModel({
  prescriptionsModal,
  paymentModalOk,
  prescription_details,
}) {
  const columns = [
    {
      title: "Tablet Name",
      dataIndex: "medicine_name",
    },
    {
      title: "Medicine Type",
      dataIndex: "medicine_type",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (data) => (data === "BF" ? "BEFORE FOOD" : "AFTER FOOD"),
    },
    {
      title: "Dosage",
      dataIndex: "dosage",
    },
    {
      title: "Morning",
      dataIndex: "morning",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "Lunch",
      dataIndex: "lunch",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "Evening",
      dataIndex: "evening",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "Night",
      dataIndex: "night",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "NO OF DAY",
      dataIndex: "days",
      // render: () => 5,
    },
  ];
  console.log("prescription_details", prescription_details);
  return (
    <div>
      {" "}
      <Modal
        title={
          <Typography className="cancel-sales">View Prescriptions</Typography>
        }
        open={prescriptionsModal}
        width={800}
        closable={false}
        footer={[
          <Button key="back" type="danger" onClick={paymentModalOk}>
            Close
          </Button>,
        ]}
      >
        <TableComponent
          columns={columns}
          dataSource={prescription_details}
          disableTablePageRow
          pagination={false}
        />
      </Modal>
    </div>
  );
}

export default PrescriptionModel;
