import React, { useRef, useState } from "react";
import Auxiliary from "../../util/Auxiliary";
import AddConsultationForm from "./components/AddConsultationForm";
import ConsultationHeader from "./components/ConsultationHeader";

const ConsultationScreen = () => {
  return (
    <Auxiliary>
      <ConsultationHeader />
      <AddConsultationForm />
    </Auxiliary>
  );
};

export default ConsultationScreen;
