import React from "react";

import { Card } from "antd";
import ProfileHeader from "./Components/ProfileHeader";
import ProfileForm from "./Components/ProfileForm";

const ProfileScreen = () => {
  return (
    <Card>
      <ProfileHeader />
      <ProfileForm />
    </Card>
  );
};

export default ProfileScreen;
