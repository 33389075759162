import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row, Table } from "antd";
import "../../../styles/modules/billingPackages.less";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { DeleteFilled } from "@ant-design/icons";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/Routes";
import Auxiliary from "../../../util/Auxiliary";
import { getSubPackagesListInProgress } from "../../../appRedux/doctor/actions/billingPackage";
import { useDispatch, useSelector } from "react-redux";

const makeRow = (counter) => ({
  key: counter,
  package_name: counter,
  quantity: counter,
  price: counter,
  total_amount: counter,
});

const BillingPackageForm = ({ onFinish, form, editBillingData }) => {
  const [input, setInput] = useState([makeRow(0)]);
  const [counter, setCounter] = useState(1);
  const [amount, setAmount] = useState(0);
  const [key, setKey] = useState();
  const [totalKey, setTotalKey] = useState();
  const [formData, setFormData] = useState();
  const [selectedPackages, setSelectedPackages] = useState([]);

  const dispatch = useDispatch();
  const values = form.getFieldsValue();
  const formRef = useRef(null);
  const organization_id = localStorage.getItem("org_id");
  console.log("editBillingData", editBillingData);

  useEffect(() => {
    dispatch(getSubPackagesListInProgress({ organization_id }));
  }, []);

  const { billingPackages } = useSelector((state) => state);
  const subPackageList = billingPackages?.subPackagesList?.data?.data || [];

  useEffect(() => {
    if (editBillingData) {
      setFormData(editBillingData);
      // Initialize selectedPackages with existing values
      const initialSelectedPackages = editBillingData.subPackages.map(
        (pkg) => pkg.package_name
      );
      setSelectedPackages(initialSelectedPackages);
    }
  }, [editBillingData]);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [form, formData]);

  useEffect(() => {
    if (
      editBillingData &&
      input.length !== editBillingData.subPackages.length
    )
    {
      const diffLength = editBillingData.subPackages.length - input.length;
      if (diffLength > 0) {
        const newInputs = Array.from({ length: diffLength }, (_, index) =>
          makeRow(input.length + index)
        );
        setInput((prevInput) => [...prevInput, ...newInputs]);
        setCounter((prevCounter) => prevCounter + diffLength);
      }
    }
  }, [editBillingData]);

  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };

  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);
    setInput(updatedInput);
    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.subPackages[key] = null;
      setFormData(formDataCopy);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.subPackages[key] = null;
      setFormData(formDataCopy);
    }

    const updatedSelectedPackages = selectedPackages.filter(
      (_, idx) => idx !== key
    );
    setSelectedPackages(updatedSelectedPackages);
  };

  useEffect(() => {
    if (values?.subPackages || Number(totalKey) >= 0) {
      if (Array.isArray(values?.subPackages)) {
        let sum = 0;
        values?.subPackages?.forEach((subPackage) => {
          sum += Number(
            subPackage?.total_amount ? subPackage?.total_amount : 0
          );
        });
        setAmount(sum);
        form.setFieldValue("total_amount", sum);
      } else {
        const subPackagesArray = Object.keys(values?.subPackages)?.map(
          (key) => ({
            ...values.subPackages[key],
          })
        );

        let sum = 0;
        subPackagesArray?.forEach((subPackage) => {
          sum += Number(
            subPackage?.total_amount ? subPackage?.total_amount : 0
          );
        });
        setAmount(sum);
        form.setFieldValue("total_amount", sum);
      }
      setTotalKey();
    }
  }, [values, totalKey]);

  const handleSelectChange = (option, render) => {
    setKey(render);
    const selectedPackage = subPackageList.find(
      (item) => item.id === option.value
    );

    if (selectedPackage) {
      const { quantity, price } = selectedPackage;
      form.setFieldValue([`subPackages`, render, "quantity"], quantity);
      form.setFieldValue([`subPackages`, render, "price"], price);
    } else {
      console.log("Package not found in subPackageList");
    }

    const updatedSelectedPackages = [...selectedPackages];
    updatedSelectedPackages[render] = option.value;
    setSelectedPackages(updatedSelectedPackages);
  };

  useEffect(() => {
    if (Number(key) >= 0) {
      const quantity = form.getFieldValue([`subPackages`, key, "quantity"]);
      const price = form.getFieldValue([`subPackages`, key, "price"]);
      const totalAmount = Number(quantity) * Number(price);
      form.setFieldValue([`subPackages`, key, "total_amount"], totalAmount);
      setKey();
    }
  }, [key, handleSelectChange]);

  const validateUniquePackage = (_, value, callback) => {
    const duplicate = selectedPackages.filter((pkg) => pkg === value).length > 1;
    if (duplicate) {
      callback(new Error("This package has already been selected"));
    } else {
      callback();
    }
  };

  const columns = [
    { 
      title: "Category",
      dataIndex: "package_name",
      render: (_, render) => (
        <Form.Item
          name={[`${render.key}`, "package_name"]}
          rules={[
            {
              required: true,
              message: (
                <IntlMessages id={"Addnew.billingpackage.validator.list"} />
              ),
            },
            { validator: validateUniquePackage },
          ]}
        >
          <FormSelectBox
            options={subPackageList?.map((pack) => ({
              label: pack.package_name,
              value: pack.id,
              key: pack.id,
            }))}
            placeholder="Select"
            className="sel"
            onChange={(value, option) =>
              handleSelectChange(option, render.key)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            name={[`${render.key}`, "quantity"]}
            onChange={() => setKey(render.key)}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages
                    id={"Addnew.billingpackage.validator.quantity"}
                  />
                ),
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            name={[`${render.key}`, "price"]}
            onChange={() => setKey(render.key)}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"Addnew.billingpackage.validator.price"} />
                ),
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Total",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            name={[`${render.key}`, "total_amount"]}
            type="number"
            onChange={() => setTotalKey(render.key)}
            className="input len"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Addnew.total"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record) => (
        <ButtonComponent
          icon={<DeleteFilled />}
          className="btn-edits"
          type="danger"
          onClick={() => deleteRow(record.key)}
        />
      ),
    },
  ];

  return (
    <Auxiliary>
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        ref={formRef}
      >
        <Row>
          <Col xs={24}>
            <FormInput
              type="text"
              name="package_name"
              label={
                <IntlMessages id={"Addnew.billingpackage.label.packagename"} />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id={"Addnew.billingpackage.validator.packagename"}
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>

        <Form.List name={"subPackages"}>
          {(fields) => (
            <>
              <Row>
                <Col xs={24}>
                  <ButtonComponent
                    className="b"
                    type="primary"
                    label="New"
                    onClick={addRow}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <p className="txt">
                    {<IntlMessages id={"Addnew.select"} />}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Table
                    showHeader={true}
                    columns={columns}
                    dataSource={input}
                    pagination={false}
                  />
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Row
          className="bb"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Col md={3}>
            <h3>{<IntlMessages id={"Addnew.total"} />}</h3>
          </Col>
          <Col md={6}>
            <FormInput
              name={"total_amount"}
              className="input"
              type="number"
              value={amount}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"Addnew.total"} />,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="btn-space">
          <Col className="btn-pos">
            <Link to={APP_ROUTES.BILLINGPACKAGES} className="btn-gap">
              <ButtonComponent
                htmlType="reset"
                label="Cancel"
                className="secondary-color"
              />
            </Link>
            <ButtonComponent
              type="primary"
              htmlType="Submit"
              label="Submit"
            />
          </Col>
        </Row>
      </Form>
    </Auxiliary>
  );
};

export default BillingPackageForm;
