import React, { useEffect, useState } from "react";
import Auxiliary from "../../util/Auxiliary";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Progress,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import IntlMessages from "../../util/IntlMessages";
import FormInput from "../../components/Input/FormInput";
import {
  CloseOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { upperCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import SelectBoxAppointment from "./SelectBox";
import {
  getDiseaseListInProgress,
  getQuestionAnswerByDiseasesListInProgress,
} from "../../appRedux/doctor/actions/appointmentQuestions";
import TextArea from "antd/lib/input/TextArea";
import { APP_ROUTES } from "../../constants/Routes";
import { useHistory } from "react-router-dom";

function AppointmentAnswerModel() {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [value, setValue] = useState([]);
  const history = useHistory();

  const dispatch = useDispatch();
  const [addDiseaseModal, setAddDiseaseModal] = useState(false);
  const [diseaseName, setDiseaseName] = useState("");
  const [diseaseLabel, setDiseaseLabel] = useState("");
  const [select, setSelect] = useState(0);
  // const [question, setQuestion] = useState([]);
  const { getListDiseases, getListQuestionAnswerByDiseases } = useSelector(
    (state) => state?.appointmentQuestions
  );
  const question = getListQuestionAnswerByDiseases?.data?.data || [];
  const onFinish = (data) => {
    form
      .validateFields()
      .then(() => {
        //
        console.log("data", data);
        let updatedQuestions;
        if (data?.qu) {
          updatedQuestions = data?.qu?.map((question, i) => {
            const setValues = value.find((val) => val.i === i);
            return {
              ...question,
              answer: setValues?.value || setValues?.values,
            };
          });
          const showError = updatedQuestions
            .map((val) => (val?.answer ? null : val?.question))
            .filter((val) => val !== null);
        }

        history.push({
          pathname: APP_ROUTES.ADDNEWAPPOINMENT,
          state: {
            diseaseName: diseaseLabel,
            qusetion_answer: updatedQuestions,
            disease_id: data?.diseases,
          },
        });

        // if (showError.length === 0) {
        //   history.push({
        //     pathname: APP_ROUTES.ADDNEWAPPOINMENT,
        //     state: {
        //       qusetion_answer: updatedQuestions,
        //       disease_id: data?.diseases,
        //     },
        //   });
        // } else {
        //   showError.map((val) => warning(val));
        // }
      })

      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  const warning = (val) => {
    messageApi.open({
      type: "warning",
      content: `Can you fill this - ${val} ?`,
    });
  };

  useEffect(() => {
    if (question) {
      form.resetFields(["qu"]);
      question.map((answer, index) => {
        form.setFieldValue([`qu`, index, "question"], answer.questions);
        form.setFieldValue([`qu`, index, "answer_type"], answer.answers_type);
        // form.setFieldValue([`qu`, index, "answers"], answer.answers);
        // setError([...error, { key: index, value: "" }]);
      });
    }
  }, [question, getListQuestionAnswerByDiseases?.data?.data]);
  useEffect(() => {
    if (diseaseName) {
      dispatch(getQuestionAnswerByDiseasesListInProgress({ id: diseaseName }));
    }
  }, [diseaseName]);
  useEffect(() => {
    const payLoad = {
      pagination: false,
      page: 1,
    };
    dispatch(getDiseaseListInProgress(payLoad));
  }, []);
  const handleAnswerType = (e) => {
    if (e) {
      setDiseaseLabel(e.label);
      setAddDiseaseModal(true);
      setDiseaseName(e.value);
    }
  };
  const formValue = { qu: question };

  const onChange = (values, i, checked, Checkbox) => {
    if (Checkbox) {
      setValue((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex((item) => item.i === i);
        if (checked) {
          if (index !== -1) {
            // If the question ID exists, update the values array
            newState[index].values.push(values);
          } else {
            // If the question ID doesn't exist, add a new entry
            newState.push({ i, values: [values] });
          }
        } else {
          if (index !== -1) {
            // If the question ID exists and the checkbox is unchecked, remove the value
            newState[index].values = newState[index].values.filter(
              (val) => val !== values
            );
            // If there are no values left for the question, remove the entry
            if (newState[index].values.length === 0) {
              newState.splice(index, 1);
            }
          }
        }
        return newState;
      });
    } else {
      if (checked) {
        const existingIndex = value.findIndex((val) => val.i === i);
        if (existingIndex !== -1) {
          value.splice(existingIndex, 1);
        }
        setValue([...value, { value: values, i }]);
      } else {
        setValue(value.filter((val) => val.i !== i));
      }
    }
  };
  const handleNext = (next) => {
    if (next) {
      setSelect(select + 1);
    } else {
      setSelect(select - 1);
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  console.log((value.length / question.length) * 100);
  const percentage = (value.length / question.length) * 100;
  return (
    <Auxiliary>
      {contextHolder}
      <Spin
        indicator={antIcon}
        spinning={
          getListQuestionAnswerByDiseases?.progressing ||
          getListDiseases?.progressing
        }
      >
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col xs={24}>
              <SelectBoxAppointment
                margin="mb-10"
                label={<IntlMessages id={"You are affected by"} />}
                name={"diseases"}
                placeholder={"Select the type of disease"}
                editMode={true}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select the type of disease",
                //   },
                // ]}
                options={
                  getListDiseases?.data?.data?.map((val) => {
                    return { label: val.name, value: val.id };
                  }) || []
                }
                onChange={handleAnswerType}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col xs={20}>
              {addDiseaseModal && (
                <Form.List name="qu">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          {select === key && (
                            <>
                              <Col xs={0}>
                                <FormInput
                                  type="text"
                                  name={[name, "question"]}
                                  placeholder={"Enter the answer"}
                                  readOnly={true}
                                  hidden
                                />
                              </Col>

                              <Col xs={24}>
                                <Form.Item
                                  {...restField}
                                  label={`${key + 1}. ${
                                    formValue?.qu[key]?.questions
                                  }`}
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select an answer",
                                    },
                                  ]}
                                >
                                  <Row>
                                    {formValue?.qu[key]?.answers_type === 1 ? (
                                      <>
                                        {question[key]?.answers.map(
                                          (row, index) => (
                                            <Col xs={8} key={index}>
                                              <Radio.Group
                                                onChange={(e) =>
                                                  onChange(
                                                    e?.target?.value,
                                                    key,
                                                    true
                                                  )
                                                }
                                                value={
                                                  value?.find(
                                                    (val) => val?.i === key
                                                  )?.value
                                                }
                                                name={[name, "answer"]}
                                              >
                                                <Space direction="vertical">
                                                  <Radio value={row.answer}>
                                                    {row.answer}
                                                  </Radio>
                                                </Space>
                                              </Radio.Group>
                                            </Col>
                                          )
                                        )}
                                      </>
                                    ) : formValue?.qu[key]?.answers_type ===
                                      5 ? (
                                      <>
                                        {question[key]?.answers.map(
                                          (row, index) => (
                                            <Col xs={8} key={index}>
                                              {console.log(
                                                value?.find(
                                                  (val) => val?.i === key
                                                )?.values
                                              )}
                                              <Checkbox
                                                value={row.answer}
                                                name={[name, "answer"]}
                                                onChange={(e) => {
                                                  onChange(
                                                    e?.target?.value,
                                                    key,
                                                    e?.target?.checked,
                                                    true
                                                  );
                                                }}
                                                checked={
                                                  value?.find(
                                                    (val) => val?.i === key
                                                  )?.values
                                                    ? value
                                                        ?.find(
                                                          (val) =>
                                                            val?.i === key
                                                        )
                                                        ?.values.filter(
                                                          (val) =>
                                                            val === row.answer
                                                        ).length !== 0
                                                      ? true
                                                      : false
                                                    : false
                                                }
                                              >
                                                {row.answer}
                                              </Checkbox>
                                            </Col>
                                          )
                                        )}
                                      </>
                                    ) : formValue?.qu[key]?.answers_type ===
                                      3 ? (
                                      <Col xs={24}>
                                        <Select
                                          name={[name, "answer"]}
                                          onChange={(e) =>
                                            onChange(e, key, true)
                                          }
                                          placeholder={"Select your options"}
                                          value={
                                            value?.find((val) => val?.i === key)
                                              ?.value
                                          }
                                        >
                                          {question[key]?.answers.map(
                                            (val, index) => (
                                              <Select.Option
                                                key={index}
                                                value={val.answer}
                                              >
                                                {val.answer}
                                              </Select.Option>
                                            )
                                          )}
                                        </Select>
                                      </Col>
                                    ) : formValue?.qu[key]?.answers_type ===
                                      4 ? (
                                      <Col xs={24}>
                                        <TextArea
                                          onChange={(e) =>
                                            onChange(
                                              e?.currentTarget?.value,
                                              key,
                                              true
                                            )
                                          }
                                          name={[name, "answer"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please fill this field",
                                            },
                                          ]}
                                        />
                                      </Col>
                                    ) : (
                                      <Col xs={24}>
                                        <FormInput
                                          labelCol={"24"}
                                          wrapperCol={"24"}
                                          type="text"
                                          name={[name, "answer"]}
                                          placeholder={"Enter the answer"}
                                          onChange={(e) =>
                                            onChange(
                                              e?.target?.value,
                                              key,
                                              true
                                            )
                                          }
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </Form.List>
              )}
            </Col>
            <Col xs={4}>
              <Tooltip
                title={` you answered ${value.length} of ${question.length} question `}
              >
                {console.log(
                  Number(((value.length / question.length) * 100).toFixed(0))
                )}
                <Progress
                  trailColor={"#fff"}
                  percent={
                    Number(((value.length / question.length) * 100).toFixed(0))
                      ? Number(
                          ((value.length / question.length) * 100).toFixed(0)
                        )
                      : 0
                  }
                  success={{
                    percent: Number(
                      ((value.length / question.length) * 100).toFixed(0)
                    )
                      ? Number(
                          ((value.length / question.length) * 100).toFixed(0)
                        )
                      : 0,
                    strokeColor: "green",
                  }}
                  type="circle"
                />
              </Tooltip>
            </Col>
          </Row>
          <Form.Item>
            {select !== 0 && (
              <Button
                type="primary"
                onClick={() => {
                  handleNext();
                }}
              >
                previous{" "}
              </Button>
            )}

            {question.length === select || question.length === 0 ? (
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  handleNext(true);
                }}
              >
                Next
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Auxiliary>
  );
}

export default AppointmentAnswerModel;
