import { Row, Col, Card, Typography, Avatar, Image } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../../../styles/modules/patient.less";
import { APP_ROUTES } from "../../../constants/Routes";
import { formatDate } from "../../../util/utilz";
const ViewPatient = ({ PurchaseType, data, columns, viewTable }) => {
  const history = useHistory();
  const location = useLocation();
  const patientData = location.state?.patientData;
  console.log("patientData", patientData);
  const goBackHandler = () => {
    history.goBack();
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Card>
      <Row justify="center" align="middle">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row className="margin-row" justify={"space-between"}>
            <Col xl={23} lg={22} md={22} sm={20} xs={20}>
              <Row justify={"space-between"} gutter={[0, 16]}>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <Typography className="view-header">
                    Patient ID:{patientData?.patient_serial_no}
                  </Typography>
                </Col>
                <Col
                  xl={12}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="view-button"
                >
                  <Link
                    to={{
                      pathname: "/patient1/view/healthRecord",
                      state: patientData?.id,
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      label="View Health Records"
                      size="medium"
                    />
                  </Link>
                  <Link
                    to={{
                      pathname: "/patient1/view/appointment",
                      state: patientData?.id,
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      label="View Appointments"
                      size="medium"
                      className="ml-10"
                    />
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xl={1} lg={2} md={2} sm={4} xs={4}>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              />
            </Col>
          </Row>

          <Row>
            <Col
              xl={20}
              lg={20}
              md={18}
              sm={20}
              xs={24}
              className="viewdata-xs"
              justify="center"
              align="center"
            >
              <Avatar
                size={80}
                icon={<UserOutlined />}
                src={patientData?.profile_image}
                shape="circle"
              />
            </Col>
            <Col xl={24} lg={24} md={20} sm={20} xs={20} justify="center">
              <Row>
                <Col xl={10} lg={10} md={10} sm={12} xs={24}>
                  <Row className="viewdata-xs" gutter={[0, 16]}>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        First Name
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.firstName}
                      </Typography>
                    </Col>
                  </Row>{" "}
                  <Row className="viewdata-xs" gutter={[0, 16]}>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        Last Name
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.lastName}
                      </Typography>
                    </Col>
                  </Row>{" "}
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        Blood Group
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.blood_group}
                      </Typography>
                    </Col>
                  </Row>{" "}
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        Gender
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.gender_type === "M" ? "Male" : "Female"}
                      </Typography>
                    </Col>
                  </Row>{" "}
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        DOB
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {formatDate(patientData?.dob)}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xl={10} lg={10} md={10} sm={12} xs={24}>
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        Address
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.address1}
                      </Typography>
                    </Col>
                  </Row>{" "}
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        Contact Number
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.contact}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        City
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.city}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        State
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.state}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="viewdata-xs">
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography
                        className="view-patient-details-label"
                        align="right"
                      >
                        Country
                      </Typography>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography className="view-patient-details">
                        :
                      </Typography>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                      <Typography className="view-patient-details">
                        {patientData?.country_code}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ViewPatient;
