import {
  ADD_HOSPITAL_ERROR,
  ADD_HOSPITAL_IN_PROGRESS,
  ADD_HOSPITAL_SUCCESS,
  ADD_ORG_TIME_ERROR,
  ADD_ORG_TIME_PROGRESS,
  ADD_ORG_TIME_SUCCESS,
  ADD_PACKAGE_ERROR,
  ADD_PACKAGE_IN_PROGRESS,
  ADD_PACKAGE_SUCCESS,
  ADD_PLAN_ERROR,
  ADD_PLAN_IN_PROGRESS,
  ADD_PLAN_SUCCESS,
  ADD_SA_ORG_ERROR,
  ADD_SA_ORG_IN_PROGRESS,
  ADD_SA_ORG__SUCCESS,
  ADD_SUBPACKAGE_IN_PROGRESS,
  ADD_SUBSCRIPTION_DETAILS_ERROR,
  ADD_SUBSCRIPTION_DETAILS_INPROGRESS,
  ADD_SUBSCRIPTION_DETAILS_SUCCESS,
  ADD_SUBSCRIPTION_ERROR,
  ADD_SUBSCRIPTION_IN_PROGRESS,
  ADD_SUBSCRIPTION_PAYMENT_ERROR,
  ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS,
  ADD_SUBSCRIPTION_PAYMENT_SUCCESS,
  ADD_SUBSCRIPTION_SUCCESS,
  DEFAULT_ORGANIZATION,
  DEFAULT_SUBSCRIPTION,
  DEFAULT_SUBSCRIPTION_PAYMENT,
  DELETE_HOSPITAL_ERROR,
  DELETE_HOSPITAL_IN_PROGRESS,
  DELETE_HOSPITAL_SUCCESS,
  DELETE_PACKAGE_SA_ERROR,
  DELETE_PACKAGE_SA_IN_PROGRESS,
  DELETE_PACKAGE_SA__SUCCESS,
  DELETE_PLAN_ERROR,
  DELETE_PLAN_IN_PROGRESS,
  DELETE_PLAN_SUCCESS,
  DELETE_SA_ORG_ERROR,
  DELETE_SA_ORG_IN_PROGRESS,
  DELETE_SA_ORG__SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION_IN_PROGRESS,
  DELETE_SUBSCRIPTION_SUCCESS,
  EDIT_HOSPITAL_ERROR,
  EDIT_HOSPITAL_IN_PROGRESS,
  EDIT_HOSPITAL_SUCCESS,
  EDIT_PACKAGE_ERROR,
  EDIT_PACKAGE_IN_PROGRESS,
  EDIT_PACKAGE_SUCCESS,
  EDIT_PLAN_ERROR,
  EDIT_PLAN_IN_PROGRESS,
  EDIT_PLAN_SUCCESS,
  EDIT_SA_ORG_ERROR,
  EDIT_SA_ORG_IN_PROGRESS,
  EDIT_SA_ORG__SUCCESS,
  EDIT_SUBSCRIPTION_ERROR,
  EDIT_SUBSCRIPTION_IN_PROGRESS,
  EDIT_SUBSCRIPTION_SUCCESS,
  GET_HOSPITAL_LIST_ERROR,
  GET_HOSPITAL_LIST_IN_PROGRESS,
  GET_HOSPITAL_LIST_SUCCESS,
  GET_MODULE_PERMISSION_ERROR,
  GET_MODULE_PERMISSION_IN_PROGRESS,
  GET_MODULE_PERMISSION_SUCCESS,
  GET_PACKAGES_ERROR,
  GET_PACKAGES_IN_PROGRESS,
  GET_PACKAGES_SUCCESS,
  GET_PLAN_LIST_ERROR,
  GET_PLAN_LIST_IN_PROGRESS,
  GET_PLAN_LIST_SUCCESS,
  GET_SA_ORG__LIST_ERROR,
  GET_SA_ORG__LIST_IN_PROGRESS,
  GET_SA_ORG__LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_ERROR,
  GET_SUBSCRIPTION_LIST_IN_PROGRESS,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUPER_ADMIN_DASHBOARD_ERROR,
  GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS,
  GET_SUPER_ADMIN_DASHBOARD_SUCCESS,
} from "../../constants/ActionTypes";

// GET PATIENT APPOINMENT LIST
export const getSuperAdminDashboardInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS,
  };
};
export const getSuperAdminDashboardSuccess = (payload) => {
  return {
    type: GET_SUPER_ADMIN_DASHBOARD_SUCCESS,
    payload: payload,
  };
};
export const getSuperAdminDashboardError = (payload) => {
  return {
    type: GET_SUPER_ADMIN_DASHBOARD_ERROR,
    payload: payload,
  };
};
export const getHospitalListInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: GET_HOSPITAL_LIST_IN_PROGRESS,
  };
};
export const getHospitalListSuccess = (payload) => {
  return {
    type: GET_HOSPITAL_LIST_SUCCESS,
    payload: payload,
  };
};
export const getHospitalListError = (payload) => {
  return {
    type: GET_HOSPITAL_LIST_ERROR,
    payload: payload,
  };
};
export const addHospitalInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: ADD_HOSPITAL_IN_PROGRESS,
  };
};
export const addHospitalSuccess = (payload) => {
  return {
    type: ADD_HOSPITAL_SUCCESS,
    payload: payload,
  };
};
export const addHospitalError = (payload) => {
  return {
    type: ADD_HOSPITAL_ERROR,
    payload: payload,
  };
};
export const editHospitalInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: EDIT_HOSPITAL_IN_PROGRESS,
  };
};
export const editHospitalSuccess = (payload) => {
  return {
    type: EDIT_HOSPITAL_SUCCESS,
    payload: payload,
  };
};
export const editHospitalError = (payload) => {
  return {
    type: EDIT_HOSPITAL_ERROR,
    payload: payload,
  };
};
export const deleteHospitalInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: DELETE_HOSPITAL_IN_PROGRESS,
  };
};
export const deleteHospitalSuccess = (payload) => {
  return {
    type: DELETE_HOSPITAL_SUCCESS,
    payload: payload,
  };
};
export const deleteHospitalError = (payload) => {
  return {
    type: DELETE_HOSPITAL_ERROR,
    payload: payload,
  };
};

export const getPackageListInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: GET_PACKAGES_IN_PROGRESS,
  };
};
export const getPackageListSuccess = (payload) => {
  return {
    type: GET_PACKAGES_SUCCESS,
    payload: payload,
  };
};
export const getPackageListError = (payload) => {
  return {
    type: GET_PACKAGES_ERROR,
    payload: payload,
  };
};
export const addPackageInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: ADD_PACKAGE_IN_PROGRESS,
  };
};
export const addPackageSuccess = (payload) => {
  return {
    type: ADD_PACKAGE_SUCCESS,
    payload: payload,
  };
};
export const addPackageError = (payload) => {
  return {
    type: ADD_PACKAGE_ERROR,
    payload: payload,
  };
};
export const editPackageInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: EDIT_PACKAGE_IN_PROGRESS,
  };
};
export const editPackageSuccess = (payload) => {
  return {
    type: EDIT_PACKAGE_SUCCESS,
    payload: payload,
  };
};
export const editPackageError = (payload) => {
  return {
    type: EDIT_PACKAGE_ERROR,
    payload: payload,
  };
};
export const deletePackageInProgress = () => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: DELETE_PACKAGE_SA_IN_PROGRESS,
  };
};
export const deletePackageSuccess = (payload) => {
  return {
    type: DELETE_PACKAGE_SA__SUCCESS,
    payload: payload,
  };
};
export const deletePackageError = (payload) => {
  return {
    type: DELETE_PACKAGE_SA_ERROR,
    payload: payload,
  };
};

export const getsubscriptionListInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: GET_SUBSCRIPTION_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getsubscriptionListSuccess = (payload) => {
  return {
    type: GET_SUBSCRIPTION_LIST_SUCCESS,
    payload: payload,
  };
};
export const getsubscriptionListError = (payload) => {
  return {
    type: GET_SUBSCRIPTION_LIST_ERROR,
    payload: payload,
  };
};
export const addSubscriptionInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: ADD_SUBSCRIPTION_IN_PROGRESS,
    payload: payload,
  };
};
export const addSubscriptionSuccess = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
};
export const addSubscriptionError = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_ERROR,
    payload: payload,
  };
};
export const editSubscriptionInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress", payload);
  return {
    type: EDIT_SUBSCRIPTION_IN_PROGRESS,
    payload: payload,
  };
};
export const editSubscriptionSuccess = (payload) => {
  return {
    type: EDIT_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
};
export const editSubscriptionError = (payload) => {
  return {
    type: EDIT_SUBSCRIPTION_ERROR,
    payload: payload,
  };
};
export const deleteSubscriptionInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: DELETE_SUBSCRIPTION_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteSubscriptionSuccess = (payload) => {
  return {
    type: DELETE_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
};
export const deleteSubscriptionError = (payload) => {
  return {
    type: DELETE_SUBSCRIPTION_ERROR,
    payload: payload,
  };
};
export const defaultSubscription = (payload) => {
  return {
    type: DEFAULT_SUBSCRIPTION,
    payload: payload,
  };
};

export const getModulePermissionInProgress = (payload) => {
  return {
    type: GET_MODULE_PERMISSION_IN_PROGRESS,
    payload: payload,
  };
};
export const getModulePermissionSuccess = (payload) => {
  return {
    type: GET_MODULE_PERMISSION_SUCCESS,
    payload: payload,
  };
};
export const getModulePermissionError = (payload) => {
  return {
    type: GET_MODULE_PERMISSION_ERROR,
    payload: payload,
  };
};
export const getPlanListInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: GET_PLAN_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getPlanListSuccess = (payload) => {
  return {
    type: GET_PLAN_LIST_SUCCESS,
    payload: payload,
  };
};
export const getPlanListError = (payload) => {
  return {
    type: GET_PLAN_LIST_ERROR,
    payload: payload,
  };
};
export const addPlanInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: ADD_PLAN_IN_PROGRESS,
    payload: payload,
  };
};
export const addPlanSuccess = (payload) => {
  return {
    type: ADD_PLAN_SUCCESS,
    payload: payload,
  };
};
export const addPlanError = (payload) => {
  return {
    type: ADD_PLAN_ERROR,
    payload: payload,
  };
};
export const editPlanInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress", payload);
  return {
    type: EDIT_PLAN_IN_PROGRESS,
    payload: payload,
  };
};
export const editPlanSuccess = (payload) => {
  return {
    type: EDIT_PLAN_SUCCESS,
    payload: payload,
  };
};
export const editPlanError = (payload) => {
  return {
    type: EDIT_PLAN_ERROR,
    payload: payload,
  };
};
export const deletPlanInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: DELETE_PLAN_IN_PROGRESS,
    payload: payload,
  };
};
export const deletePlanSuccess = (payload) => {
  return {
    type: DELETE_PLAN_SUCCESS,
    payload: payload,
  };
};
export const deletePlanError = (payload) => {
  return {
    type: DELETE_PLAN_ERROR,
    payload: payload,
  };
};
export const defaultPlan = (payload) => {
  return {
    type: DEFAULT_SUBSCRIPTION,
    payload: payload,
  };
};
export const getOrganisationListInProgress = (payload) => {
  console.log("action-getSuperAdminDashboardInProgress");
  return {
    type: GET_SA_ORG__LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getOrganisationListSuccess = (payload) => {
  return {
    type: GET_SA_ORG__LIST_SUCCESS,
    payload: payload,
  };
};
export const getOrganisationListError = (payload) => {
  return {
    type: GET_SA_ORG__LIST_ERROR,
    payload: payload,
  };
};
export const defaultOrganization = (payload) => {
  return {
    type: DEFAULT_ORGANIZATION,
    payload: payload,
  };
};
export const addOrganisationInProgress = (payload) => {
  return {
    type: ADD_SA_ORG_IN_PROGRESS,
    payload: payload,
  };
};
export const addOrganisationSuccess = (payload) => {
  return {
    type: ADD_SA_ORG__SUCCESS,
    payload: payload,
  };
};
export const addOrganisationrror = (payload) => {
  return {
    type: ADD_SA_ORG_ERROR,
    payload: payload,
  };
};
export const editOrganisationInProgress = (payload) => {
  return {
    type: EDIT_SA_ORG_IN_PROGRESS,
    payload: payload,
  };
};
export const editOrganisationSuccess = (payload) => {
  return {
    type: EDIT_SA_ORG__SUCCESS,
    payload: payload,
  };
};
export const editOrganisationError = (payload) => {
  return {
    type: EDIT_SA_ORG_ERROR,
    payload: payload,
  };
};
export const deleteOrganisationInProgress = (payload) => {
  return {
    type: DELETE_SA_ORG_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteOrganisationSuccess = (payload) => {
  return {
    type: DELETE_SA_ORG__SUCCESS,
    payload: payload,
  };
};
export const deleteOrganisationrror = (payload) => {
  return {
    type: DELETE_SA_ORG_ERROR,
    payload: payload,
  };
};
export const addSubscriptionPaymentInProgress = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS,
    payload: payload,
  };
};
export const addSubscriptionPaymentSuccess = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_PAYMENT_SUCCESS,
    payload: payload,
  };
};
export const addSubscriptionPaymentError = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_PAYMENT_ERROR,
    payload: payload,
  };
};
export const addSubscriptionDetailsInProgress = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_DETAILS_INPROGRESS,
    payload: payload,
  };
};
export const addSubscriptionDetailsSuccess = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_DETAILS_SUCCESS,
    payload: payload,
  };
};
export const addSubscriptionDetailsError = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_DETAILS_ERROR,
    payload: payload,
  };
};
export const defaultSubscriptionPayment = (payload) => {
  return {
    type: DEFAULT_SUBSCRIPTION_PAYMENT,
    payload: payload,
  };
};
export const addOrgTimeInProgress = (payload) => {
  return {
    type: ADD_ORG_TIME_PROGRESS,
    payload: payload,
  };
};
export const addOrgTimeSuccess = (payload) => {
  return {
    type: ADD_ORG_TIME_SUCCESS,
    payload: payload,
  };
};
export const addOrgTimeError = (payload) => {
  return {
    type: ADD_ORG_TIME_ERROR,
    payload: payload,
  };
};
