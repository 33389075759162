import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal } from "antd";
import {
  WomanOutlined,
  ManOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Auxiliary from "../../../util/Auxiliary";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";

import { APP_ROUTES } from "../../../constants/Routes";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";

import moment from "moment";
import {
  deleteDesignationInProgress,
  getDesignationInProgress,
} from "../../../appRedux/doctor/actions/Designation";

const DesignationTable = () => {
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_,record) => {
    
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: "/designation/edit",
                  state: record,
                }}
              >
                <ButtonComponent type="primary" icon={<EditOutlined />} />
              </Link>
            </Col>

            <Col className="button-edit">
          
              <ButtonComponent
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setId] = useState("");
  const designationData = useSelector((state) => state.designation);
  const designationListData = designationData?.designation;
  const designationList = designationListData?.data?.data;

  const totalRecords = designationListData?.data?.totalRecords;

  const designation_List =
    designationList &&
    designationList.map((obj) => {
      if (obj.createdAt && obj.updatedAt) {
        return {
          ...obj,
          createdAt: moment(obj.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(obj.updatedAt).format("MM/DD/YYYY"),
        };
      }

      return obj;
    });
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteDesignationInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePage = (page) => {
    dispatch(getDesignationInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(designation_List);
    } else {
      const newData = designation_List.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    const page = 1;
    dispatch(getDesignationInProgress(page));
  }, []);
  useEffect(() => {
    const page = 1;
    dispatch(getDesignationInProgress(page));
  }, [designationData?.deleteDesignation?.success]);
  useEffect(() => {
    if (designationListData?.inProgress) {
      setLoading(true);
    } else if (designationListData?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [designationListData?.inProgress]);
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : designation_List}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          itemList={designation_List}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"Name"}
          rowselector={handleSelectChange}
        />
        <Modal
          title="Delete Data"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure, you want to delete the Data ?</p>
        </Modal>
      </Spin>
    </Auxiliary>
  );
};

export default DesignationTable;
