import { LoadingOutlined } from "@ant-design/icons";
import { Card, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { patientAddAppointmentInProgress } from "../../../../appRedux/Patient/Action/Action";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormInput from "../../../../components/Input/FormInput";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import { PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS } from "../../../../constants/ActionTypes";
import { APP_ROUTES } from "../../../../constants/Routes";
import IntlMessages from "../../../../util/IntlMessages";
import { appoinment_type } from "../../data";
import DoctorSelectAppointment from "../../DoctorSelectBox";
import TimeSlot from "./TimeSlot";
import TimeSlotDates from "./TimeSlotDates";

const AppoinmentType = () => {
const[form] =Form.useForm();
const date = localStorage.getItem("date");
  const doctor_id = localStorage.getItem("doctor-id");
  const patient_id = localStorage.getItem("patient_id");
  const schedule_id = localStorage.getItem("schedule-id");
    const {addAppointmentList} = useSelector((state)=>state.patientAppointment)
    console.log("add apointment list",addAppointmentList)
  const dispatch = useDispatch();
const onFinish = (datas) => {
  let form = new URLSearchParams();
    form.append("date",date);
    form.append("patient_id",patient_id,);
    form.append("doctor_id",doctor_id  );
    form.append("schedule_id",schedule_id)
    // form.append("start_time",datas.start_time);
    // form.append("end_time",datas.end_time);
    form.append("reason",datas.reason);
    form.append("symptom",datas.symptom);
    setLoading(true);
    dispatch(patientAddAppointmentInProgress(form));
    setLoading(false);
  console.log("DATA", datas);
};
const [Loading,setLoading]=useState(false);
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 72,
    }}
    spin
  />
);
useEffect(()=>{
  if(addAppointmentList.error)
  {
    setLoading(false)
  }
   if(addAppointmentList.inProgress)
  {
    setLoading(true);
  }
  else if(addAppointmentList.success)
  {
    setLoading(false);
  }
 
},[addAppointmentList.inProgress,addAppointmentList.success,addAppointmentList.error]);
  return (
    <div >
    <Spin indicator={antIcon} spinning={Loading} >
      <Card>
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 17,
          }}
          labelAlign="right"
          layout="horizontal"
          onFinish={onFinish}
          form={form}
        >
          
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <TimeSlot form={form}/>
       </Col>
{/* <TimeSlotDates form={form}/> */}

      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
           <FormInput
           labelCol={"24"}
           wrapperCol={"24"}
            type="text" placeholder="reason" name="reason" 
          label={<IntlMessages id={"Addnew.appoinment.label.reason"} />}
          rules={[{required: true, message:(<IntlMessages id={"Addnew.appoinment.validator.reason"} />),},]} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormInput
          labelCol={"24"}
          wrapperCol={"24"} 
          type="text" placeholder="symptoms" name="symptom" 
          label={<IntlMessages id={ "Addnew.appoinment.label.symptoms"} />}
          rules={[{required: true, message:(<IntlMessages id={"Addnew.appointment.validator.symptoms"} />),},]} />
        </Col>
       
                <Row className='space'>
                    <Col className='btn-pos'>
                      <Link to={APP_ROUTES.PREVIOUSADDAPPOINTMENT} >
                        <ButtonComponent type="danger" htmlType="reset" label="Previous"  className="btn-previous"/>
                        </Link>
                        <ButtonComponent type="primary" htmlType="Submit"  label="Save" />
                    </Col>
                </Row> 
                
      {/* </Form.Item> */}
      </Form>
      </Card>
      </Spin>
    </div>
  );
};

export default AppoinmentType;
