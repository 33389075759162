import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddSubscription from "./AddSubscription";

const EditSubscription = () => {
  const location = useLocation();
  const data = location.state?.subscriptionData;
  console.log("subscriptionData", location.state?.subscriptionData);

  return (
    <AddSubscription editSubscriptionData={data} editSubscription={"true"} />
  );
};

export default EditSubscription;
