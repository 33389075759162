import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import "../../../../styles/modules/pharmacy.less";
import { APP_ROUTES } from "../../../../constants/Routes";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  CreditCardOutlined,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormInput from "../../../../components/Input/FormInput";
import IntlMessages from "../../../../util/IntlMessages";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import PurchaseRequestFormTable from "../PurchaseRequest/PurchaseRequestFormTable";
import {
  addPurchaseReturnInProgress,
  editPurchaseReturnInProgress,
  getPurchaseListInProgress,
  getSupplierListInProgress,
  getTabletsIdInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import { currentDate, disablePastDate } from "../../../../util/utilz";
import PurchaseFooters from "../PurchaseFooters";
import PurchaseReturnFooter from "./PurchaseReturnFooter";

const AddPurchaseReturn = ({
  editPurchaseReturn,
  purchaseRetunData,
  viewData,
  viewPurchaseData,
}) => {
  const makeRow = (counter) => ({
    key: counter,
    product_name: counter,
    tablet_id: counter,
    batch_no: counter,
    quantity: counter,
    purchase_price: counter,
    unit_price: counter,
    gst_percentag: counter,
    total_amount: counter,
    return_amount: counter,
  });
  const [form] = Form.useForm();
  const organization_id = localStorage.getItem("org_id");
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [returnQunatity, setReturnQunatity] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [purchaseCode, setPurchaseCode] = useState([]);
  const dispatch = useDispatch();
  const [batchNo, setBatchNo] = useState([]);
  const [key, setKey] = useState();
  const [input, setInput] = useState([makeRow(0)]);
  const [counter, setCounter] = useState(1);
  const values = form.getFieldsValue();
  const [formData, setFormData] = useState();
  const { getTabletsId, getSupplierList, purchaseList } = useSelector(
    (state) => state.pharmacy
  );
  const addpurchaseReturnData = useSelector(
    (state) => state.pharmacy.addPurchaseReturn
  );
  const editpurchaseReturnData = useSelector(
    (state) => state.pharmacy.editPurchaseReturn
  );
  const orgId = localStorage.getItem("org_id");
  console.log("editpurchaseReturnData", editpurchaseReturnData);
  useEffect(() => {
    dispatch(getSupplierListInProgress(false));
    const fromData = { pagination_required: false, page: 1 };
    dispatch(getTabletsIdInProgress(fromData));
    const formDatas = { page: 1, orgId: orgId, pagination: false };
    dispatch(getPurchaseListInProgress(formDatas));
    form.setFieldValue("return_date", currentDate);
  }, []);
  useEffect(() => {
    if (addpurchaseReturnData?.success || editpurchaseReturnData?.success) {
      history.push("/purchase/return");
    }
  }, [editpurchaseReturnData, addpurchaseReturnData]);
  const onFinish = (datas) => {
    console.log("dataaa", datas);
    const formData = {
      return_date: datas?.return_date,
      mobile: datas?.mobile,
      purchase_code: datas?.purchase_code,
      supplier_id: form.getFieldValue("supplier_id"),
      company_name: datas?.company_name,
      address1: datas?.address1,
      address2: datas?.address2,
      city: datas?.city,
      state: datas?.state,
      zipcode: datas?.zipcode,
      sub_total: form.getFieldValue("sub_total"),
      gst_amount: form.getFieldValue("gst_amount"),
      total: form.getFieldValue("total"),
      discount: form.getFieldValue("discount"),
      organization_id: organization_id,
      purchase_return_details: Object.values(
        datas?.purchase_request_details
      ).filter((value) => value != null),
    };
    if (editPurchaseReturn) {
      const editFormData = {
        return_date: datas?.return_date,
        mobile: datas?.mobile,
        purchase_code: datas?.purchase_code,
        supplier_id: form.getFieldValue("supplier_id"),
        company_name: datas?.company_name,
        address1: datas?.address1,
        address2: datas?.address2,
        city: datas?.city,
        state: datas?.state,
        zipcode: datas?.zipcode,
        sub_total: form.getFieldValue("sub_total"),
        gst_amount: form.getFieldValue("gst_amount"),
        discount: form.getFieldValue("discount"),
        total: form.getFieldValue("total"),
        organization_id: organization_id,
        purchase_return_details: Object.values(
          datas?.purchase_request_details
        ).filter((value) => value != null),
      };

      dispatch(
        editPurchaseReturnInProgress({
          editFormData,
          id: purchaseRetunData?.id,
        })
      );
    } else {
      dispatch(addPurchaseReturnInProgress(formData));
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const changeCompanyName = (value) => {
    setCompanyName(value);
    console.log(" getSupplierList?.data?.data", getSupplierList?.data?.data);
  };

  const changePurchaseCode = (value) => {
    const purcahseValue = purchaseList?.data?.data?.find(
      (val) => val.purchase_code === value
    );

    form.setFieldValue("company_name", purcahseValue?.company_name);
    // form.setFieldValue("return_date", purcahseValue?.invoice_date);
    form.setFieldValue("billNumber", purcahseValue?.billNumber);
    form.setFieldValue("address1", purcahseValue?.address1);
    form.setFieldValue("address2", purcahseValue?.address2);
    form.setFieldValue("city", purcahseValue?.city);
    form.setFieldValue("state", purcahseValue?.state);
    form.setFieldValue("zipcode", purcahseValue?.zipcode);
    form.setFieldValue("mobile", purcahseValue?.mobile);
    form.setFieldValue("supplier_id", purcahseValue?.supplier_id);
    form.setFieldValue("gst_amount", purcahseValue?.gst_amount);
    form.setFieldValue("sub_total", purcahseValue?.sub_total);
    form.setFieldValue("total", purcahseValue?.total);
    form.setFieldValue("discount", purcahseValue?.discount);
    const newInput = purcahseValue?.purchase_details?.map((value, index) => {
      onChangeTablet(value, index);
      setSelectedProducts(value?.batch_no);
      return makeRow(index);
    });
    if (newInput && newInput.length > 0) {
      setInput(newInput);
      setCounter(newInput.length);
    }
    const batchNumbers = purcahseValue?.purchase_details?.map(
      (value) => value.batch_no
    );

    const updatedSelectedProducts = { ...selectedProducts };
    batchNumbers.forEach((batchNo, index) => {
      updatedSelectedProducts[index] = batchNo;
    });

    setSelectedProducts(updatedSelectedProducts);
  };

  useEffect(() => {
    if (Number(key) >= 0) {
      const return_quantity = form.getFieldValue([
        `purchase_request_details`,
        key,
        "return_quantity",
      ]);
      const quantity = form.getFieldValue([
        `purchase_request_details`,
        key,
        "quantity",
      ]);
      const purchase_price = form.getFieldValue([
        `purchase_request_details`,
        key,
        "purchase_price",
      ]);
      const returnAmount = Number(return_quantity) * Number(purchase_price);
      const totalAmount = Number(quantity) * Number(purchase_price);
      if (Number(returnAmount)) {
        form.setFieldValue(
          [`purchase_request_details`, key, "return_amount"],
          returnAmount.toFixed(2)
        );
        form.setFieldValue(
          [`purchase_request_details`, key, "total_amount"],
          totalAmount.toFixed(2)
        );
      }

      setKey();
    }
  }, [key]);
  useEffect(() => {
    let calculatedSubtotal = 0;
    let calculatedTotalGST = 0;
    let calculatedDiscount = 0;

    const purchaseDetailsArray = values?.purchase_request_details
      ? Object.values(values?.purchase_request_details).filter(
          (value) => value != null
        )
      : [];

    purchaseDetailsArray?.forEach((product) => {
      const productGST =
        ((parseFloat(product.gst_percentage)
          ? parseFloat(product.gst_percentage)
          : 0) *
          (parseFloat(product.purchase_price)
            ? parseFloat(product.purchase_price)
            : 0) *
          (parseFloat(product.return_quantity)
            ? parseFloat(product.return_quantity)
            : 0)) /
        100;
      if (
        parseFloat(product.purchase_price) &&
        parseFloat(product.return_quantity)
      ) {
        calculatedSubtotal +=
          parseFloat(product.purchase_price) *
          parseFloat(product.return_quantity);
      }
      const productDiscount =
        (parseFloat(product.discount) ? parseFloat(product.discount) : 0) / 100;
      calculatedDiscount += productDiscount;
      calculatedTotalGST += productGST;
    });

    let totalAmount = calculatedSubtotal + calculatedTotalGST;
    totalAmount = totalAmount - calculatedDiscount;
    form.setFieldValue("gst_amount", calculatedTotalGST.toFixed(2));
    form.setFieldValue("sub_total", calculatedSubtotal.toFixed(2));
    form.setFieldValue("discount", calculatedDiscount.toFixed(2));
    form.setFieldValue("total", totalAmount.toFixed(2));
    setKey();
  }, [key]);
  useEffect(() => {
    if (editPurchaseReturn) {
      form.setFieldValue("return_date", purchaseRetunData?.return_date);
      form.setFieldValue("company_name", purchaseRetunData?.company_name);
      form.setFieldValue("purchase_code", purchaseRetunData?.purchase_code);
      form.setFieldValue(
        "purchasereturnCode",
        purchaseRetunData?.purchase_return_code
      );
      form.setFieldValue("address1", purchaseRetunData?.address1);
      form.setFieldValue("address2", purchaseRetunData?.address2);
      form.setFieldValue("city", purchaseRetunData?.city);
      form.setFieldValue("state", purchaseRetunData?.state);
      form.setFieldValue("zipcode", purchaseRetunData?.zipcode);
      form.setFieldValue("mobile", purchaseRetunData?.mobile);
      form.setFieldValue("sub_total", purchaseRetunData?.sub_total);
      form.setFieldValue("gst_amount", purchaseRetunData?.gst_amount);
      form.setFieldValue("total", purchaseRetunData?.total);
      form.setFieldValue("discount", purchaseRetunData?.discount);
      setCompanyName(purchaseRetunData?.company_name);
      setPurchaseCode(purchaseRetunData?.purchaseCode);
      form.setFieldsValue({
        supplier_id: purchaseRetunData.supplier_id,
      });
      const newInput = purchaseRetunData?.purchase_return_details?.map(
        (value, index) => {
          onChangeTablet(value, index);
          return makeRow(index);
        }
      );

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    } else if (viewData) {
      changePurchaseCode(viewPurchaseData?.purchase_code);
      form.setFieldValue(
        "purchasereturnCode",
        viewPurchaseData?.purchase_return_code
      );
      form.setFieldValue("orderDate", moment(viewPurchaseData?.orderDate));
      form.setFieldValue("purchase_code", viewPurchaseData?.purchase_code);
      form.setFieldValue("address1", viewPurchaseData?.address1);
      form.setFieldValue("address2", viewPurchaseData?.address2);
      form.setFieldValue("city", viewPurchaseData?.city);
      form.setFieldValue("state", viewPurchaseData?.state);
      form.setFieldValue("zipcode", viewPurchaseData?.zipcode);
      form.setFieldValue("mobile", viewPurchaseData?.mobile);
      setCompanyName(viewPurchaseData?.company_name);
      setPurchaseCode(viewPurchaseData?.purchase_code);

      setPurchaseCode(viewPurchaseData?.purchaseCode);
    }
  }, [purchaseRetunData]);

  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };
  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);

    setInput(updatedInput);
    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.purchase_request_details[key] = null;
      setFormData(formDataCopy);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.purchase_request_details[key] = null;
      setFormData(formDataCopy);
    }
    setKey(key);
  };

  const onChangeTablet = (data, key, view) => {
    const value = form.getFieldValue([
      `purchase_request_details`,
      key,
      "batch_no",
    ]);
    validateFile(key, value);
    const batchNoList =
      batchNo
        .find((values) => values.key === key)
        ?.data?.Store_details?.map((pack, i) => {
          return { label: pack.batch_no, value: pack.batch_no };
        }) || [];
    const selectedbatchNo = batchNoList.find((product) => product.id === value);
    const isBatchNumberSelected = Object.values(selectedProducts).some(
      (selectedValue, keyvalue) => selectedValue === value && keyvalue !== key
    );

    if (isBatchNumberSelected) {
      form.setFieldsValue({
        [`purchase_request_details[${key}]`]: {
          ...form.getFieldValue()[`purchase_request_details[${key}]`],
          batch_no: {
            value,
            errors: ["vvvvvvvvvvvvv"],
          },
        },
      });
    } else {
      setKey(key);

      setSelectedProducts({
        ...selectedProducts,
        [key]: value,
      });
    }
    form.setFieldValue(
      [`purchase_request_details`, key, "return_quantity"],
      data?.available_stock || data.return_quantity || data.quantity || 0
    );
    const index = returnQunatity.findIndex((values) => values.key === key);

    let availableStock;
    const availableCount = data?.available_stock
      ? data?.available_stock !== undefined
        ? "false"
        : "true"
      : "false";
    if (availableCount) {
      availableStock = Math.floor(
        (Number(data?.available_stock) || 0) / Number(data?.packing_unit)
      );
    } else {
      availableStock = Math.floor(
        Number(data.quantity) + Number(data?.free_quantity)
      );
    }
    console.log("available_stock", availableStock);
    if (index !== -1) {
      const updatedBatchNo = [...returnQunatity];

      updatedBatchNo[index] = { availableStock, key };
      setReturnQunatity(updatedBatchNo);
    } else {
      setReturnQunatity((prevBatchNo) => [
        ...prevBatchNo,
        { availableStock, key },
      ]);
    }

    form.setFieldValue(
      [`purchase_request_details`, key, "product_name"],
      data?.medicine_name || data.product_name
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "tablet_id"],
      view ? data?.product_name : data?.tablet_id || data?.id
    );

    form.setFieldValue(
      [`purchase_request_details`, key, "quantity"],
      Number(data.quantity) + Number(data.free_quantity) || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "purchase_price"],
      data.purchase_price || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "free_quantity"],
      data.free_quantity || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "purchase_code"],
      data.purchase_code || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "gst_percentage"],
      data.gst_percentage || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "batch_no"],
      data.batch_no
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "total_amount"],
      data.total_amount || 0
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "return_amount"],
      data.return_amount || "0"
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "packing_unit"],
      data.packing_unit || "0"
    );

    form.setFieldValue(
      [`purchase_request_details`, key, "discount"],
      data.discount || 0
    );

    form.setFieldValue(
      [`purchase_request_details`, key, "amount"],
      data.amount
    );
    form.setFieldValue(
      [`purchase_request_details`, key, "return_quantity"],
      data.return_quantity || 0
    );
  };
  const validateFile = (renderKey, value) => {
    const isBatchNumberSelected = Object.values(selectedProducts).some(
      (selectedValue, key) => selectedValue === value && key !== renderKey
    );

    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [renderKey]: isBatchNumberSelected,
    }));

    if (isBatchNumberSelected) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  };
  const onChangeBatch = (data, key) => {
    const index = batchNo.findIndex((values) => values.key === key);

    if (index !== -1) {
      const updatedBatchNo = [...batchNo];
      updatedBatchNo[index] = { data, key };
      setBatchNo(updatedBatchNo);
      validateFile(key, 0);
      form.setFieldValue([`purchase_request_details`, key, "batch_no"], "0");
      form.setFieldValue([`purchase_request_details`, key, "quantity"], 0);
      form.setFieldValue(
        [`purchase_request_details`, key, "return_quantity"],
        "0"
      );
      form.setFieldValue([`purchase_request_details`, key, "packing_unit"], 0);
      form.setFieldValue(
        [`purchase_request_details`, key, "purchase_price"],
        0
      );
      form.setFieldValue(
        [`purchase_request_details`, key, "gst_percentage"],
        0
      );
      form.setFieldValue([`purchase_request_details`, key, "discount"], 0);
      form.setFieldValue([`purchase_request_details`, key, "total_amount"], 0);
      form.setFieldValue([`purchase_request_details`, key, "return_amount"], 0);
    } else {
      setBatchNo((prevBatchNo) => [...prevBatchNo, { data, key }]);
    }
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "tablet_id",
      render: (_, render) => (
        <>
          {viewData ? (
            <Form.Item shouldUpdate>
              <FormInput
                name={[`${render.key}`, "product_name"]}
                onChange={(e) => {
                  setKey(render.key);
                }}
                className="input len"
                type="text"
                readOnly={true}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Enter the  batch no"} />,
                  },
                ]}
              />{" "}
            </Form.Item>
          ) : (
            <Form.Item
              name={[`${render.key}`, "tablet_id"]}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"select the  tablet"} />,
                },
              ]}
            >
              <FormSelectBox
                options={
                  getTabletsId?.data?.response?.data?.map((pack, i) => {
                    return { label: pack.medicine_name, value: pack.id };
                  }) || []
                }
                placeholder="Select Product Name"
                className="sel select-box"
                onChange={(e) => {
                  const ids = getTabletsId?.data?.response?.data?.find((id) => {
                    if (id.id === e) {
                      return id;
                    }
                  });

                  onChangeBatch(ids, render.key);
                  // setKey(render.key);
                }}
                editMode="true"
                forFilter="true"
                showSearch="true"
              />
            </Form.Item>
          )}
        </>
      ),
    },

    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",
      render: (_, render) => (
        <>
          <Form.Item
            name={[`${render.key}`, "batch_no"]}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"select the  batch number"} />,
              },
            ]}
          >
            <FormSelectBox
              options={
                batchNo
                  .find((values) => values.key === render.key)
                  ?.data?.Store_details?.map((pack, i) => {
                    return { label: pack.batch_no, value: pack.batch_no };
                  }) || []
              }
              placeholder="Select Batch.No"
              className="sel select-box"
              onChange={(e) => {
                const ids = batchNo
                  .find((values) => values.key === render.key)
                  ?.data?.Store_details?.find((id) => {
                    if (id.batch_no === e) {
                      return id;
                    }
                  });

                onChangeTablet(ids, render.key);
                setKey(render.key);
              }}
            />
          </Form.Item>
          {errorMessages[render.key] && (
            <Typography.Text type="danger">
              Batch Number already selected
            </Typography.Text>
          )}
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            readOnly={"true"}
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: false,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Return Quantity",
      dataIndex: "return_quantity",
      key: "return_quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            readOnly={viewData}
            min={0}
            // max={Number(
            //   returnQunatity.find((val) => val?.key === render?.key)
            //     ?.availableStock
            // )}
            name={[`${render.key}`, "return_quantity"]}
            className="input len"
            type="number"
            onChange={() => {
              setKey(render.key);
            }}
            rules={[
              {
                required: true,
              },

              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("return quantity");
                  }

                  if (
                    value <=
                    Number(
                      returnQunatity.find((val) => val?.key === render?.key)
                        ?.availableStock
                    )
                  ) {
                    return Promise.resolve();
                  } else {
                    const stock = Number(
                      returnQunatity.find((val) => val?.key === render?.key)
                        ?.availableStock
                    );
                    return Promise.reject(
                      `Available Stock is ${Number(
                        returnQunatity.find((val) => val?.key === render?.key)
                          ?.availableStock
                      )}`
                    );
                  }
                },
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Packing Unit",
      dataIndex: "packing_unit",
      key: "packing_unit",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "packing_unit"]}
            className="input len"
            type="number"
            rules={[
              {
                required: false,
                message: <IntlMessages id={"Enter the packing unit"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            readOnly
            min={1}
            name={[`${render.key}`, "purchase_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: false,
                message: <IntlMessages id={"Enter the purchase price"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "GST (%)",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "gst_percentage"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: false,
                message: <IntlMessages id={"Enter the gst "} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",

      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "discount"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: false,
                message: <IntlMessages id={"Enter the discount"} />,
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "totalamount",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            readOnly
            min={1}
            name={[`${render.key}`, "total_amount"]}
            className="input len"
            type="number"
            rules={[
              {
                required: false,
                message: <IntlMessages id={"Enter the "} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Return Amount",
      dataIndex: "return_amount",
      key: "return_amount",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            name={[`${render.key}`, "return_amount"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
          />
        </Form.Item>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record) => (
        <>
          {!viewData && (
            <ButtonComponent
              icon={<DeleteFilled />}
              className="form-delete"
              type="danger"
              onClick={() => deleteRow(record.key)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editPurchaseReturn ? (
                    <IntlMessages id={"label.editPurchaseReturn"} />
                  ) : viewData ? (
                    <IntlMessages id={"label.viewPurchaseReturn"} />
                  ) : (
                    <IntlMessages id={"label.addPurchaseReturn"} />
                  )}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography className="subheading">
                  {
                    <IntlMessages
                      id={"Addnew.purchaseRequest.supplierSection"}
                    />
                  }
                </Typography>
                <Row>
                  {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {viewData ? (
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"purchaseType"}
                        readOnly={viewData}
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.purchaseType"}
                          />
                        }
                      />
                    ) : (
                      <Form.Item
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.purchaseType"}
                          />
                        }
                        name="purchaseCode"
                      >
                        <FormSelectBox
                          placeholder="Search Purchase Request/ID"
                          editMode="true"
                          forFilter="true"
                          showSearch="true"
                          options={purchaseTypeList}
                          onChange={changePurchaseType}
                          className="responsive-select"
                        />
                      </Form.Item>
                    )}
                  </Col> */}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {viewData ? (
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"purchase_code"}
                        readOnly={viewData}
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.purchaseCode"}
                          />
                        }
                      />
                    ) : (
                      <Form.Item
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.purchaseCode"}
                          />
                        }
                        name="purchase_code"
                      >
                        <FormSelectBox
                          placeholder="Select Purchasecode"
                          editMode="true"
                          forFilter="true"
                          showSearch="true"
                          options={
                            purchaseList?.data?.data?.map((pack, i) => {
                              return {
                                label: pack.purchase_code,
                                value: pack.purchase_code,
                              };
                            }) || []
                          }
                          onChange={(e) => {
                            changePurchaseCode(e);
                          }}
                          className="responsive-select"
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {viewData ? (
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"company_name"}
                        readOnly={viewData}
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.comapnyName"}
                          />
                        }
                      />
                    ) : (
                      <Form.Item
                        label={
                          <IntlMessages
                            id={"Addnew.pharmacy.label.companyName"}
                          />
                        }
                        name="company_name"
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.pharmacy.validator.companyName"}
                              />
                            ),
                          },
                        ]}
                      >
                        <FormSelectBox
                          placeholder="Select Company Name"
                          editMode="true"
                          forFilter="true"
                          showSearch="true"
                          value={companyName}
                          options={
                            getSupplierList?.data?.data?.map((data) => {
                              return {
                                label: data?.company_name,
                                value: data?.id,
                              };
                            }) || []
                          }
                          onChange={changeCompanyName}
                          className="responsive-select"
                          disabled={"true"}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.address1"} />
                      }
                      name="address1"
                    >
                      <TextArea rows={3} readOnly={"true"} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"address2"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.address2"} />
                      }
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"city"}
                      readOnly={viewData}
                      label={<IntlMessages id={"Addnew.pharmacy.label.city"} />}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"state"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.state"} />
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"zipcode"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.zipCode"} />
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography className="subheading">
                  {
                    <IntlMessages
                      id={"Addnew.purchaseRequest.purchaseSection"}
                    />
                  }
                </Typography>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"purchasereturnCode"}
                      readOnly={true}
                      label={
                        <IntlMessages
                          id={"Addnew.pharmacy.label.purchaseReturnCode"}
                        />
                      }
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {viewData ? (
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"return_date"}
                        label={
                          <IntlMessages id={"Addnew.pharmacy.label.date"} />
                        }
                        readOnly={viewData}
                      />
                    ) : (
                      <Form.Item
                        name={"return_date"}
                        label={
                          <IntlMessages id={"Addnew.pharmacy.label.date"} />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Medicine.validator.add_date"}
                              />
                            ),
                          },
                        ]}
                      >
                        <FormInput
                          type="date"
                          placeholder="date"
                          name="return_date"
                          readOnly={"true"}
                          // min={disablePastDate()}
                          defaultValue={currentDate}
                          labelCol={"24"}
                          wrapperCol={"24"}
                          // disabled={"true"}
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"mobile"}
                      readOnly={viewData}
                      label={
                        <IntlMessages id={"Addnew.pharmacy.label.mobile"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.pharmacy.validator.mobile"}
                            />
                          ),
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit mobile number!",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {<IntlMessages id={"label.productInformation"} />}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.List name={"purchase_request_details"}>
                  {(fields, { add }) => (
                    <PurchaseRequestFormTable
                      addRow={addRow}
                      columns={columns}
                      input={input}
                      viewData={viewData}
                    />
                  )}
                </Form.List>{" "}
                {input.length === 0 && (
                  <Typography className="red-text">
                    select the product informations !
                  </Typography>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="footer-total">
                <PurchaseFooters
                  subtotal={form.getFieldValue("sub_total") || 0}
                  totalGST={form.getFieldValue("gst_amount") || 0}
                  total={form.getFieldValue("total") || 0}
                  discount={form.getFieldValue("discount") || 0}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy ml-10"
              >
                <Link to={APP_ROUTES.PURCHASERETURN}>
                  <ButtonComponent
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>
                {!viewData && (
                  <ButtonComponent
                    icon={<CreditCardOutlined />}
                    label={editPurchaseReturn ? "Save" : "Submit"}
                    htmlType="submit"
                    type="primary"
                    disabled={input.length === 0}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddPurchaseReturn;
