import axios from "axios";

const qualificationInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getQualificationListEffect = (token, page) => {
  return qualificationInstance.request({
    url: "/qualification/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      pageNo: page,
      pageSize: 10,
      pagination_required: true,
    },
  });
};

export const deleteQualificationListEffect = (data, token) => {
  return qualificationInstance.request({
    url: `/admin/deleteQualification/${data}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addQualificationListEffect = (formData, token) => {
  return qualificationInstance.request({
    url: "/admin/addQualification",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const updateQualificationListEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");

  return qualificationInstance.request({
    url: `/admin/updateQualification/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const getQualificationUpdateListEffect = (data, token) => {
  return qualificationInstance.request({
    url: `/qualification/details/${data}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
