import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Tooltip, Typography } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import {
  patientDeleteListInProgress,
  patientListInProgress,
} from "../../../../appRedux/admin/patient/action/action";
import TableComponent from "../../../../components/Table/TableComponent";
import {
  deletePurchaseInProgress,
  getPurchaseListInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import moment from "moment";
import { access } from "../../../../util/utilz";

const PurchaseTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { purchaseList, deletePurchase } = useSelector(
    (state) => state?.pharmacy
  );
  const orgId = localStorage.getItem("org_id");
  const PurchaseList = purchaseList?.data?.data;
  const totalRecords = purchaseList?.data?.totalRecords;
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const columns = [
    {
      title: "Purchase  Code",
      dataIndex: "purchase_code",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Bill Number",
      dataIndex: "bill_no",
      key: "billNumber",
    },

    {
      title: "Supplier Company Name",
      dataIndex: "company_name",
      key: "email",
    },

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"view"}>
                {" "}
                <Link
                  to={{
                    pathname: "/purchase/view",
                    state: { purchaseData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("purchase", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/purchase/edit`,
                      state: { supplierData: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditOutlined />}
                      // onClick={()=>editPatient(record.id)}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("purchase", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    dispatch(deletePurchaseInProgress(deleteId));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    const formData = { page: 1, orgId: orgId, pagination: true };
    dispatch(getPurchaseListInProgress(formData));
  }, [deletePurchase?.success]);

  const handlePage = (page) => {
    const formData = { page: page, orgId: orgId, pagination: true };
    dispatch(getPurchaseListInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(PurchaseList);
    } else {
      const newData = PurchaseList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  return (
    <Spin
      indicator={antIcon}
      spinning={deletePurchase?.inProgress || purchaseList?.inProgress}
    >
      {access("purchase", "view") && (
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : PurchaseList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          rowselector={handleSelectChange}
          itemList={PurchaseList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"purchase_code"}
          documentTitle={"Purchase List"}
        />
      )}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default PurchaseTable;
