import React, { useEffect, useState } from "react";
import { getBloodGroupListEffect, getpatientListEffect } from "../../appRedux/admin/patient/saga/patientEffect";
import { getpatientListAppointmentEffect } from "../../appRedux/Patient/Sagas/Effect/patientAppointmentEffect";
import PatientSelectBox from "./components/PatientSelectBox";
const BloodGroupSelectBox = ({
  placeholder = "Select patient",
  label = "patient",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getStatusList();
  }, []);
  const getStatusList = async () => {
    try {
      setLoading(true);
      const { data } = await getBloodGroupListEffect();
      const List = data?.data
      console.log("bloodGrpselectbox",List)
      let options = List.map((row) => ({
        label: row.blood_group,
        value: row.id,
      }));
      setOptions(options);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setOptions([]);
      setLoading(false);
    }
  };
  return (
    <PatientSelectBox
      loading={loading}
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
    />
  );
};
export default BloodGroupSelectBox;













