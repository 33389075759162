import { Col, Form, Row, Table } from "antd";
import React from "react";
import ButtonComponent from "../../../components/Button/ButtonComponent";

const ProductTable = ({ addRow, columns, input }) => {
  return (
    <div>
      <Row>
        <Col xs={24}>
          <Table
            showHeader={true}
            columns={columns}
            dataSource={input}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <ButtonComponent
            className="new-button"
            type="primary"
            label="New"
            onClick={addRow}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProductTable;
