import axios from "axios";

const appointmentInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getDoctorDashBoardEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/user/doctorDashBoard`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
