import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultRoom,
  deleteRoomInProgress,
  roomListsInProgress,
} from "../../../appRedux/Ward/WardAction";

const RoomsTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const wardData = location.state?.wardData;
  const history = useHistory();
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const roomListByWard = useSelector((state) => state?.ward?.roomList);
  const addRoomData = useSelector((state) => state?.ward?.addRoom);
  const editRoomResponse = useSelector((state) => state?.ward?.editRoom);
  const deleteRoom = useSelector((state) => state?.ward?.deleteRoom);
  const wardList = roomListByWard?.data?.data;
  const totalRecords = roomListByWard?.data?.data?.totalRecords;
  const goBackHandler = () => {
    history.goBack();
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteRoomInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Room Number",
      dataIndex: "room_no",
      key: "room_no",
    },
    {
      title: "Room Incharge Name",
      dataIndex: "room_incharge_name",
      key: "room_incharge_name",
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      render: (text, record) => {
        return record.room_type === "1" ? "AC ROOM" : "NON AC ROOM";
      },
    },
    {
      title: "Room Status",
      dataIndex: "room_status",
      key: "room_status",
      render: (text, record) => {
        return record.room_type === "1" ? "Active" : "Inactive";
      },
    },
    {
      title: "Room Price",
      dataIndex: "room_type",
      key: "room_type",
      render: (text, record) => {
        return record.room_type === "1"
          ? record.ac_room_price
          : record.non_ac_room_price;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/rooms/edit`,
                  state: { roomData: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteFilled />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = {
      pagination: "true",
      pageNo: page,
      pageSize: "10",
      ward_id: wardData?.id,
    };

    dispatch(roomListsInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(wardList);
    } else {
      const newData = wardList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    if (roomListByWard?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [roomListByWard]);
  useEffect(() => {
    const payload = {
      pagination: "true",
      pageNo: "1",
      pageSize: "10",
      ward_id: wardData?.id,
    };

    dispatch(roomListsInProgress(payload));
  }, []);
  console.log("blockData--table", wardData);
  useEffect(() => {
    if (addRoomData?.success || editRoomResponse?.success) {
      dispatch(defaultRoom());
    }
  }, [addRoomData, editRoomResponse]);
  useEffect(() => {
    if (deleteRoom?.success) {
      const payload = {
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        ward_id: wardData?.id,
      };

      dispatch(roomListsInProgress(payload));
    }
  }, [deleteRoom]);
  console.log("wardData--table rooms", wardData, location.state);
  console.log("location.pathname", location);
  return (
    <Card>
      <Row style={{ display: "flex", justifyContent: "end" }} className="mx-20">
        <Link
          to={{
            pathname: `/ward`,
            state: {
              blockData: location?.state?.editRoom
                ? { id: wardData?.block_id, block_name: wardData?.block_name }
                : {
                    id: wardData?.Block?.id,
                    block_name: wardData?.Block?.block_name,
                  },
            },
          }}
        >
          <ButtonComponent type="danger" icon={<ArrowLeftOutlined />} />
        </Link>
      </Row>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.rooms"} />}
            Ward - {""}
            {wardData?.ward_name}
          </Typography>
        </Col>
        <Col>
          <Link
            to={{
              pathname: `/rooms/add`,
              state: { id: wardData },
            }}
          >
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newRoom"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : wardList}
          rowselector={handleSelectChange}
          itemList={wardList}
          filteredUsers={filteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          setFilteredUsers={setFilteredUsers}
          field={"room_no"}
          documentTitle={"Room List"}
        />
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default RoomsTable;
