import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import FormInput from "../../components/Input/FormInput";
import ButtonComponent from "../../components/Button/ButtonComponent";
import {
  addWardInProgress,
  getBlockListInProgress,
  getWardListByBlockInProgress,
  roomDischargeInProgress,
  roomListsInProgress,
  roomShiftInProgress,
} from "../../appRedux/Ward/WardAction";

import { currentDate } from "../../util/utilz";

const DischargeRoom = ({ editWard }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const bookedRoomData = location.state?.roomData;

  const dischargeRoom = useSelector((state) => state?.ward?.roomDischarge);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (data) => {
    console.log("datas", data);

    const payload = {
      check_out_date: data?.check_out_date,
      checkout_by: data?.check_Out_By || "",
      discharge_date: data?.check_out_date,
      check_out_incharge: data?.check_out_Incharge_Name || "",
      complaints: data?.complaint || "",
      booked_status: "4",
      room_id: bookedRoomData?.room_id,
      id: bookedRoomData?.id,
    };
    console.log("payload", payload);
    dispatch(roomDischargeInProgress(payload));

    if (dischargeRoom?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (dischargeRoom?.success) {
      history.push("/ward-management");
    }
  }, [dischargeRoom]);

  useEffect(() => {
    form.setFieldValue("check_out_date", currentDate);
  }, []);

  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  <IntlMessages id={"label.dischargeRoom"} />
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="date"
                      readOnly={true}
                      name={"check_out_date"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkoutDate"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomShiftDate"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"check_Out_Incharge_Name"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.checkoutInchargeName"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkoutInchargeName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"complaints"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.complaints"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.complaints"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"checkout_by"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkout_by"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkout_by"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link
                  to={{
                    pathname: `/booked-rooms`,
                  }}
                >
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editWard ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default DischargeRoom;
