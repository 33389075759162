import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../util/utilz";

const billingPackagesInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getSubPackagesListEffect = (organization_id) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: `/subPackageCategory/getAllPackage?pagination_required=false`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const AddBillingPackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: "/package/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getMainPackagesListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: `/package/packageGetAll?pagination_required=true&organization_id=${payload?.organization_id}&pageNo=${payload?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateBillingPackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: "/package/update",
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteBillingPackagesEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: `/package/delete/${payload?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const AddSubpackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: "/subPackageCategory/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateSubpackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: `/subPackageCategory/update/${formData.id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteSubpackagesEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return billingPackagesInstance.request({
    url: `/subPackageCategory/delete/${payload?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
