import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography } from "antd";
import FormInput from "../../../../components/Input/FormInput";
import IntlMessages from "../../../../util/IntlMessages";
import "../../../../styles/modules/pharmacy.less";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";

const SalesReturnProductForm = ({
  salesInformationData,
  setSaleseData,
  salesData,
  editSales,
}) => {
  const [form] = Form.useForm();

  const [products, setProducts] = useState([
    {
      productName: "",
      quantity: 0,
      freeQuantity: 0,
      packingUnit: 0,
      batchNo: "",
      expiryDate: "",
      purchasePrice: 0,
      salesPrice: 0,
      gst: 0,
      discount: 0,
      amount: 0,
    },
  ]);

  const [medicineInformation, setMedicineInformation] = useState([]);
  const [subTotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [needToPay, setNeedToPay] = useState(0);

  const [due, setDue] = useState(0);

  const handleRemarksChange = (index, value) => {
    const updatedProducts = [...products];
    updatedProducts[index].remarks = parseFloat(value);
    setProducts(updatedProducts);
  };

  const initializeProducts = (data) => {
    const purchaseData = data?.productInformation;
    if (Array.isArray(purchaseData) && purchaseData.length > 0) {
      const initialProducts = purchaseData.map((productInfo) => ({
        medicine: productInfo.productName,
        batchNo: productInfo.batchNo,
        salesPrice: parseFloat(productInfo.salesPrice),
        unitPrice: parseFloat(productInfo.unitPrice),
        quantity: parseFloat(productInfo.quantity),
        returnQuantity: parseFloat(productInfo.returnQuantity),
        gst: parseFloat(productInfo.gst),
        remarks: parseFloat(productInfo.remarks),
        total: parseFloat(productInfo.total),
      }));

      setProducts(initialProducts);
    }
  };

  useEffect(() => {
    initializeProducts(salesInformationData);
  }, [salesInformationData]);
  const calculateRoundoff = (value) => {
    return Math.round(value * 100) / 100;
  };
  useEffect(() => {
    let calculatedTotal = 0;
    let calculatedSubtotal = 0;
    let calculatedTotalGST = 0;
    let calculatedTotalDiscount = 0;
    let calculatedTotalRoundoff = 0;

    products.forEach((product) => {
      const amount = product.quantity * product.purchasePrice;
      calculatedSubtotal += amount;
      calculatedTotalGST += (amount * product.gst) / 100;
      calculatedTotal += amount;
      calculatedTotalDiscount += (amount * product.discount) / 100;
    });
    calculatedTotal -= calculatedTotalDiscount;

    calculatedTotalRoundoff = calculateRoundoff(calculatedTotal);
    setSubtotal(
      salesInformationData?.subTotal
        ? salesInformationData?.subTotal
        : calculatedSubtotal
    );

    setTotal(
      salesInformationData?.total
        ? salesInformationData?.total
        : calculatedTotal
    );

    setDiscount(
      salesInformationData?.discount
        ? salesInformationData?.discount
        : calculatedTotalDiscount
    );
    setSaleseData({
      total: calculatedTotal,
      subtotal: calculatedSubtotal,
      totalGST: calculatedTotalGST,
      totalDiscount: calculatedTotalDiscount,
      totalRoundoff: calculatedTotalRoundoff,
      products: products,
    });
  }, [products]);

  useEffect(() => {
    // const salesData = medicineInformationList.filter(
    //   (item) => item.salesId === salesId
    // );

    const arrayData = salesData && salesData?.medicineInformation;
    setMedicineInformation(arrayData);
    setDiscount(salesData?.discount);
    setSubtotal(salesData?.subTotal);
    setTotal(salesData?.total);
    setDeposit(salesData?.deposits);
    setDue(salesData?.due);
    setNeedToPay(salesData?.needToPay);
  }, [salesData]);
  useEffect(() => {
    if (medicineInformation && form) {
      const initialValues = {};

      medicineInformation.forEach((product, index) => {
        initialValues[`medicine_${index}`] = product.medicine;
        initialValues[`batchNo_${index}`] = product.batchNo;
        initialValues[`salesPrice_${index}`] = product.salesPrice;
        initialValues[`unitPrice_${index}`] = product.unitPrice;
        initialValues[`gst_${index}`] = product.gst;
        initialValues[`quantity_${index}`] = product.quantity;
        initialValues[`returnQuantity_${index}`] = product.returnQuantity;
        initialValues[`total_${index}`] = product.total;
        initialValues[`remarks_${index}`] = product.remarks;
      });

      form.setFieldsValue(initialValues);
    }
  }, [medicineInformation, form]);

  return (
    <div>
      {/* <div class="scroll">
        <div class="wide-content"> */}
      <Row className="margin-row" justify="end" gutter={[16, 16]}>
        <Col xl={18}>
          <Row gutter={[4, 16]} className="margin-row mt-10" justify="end">
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                readOnly
                wrapperCol={24}
                labelCol={10}
                label="Sub Total"
                value={subTotal}
                type="number"
                name={"sub_total"}
              />
            </Col>
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                wrapperCol={24}
                labelCol={10}
                label="Discount"
                value={discount}
                type="number"
                readOnly
                name={"discount"}
              />
            </Col>
            <Col xs={24} lg={24} md={24} sm={24} xl={24}>
              <FormInput
                wrapperCol={24}
                labelCol={10}
                value={total}
                type="number"
                readOnly
                label="Total"
                name={"total"}
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                wrapperCol={24}
                labelCol={10}
                label="Deposits"
                value={deposit}
                type="number"
                readOnly
                name={"deposits"}
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                wrapperCol={24}
                labelCol={10}
                label="Due"
                value={due}
                type="number"
                readOnly
                name={"due"}
              />
            </Col>
            <Col lg={24} md={24} sm={24} xl={24} xs={24}>
              <FormInput
                name={"need_to_pay"}
                wrapperCol={24}
                labelCol={10}
                label="Need to pay"
                value={needToPay}
                type="number"
                readOnly
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </div>
      </div>{" "} */}
    </div>
  );
};

export default SalesReturnProductForm;
