import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Popover, Typography } from "antd";
import { userSignOut } from "../../appRedux/doctor/actions/Auth";
import { getDataUpdateInProgress } from "../../appRedux/admin/Doctor/actions/Doctors";
import { getPatientListByIdInProgress } from "../../appRedux/admin/patient/action/action";
import { getUserProfileInProgress } from "../../appRedux/doctor/actions/Profile";
import ProfileForm from "../../screens/Profile/Components/ProfileForm";
import { APP_ROUTES } from "../../constants/Routes";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { useHistory } from "react-router-dom";
import profile from "../../assets/images/DocMotez/contactProfile.png";

const UserProfile = () => {
  ProfileForm();
  const { getprofile } = useSelector((state) => state.profile);
  const getprofiledata = getprofile?.data;
  console.log("getprofiledata", getprofiledata?.profile_image);
  const history = useHistory();
  const doctorsid = localStorage.getItem("doctor_id");
  const { getupdatelist } = useSelector((state) => state.doctor);
  const getDoctorProfile = getupdatelist?.profile_image;
  const succ = getupdatelist?.success;

  // useEffect(() => {
  // if (succ === true) {
  // dispatch(getDataUpdateInProgress(doctorsid));
  // }
  // }, []);
  // console.log("Get doctor list", getDoctorProfile);

  const patientsid = localStorage.getItem("patient_id");
  const { getListById } = useSelector((state) => state.patient);
  const patientProfile = getListById?.data?.profile_image;
  const patsucc = getListById?.success;

  // useEffect(() => {
  // if (patsucc === true) {
  // dispatch(getPatientListByIdInProgress(patientsid));
  // }
  // }, []);
  //   const [adprofile, setAdprofile] = useState();
  // const doctorsid = localStorage.getItem("doctor_id");
  const roleId = localStorage.getItem("role_id");

  //   useEffect(() => {
  // if(roleId==="2")
  //   {
  //     setDoc(<span>Doctor</span>)
  //   }
  //   }, []);
  // console.log("Get doctor list",getDoctorProfile)

  // const patientsid = localStorage.getItem("patient_id");
  // const {getListById} = useSelector((state)=> state.patient);
  // const patientProfile = getListById?.data?.profile_image;
  // const patsucc = getListById?.success;
  // console.log("patient success",patsucc)
  // console.log("patient profile",patientProfile)

  // useEffect(()=>{
  //   if(roleId==="3"){
  //   dispatch(getPatientListByIdInProgress(patientsid))
  //   }
  // },[]);

  // useEffect(()=>{
  //   if(roleId==="1"){

  //   // setAdprofile(adminProfile);
  //   }
  // },[]);

  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );
  const handleNavigate = () => {
    history.push(APP_ROUTES.LOGOUT);
  };
  return (
    <div className="gx-flex-row gx-align-items-center  gx-avatar-row">
      {/* <Popover
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    > */}
      <Avatar
        src={getprofiledata?.profile_image || profile}
        className="gx-size-40 gx-pointer gx-mr-3"
        alt=""
      />
      <div>
        <Typography className="gx-avatar-name menu-text mt-5">
          {getprofiledata.firstName} {getprofiledata.lastName}
          {/* <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /> */}
        </Typography>

        <Button
          className="m-0 p-0 menu-text f-13"
          type="text"
          onClick={() => handleNavigate()}
        >
          {" "}
          Log Out
        </Button>
      </div>
      {/* </Popover> */}
    </div>
  );
};

export default UserProfile;
