import { Row, Col, Typography, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";

import "../../../../styles/modules/pharmacy.less";

import { useHistory } from "react-router-dom";

import ViewHeader from "../../view/ViewHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getPharmacyProfileInProgress } from "../../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import PurchaseFooters from "../PurchaseFooters";
import IntlMessages from "../../../../util/IntlMessages";

const PrintPurchase = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const location = useLocation();
  const [printingDone, setPrintingDone] = useState(false);
  const { pharmacyProfile } = useSelector((state) => state?.pharmacy);
  const profileData = pharmacyProfile?.data?.data;
  const salesData = location.state?.purchaseRequestData;
  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      render: (_, __, index) => index + 1,
    },
    {
      title: "ProductName",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "BatchNo",
      dataIndex: "batch_no",
      key: "batch_no",
    },
    // {
    //   title: "Date",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },

    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
    },

    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
    },
    {
      title: "GST",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);

  useEffect(() => {
    if (profileData && !printingDone) {
      window.print();
      setPrintingDone(true);
    }
  }, [profileData, printingDone]);

  const columnss = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "value",
      dataIndex: "value",
    },
  ];
  const input = [
    salesData?.Supplier?.contact_person
      ? {
          name: "SupplierName",
          value: salesData?.Supplier?.contact_person,
        }
      : null,
    salesData?.mobile
      ? {
          name: "Mobile",
          value: salesData?.mobile,
        }
      : null,
    salesData?.purchase_request_code
      ? {
          name: "purchase Request Code",
          value: salesData?.purchase_request_code,
        }
      : null,
    {
      name: " Address",
      value: `${salesData?.Supplier?.address},
                           ${salesData?.Supplier?.city},
                            ${salesData?.Supplier?.country},
                            ${salesData?.state},
                            ${salesData?.Supplier?.zipcode}.`,
    },
  ];
  return (
    <Row justify="center" align="middle">
      <Col xl={23} lg={23} md={23} sm={24} xs={24}>
        <Row gutter={[0, 16]} className="margin-row">
          <ViewHeader
            headerType={"request"}
            headerData={salesData}
            profileData={profileData}
            printingDone={printingDone}
            setPrintingDone={setPrintingDone}
          />

          <Col xl={24} lg={24} md={24} sm={14} xs={24}>
            <Row>
              <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                <Table
                  showHeader={false}
                  columns={columnss}
                  dataSource={input.filter((element) => element !== null)}
                  pagination={false}
                  scroll={{ x: true }}
                />
              </Col>
            </Row>
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="margin-row">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Typography className="products">Products</Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Table
                  columns={columns}
                  dataSource={salesData?.purchase_request_details}
                  pagination={false}
                  className="responsive-table"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} className="footer-total">
            <PurchaseFooters
              subtotal={salesData?.sub_total}
              totalGST={salesData?.gst_amount}
              total={salesData?.total}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PrintPurchase;
