import axios from "axios";
import moment from "moment";

const appointmentInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});
const token = localStorage.getItem("user_id");
const organization_id = localStorage.getItem("org_id");
export const getAppointmentListEffect = (page, token) => {
  // console.log("effect appointment", token);
  const current_date = moment().format("YYYY-MM-DD");
  const current_time = moment().format("hh mm");

  return appointmentInstance.request({
    url: `/appointment/getbyorganization?pagesize=10&pageNo=${page.page}&pagination_required=true&organization_id=${organization_id}&current_date=${current_date}&current_time=${current_time}&isPrev=${page.isPrev}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDoctorAppointmentListEffect = ({
  page,
  currentDate,
  currentTime,
}) => {
  const doctorid = localStorage.getItem("doctor_id");
  const token = localStorage.getItem("user_id");

  // const isPrev = "1";
  console.log(
    "effect doctor previous appointment now",
    currentTime,
    currentDate
  );
  return appointmentInstance.request({
    url: `/appointment/doctor/?doctor_id=${doctorid}&pageNo=${page}&isPrev=1&pagesize=10&pagination_required=true&current_time=${currentTime}&current_date=${currentDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDoctorUpcomingAppointmentListEffect = ({
  page,
  currentDate,
  currentTime,
}) => {
  const doctorid = localStorage.getItem("doctor_id");
  const token = localStorage.getItem("user_id");
  // const isPrev = "1";
  // console.log("effect appointment",formData)
  return appointmentInstance.request({
    url: `/appointment/doctor/?doctor_id=${doctorid}&pageNo=${page}&isPrev=0&pagination_required=true&current_time=${currentTime}&current_date=${currentDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const reScheduleAppointment = ({ id }) => {
  console.log("effect appointment", id);
  return appointmentInstance.request({
    url: `/appointment/appointmentsReschedule/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
