import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddMedicineCategory from "./AddMedicineCategory";

const EditMedicineCategory = () => {
  const location = useLocation();
  const medicineListData = location.state?.medicineList;
  console.log(
    "medicineListDatamedicineListDatamedicineListData",
    medicineListData
  );
  return (
    <AddMedicineCategory
      medicineListData={medicineListData}
      editMedicine="true"
    />
  );
};

export default EditMedicineCategory;
