import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  Modal,
  Typography,
  Button,
  Table,
  Form,
  Spin,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  LoadingOutlined,
  PlusCircleOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../components/Button/ButtonComponent";
import IntlMessages from "../../util/IntlMessages";
import FormInput from "../../components/Input/FormInput";
import { APP_ROUTES } from "../../constants/Routes";
import {
  addDiseasesInProgress,
  deleteDiseasesInProgress,
  getDiseaseListInProgress,
  updateDiseasesInProgress,
} from "../../appRedux/doctor/actions/appointmentQuestions";

const AppointmentQuestionScreenHeader = () => {
  const [diseaseModal, setDiseaseModal] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [diseaseId, setDiseaseId] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [disease, setDisease] = useState("Add Diseases");
  const [deleteId, setDeleteId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [addDiseaseModal, setAddDiseaseModal] = useState(false);
  const { addDiseases, getListDiseases, updateDiseases, deleteDiseases } =
    useSelector((state) => state?.appointmentQuestions);
  console.log("diseaseListDatas", getListDiseases?.data);
  const diseaseList = getListDiseases?.data?.data || [];

  const handleDiseaseModalClose = () => {
    setDiseaseModal(false);
  };
  const handleAddDiseaseModalClose = () => {
    setAddDiseaseModal(false);
    form.resetFields();
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };
  const handleEditDepartment = (record) => {
    setAddDiseaseModal(true);
    form.setFieldValue("name", record?.name);
    setDisease("Edit Diseases");
    setDiseaseId(record?.id);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      render: (_, __, index) => index + 1,
    },

    {
      title: "Diseases",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <ButtonComponent
                className="secondary-color"
                icon={<EditFilled />}
                size="default"
                label="Edit"
                onClick={() => handleEditDepartment(record)}
              ></ButtonComponent>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteFilled />}
                size="default"
                label={"Delete"}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    const payLoad = {
      pagination: true,
      page: 1,
    };
    dispatch(getDiseaseListInProgress(payLoad));
  }, [addDiseases?.success, updateDiseases?.success, deleteDiseases?.success]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const onFinish = (datas) => {
    datas.profile_image = datas.profile_image ? datas.profile_image : null;
    if (disease === "Edit Diseases") {
      dispatch(updateDiseasesInProgress({ data: datas, id: diseaseId }));
    } else {
      dispatch(addDiseasesInProgress(datas));
    }
  };
  useEffect(() => {
    if (addDiseases?.success || updateDiseases?.success) {
      setAddDiseaseModal(false);
      form.resetFields();
    }
  }, [addDiseases?.success, updateDiseases?.success]);

  const handlePage = (page) => {
    const payLoad = {
      pagination: true,
      page: page,
    };
    dispatch(getDiseaseListInProgress(payLoad));
  };

  const handleOk = () => {
    setIsModalOpen(false);

    dispatch(deleteDiseasesInProgress({ id: deleteId }));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Label.questions.heading"} />}
          </p>
        </Col>
        <Col>
          <ButtonComponent
            type="primary"
            label={<IntlMessages id={"Addnew.questions.diseases"} />}
            onClick={() => setDiseaseModal(true)}
          />
          <Link to={APP_ROUTES.APPOINMENTQUESTIONS}>
            <ButtonComponent
              className="secondary-color"
              label={<IntlMessages id={"Addnew.patient.addnew"} />}
              icon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Modal
        title={
          <Row justify={"space-between"}>
            <Col>
              <Typography className="cancel-sales">
                <IntlMessages id={"Addnew.questions.diseases"} />
              </Typography>
            </Col>
            <Col>
              <ButtonComponent
                label={"Add Diseases"}
                type="primary"
                onClick={() => {
                  setAddDiseaseModal(true);
                  setDisease("Add Diseases");
                }}
              />
            </Col>
          </Row>
        }
        open={diseaseModal}
        width={600}
        closable={false}
        footer={[
          <Button
            key="back"
            className="secondary-color"
            onClick={handleDiseaseModalClose}
          >
            Close
          </Button>,
        ]}
      >
        <Spin
          indicator={antIcon}
          spinning={getListDiseases?.progressing || deleteDiseases?.progressing}
        >
          <Table
            columns={columns}
            dataSource={diseaseList}
            pagination={{
              total: getListDiseases?.data?.totalPages || "",
              onChange: (page) => handlePage(page),
            }}
            className="responsive-table"
          />
        </Spin>
      </Modal>
      <Modal
        title={<Typography className="cancel-sales"> {disease}</Typography>}
        open={addDiseaseModal}
        width={600}
        closable={false}
        footer={[]}
      >
        <Spin
          indicator={antIcon}
          spinning={addDiseases?.progressing || updateDiseases?.progressing}
        >
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"name"}
                  label={<IntlMessages id={"Addnew.questions.diseases"} />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id={"Enter the disease name"} />,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row justify={"end"}>
              <Button key="back" type="primary" htmlType="submit">
                Save
              </Button>
              <Button
                key="back"
                className="secondary-color"
                onClick={handleAddDiseaseModalClose}
              >
                Close
              </Button>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
      <Divider />
    </div>
  );
};

export default AppointmentQuestionScreenHeader;
