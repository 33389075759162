import axios from "axios";
import moment from "moment";
const token = localStorage.getItem("user_id");
const patient_id = localStorage.getItem("patient_id");
// const id = localStorage.getItem("id");
let pharmacyInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getSuperAdminDashboardEffect = () => {
  console.log("pharmacy-effect");

  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/user/superadmindashboard",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const HospitalListEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: "/departments/getlist/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addHospitalEffect = (formData) => {
  console.log("formData-AddTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: "/departments/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addOrgTimeEffect = (formData) => {
  console.log("formData-addOrgTimeEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/admin/update/${formData?.orgId}`,
    data: formData?.payload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editHospitalEffect = (formData) => {
  console.log("formData-EditTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: `/departments/update/${formData?.id}`,
    data: formData?.datas,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteHospitalEffect = (formData) => {
  console.log("formData-DeleteTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: `/departments/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const PackageListEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: "/departments/getlist/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPackageEffect = (formData) => {
  console.log("formData-AddTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: "/departments/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPackageEffect = (formData) => {
  console.log("formData-EditTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: `/departments/update/${formData?.id}`,
    data: formData?.datas,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePackageEffect = (formData) => {
  console.log("formData-DeleteTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    // url: `/departments/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const subscriptionListEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/subscription/getsubscriptions?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSubscriptionEffect = (formData) => {
  console.log("formData-AddTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/subscription/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editSubscriptionEffect = (formData) => {
  console.log("formData-editSubscriptionEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/subscription/updatesubscription/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteSubscriptionEffect = (formData) => {
  console.log("formData-DeleteTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/subscription/deletesubscription/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getModulePermissionEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/mastermenus/menus",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const planListEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/subscriptiondetails/getsubscriptions?pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}&pagination_required=${formData?.pagination}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPlanEffect = (formData) => {
  console.log("formData-AddTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/subscription/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPlanEffect = (formData) => {
  console.log("formData-editSubscriptionEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/subscription/updatesubscription/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePlanEffect = (formData) => {
  console.log("formData-DeleteTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/subscription/deletesubscription/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const orgListEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/organizations/list?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addOrgEffect = (formData) => {
  console.log("formData-AddTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/admin/addOrganizations",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editOrgEffect = (formData) => {
  console.log("formData-editSubscriptionEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/admin/updateOrganizations/${formData?.id}`,
    data: formData?.payload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteOrgEffect = (formData) => {
  console.log("formData-DeleteTabletCategoryEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/admin/deleteOrganizations/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSubscriptionPaymentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/subscriptionpayments/insertsubscriptionpayment",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSubscriptionDetailsEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/subscriptiondetails/insertsubscriptiondetails",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
