import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import PackageHeader from "../../Admin/Package/PackageHeader";
import { APP_ROUTES } from "../../../constants/Routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultWard,
  deleteWardInProgress,
  getWardListByBlockInProgress,
  getWardListInProgress,
} from "../../../appRedux/Ward/WardAction";
import { DEFAULT_WARD } from "../../../constants/ActionTypes";

const WardTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const blockData = location.state?.blockData;
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const wardListByBlockData = useSelector(
    (state) => state?.ward?.wardListByBlock
  );
  const addWardData = useSelector((state) => state?.ward?.addWard);
  const editWardResponse = useSelector((state) => state?.ward?.editWard);
  const deleteWard = useSelector((state) => state?.ward?.deleteWard);
  const wardList = wardListByBlockData?.data?.data;
  const totalRecords = wardListByBlockData?.data?.data?.totalRecords;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteWardInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Ward  Name",
      dataIndex: "ward_name",
      key: "ward_name",
    },
    {
      title: "Ward Rooms",
      dataIndex: "ward_status",
      key: "ward_status",
    },
    {
      title: "Ward Incharge",
      dataIndex: "ward_incharge_name",
      key: "ward_incharge_name",
    },
    {
      title: "AC/NON-AC",
      dataIndex: "ward_number",
      key: "ward_number",
      render: (text, record) => {
        const totalRooms = record?.total_rooms;
        const acRooms = record?.ac_room_count;
        const nonAcRooms = record?.non_ac_room_count;
        return `${totalRooms} (${acRooms}-AC/${nonAcRooms}-Non-AC)`;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/rooms`,
                  state: { wardData: record },
                }}
              >
                <ButtonComponent
                  type="primary"
                  label={"Room List"}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/ward/edit`,
                  state: { wardData: record, blockData: blockData },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteFilled />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = {
      pagination: "true",
      pageNo: page,
      pageSize: "10",
      block_id: blockData?.id,
    };
    console.log("payload", payload);
    dispatch(getWardListByBlockInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(wardList);
    } else {
      const newData = wardList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    if (wardListByBlockData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [wardListByBlockData]);
  useEffect(() => {
    const payload = {
      pagination: "true",
      pageNo: "1",
      pageSize: "10",
      block_id: blockData?.id,
    };
    console.log("payload", payload);
    dispatch(getWardListByBlockInProgress(payload));
  }, []);

  useEffect(() => {
    if (addWardData?.success || editWardResponse?.success) {
      dispatch(defaultWard());
    }
  }, [addWardData, editWardResponse]);
  useEffect(() => {
    if (deleteWard?.success) {
      const payload = {
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        block_id: blockData?.id,
      };
      dispatch(getWardListByBlockInProgress(payload));
    }
  }, [deleteWard]);
  return (
    <Card>
      <Row style={{ display: "flex", justifyContent: "end" }} className="mx-20">
        <Link
          to={{
            pathname: `block`,
          }}
        >
          <ButtonComponent type="danger" icon={<ArrowLeftOutlined />} />
        </Link>
      </Row>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.wards"} />} Block - {""}
            {blockData?.block_name}
          </Typography>
        </Col>
        <Col>
          <Link
            to={{
              pathname: `/ward/add`,
              state: { blockId: blockData },
            }}
          >
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newWard"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : wardList}
          rowselector={handleSelectChange}
          itemList={wardList}
          filteredUsers={filteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          setFilteredUsers={setFilteredUsers}
          field={"ward_name"}
          documentTitle="Ward List"
        />
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default WardTable;
