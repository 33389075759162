import React, { useEffect, useState } from "react";
import { Row, Col, message, Popconfirm, Spin } from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined, EditFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import TableComponent from "../../../components/Table/TableComponent";
import moment from "moment";
import { deleteQualificationListInProgress, getQualificationListInProgress } from "../../../appRedux/doctor/actions/Qualification";
import { deleteHolidaysListInProgress, getHolidaysListInProgress, setHolidaysListInSuccess } from "../../../appRedux/doctor/actions/Holiday";
import { deleteUsersListInProgress, getUserListInProgress } from "../../../appRedux/doctor/actions/User";

const UsersTable = () => {
  
 
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      sortDirections: ['ascend', 'descend'],
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName)
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      sortDirections: ['ascend', 'descend'],
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName)
    },
    {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Contact",
        dataIndex: "contact",
      },
      {
        title: "Address 1",
        dataIndex: "address1",
      },
      {
        title: "Address 2",
        dataIndex: "address2",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "State",
        dataIndex: "state",
      },
      {
        title: "Country Code",
        dataIndex: "country_code",
      },
      {
        title: "Role Name",
        dataIndex: "role_name",
      },
      {
        title: "Status",
        dataIndex: "status_name",
      },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        const Delete = () => {
          DeleteUsers(record.id)
    
        };
        const cancel = () => {
          message.error("Delete cancelled")    
            };
        return (
          
          <Row className="table-button">
            <Col className="button-edit">
              <Link to={`/admin/getusers/update/${record.id}`}>
                <ButtonComponent
                  icon={<EditFilled />}
                  type="primary"
                  // onClick={()=> updateDoctor(record.id)}
                />
              </Link>
            </Col>
            <Col className="button-edit">
               <Popconfirm
                title="Are you sure to delete?"
                onConfirm={Delete}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                 <ButtonComponent
                type="danger"
                icon={<DeleteOutlined />}
              ></ButtonComponent>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const[loading,setLoading]=useState(false)
  const usersData = useSelector((state) => state.Users);
  const usersList = usersData?.userslist?.data?.data;
  console.log("Users data",usersList)

  const usersNetwork = useSelector((state) => state);
  console.log("users network",usersNetwork);

  useEffect(()=>{
dispatch(getUserListInProgress())
},[])

  const  deleteUsers  = useSelector((state) => state.Users.deleteuserslist);  
// console.log("delete holiday",deleteHoliday)
  const DeleteUsers = (id) => {
    let data =
    usersList &&
    usersList.filter((filterData) => filterData.id === id);
    dispatch(deleteUsersListInProgress(data[0].id));
    
  };
  useEffect(()=>{
    // if(usersData?.userslist?.success ===false)
    // {
    //   setLoading(true)
    //   setTimeout(()=>{
    //     setLoading(false)
    //   },1000)
      
    // }
   if(usersData?.userslist?.progressing ===true)
    {
      setLoading(true)
    }
    else if(usersData?.userslist?.success ===true){
  setLoading(false)
    }
    else{
      setLoading(false)
    }
  },[usersData?.userslist?.progressing ===true,usersData?.userslist?.success ===true])
  useEffect(() => {
    dispatch(getUserListInProgress());
  }, [deleteUsers]);
  const [filteredUsers, setFilteredUsers] = useState("");
  
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(usersList);
    } else {
      const newData = usersList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  return (
    <Spin size='large' spinning={loading} tip="Please wait a moment" style={{margin:"20px"}}>
  <TableComponent 
  columns={columns} 
  dataSource={filteredUsers?filteredUsers:usersList}
  rowselector={handleSelectChange}
  itemList={usersList}
  filteredUsers={filteredUsers}
  setFilteredUsers={setFilteredUsers}
  field={"firstName"}
  />
  </Spin>
  );
};

export default UsersTable;
