import React, { useEffect } from "react";
import "../../styles/modules/admin.less";
import { Avatar, Card, Col, List, Row, Skeleton, Typography } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import {
  MedicineBoxOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  BookOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import TicketList from "../../components/card/TicketList";
import Meta from "antd/lib/card/Meta";
import Widget from "../../components/Widget";
import { useDispatch, useSelector } from "react-redux";
import { getBillingListInProgress } from "../../appRedux/doctor/actions/addPayment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../constants/Routes";
const { Title, Text } = Typography;
const BillingList = ({ recentAppointments }) => {
  const organization_id = localStorage.getItem("org_id");
  const dispatch = useDispatch();

  const { billingPaymentreducer } = useSelector((state) => state);
  const tableData = billingPaymentreducer?.biilingList?.data?.data;
  useEffect(() => {
    const paylod = {
      organization_id: organization_id,
      pageNo: 1,
    };
    dispatch(getBillingListInProgress(paylod));
  }, []);
  console.log("recentAppointments", recentAppointments);
  return (
    <Widget
      title={<h2 className="h4 gx-text-capitalize gx-ml-2">Recent Billing</h2>}
      styleName="gx-card-ticketlist"
      extra={
        <Link to={APP_ROUTES.BILLINGS}>
          <h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            All Billing
            <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </h5>
        </Link>
      }
    >
      {/* {recentAppointments?.map((ticket, index) => (
        <Card size="small">
          <Meta
            // avatar={<Avatar />}
            title={ticket?.grand_total}
            description={ticket?.patient_name}
          />{" "}
          ticket
        </Card>
      ))} */}

      <List
        itemLayout="horizontal"
        dataSource={recentAppointments}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  size={40}
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#f56a00",
                  }}
                >
                  {index + 1}
                </Avatar>
              }
              title={<Title level={5}>{item.patient_name}</Title>}
              description={
                <>
                  {" "}
                  <Typography className="my-10">
                    <Text type="success">Total Amount</Text> -{" "}
                    <Text strong>{item?.grand_total}</Text>
                  </Typography>{" "}
                  <Typography className="my-10">
                    <Text type="warning">Paid Amount</Text> -{" "}
                    <Text strong>{item?.paid_amount}</Text>
                  </Typography>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Widget>
  );
};

export default BillingList;
