import axios from "axios";
const registerInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});
const OtpSendEffect = (formData) => {
  return registerInstance.request({
    url: "/auth/otpSend",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const VerifyOtpEffect = (formData) => {
  return registerInstance.request({
    url: "/auth/otpVerification",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const UpdatePasswordEffect = (formData) => {
  return registerInstance.request({
    url: "/auth/updatePassword",
    method: "POST",
    data: formData.formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export { OtpSendEffect, VerifyOtpEffect, UpdatePasswordEffect };
