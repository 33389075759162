import axios from "axios";
import moment from "moment";
const token = localStorage.getItem("user_id");
const patient_id = localStorage.getItem("patient_id");
// const id = localStorage.getItem("id");
let pharmacyInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const blockListEffect = (formData) => {
  console.log("formData-TabletCategoryListEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/blocks/blocksbyorganization?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addBlockEffect = (formData) => {
  console.log("formData-addBlockEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/blocks/insert",
    data: formData?.payload,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editBlockEffect = (formData) => {
  console.log("formData-editBlockEffect", formData);
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/blocks/updateblock/${formData?.id}`,
    data: formData?.payload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteBlockEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/blocks/deleteblock/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const wardListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/wards/wardslist?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const wardListbyBlockEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/wards/blockbylist?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}&block_id=${formData?.block_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addWardEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/wards/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editWardEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/wards/update/${formData?.id}`,
    data: formData?.editPayload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteWardEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/wards/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const roomListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/rooms/getroomsbyward?ward_id=${formData?.ward_id}&pagination_required=${formData?.pagination}&pagesize=${formData?.pageSize}&pageNo=${formData?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addRoomEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/rooms/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editRoomEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/rooms/update/${formData?.id}`,
    data: formData?.payload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteRoomEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/rooms/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const allDoctorListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/doctors/list/&pagination_required=${formData?.pagination}&pagesize=${formData?.pageSize}&pageNo=${formData?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const availableRoomListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/roomsbooking/availablerooms?pagesize=${formData?.pageSize}&pageNo=${formData?.pageNo}&pagination_required=${formData?.pagination}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const RoomBookingEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/roomsbooking/add",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const wardCountEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: "/user/roomsdashboard",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const bookedRoomsListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/roomsbooking/getbookedrooms?pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}0&pagination_required==${formData?.pagination}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const totalRoomsListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return pharmacyInstance.request({
    url: `/rooms/getbyorganization?pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}0&pagination_required==${formData?.pagination}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const shiftRoomEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const id = formData?.id;
  return pharmacyInstance.request({
    url: `/roomsbooking/roomshift/${id}`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const dischargeRoomEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const id = formData?.id;
  return pharmacyInstance.request({
    url: `/roomsbooking/roomdischarge/${id}`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
