import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addDesignationInProgress } from "../../../appRedux/doctor/actions/Designation";

import DesignationForm from "./DesignationForm";

const AddDesignation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const designationData = useSelector(
    (state) => state?.designation?.addDesignation
  );
  console.log("designationData", designationData);
  const onFinish = (datas) => {
    console.log("DATA", datas);
    let formData = new URLSearchParams();
    formData.append("Name", datas.Name);
    dispatch(addDesignationInProgress(formData));
  };
  useEffect(() => {
    if (designationData?.success === true) {
      history.push("/designation");
    }
  }, [designationData?.success]);
  return (
    <DesignationForm
      onFinish={onFinish}
      title={"Addnew.desg.label.newdesignation"}
      buttonName={"submit"}
    />
  );
};

export default AddDesignation;
