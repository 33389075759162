import axios from "axios";

const appointmentInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const addDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/disease/insert`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/disease/getlist?pagination_required=${formData?.pagination}&pageNo=${formData?.page}&pagesize=10`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/disease/update/${formData.id}`,
    method: "PUT",
    data: formData.data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/disease/delete/${formData.id}`,
    method: "DELETE",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/answer/insertanswer`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/answer/getlist?pagination_required=${formData?.pagination}&pageNo=${formData?.page}&pagesize=10`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  console.log(formData);
  return appointmentInstance.request({
    url: `/answer/updateanswer/${formData.id}`,
    method: "PUT",
    data: formData.data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/answer/deleteanswer/${formData.id}`,
    method: "DELETE",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getQuestionAnswerByDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return appointmentInstance.request({
    url: `/answer/getanswer?pagination_required=false&disease_id=${formData.id}`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
