import { Col, Form, Row } from 'antd';

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPatientAppointmentByIdInProgress, patientUpdateAppointmentInProgress } from '../../appRedux/Patient/Action/Action';
import ButtonComponent from '../../components/Button/ButtonComponent';
import FormInput from '../../components/Input/FormInput';
import IntlMessages from '../../util/IntlMessages';
import DoctorSelectAppointment from './DoctorSelectBox';
import SelectorsAppointment from './Selectors';

function EditPatientAppointment() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {getAppointmentById} = useSelector((state)=>state.patientAppointment)
    console.log("reducer appointment",getAppointmentById)
    const patientAppList =getAppointmentById.data?.data;
    console.log("patientapplist",patientAppList)
  let {id} = useParams();

    const onFinish = (data) => {
        console.log("Formdata",data)
        let form = new URLSearchParams();
        form.append("patient_id",data.patient_id);
        form.append("doctor_id",data.doctor_id);
        form.append("week_day",data.week_day);
        form.append("start_time",data.start_time);
        form.append("end_time",data.end_time);
        form.append("date",data.date);
        form.append("reason",data.reason);
        form.append("symptom",data.symptom);
        form.append("createdAt","2022-12-30T07:33:04.000Z");
        form.append("id","87f0f6b2-46b4-4450-8603-7e7782f86362");
        form.append("is_cancel","0");
        form.append("updatedAt","2022-12-30T07:33:04.000Z");
        form.append("week_day_id","6");
        form.append("slot_id","8030dec9-380b-4f5b-87f7-98b12ab16ef9")
        dispatch(patientUpdateAppointmentInProgress({form,id}))
    }
    useEffect(() => {
        form.setFieldsValue(patientAppList);
      });
      useEffect(()=>{
        dispatch(getPatientAppointmentByIdInProgress(id))
        console.log("get appointment list by id",id)
      },[]);
  return (
    <div>
           <Form form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        colon={"true"}
        onFinish={onFinish}
        autoComplete="off"
      >
             
                <Row>
                    
                    <Col xs={24} md={12}>
                    <SelectorsAppointment
                      margin="mb-30"
                      label={"Patient"}
                      name={"patient_id"}
                      placeholder={"Enter your name"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your patient",
                        },
                      ]}
                    />
                    </Col>
                    <Col xs={24} md={12}>
                    <DoctorSelectAppointment
                    margin="mb-30"
                    label={"Doctor"}
                    name={"doctor_id"}
                    placeholder={"Enter your doctor"}
                    editMode={true}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Dcotor",
                      },
                    ]}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                        <FormInput type="text" placeholder="weekday" name="week_day" 
                            label={<IntlMessages id={"Addnew.patient.label.week_day"} />}
                            rules={[{required: true, message:(<IntlMessages id={"Addnew.patient.validator.week_day"} />),},]} />
                    </Col>
                    <Col xs={24} md={12}>
                        <FormInput type="text" placeholder="start time" name="start_time" 
                            label={<IntlMessages id={"Addnew.patient.label.start_time"} />}
                            rules={[{required: true, message:(<IntlMessages id={"Addnew.patient.validator.start_time"} />),},]} />
                    </Col>
                    <Col xs={24} md={12}>
                        <FormInput type="text" placeholder="end time" name="end_time" 
                            label={<IntlMessages id={"Addnew.patient.label.end_time"} />}
                            rules={[{required: true, message:(<IntlMessages id={"Addnew.patient.validator.end_time"} />),},]} />
                    </Col>
                  <Col xs={24} md={12}>
                        <FormInput type="text" placeholder="date" name="date" 
                            label={<IntlMessages id={"Addnew.patient.label.date"} />}
                            rules={[{required: true, message:(<IntlMessages id={"Addnew.patient.validator.date"} />),},]} />
                    </Col>
                    <Col xs={24} md={12}>
                        <FormInput placeholder="reason" name="reason" 
                            label={<IntlMessages id={"Addnew.patient.label.reason"} />}
                            rules={[ {required: true, message:(<IntlMessages id={"Addnew.patient.validator.reason"} />),},]}/>
                            
                    </Col>
                    <Col xs={24} md={12}>
                        <FormInput type="text" placeholder="symptom" name="symptom" 
                            label={<IntlMessages id={"Addnew.patient.label.symptom"} />}
                            rules={[{required: true, message:(<IntlMessages id={"Addnew.patient.validator.symptom"} />),},]} />
                    </Col>
                </Row>
                <Row className='space'>
                    <Col className='btn-pos'>
                        <ButtonComponent type="danger" htmlType="reset" label="Cancel" />
                        <ButtonComponent type="primary" htmlType="Submit" label="Submit"/>
                    </Col>
                </Row>
            </Form>
    </div>
  )
}

export default EditPatientAppointment