import React, { useEffect, useRef, useState } from "react";
import { getAllCountriesEffect } from "../../../appRedux/admin/Doctor/sagas/effects/doctorEffect";
import DoctorSelectBox from "./DoctorSelectBox";

const CountrySelector = ({
  placeholder = "Select Country",
  label = "",
  rules = [],
  name,
  editMode,
  margin,
  onChange,
  value,
  setCity,
  defaultValue

}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = async () => {
    try {
      const { data } = await getAllCountriesEffect();
      const countryList = data?.data;
      let options = countryList.map((row) => ({
        label: row.countryName,
        value: row.countryName,
        id: row.id,
      }));
      setOptions(options);
    } catch (error) {
      setOptions([]);
    }
  };

  return (
    <DoctorSelectBox
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
      onChange={onChange}
      defaultValue={defaultValue}

      value={value}
    />
  );
};

export default CountrySelector;
