import axios from "axios";

const profileUserInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const getUserProfileEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const userid = localStorage.getItem("id");
  const orgId = localStorage.getItem("org_id");
  return profileUserInstance.request({
    url: `/user/details/${userid}?organization_id=${orgId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateUserProfileEffect = ({ formData }) => {
  const token = localStorage.getItem("user_id");
  const userid = localStorage.getItem("id");
  return profileUserInstance.request({
    url: `/user/update/${userid}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
