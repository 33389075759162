import {
  DeleteOutlined,
  FormOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import React, { Fragment, useEffect, useState } from "react";
import ButtonComponent from "../../components/Button/ButtonComponent";
import PageHeader from "../../components/PageHeader/PageHeader";

import IntlMessages from "../../util/IntlMessages";
import { data, UpcomingAppoinment } from "./data";
import { Row, Col, Popconfirm, message } from "antd";
import {
  getUpcomingPatientAppoinmentListInProgress,
  patientUpcomingDeleteAppointmentListInProgress,
} from "../../appRedux/Patient/Action/Action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableComponent from "../../components/Table/TableComponent";
import moment from "moment";
const UpcomingAppoinments = () => {
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
    },
    {
      title: "Week Day",
      dataIndex: "week_day",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (start_time) => moment(start_time, ["h:mm A"]).format("hh:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (end_time) => moment(end_time, ["h:mm A"]).format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Symptom",
      dataIndex: "symptom",
    },
    {
      title: "action",
      dataIndex: "action",
      render: (_, record) => {
        const confirm = () => {
          handleDelete(record.id);
          message.success("Deleted successfully");
        };
        const cancel = () => {
          message.success("cancelled");
        };
        return (
          <Row className="appoinment-row">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={8}
              className="appoinment-button"
            >
              <ButtonComponent
                type="primary"
                icon={<HistoryOutlined />}
              ></ButtonComponent>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={8}
              className="appoinment-button"
            >
              {" "}
              <Popconfirm
                title="Delete the task"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <ButtonComponent
                  type="danger"
                  icon={<DeleteOutlined />}
                ></ButtonComponent>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm A"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const dispatch = useDispatch();
  const patientUpcomingAppList = useSelector(
    (state) => state.patientAppointment
  );
  const upcomingPatientList =
    patientUpcomingAppList?.getUpcomingAppointmentList?.data?.data;
  const upcomingPatTotalRecords = upcomingPatientList?.totalRecords;
  const deleteUpcomingAppList = useSelector(
    (state) => state.patientAppointment.deleteUpcomingAppointmentList
  );
  console.log("upcomingPatientList", upcomingPatientList);

  const handleDelete = (id) => {
    let data =
      upcomingPatientList && upcomingPatientList.filter((v) => v.id === id);
    console.log("j", data);
    console.log("delete");
    dispatch(patientUpcomingDeleteAppointmentListInProgress(data[0].id));
  };

  useEffect(() => {
    handlePage(1);
  }, [deleteUpcomingAppList]);
  const handlePage = (page) => {
    dispatch(
      getUpcomingPatientAppoinmentListInProgress({
        page,
        currentDate,
        currentTime,
      })
    );
  };
  return (
    <Fragment>
      <PageHeader
        header={<IntlMessages id={"Addnew.upcomingappoinment"} />}
        accessData="false"
      />
      <TableComponent
        columns={columns}
        dataSource={upcomingPatientList}
        pagination={{
          total: upcomingPatTotalRecords,
          onChange: (page) => handlePage(page),
        }}
        itemList={upcomingPatientList}
      />
    </Fragment>
  );
};

export default UpcomingAppoinments;
