import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Typography, Modal, Tooltip } from "antd";
import {
  LoadingOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import TableComponent from "../../../components/Table/TableComponent";
import {
  deleteStockAdjustmentInProgress,
  stockAdjustmentListInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { access, formatPrice } from "../../../util/utilz";

const StockAdjustmentTable = () => {
  const dispatch = useDispatch();
  const [deleteId, setId] = useState("");
  const [Loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stockAdjustListData = useSelector(
    (state) => state?.pharmacy?.stockAdjustmentList
  );
  const deleteData = useSelector(
    (state) => state?.pharmacy?.deleteStockAdjustment
  );
  const stockAdjustment = stockAdjustListData?.data?.data || [];
  const totalRecords = stockAdjustListData?.data?.totalRecords;
  console.log("totalRecords", totalRecords, stockAdjustListData);
  const handlePage = (page) => {
    const payload = {
      pagination: true,
      pageSize: 10,
      pageNo: page,
    };
    dispatch(stockAdjustmentListInProgress(payload));
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteStockAdjustmentInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Product Name",
      dataIndex: "product_name",
      sortDirections: ["ascend", "descend"],
      key: "product_name",
      sorter: (a, b) => {
        if (a && b && a.product_name && b.product_name) {
          return a.product_name.localeCompare(b.product_name);
        }
        return 0;
      },
    },
    {
      title: "Batch No.",
      dataIndex: "batch_no",
      sortDirections: ["ascend", "descend"],
      key: "batch_no",
      sorter: (a, b) => {
        if (a && b && a.batch_no && b.batch_no) {
          return a.batch_no.localeCompare(b.batch_no);
        }
        return 0;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sortDirections: ["ascend", "descend"],
      key: "quantity",
      sorter: (a, b) => {
        if (a && b && a.quantity && b.quantity) {
          return a.quantity.localeCompare(b.quantity);
        }
        return 0;
      },
    },
    {
      title: "Short Quantity",
      dataIndex: "short_quantity",
      sortDirections: ["ascend", "descend"],
      key: "short_quantity",
      sorter: (a, b) => {
        if (a && b && a.short_quantity && b.short_quantity) {
          return a.short_quantity.localeCompare(b.short_quantity);
        }
        return 0;
      },
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      sortDirections: ["ascend", "descend"],
      key: "purchasePrice",
      render: (text, record, index) => formatPrice(Number(text)),
      sorter: (a, b) => {
        if (a && b && a.purchase_price && b.purchase_price) {
          return a.purchase_price.localeCompare(b.purchase_price);
        }
        return 0;
      },
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      sortDirections: ["ascend", "descend"],
      key: "sale_price",
      render: (text, record, index) => formatPrice(Number(text)),
      sorter: (a, b) => {
        if (a && b && a.sale_price && b.sale_price) {
          return a.sale_price.localeCompare(b.sale_price);
        }
        return 0;
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      sortDirections: ["ascend", "descend"],
      key: "expiry_date",
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      sorter: (a, b) => {
        if (a && b && a.expiry_date && b.expiry_date) {
          return a.expiry_date.localeCompare(b.expiry_date);
        }
        return 0;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      sortDirections: ["ascend", "descend"],
      key: "reason",
      sorter: (a, b) => {
        if (a && b && a.reason && b.reason) {
          return a.reason.localeCompare(b.reason);
        }
        return 0;
      },
    },
    {
      title: "Available Stock",
      dataIndex: "available_stock",
      sortDirections: ["ascend", "descend"],
      key: "available_stock",
      sorter: (a, b) => {
        if (a && b && a.available_stock && b.available_stock) {
          return a.available_stock.localeCompare(b.available_stock);
        }
        return 0;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("stockAdjustment", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  <Link
                    to={{
                      pathname: `/stockAdjustment/edit`,
                      state: { stockAdjustment: record },
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      icon={<EditOutlined />}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("stockAdjustment", "delete") && (
              <Col className="button-edit">
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    console.log("value", value);
    if (value === "all") {
      setFilteredUsers(stockAdjustment);
    } else {
      const newData = stockAdjustment.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payload = { pagination: true, pageSize: 10, pageNo: 1 };
    dispatch(stockAdjustmentListInProgress(payload));
    // dispatch(stockAdjustmentDefaultData());
  }, []);
  useEffect(() => {
    if (stockAdjustListData?.inProgress || deleteData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [stockAdjustListData, deleteData]);
  useEffect(() => {
    if (deleteData?.success) {
      const payload = { pagination: true, pageSize: 10, pageNo: 1 };
      dispatch(stockAdjustmentListInProgress(payload));
    }
  }, [deleteData]);

  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : stockAdjustment}
        rowselector={handleSelectChange}
        itemList={stockAdjustment}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"product_name"}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        documentTitle={"Stock Adjustment List"}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography>Are you sure, you want to delete the Data</Typography>
      </Modal>
    </Spin>
  );
};

export default StockAdjustmentTable;
