import { Row, Col, Typography, Table, DatePicker, Form } from "antd";
import React, { useState } from "react";

import "../../../../styles/modules/pharmacy.less";

import { useHistory } from "react-router-dom";

import ViewHeader from "../../view/ViewHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import IntlMessages from "../../../../util/IntlMessages";
import FormInput from "../../../../components/Input/FormInput";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import moment from "moment";
import { paymentTypeList } from "../../../Doctor/data";
import { disablePastDate } from "../../../../util/utilz";

const RecordPayment = () => {
  const history = useHistory();
  const location = useLocation();
  const salesData = location.state?.salesData;
  const [paymentType, setpaymentType] = useState("");

  const changePaymentType = (value) => {
    setpaymentType(value);
  };
  const onChangeDate = (date, dateString) => {
    const parsedDate = moment(date);
    console.log(parsedDate.format("YYYY-MM-DD"));
  };
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <FormInput
          labelCol={"24"}
          wrapperCol={"24"}
          type="number"
          name={"Paid_amount"}
          label={<IntlMessages id={"Addnew.pharmacy.label.amountPaid"} />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"Medicine.validator.amountPaid"} />,
            },
          ]}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          name={"date"}
          label={<IntlMessages id={"Addnew.pharmacy.label.date"} />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"Date is required"} />,
            },
          ]}
        >
          <FormInput
            type="date"
            placeholder="date"
            name={"date"}
            min={disablePastDate()}
            labelCol={"24"}
            wrapperCol={"24"}
          />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label={<IntlMessages id={"Addnew.pharmacy.label.paymentType"} />}
          name="payment_type"
          rules={[
            {
              required: true,
              message: <IntlMessages id={"Medicine.validator.paymentType"} />,
            },
          ]}
        >
          <FormSelectBox
            placeholder={"Select Payment"}
            editMode="true"
            forFilter="true"
            showSearch="true"
            value={paymentType}
            options={paymentTypeList}
            onChange={changePaymentType}
            className="responsive-select"
          />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <FormInput
          labelCol={"24"}
          wrapperCol={"24"}
          type="text"
          name={"remarks"}
          label={<IntlMessages id={"Addnew.pharmacy.label.remarks"} />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"Medicine.validator.remarks"} />,
            },
          ]}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <ButtonComponent label="Save" htmlType="submit" type="primary" />
      </Col>
    </Row>
  );
};

export default RecordPayment;
