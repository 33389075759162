import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultShiftRoom,
  getBlockListInProgress,
  getBookedRoomsListInProgress,
} from "../../../appRedux/Ward/WardAction";
import { Link } from "react-router-dom/cjs/react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";

const BookedRoomsList = ({ selectedBlockId, onBlockSelect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const bookedRoomData = useSelector((state) => state.ward.bookedRoomsList);
  const shiftRoom = useSelector((state) => state?.ward?.roomShift);
  const bookedRoomList = bookedRoomData?.data?.data;
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(bookedRoomList);
    } else {
      const newData = bookedRoomList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const handleBlockSelect = (record) => {
    if (onBlockSelect) {
      onBlockSelect(record.id);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Check-in By",
      dataIndex: "checkin_by",
      key: "checkin_by",
    },
    {
      title: "Block Name",
      dataIndex: "block_name",
      key: "block_name",
    },
    {
      title: "Ward Name",
      dataIndex: "ward_name",
      key: "ward_name",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "doctor Name",
      dataIndex: "doctor_name",
      key: "doctor_name",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        console.log("record", record);
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/shift-room`,
                  state: { roomData: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  label="Shift Room"
                />
              </Link>
            </Col>
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/discharge-room`,
                  state: { roomData: record },
                }}
              >
                <ButtonComponent type="primary" label="Discharge Room" />
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (shiftRoom?.success) {
      dispatch(defaultShiftRoom());
    }
  }, [shiftRoom]);
  useEffect(() => {
    if (bookedRoomData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookedRoomData]);

  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getBookedRoomsListInProgress(payload));
  }, [dispatch]);

  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.bookedRoomList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : bookedRoomList}
          rowselector={handleSelectChange}
          itemList={bookedRoomList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"block_name"}
          documentTitle={"Booked Room List"}
        />
      </Spin>
    </Card>
  );
};

export default BookedRoomsList;
