import { Row, Col, Typography, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../../../../styles/modules/pharmacy.less";

import { useHistory } from "react-router-dom";

import ViewHeader from "../../view/ViewHeader";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPharmacyProfileInProgress,
  getSalesInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import { salesTypeList } from "../../../Doctor/data";
import SalesFooter from "../SalesFooter";

const ViewSales = ({}) => {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const location = useLocation();
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const [printingDone, setPrintingDone] = useState(false);
  const dispatch = useDispatch();
  const { getSales, pharmacyProfile } = useSelector((state) => state?.pharmacy);
  const salesData = getSales?.data?.data || "";
  const salesLoading = getSales;
  console.log("salesLoading", location.state?.print);
  useEffect(() => {
    dispatch(getSalesInProgress(location.state?.salesData?.id));
  }, [location.state?.salesData?.id]);

  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);
  useEffect(() => {
    if (salesLoading?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [salesLoading]);

  useEffect(() => {
    if (
      location.state?.print &&
      !printingDone &&
      salesData &&
      pharmacyProfile?.data
    ) {
      window.print();
      setPrintingDone(true);
    }
  }, [location.state?.print, printingDone, salesData, pharmacyProfile?.data]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      render: (_, __, index) => index + 1,
    },
    {
      title: "ProductName",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "BatchNo",
      dataIndex: "batch_no",
      key: "batch_no",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
    },

    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unitPrice",
    },
    {
      title: "GST",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Spin
      indicator={antIcon}
      spinning={location.state?.print ? false : Loading}
    >
      <Row justify="center" align="middle">
        <Col xl={23} lg={23} md={23} sm={24} xs={24}>
          <Row gutter={[0, 16]} className="margin-row">
            <ViewHeader
              headerType={"sales"}
              headerData={salesData}
              profileData={pharmacyProfile?.data?.data}
            />

            <Col xl={24} lg={24} md={24} sm={12} xs={24}>
              <Row>
                <Col xs={24} className="display-class">
                  <Col xs={24}>
                    <Typography className="view-list-details text-warp">
                      Doctor : {salesData?.doctor_name}
                    </Typography>
                  </Col>
                </Col>
                <Col xs={12} justify="left">
                  <Col xs={24}>
                    <Typography className="text-warp">
                      Patient : {salesData?.patient_name}
                    </Typography>
                  </Col>
                  <Col xs={24} className="text-warp">
                    <Typography>
                      Mobile : {salesData?.patient_mobile}
                    </Typography>
                  </Col>
                  <Col xs={24}>
                    <Typography className="text-warp">
                      Type :{" "}
                      {
                        salesTypeList.find(
                          (data) =>
                            Number(data.value) ===
                            Number(salesData?.patient_type)
                        )?.label
                      }
                    </Typography>
                  </Col>{" "}
                </Col>
              </Row>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row className="margin-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Typography className="products">Products</Typography>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Table
                    columns={columns}
                    dataSource={salesData?.sales_details || []}
                    pagination={false}
                    className="responsive-table"
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={24} className="footer-total">
              <SalesFooter
                subtotal={salesData?.sub_total}
                total={salesData?.total}
                // discount={salesData?.discount}
                receivedAmount={salesData?.deposite}
                Due={salesData?.due}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default ViewSales;
