import React, { useEffect, useState } from "react";
import { getAllStatesEffect } from "../../../appRedux/admin/Doctor/sagas/effects/doctorEffect";
import DoctorSelectBox from "./DoctorSelectBox";

const StateSelector = ({
  placeholder = "Select State",
  label = "",
  rules = [],
  name,
  editMode,
  margin,
  onChange,
  countryId,
  setCity,
  defaultValue,
  value = "",
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getStatusList(countryId);
    // setCity({});
  }, [countryId]);

  const getStatusList = async (countryId) => {
    try {
      const { data } = await getAllStatesEffect(countryId);
      const countryList = data?.data;
      let options = countryList.map((row) => ({
        label: row.stateName,
        value: row.stateName,
        id: row.id,
      }));

      console.log("state list", options);
      setOptions(options);
    } catch (error) {
      setOptions([]);
    }
  };

  return (
    <DoctorSelectBox
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      
      editMode={editMode}
      onChange={onChange}
      value={value}
     
    />
  );
};

export default StateSelector;
