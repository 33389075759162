import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Spin, Typography } from "antd";

import { DeleteOutlined, EditFilled, LoadingOutlined } from "@ant-design/icons";
import TableComponent from "../../../components/Table/TableComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import pdfFile from "../../../common/PDF/pdf.pdf";
import { useDispatch, useSelector } from "react-redux";
import {
  PatientHeathRecordsDefaultState,
  deletePatientHeathRecordsInProgress,
  getPatientHeathRecordsInProgress,
} from "../../../appRedux/Patient/Action/Action";
const PatientHealthRecordList = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const HealthRecordsList = useSelector(
    (state) => state?.patientAppointment?.getPatientHealthRecords?.data?.data
  );
  const deleteHealthRecord = useSelector(
    (state) => state?.patientAppointment?.deletePatientHealthRecords
  );
  const getHealthRecord = useSelector(
    (state) => state?.patientAppointment?.getPatientHealthRecords
  );
  console.log("HealthRecordsList", HealthRecordsList);
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
    console.log("idd", id);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "PDF Name",
      dataIndex: "fileName",
      sortDirections: ["ascend", "descend"],
      key: "fileName",
      sorter: (a, b) => {
        if (a && b && a.fileName && b.fileName) {
          return a.fileName.localeCompare(b.fileName);
        }
        return 0;
      },
      width: 400,
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      sortDirections: ["ascend", "descend"],
      key: "updatedAt",
      render: (text) => {
        const date = new Date(text);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      },
      sorter: (a, b) => {
        if (a && b && a.updatedAt && b.updatedAt) {
          return a.updatedAt.localeCompare(b.updatedAt);
        }
        return 0;
      },
      width: 200,
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/patient/healthrecord/edit`,
                  state: { healthRecord: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                  // onClick={()=>editPatient(record.id)}
                  label="Edit"
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                label="Delete"
                icon={<DeleteOutlined />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handleOk = () => {
    setIsModalOpen(false);
    console.log("deleteIddeleteIddeleteIddeleteId", deleteId);
    dispatch(deletePatientHeathRecordsInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  // console.log("pdf", pdfFile);
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(HealthRecordsList);
    } else {
      const newData = HealthRecordsList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    dispatch(getPatientHeathRecordsInProgress());
    dispatch(PatientHeathRecordsDefaultState());
  }, []);
  useEffect(() => {
    if (deleteHealthRecord?.success)
      dispatch(getPatientHeathRecordsInProgress());
  }, [deleteHealthRecord]);
  useEffect(() => {
    if (
      deleteHealthRecord?.inProgress === "true" ||
      getHealthRecord?.inProgress === "true"
    )
      setLoading("true");
  }, [deleteHealthRecord, getHealthRecord]);
  console.log(
    " getHealthRecord?.inProgress",
    getHealthRecord?.inProgress === "true"
  );
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : HealthRecordsList}
        // pagination={{
        //   total: totalRecords,
        //   onChange: (page) => handlePage(page),
        // }}
        rowselector={handleSelectChange}
        itemList={HealthRecordsList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"fileName"}
        documentTitle={"Health Records"}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography>Are you sure, you want to delete the Data</Typography>
      </Modal>
    </Spin>
  );
};

export default PatientHealthRecordList;
