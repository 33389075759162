import { Card, Col, Form, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { addQualificationListInProgress } from "../../../appRedux/doctor/actions/Qualification";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";

import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";

const QualificationAddForm = () => {
  const [loading, setLoading] = useState(false);
  const [double, setDouble] = useState(false);
  const [form] = Form.useForm();
  const loginid = localStorage.getItem("id");

  const userid = localStorage.getItem("user_id");
  const history = useHistory();

  const qualificationData = useSelector((state) => state.qualification);
  const qualificationStatus = qualificationData?.addqualificationlist;

  useEffect(() => {
    if (qualificationStatus?.success === true) {
      form.resetFields();
    }
  }, [qualificationStatus?.success === true]);

  useEffect(() => {
    if (qualificationStatus?.progressing === true) {
      setDouble(true);
    } else if (qualificationStatus?.success === true) {
      setDouble(false);
    } else {
      setDouble(false);
    }
  }, [
    qualificationStatus?.progressing === true,
    qualificationStatus?.success === true,
  ]);

  const dispatch = useDispatch();
  const onFinish = (datas) => {
    console.log("DATA", datas);
    let formData = new URLSearchParams();
    formData.append("name", datas.name);
    formData.append("description", datas.description);
    dispatch(addQualificationListInProgress(formData));
  };
  useEffect(() => {
    if (qualificationStatus?.success) {
      history.push("/qualification");
    }
  }, [qualificationStatus?.success]);
  return (
    <Auxiliary>
      <Card>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          onFinish={onFinish}
        >
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Qualification"
                name="name"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.qualification.label.name"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.qualification.validator.name"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Description"
                name="description"
                labelCol={"24"}
                wrapperCol={"24"}
                label={
                  <IntlMessages id={"Addnew.qualification.label.description"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.qualification.validator.description"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
          <Row className="space">
            <Col className="btn-pos">
              <ButtonComponent type="danger" htmlType="reset" label="Clear" />
              <ButtonComponent
                type="primary"
                htmlType="submit"
                label="Save"
                loading={double}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </Auxiliary>
  );
};

export default QualificationAddForm;
