import React, { useState } from "react";

import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
// import ButtonComponent from "../../../components/Button/ButtonComponent";
import { PlusCircleOutlined } from "@ant-design/icons";
// import IntlMessages from "../../../util/IntlMessages";
import "../../styles/modules/billingPackages.less";

import Auxiliary from "../../util/Auxiliary";
import { APP_ROUTES } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";
import ButtonComponent from "../../components/Button/ButtonComponent";
import SubPackageModel from "./SubPackageModel";
import { access } from "../../util/utilz";

const SubPackagesHeader = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalSubmit = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  return (
    <Auxiliary>
      <Row className="bot">
        <Col span={12}>
          <h1 className="text">
            {<IntlMessages id={"subcategories.package"} />}
          </h1>
        </Col>

        {access("billingSubPackage", "add") && (
          <Col span={12} className="btn-main">
            {/* <Link to={APP_ROUTES.ADDBILLINGS}> */}
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.package"} />}
              onClick={() => {
                showModal();
              }}
            />
            {/* </Link> */}
          </Col>
        )}
        <SubPackageModel
          isVisible={isModalVisible}
          onCancel={handleCancel}
          onSubmit={handleModalSubmit}
        />
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default SubPackagesHeader;
