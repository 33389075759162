import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography, DatePicker } from "antd";
import "../../../styles/modules/pharmacy.less";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import {
  categoryList,
  departmentList,
  gstList,
  itemTransferList,
  pharmacyItemTransferList,
  productTypeList,
  productUnitList,
} from "../../Doctor/data";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ItemTransferProductForm from "./ItemTranferProductForm";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import {
  addItemTransferInProgress,
  editItemTransferInProgress,
  getDepartmentListInProgress,
  getPharmacyListInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
import { currentDate, disablePastDate } from "../../../util/utilz";

const AddItemTransfer = ({
  editItemTransfer,
  medicineListData,
  viewData,
  editItem,
  itemTransferData,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [key, setKey] = useState();
  const [department, setDepartment] = useState();
  const [itemTranserTo, setItemTransferTo] = useState("");
  const [pharmacy, setPharmacy] = useState();
  const [totalItems, setTotalItems] = useState("0");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const additemTransferData = useSelector(
    (state) => state.pharmacy?.addItemTransfer
  );
  const edititemTransferData = useSelector(
    (state) => state.pharmacy?.editItemTransfer
  );
  console.log("edititemTransferDataedititemTransferData", edititemTransferData);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    console.log("datas", datas);
    const dataArray = Object.values(datas.item_transfer_details).filter(
      (value) => value != null
    );

    const itemTransferDetailsArray = Object.values(dataArray).map((item) => ({
      batch_no: item.batch_no,
      tablet_id: item.tablet_id,
      quanity: item.quantity,
      expiry_date: item.expiry_date,
      purchase_code: item?.purchase_code,
    }));
    const formData = {
      department_id: datas?.department,
      person_name: datas?.person_name,
      person_contact: datas?.person_contact,
      description: datas?.description,
      date: moment(datas?.date).format("YYYY-MM-DD"),
      item_transfer_to: datas?.item_transfer_to === "Hospital" ? "1" : "2",
      total_items: totalItems,
      total_quantity: totalQuantity,
      ...(datas?.item_transfer_to !== "Hospital" && {
        select_pharmacy: datas?.select_pharmacy,
      }),
      item_transfer_details: itemTransferDetailsArray.map((item) => ({
        ...item,
        batch_no: item.batch_no.replace(/\s*\([^)]*\)/, ""),
      })),
    };

    console.log("formData", formData);
    if (editItemTransfer) {
      dispatch(
        editItemTransferInProgress({ formData, id: itemTransferData?.id })
      );
    } else {
      dispatch(addItemTransferInProgress(formData));
    }
    if (additemTransferData?.inProgress || edititemTransferData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (additemTransferData?.error || edititemTransferData?.error) {
      setLoading(false);
    }
  }, [additemTransferData, edititemTransferData]);
  const departmentData = useSelector(
    (state) => state?.pharmacy?.departmentList
  );
  const departmentList = departmentData?.data?.data || [];
  const pharmacyListData = useSelector(
    (state) => state?.pharmacy?.pharmacyList
  );
  const pharmacyList = pharmacyListData?.data?.data || [];
  console.log("pharmacyList", pharmacyList);
  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const changeDepartment = (value) => {
    setDepartment(value);
  };
  const changeItemTranserTo = (value) => {
    console.log("value", value);
    setItemTransferTo(value);
  };
  const changePharmacy = (value) => {
    setPharmacy(value);
  };

  useEffect(() => {
    console.log(
      "moment(itemTransferData?.date)",
      itemTransferData,
      itemTransferData?.item_transfer_to
    );
    if (editItemTransfer) {
      form.setFieldValue("date", itemTransferData?.date);
      form.setFieldValue("department", itemTransferData?.department_id);
      form.setFieldValue("person_name", itemTransferData?.person_name);
      form.setFieldValue("person_contact", itemTransferData?.person_contact);
      form.setFieldValue("description", itemTransferData?.description);

      // itemTransferData?.item_transfer_to === 1
      //   ?
      //   :
      if (itemTransferData?.item_transfer_to === 2) {
        changeItemTranserTo("Pharmacy");
        form.setFieldValue("item_transfer_to", "Pharmacy");
      } else {
        changeItemTranserTo("Hospital");
        form.setFieldValue("item_transfer_to", "Hospital");
      }
      form.setFieldValue("select_pharmacy", itemTransferData?.select_pharmacy);
    }
  }, [editItemTransfer, itemTransferData]);
  useEffect(() => {
    dispatch(getDepartmentListInProgress());
  }, []);
  console.log("additemTransferData?.success", additemTransferData?.success);
  useEffect(() => {
    if (additemTransferData?.success || edititemTransferData?.success) {
      history.push("/itemtransfer");
    }
  }, [additemTransferData, edititemTransferData]);
  useEffect(() => {
    if (!editItemTransfer) {
      form.setFieldValue("date", currentDate);
    }
  }, []);
  useEffect(() => {
    const formData = {
      pagination: false,
      pageNo: 1,
      pageSize: 1,
      orgId: localStorage.getItem("org_id"),
    };

    dispatch(getPharmacyListInProgress(formData));
  }, [itemTranserTo === "Pharmacy"]);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editItemTransfer ? (
                    <IntlMessages id={"label.editItemTransfer"} />
                  ) : (
                    <IntlMessages id={"label.addItemTransfer"} />
                  )}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.department"} />
                  }
                  name="department"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.department"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select Department"
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    value={department}
                    options={[
                      ...departmentList?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      })),
                    ]}
                    onChange={changeDepartment}
                    className="responsive-select"
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"person_name"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.personName"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.personName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"person_contact"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.personContact"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.personContact"}
                        />
                      ),
                    },
                    {
                      pattern: /^[6789][0-9]{9}$/,
                      message: "Please enter a valid 10-digit mobile number!",
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name={"date"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.date"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Medicine.validator.add_date"} />
                      ),
                    },
                  ]}
                >
                  <FormInput
                    type="date"
                    placeholder="date"
                    name="date"
                    readOnly={"true"}
                    min={disablePastDate()}
                    defaultValue={currentDate}
                    labelCol={"24"}
                    wrapperCol={"24"}
                    // disabled={"true"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.itemTransferTo"} />
                  }
                  name="item_transfer_to"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.itemTransferTo"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select  Item Transfer To"
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    value={itemTranserTo}
                    options={itemTransferList}
                    onChange={changeItemTranserTo}
                    className="responsive-select"
                  />
                </Form.Item>
              </Col>
              {itemTranserTo === "Pharmacy" && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={
                      <IntlMessages
                        id={"Addnew.pharmacy.label.selectPharmacy"}
                      />
                    }
                    name="select_pharmacy"
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.pharmacy"}
                          />
                        ),
                      },
                    ]}
                  >
                    <FormSelectBox
                      placeholder="Select Pharmacy"
                      editMode="true"
                      forFilter="true"
                      showSearch="true"
                      value={pharmacy}
                      options={[
                        ...pharmacyList?.map((item) => ({
                          label: item.firstName,
                          value: item.id,
                        })),
                      ]}
                      onChange={changePharmacy}
                      className="responsive-select"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"description"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.description"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.description"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  <IntlMessages id={"label.Items"} />
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  <ItemTransferProductForm
                    form={form}
                    editItemTransferData={itemTransferData}
                    editItemTransfer={editItemTransfer}
                    setTotalItems={setTotalItems}
                    setTotalQuantity={setTotalQuantity}
                  />
                </Typography>
              </Col>
            </Row>
            <Row className="margin-row" justify="end">
              <Col xl={15} xs={24} lg={24} md={24} sm={24}>
                <Row gutter={[4, 4]} className="margin-row" justify="end">
                  <Col xs={24} lg={24} md={24} sm={24} xl={24}>
                    <FormInput
                      wrapperCol={24}
                      labelCol={10}
                      value={totalItems}
                      type="number"
                      min={0}
                      label="Total Items"
                      // onChange={(e) => OnChangeItems(e.target.value)}
                    />
                  </Col>
                  <Col xs={24} lg={24} md={24} sm={24} xl={24}>
                    <FormInput
                      wrapperCol={24}
                      labelCol={10}
                      label="Total quantity"
                      value={totalQuantity}
                      type="number"
                      min={0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.ITEMTRANSFER}>
                  <ButtonComponent
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>
                {!viewData && (
                  <ButtonComponent
                    icon={<CreditCardOutlined />}
                    label={editItem ? "Save" : "Submit"}
                    htmlType="submit"
                    type="primary"
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddItemTransfer;
