import {
  APPOINMENT_RESCHEDULE_ERROR,
  APPOINMENT_RESCHEDULE_PROGRESS,
  APPOINMENT_RESCHEDULE_SUCCESS,
  GET_APPOINTMENTS_LIST_IN_ERROR,
  GET_APPOINTMENTS_LIST_IN_PROGRESS,
  GET_APPOINTMENTS_LIST_IN_SUCCESS,
  GET_DOCTOR_APPOINTMENT_LIST_IN_ERROR,
  GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS,
  GET_DOCTOR_APPOINTMENT_LIST_IN_SUCCESS,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_ERROR,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  appointmentlist: { ...defaultStatus },
  doctorappointmentlist: { ...defaultStatus },
  doctorupcomingappointmentlist: { ...defaultStatus },
  reSchedule: { ...defaultStatus },
};
const appointmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_APPOINTMENTS_LIST_IN_PROGRESS:
      return {
        ...state,
        appointmentlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_APPOINTMENTS_LIST_IN_SUCCESS:
      console.log("Payload appointment", payload);

      return {
        ...state,
        appointmentlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_APPOINTMENTS_LIST_IN_ERROR:
      return {
        ...state,
        appointmentlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    // DOCTOR APPOINTMENT

    case GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        doctorappointmentlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_DOCTOR_APPOINTMENT_LIST_IN_SUCCESS:
      console.log("Payload appointment doctor", payload);

      return {
        ...state,
        doctorappointmentlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_APPOINTMENT_LIST_IN_ERROR:
      return {
        ...state,
        doctorappointmentlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    // DOCTOR Upcoming APPOINTMENT

    case GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS:
      return {
        ...state,
        doctorupcomingappointmentlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_DOCTOR_APPOINTMENT_UPCOMING_IN_SUCCESS:
      console.log("Payload appointment upcoming doctor", payload);

      return {
        ...state,
        doctorupcomingappointmentlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_APPOINTMENT_UPCOMING_IN_ERROR:
      return {
        ...state,
        doctorupcomingappointmentlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    // RESCHEDULE
    case APPOINMENT_RESCHEDULE_PROGRESS:
      return {
        ...state,
        reSchedule: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case APPOINMENT_RESCHEDULE_SUCCESS:
      return {
        ...state,
        reSchedule: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case APPOINMENT_RESCHEDULE_ERROR:
      return {
        ...state,
        reSchedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default appointmentReducer;
