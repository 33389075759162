import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../util/utilz";

const BillingPaymentsInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});

export const AddBillingPaymentsEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: "/billingPayment/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getBillingsListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  console.log("token and auth", token);
  return BillingPaymentsInstance.request({
    url: `/billing/getAllBillByOrganization?pagination_required=true&organization_id=${payload?.organization_id}&pageNo=${payload?.pageNo} `,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addBillingEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateBillingEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/update`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteBillingEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/delete/${formData.id}`,
    method: "DELETE",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getBillingsByInvoiceEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/getBillByInvoice?invoice_no=${payload}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getBillingsByPatientEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/getAllBillByPatientId?patient_id=${payload}&pagination_required=false`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDueByPatientEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/getDueAmount?patient_id=${payload}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const undoBillEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return BillingPaymentsInstance.request({
    url: `/billing/undoBilling/${payload}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
