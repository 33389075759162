import React, { useEffect } from "react";
import { Card, Col, Form, Input, Row, Typography } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import { useDispatch } from "react-redux";
import { changePasswordInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (datas) => {
    console.log("purchase-Request-Data", datas);
    const formData = {
      current_password: datas?.old_password,
      new_password: datas?.new_password,
    };
    dispatch(changePasswordInProgress(formData));
  };

  return (
    <Card>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="my-2">
          <Typography className="header-tag">
            {<IntlMessages id={"label.changePassword"} />}
          </Typography>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  password="true"
                  name={"old_password"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.enterPassword"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.password"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="password"
                  password="true"
                  name={"new_password"}
                  label={
                    <IntlMessages
                      id={"Addnew.pharmacy.label.confirmPassword"}
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.password"}
                        />
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("old_password") != value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.confirmPassword"}
                          />
                        );
                      },
                    }),
                  ]}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col xl={12} align="right" className="button-alignment-pharmacy">
                <ButtonComponent
                  label="Change Password"
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default ChangePassword;
