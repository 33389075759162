import React, { useEffect, useState } from "react";

import { Card, Col, Divider, Form, Row } from "antd";
import IntlMessages from "../../util/IntlMessages";
import BillingPackageForm from "./component/BillingPackageForm";
import Auxiliary from "../../util/Auxiliary";
import { useDispatch } from "react-redux";
import { addPackagesInProgress } from "../../appRedux/doctor/actions/billingPackage";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../constants/Routes";

const BillingPackageAddNew = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [id, setId] = useState(false);
  const history = useHistory();
  const { billingPackages } = useSelector((state) => state);
  const organization_id = localStorage.getItem("org_id");
  const onFinish = (values) => {
    console.log("valuessubPackages", values?.subPackages)
    const subPackagesArray = values?.subPackages
      ? Object.keys(values?.subPackages)
          .map((key) => values.subPackages[key])
          .filter(
            (subPackage) =>
              subPackage !== null && Object.keys(subPackage).length > 0
          )
          .map((key) => ({
            ...key,
            sub_package_id: key?.package_name,
            status: 1,
          }))
      : [];
      console.log("subPackagesArray",subPackagesArray)
    const updatedValues = [
      {
        ...values,
        organization_id: organization_id,
        subPackages: subPackagesArray,
      },
    ];
    dispatch(addPackagesInProgress({ package_details: updatedValues }));
    setNavigate(true);
  };
  useEffect(() => {
    if (billingPackages?.addPackages?.success && navigate) {
      history.push(APP_ROUTES.BILLINGPACKAGES);
      setNavigate(false);
    }
  }, [billingPackages?.addPackages?.success && navigate]);
  return (
    <Auxiliary>
      <Card>
        <Row>
          <Col span={24}>
            <h1 className="text">
              {<IntlMessages id={"Addnew.billingpackage.label.addpackage"} />}
            </h1>
          </Col>
          <Divider />
        </Row>
        <BillingPackageForm onFinish={onFinish} form={form} />
      </Card>
    </Auxiliary>
  );
};

export default BillingPackageAddNew;
