import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Tag, Tooltip, Typography } from "antd";
import {
  LoadingOutlined,
  PrinterOutlined,
  DeleteFilled,
  EyeFilled,
  EditFilled,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import {
  patientDeleteListInProgress,
  patientListInProgress,
} from "../../../../appRedux/admin/patient/action/action";
import TableComponent from "../../../../components/Table/TableComponent";
import {
  defaultPurchaseReturn,
  deletePurchaseReturnInProgress,
  getPurchaseReturnListInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import moment from "moment";
import { access } from "../../../../util/utilz";

const PurchaseReturnTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const organization_id = localStorage.getItem("org_id");
  const purchaseReturnListData = useSelector(
    (state) => state.pharmacy?.purchaseReturnList
  );
  const deletePurchaseReturnData = useSelector(
    (state) => state.pharmacy?.deletePurchaseReturn
  );
  const purchaseReturnList = purchaseReturnListData.data?.data;

  const totalRecords = purchaseReturnListData.data?.totalRecords;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
    console.log("idd", id);
  };
  const columns = [
    {
      title: "Purchase Return Code",
      dataIndex: "purchase_return_code",
    },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
      sortDirections: ["ascend", "descend"],
      key: "createdAt",
      sorter: (a, b) => {
        if (a && b && a.createdAt && b.createdAt) {
          return a.createdAt.localeCompare(b.createdAt);
        }
        return 0;
      },
    },

    {
      title: "Supplier Company Name",
      dataIndex: "company_name",
      sortDirections: ["ascend", "descend"],
      key: "email",
      sorter: (a, b) => {
        if (a && b && a.company_name && b.company_name) {
          return a.company_name.localeCompare(b.company_name);
        }
        return 0;
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      sortDirections: ["ascend", "descend"],
      key: "mobile",
      sorter: (a, b) => {
        if (a && b && a.mobile && b.mobile) {
          return a.mobile.localeCompare(b.mobile);
        }
        return 0;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      sortDirections: ["ascend", "descend"],
      key: "total",
      sorter: (a, b) => {
        if (a && b && a.total && b.total) {
          return a.total.localeCompare(b.total);
        }
        return 0;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sortDirections: ["ascend", "descend"],
    //   key: "status",
    //   render: (status) => (
    //     <Tag className="sharp-edge-button" color="#87d068">
    //       {status}
    //     </Tag>
    //   ),

    //   sorter: (a, b) => {
    //     if (a && b && a.status && b.status) {
    //       return a.status.localeCompare(b.status);
    //     }
    //     return 0;
    //   },
    // },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"View"}>
                {" "}
                <Link
                  to={{
                    pathname: `/purchase/return/view`,
                    state: { purchaseReturnData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeFilled />}
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("purchaseReturn", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/purchase/return/edit`,
                      state: { supplierData: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditFilled />}

                      // onClick={()=>editPatient(record.id)}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}

            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Print"}>
                {" "}
                <Link
                  to={{
                    pathname: "/purchase/return/print",
                    state: { purchaseReturnData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<PrinterOutlined />}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("purchaseReturn", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteFilled />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deletePurchaseReturnInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = {
      pagination_required: "true",
      organization_id: organization_id,
      pageNo: page,
      pagesize: 10,
    };
    dispatch(getPurchaseReturnListInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(purchaseReturnList);
    } else {
      const newData = purchaseReturnList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    const formData = {
      pagination_required: "true",
      organization_id: organization_id,
      pageNo: 1,
      pagesize: 10,
    };
    dispatch(getPurchaseReturnListInProgress(formData));
    dispatch(defaultPurchaseReturn());
    if (purchaseReturnListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (purchaseReturnListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [purchaseReturnListData]);
  useEffect(() => {
    if (deletePurchaseReturnData?.success) {
      const formData = {
        pagination_required: "true",
        organization_id: organization_id,
        pageNo: 1,
        pagesize: 10,
      };
      dispatch(getPurchaseReturnListInProgress(formData));
    }
  }, [deletePurchaseReturnData]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      {access("purchaseReturn", "view") && (
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : purchaseReturnList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          rowselector={handleSelectChange}
          itemList={purchaseReturnList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"purchase_return_code"}
          documentTitle={"Purchase Return List"}
        />
      )}

      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default PurchaseReturnTable;
