import React from "react";
import { Card } from "antd";

import Auxiliary from "../../util/Auxiliary";
import TeamMemberHeader from "./components/TeamMemberHeader";
import TeamMemberForm from "./components/TeamMemberForm";
import TeamMemberTable from "./components/TeamMemberTable";

const TeamMember = () => {
  return (
    <Auxiliary>
      <Card>
        <TeamMemberHeader />
        <TeamMemberTable/>
      </Card>
    </Auxiliary>
  );
};
export default TeamMember;
