import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal } from "antd";
import {
  LoadingOutlined,
  PrinterFilled,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import PackageHeader from "../../Admin/Package/PackageHeader";
import { APP_ROUTES } from "../../../constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultBlock,
  deleteBlockInProgress,
  getBlockListInProgress,
} from "../../../appRedux/Ward/WardAction";

const BlockTable = () => {
  const dispatch = useDispatch();
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");

  const blockListData = useSelector((state) => state?.ward?.blockList);
  const addBlockData = useSelector((state) => state?.ward?.addBlock);
  const editBlockResponse = useSelector((state) => state?.ward?.editBlock);
  const deleteBlock = useSelector((state) => state?.ward?.deleteBlock);
  const blockList = blockListData?.data?.data;
  const totalRecords = blockListData?.data?.data?.totalRecords;
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
    console.log("idd", id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteBlockInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Block  Name",
      dataIndex: "block_name",
      key: "block_name",
    },
    {
      title: "Block Status",
      dataIndex: "block_status",
      key: "block_status",
      render: (text, record) => {
        return record.block_status === "1" ? "Active" : "Inactive";
      },
    },
    {
      title: "Block Number",
      dataIndex: "block_number",
      key: "block_number",
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => {
        console.log("record", record);
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/ward`,
                  state: { blockData: record },
                }}
              >
                <ButtonComponent
                  type="primary"
                  label={"Ward List"}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/block/edit`,
                  state: { blockData: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteFilled />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(blockList);
    } else {
      const newData = blockList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    if (blockListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [blockListData]);
  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  }, []);
  console.log("blockListData", blockListData);
  useEffect(() => {
    if (addBlockData?.success || editBlockResponse?.success) {
      dispatch(defaultBlock());
    }
  }, [addBlockData, editBlockResponse]);
  useEffect(() => {
    if (deleteBlock?.success) {
      const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
      dispatch(getBlockListInProgress(payload));
    }
  }, [deleteBlock]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.blocks"} />}
          </Typography>
        </Col>
        <Col>
          <Link to={APP_ROUTES.ADD_BLOCK}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newBlock"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : blockList}
          rowselector={handleSelectChange}
          itemList={blockList}
          filteredUsers={filteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          setFilteredUsers={setFilteredUsers}
          field={"hospital_name"}
          documentTitle="Block List"
        />
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default BlockTable;
