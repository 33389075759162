import React from "react";

import { Card, Col, DatePicker, Form, Row } from "antd";
import { Link, useHistory } from "react-router-dom";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import NumberData from "../../../components/NumberInput/InputNumber";

const TabletsForm = ({
  onFinish,
  Button,
  form,
  title,
  HeaderButton,
  icon,
  type = "primary",
}) => {
  const history = useHistory();
  return (
    <Auxiliary>
      <Card>
        <Row>
          <Col xs={12} className="add-tablet-head">
            <h1>{<IntlMessages id={title} />}</h1>
          </Col>
          <Col xs={12} className="btn-back-cancle">
            <ButtonComponent
              type={type}
              onClick={() => {
                history.goBack();
              }}
              rightIcon={icon}
              label={HeaderButton}
            />
          </Col>
        </Row>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          onFinish={onFinish}
        >
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Medicine Name"
                name="medicine_name"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.MedicineName"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.MedicineName"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="category"
                name="category"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.category"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.category"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                style={{
                  width: "100%",
                }}
                placeholder="price"
                name="price"
                label={<IntlMessages id={"Medicine.label.price"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.price"} />,
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="s price"
                name="s_price"
                label={<IntlMessages id={"Medicine.label.s_price"} />}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.s_price"} />,
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="Quantity"
                name="quantity"
                label={<IntlMessages id={"Medicine.label.quantity"} />}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.quantity"} />
                    ),
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="Minimum Order Quantity"
                name="minimum_order_quantity"
                label={
                  <IntlMessages id={"Medicine.label.minimum_order_quantity"} />
                }
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Medicine.validator.minimum_order_quantity"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Effects"
                name="effects"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.effects"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.effects"} />,
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="box"
                name="box"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.box"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.box"} />,
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Generic"
                name="generic"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.generic"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.generic"} />,
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Company"
                name="company"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.company"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.company"} />,
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="add_date"
                label={<IntlMessages id={"Medicine.label.add_date"} />}
                rules={[
                  {
                    type: "object",
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.add_date"} />
                    ),
                  },
                ]}
              >
                <DatePicker
                  className="calender-responsive"
                  placeholder="Add Date"
                  format={"D/MM/YYYY"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="e_date"
                label={<IntlMessages id={"Medicine.label.e_date"} />}
                rules={[
                  {
                    type: "object",
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.e_date"} />,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("add_date") < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Expired Date should be greater than add date!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <DatePicker
                  className="calender-responsive"
                  placeholder="E Date"
                  format={"D/MM/YYYY"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="Gst Value"
                name="gst_value"
                label={<IntlMessages id={"Medicine.label.gst_value"} />}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.gst_value"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="GST Percent"
                name="gst_percent"
                label={<IntlMessages id={"Medicine.label.gst_percent"} />}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.gst_percent"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Medicine Type"
                name="medicine_type"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.medicine_type"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.medicine_type"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="Reorder Quantity"
                name="reorder_quantity"
                label={<IntlMessages id={"Medicine.label.reorder_quantity"} />}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Medicine.validator.reorder_quantity"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Product Code"
                name="product_code"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.product_code"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.product_code"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Hsn Code"
                name="hsn_code"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.hsn_code"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.hsn_code"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NumberData
                placeholder="Product Unit"
                name="product_unit"
                label={<IntlMessages id={"Medicine.label.product_unit"} />}
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.product_unit"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Product Type"
                name="product_type"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Medicine.label.product_type"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.product_type"} />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
          <Row className="space">
            <Col className="btn-pos">
              <ButtonComponent
                type="primary"
                htmlType="submit"
                label={Button}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </Auxiliary>
  );
};

export default TabletsForm;
