import axios from "axios";
const authInstance = axios.create({
  baseURL: `http://3.113.123.43:5000`,
});
const userLoginEffect = (formData) => {
  const LoginData = authInstance.request({
    url: "/auth/login",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return LoginData;
};
const RegisterEffect = (formData) => {
  const SigninData = authInstance.request({
    url: "/auth/register",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });

  return SigninData;
};
export { userLoginEffect, RegisterEffect };
